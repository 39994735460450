/* Theme vars */
@roundedCorners: 6px;
@panelPaddingMobile: 25px;
@panelPadding: 35px;
@panelBodyPadding: 23px;
@fieldHeight: 40px;
@defaultTextSize: 16px;
@guidePanelHeadingFontSize: 21px;
@panelHeadingFontSize: 20px;
@subPanelHeadingFontSize: 18px;
@miniPanelHeadingFontSize: 21px;
@panelHeadingBottomPadding: 10px;
@panelHeadingPaddingY: 21px;
@miniPanelYPadding: 20px;

/* Font */
// Open Sans 400 500 600 700 800
@font-face {
  font-family: 'Open Sans';
  font-weight: 800;
  src: local('Open Sans ExtraBold'),
       url('/assets/fonts/opensans/opensans-extrabold-webfont.woff2') format('woff2'),
       url('/assets/fonts/opensans/opensans-extrabold-webfont.woff') format('woff');
}
@font-face {
  font-family: 'Open Sans';
  font-weight: 700;
  src: local('Open Sans Bold'),
       url('/assets/fonts/opensans/opensans-bold-webfont.woff2') format('woff2'),
       url('/assets/fonts/opensans/opensans-bold-webfont.woff') format('woff');
}
@font-face {
  font-family: 'Open Sans';
  font-weight: 600;
  src: local('Open Sans SemiBold'),
       url('/assets/fonts/opensans/opensans-semibold-webfont.woff2') format('woff2'),
       url('/assets/fonts/opensans/opensans-semibold-webfont.woff') format('woff');
}
@font-face {
  font-family: 'Open Sans';
  font-weight: 500;
  src: local('Open Sans Medium'),
       url('/assets/fonts/opensans/opensans-medium-webfont.woff2') format('woff2'),
       url('/assets/fonts/opensans/opensans-medium-webfont.woff') format('woff');
}
@font-face {
  font-family: 'Open Sans';
  font-weight: 400;
  src: local('Open Sans'),
       url('/assets/fonts/opensans/opensans-regular-webfont.woff2') format('woff2'),
       url('/assets/fonts/opensans/opensans-regular-webfont.woff') format('woff');
}

/* Colors */
@import "../default/_themes-colors.less";

/* Theme partials */
@import "./_service-bar.less";
@import "./_dashboard.less";
@import "./_application-steps.less";
@import "./_form-components.less";
@import "./_part-b.less";
@import "./_inspector.less";
@import "./_finished-service.less";
@import "./_business-list.less";

/* Common */
body {
  &.modern-theme {
    background-color: @pageBgColor;
    font-family: 'Open Sans', sans-serif;
    padding-inline-end: 0;
    padding-right: initial !important;

    .border-none {
      border: none;
    }
  }
}