/* App status colors */
@appStatusDraft: @darkTextColor50;
@appStatusDraft_a: #5B5B5B;
@appStatusPending: rgba(70, 120, 170, 1);
@appStatusProcessed: rgba(56, 159, 143, 1);
@appStatusAction: #FFA076;
@appStatusRejected: rgba(232, 79, 115, 1);

body {
  &.modern-theme {
    .warning-list {
      &[hidden] {
        display: flex;
        flex-direction: column;
        background-color: @errorColor30;

        &.modern-warning-list {
          background-color: @accentColor10;
          box-shadow: -1200px 0 0 @accentColor10, -2400px 0 0 @accentColor10, 1200px 0 0 @accentColor10, 2400px 0 0 @accentColor10;
          padding: 20px 0;
          
          li {
            color: @accentColor;
            font-weight: 600;
            width: 100%;
            display: flex;
            align-items: center;

            &.several-msg {
              display: block;
              position: relative;

              strong {
                margin-inline-start: 6px;
                margin-inline-end: 6px;
                font-weight: 600;

                &:hover {
                  text-decoration: underline;
                }
              }
              span {
                &.close {
                  position: absolute;
                  inset-inline-end: 0;
                  top: calc(50% - 11px);
                }
              }
            }
            &:last-of-type {
              margin-bottom: 0 !important;
            }
            .close {
              margin-inline-start: auto;
            }
          }

          @media screen and (max-width: 1200px) {
            padding-inline-start: 2.4rem;
            padding-inline-end: 2.4rem;
          }
        }
      }
    }
    .management_bar {
      position: relative;
      z-index: 999;

      &.white-bg {
        background-color: @pageBgColor;
      }
      .contain-lg {
        ul {
          &.nav {
            li {
              &.nav-item {
                a {
                  &.nav-link {
                    font-size: 21px;
                    color: @accentColor40;
                    font-weight: 600;
                    padding-inline-start: 35px;
                    padding-inline-end: 35px;
                    border-top: none;
                    border-top-left-radius: @roundedCorners;
                    border-top-right-radius: @roundedCorners;

                    &.active {
                      background-color: @whiteColor;
                      box-shadow: 0px -6px 20px @black2Color;
                      color: @accentColor;
                    }
                    small {
                      opacity: 1;
                      color: @accentColor40;
                      font-size: 16px;
                      font-weight: 600;
                      margin-inline-start: 10px;

                      @media screen and (max-width: 640px) {
                        position: absolute;
                        inset-inline-end: 0;
                        top: -12px;
                        background: #e7e9ed;
                        padding: 6px;
                        border-radius: 12px;
                      }
                    }

                    @media screen and (max-width: 840px) {
                      padding: 1.4rem;

                      span {
                        font-size: 16px;
                      }
                      small {
                        font-size: 14px;
                      }
                    }
                  }
                }
                &.dropdown {
                  display: none;
                }
              }
            }
          }
        }
      }
      @media screen and (max-width: 380px) {
        margin-inline-start: 1.4rem;
        margin-inline-end: 1.4rem;
      }
      &.document-modal-open {
        & ~ #base {
          z-index: 99999;
        }
      }
    }
    #header {
      .headerbar {
        .headerbar-right {
          display: none;
          
          &.modern-theme-headerbar-right {
            &[hidden] {
              display: flex;

              .headerbar-logout {
                display: flex;
                gap: 16px;
                text-decoration: none;
                color: @accentColor;
                border-radius: @roundedCorners;
                transition-duration: 0.1s;

                i {
                  font-size: 23px;
                }
                span {
                  font-size: 13px;
                  font-weight: 500;
                }
                &:hover {
                  background-color: @accentColor10;
                  box-shadow: 0 0 0 10px @accentColor10;
                }
              }
            }
          }
        }
      }
    }
    #base {
      background-color: @pageBgColor;
      position: relative;
      z-index: 999;

      #content {
        .default-theme-demo-heading {
          .demo-mode-heading {
            background-color: @accentColor10;
            box-shadow: -1200px 0 0 @accentColor10, -2400px 0 0 @accentColor10, 1200px 0 0 @accentColor10, 2400px 0 0 @accentColor10;
            padding: 20px;
            
            h4 {
              display: none;
            }
            span {
              color: @accentColor;
              font-weight: 600;
            }
          }
        }
        > .section-body {
          .dashboard {
            :where(#applications-list, #tab-documents, #tab-messages) {
              padding: @panelPadding;
              background: @whiteColor;
              border: none;
              border-radius: @roundedCorners;
              box-shadow: 0px 9px 20px @black2Color;
            }
            #applications-list {
              border-radius: 0 @roundedCorners @roundedCorners @roundedCorners;

              table {
                &.table {
                  thead {
                    opacity: 1;

                    tr {
                      th {
                        padding: 0;

                        span {
                          &:not(.sortorder) {
                            background-color: @accentColor5;
                            text-transform: none;
                            color: @accentColor;
                            font-size: 14px;
                            font-weight: 500;
                            display: flex;
                            padding: 10px 20px;
                            white-space: nowrap;
                          }
                          &.sortorder {
                            top: 10px;

                            &::after {
                              content: "\f175";
                              color: @accentColor;
                              font-weight: 300;
                            }
                          }
                        }
                        &:first-child {
                          span {
                            &:not(.sortorder) {
                              border-radius: @roundedCorners 0 0 @roundedCorners;
                            }
                          }
                        }
                        &.results-col {
                          z-index: 9;
                        }
                        &.opt-col {
                          z-index: 10;
                          
                          &::before {
                            content: "";
                            display: block;
                            height: 34px;
                            background-color: @accentColor5;
                            border-radius: 0 @roundedCorners @roundedCorners 0;
                          }
                        }
                        input {
                          border: solid 1px @accentColor10;
                          background-color: transparent;
                          border-radius: @roundedCorners;
                          margin-top: 18px;
                          height: 34px;
                          box-shadow: none;
                          margin-inline-end: 20px;
                          width: calc(100% - 20px);
                          margin-bottom: 12px;
                          font-weight: 600;
                          font-size: 13px;
                          color: @darkTextColor;
                          padding-inline-start: 20px;

                          &::placeholder {
                            color: @accentColor50;
                            font-weight: 600;
                          }
                        }
                        &.status-col {
                          width: auto;
                          position: relative;
                          z-index: 4;
                        }
                        &.results-col {
                          position: relative;
                          z-index: 3;
                          isolation: isolate;
                        }
                      }
                    }
                    &.responsive-sorting {
                      tr {
                        td {
                          padding: 0 0 10px;
                          display: flex;
                          align-items: center;

                          .sorting-label {
                            background: @accentColor5;
                            color: @accentColor;
                            font-weight: 600;
                            padding: 6px 12px;
                            border-radius: @roundedCorners;
                            display: inline-flex;
                            white-space: nowrap;
                            transition-duration: 0.3s;

                            .sort_by_label {
                              & ~ span {
                                margin-inline-start: 6px;
                                font-weight: 600;
                                position: relative;

                                &::after {
                                  content: "\f354";
                                  font-family: 'Font Awesome 5 Pro';
                                  font-weight: 900;
                                  -moz-osx-font-smoothing: grayscale;
                                  -webkit-font-smoothing: antialiased;
                                  display: inline-block;
                                  font-style: normal;
                                  font-variant: normal;
                                  text-rendering: auto;
                                  line-height: 1;
                                  font-size: 1.1rem;
                                  margin-inline-start: 6px;
                                  border: none;
                                  opacity: 0.8;
                                }
                                &.reverse-ordering {
                                  &::after {
                                    content: "\f357";
                                    color: #4a6288;
                                    font-weight: 900;
                                  }
                                }
                              }
                            }
                            &:hover {
                              background: @accentColor10;
                            }
                          }
                          .cells-filter-toggler {
                            background: @accentColor5;
                            color: @accentColor;
                            padding: 6px 12px;
                            border-radius: @roundedCorners;
                            transition-duration: 0.3s;
                            display: inline-flex;
                            height: 37px;
                            align-items: center;
                            margin-inline-start: 12px;
                            cursor: pointer;

                            &:hover {
                              background: @accentColor10;
                            }
                          }
                        }
                      }
                    }
                    &.heading-with-filters {
                      tr {
                        th {
                          &.status-col {
                            width: 15%;
                            min-width: 170px;
                          }
                          &.opt-col {
                            .show-hide-filters {
                              &[hidden] {
                                display: block;
                                position: absolute;
                                inset-inline-end: calc(50% - 12px);
                                top: 6px;
                                cursor: pointer;
                                padding: 6px;
                                border-radius: 6px;
                                transition-duration: 0.2s;

                                &:hover {
                                  background-color: @accentColor10;
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                  tbody {
                    tr {
                      background-color: transparent;

                      td {
                        background-color: transparent;
                        border: none;
                        border-bottom: solid 1px @accentColor10;
                        padding-inline-start: 20px;
                        padding-inline-end: 20px;
                        font-size: 15px;
                        font-weight: 400;
                        color: @darkTextColor;

                        &.app-status {
                          box-shadow: none;

                          &:where(.status-info, .status-default, .status-warning, .status-success, .status-danger) {
                            &::before {
                              content: "";
                              display: block;
                              width: 4px;
                              height: 36px;
                              border-radius: 0 2px 2px 0;
                              position: absolute;
                              inset-inline-start: 0;
                              top: calc(50% - 18px);
                            }
                          }
                          &.status-info {
                            &::before {
                              background-color: @appStatusPending;
                            }
                          }
                          &.status-default {
                            &::before {
                              background-color: @appStatusDraft;
                            }
                            span {
                              color: @appStatusDraft;
                            }
                            label {
                              &.status-list-toggler {
                                span {
                                  &.status-list-text {
                                    color: @appStatusDraft;
                                  }
                                }
                              }
                            }
                          }
                          &:where(.status-default + td, .status-default ~ td.date-col) {
                            color: @appStatusDraft;

                            span {
                              &.date-value,
                              &:not(.responsive-label) {
                                color: @appStatusDraft;
                              }
                            }
                          }
                          &.status-warning {
                            &::before {
                              background-color: @appStatusAction;
                            }
                            .action-required-triangle {
                              width: 20px;
                              height: 20px;
                              border: none;
                              color: @appStatusAction;
                              top: calc(50% - 10px);
                              inset-inline-start: -24px;

                              &::before {
                                font-family: 'Font Awesome 5 Pro';
                                font-weight: 400;
                                -moz-osx-font-smoothing: grayscale;
                                -webkit-font-smoothing: antialiased;
                                display: inline-block;
                                font-style: normal;
                                font-variant: normal;
                                text-rendering: auto;
                                line-height: 1;
                                content: "\f05a";
                              }
                            }
                          }
                          &.status-success {
                            &::before {
                              background-color: @appStatusProcessed;
                            }
                          }
                          &.status-danger {
                            &::before {
                              background-color: @appStatusRejected;
                            }
                          }
                        }
                        &.date-col {
                          font-size: 12px;
                          font-weight: 500;
                          text-align: start;
                        }
                        &.status-col {
                          width: auto;

                          file-status {
                            span {
                              &.label {
                                background-color: transparent;
                                padding: 0;
                                font-weight: 600;

                                &.label-info {
                                  color: @appStatusPending;
                                }
                                &.label-default {
                                  color: @appStatusDraft;
                                }
                                &.label-warning {
                                  color: @appStatusAction;
                                }
                                &.label-success {
                                  color: @appStatusProcessed;
                                }
                                &.label-danger {
                                  color: @appStatusRejected;
                                }
                              }
                            }
                            &:last-child {
                              padding-inline-end: 0;
                            }
                            &:empty {
                              display: none;
                            }
                          }
                          label {
                            display: flex;

                            &.status-list-toggler {
                              position: relative;
                              span {
                                &.status-list-text {
                                  &[hidden] {
                                    display: flex;
                                    font-size: 12px;
                                    font-weight: 600;
                                    line-height: 1;
                                    text-transform: uppercase;
                                    align-items: center;
                                    width: 100%;

                                    i {
                                      color: @darkTextColor40;
                                      font-size: 17px;
                                      line-height: 16px;
                                      cursor: pointer;
                                      border-radius: @roundedCorners;
                                      padding: 4px 10px;
                                      margin-inline-start: auto;

                                      &:hover {
                                        color: @darkTextColor60;
                                        background-color: @darkTextColor5;
                                      }
                                    }
                                  }
                                }
                              }
                              .status-list-dropdown {
                                position: absolute;
                                opacity: 0;
                                z-index: -999;
                                transition-duration: 0.3s;
                                display: flex;
                                flex-direction: column;
                                background-color: @whiteColor;
                                box-shadow: 0px 0px 20px @black5Color;
                                border-radius: @roundedCorners;
                                padding-block: 18px;
                                padding-inline-start: 24px;
                                padding-inline-end: 36px;
                                inset-inline-end: 0;
                                border: solid 1px @black5Color;

                                .close-status-list {
                                  &[hidden].hide {
                                    position: absolute;
                                    display: block !important;
                                    color: @darkTextColor40;
                                    font-size: 17px;
                                    line-height: 16px;
                                    cursor: pointer;
                                    border-radius: @roundedCorners;
                                    padding: 4px 6px;
                                    inset-inline-end: 4px;
                                    top: 4px;

                                    &:hover {
                                      color: @darkTextColor60;
                                      background-color: @darkTextColor5;
                                    }
                                  }
                                }
                              }
                              input {
                                &[type="checkbox"] {
                                  &:not(.length-) {
                                    &:checked {
                                      & ~ .status-list-dropdown {
                                        opacity: 1;
                                        z-index: 999;
                                      }
                                      & + span {
                                        &.status-list-text {
                                          i {
                                            color: @darkTextColor60;
                                            background-color: @darkTextColor5;
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                          &.status-info {
                            label {
                              &.status-list-toggler {
                                span {
                                  &.status-list-text {
                                    color: @appStatusPending;
                                  }
                                }
                              }
                            }
                          }
                          &.status-default {
                            label {
                              &.status-list-toggler {
                                span {
                                  &.status-list-text {
                                    color: @appStatusDraft;
                                  }
                                }
                              }
                            }
                          }
                          &.status-warning {
                            label {
                              &.status-list-toggler {
                                span {
                                  &.status-list-text {
                                    color: @appStatusAction;
                                  }
                                }
                              }
                            }
                          }
                          &.status-success {
                            label {
                              &.status-list-toggler {
                                span {
                                  &.status-list-text {
                                    color: @appStatusProcessed;
                                  }
                                }
                              }
                            }
                          }
                          &.status-danger {
                            label {
                              &.status-list-toggler {
                                span {
                                  &.status-list-text {
                                    color: @appStatusRejected;
                                  }
                                }
                              }
                            }
                          }
                        }
                        &.results-col {
                          color: @darkTextColor60;
                          font-size: 24px;
                          cursor: pointer;

                          &:hover {
                            color: @darkTextColor;
                          }
                          a {
                            i {
                              color: @accentColor50;
                            }
                            &:hover {
                              i {
                                color: @accentColor;
                              }
                            }
                            & + .tooltip.left {
                              inset-inline-start: initial !important;
                              inset-inline-end: calc(100% - 15px);
                              width: fit-content;
                              max-width: 200px;
                            }
                          }
                          .results-dropdown {
                            .dropdown-toggle {
                              position: relative;
                              height: 24px;
                              display: flex;

                              i {
                                position: absolute;
                                z-index: 1;
                                font-size: 20px;
                                inset-inline-start: 10px;
                                opacity: 0.6;
                                margin-top: 2px;
                                color: @accentColor50;

                                & + i {
                                  margin-top: 0;
                                  z-index: 2;
                                  font-size: 24px;
                                  inset-inline-start: 0;
                                  opacity: 1;
                                  background-color: white;
                                  padding-inline-end: 2px;
                                }
                              }
                              &:hover {
                                i {
                                  color: @accentColor;
                                }
                              }
                            }
                            .dropdown-menu {
                              padding: 0;
                              border-radius: @roundedCorners;
                              box-shadow: 0px 0px 20px @black5Color;
                              border: none;
                              overflow: hidden;
                              width: fit-content;
                              max-width: 320px;
                              min-width: 190px;
                              border: solid 1px @black5Color;

                              .dropdown-item {
                                padding: 8px 16px;
                                display: flex;
                                align-items: center;
                                gap: 12px;
                                line-height: 1.3;
                  
                                &:hover {
                                  background-color: @accentColor5;
                                  text-decoration: none;
                                }
                                i {
                                  color: @appStatusRejected;
                                }
                              }
                            }
                          }
                        }
                        &.app-options {
                          a {
                            &.btn {
                              opacity: 1;
                              
                              i {
                                display: none;
                              }
                              span {
                                &[hidden] {
                                  display: flex;
                                  font-size: 10.5px;
                                  font-weight: 500;
                                  border: solid 1px @accentColor30;
                                  color: @accentColor;
                                  border-radius: 3px;
                                  padding: 2px 6px;
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
              .status-legend-filter {
                margin-top: 2rem !important;

                .all-limited-button {
                  span {
                    background-color: @accentColor5;
                    border-radius: @roundedCorners;
                    color: @darkTextColor;
                    height: 36px;
                    padding: 0 15px;
                    display: flex;
                    align-items: center;
                    font-weight: 500;

                    &::after {
                      font-family: 'Font Awesome 5 Pro';
                      font-weight: 400;
                      -moz-osx-font-smoothing: grayscale;
                      -webkit-font-smoothing: antialiased;
                      display: inline-block;
                      font-style: normal;
                      font-variant: normal;
                      text-rendering: auto;
                      line-height: 1;
                      content: "\f107";
                      margin-inline-start: 12px;
                    }
                    &:last-child {
                      &::after {
                        content: "\f106";
                      }
                    }
                    &:hover {
                      text-decoration: none;
                    }
                  }
                }
                .application-status {
                  margin-inline-end: -2000px;
                  height: 0;

                  .applications-statuses {
                    span {
                      font-weight: 600 !important;

                      &:first-child {
                        font-size: 11px;
                        color: @darkTextColor;
                        letter-spacing: 0.55px;
                      }
                      span {
                        border: none;
                        padding: 0 0 6px;

                        &.status-unavailable {
                          color: @appStatusDraft;
                        }
                        &.status-pending {
                          color: @appStatusPending;
                        }
                        &.status-processed {
                          color: @appStatusProcessed;
                        }
                        &.status-correction {
                          color: @appStatusAction;
                        }
                        &.status-rejected {
                          color: @appStatusRejected;
                        }
                      }
                      &.active-filter {
                        background-color: transparent;

                        span {
                          border-bottom: solid 1px;
                        }
                      }
                      &:hover {
                        background-color: transparent;

                        span {
                          border-bottom: solid 1px;
                        }
                      }
                    }
                  }
                  &.show-legend {
                    margin-inline-end: 0;
                    height: auto;

                    @media screen and (max-width: 480px) {
                      .applications-statuses {
                        display: grid;

                        span {
                          text-align: start;
                        }
                      }
                    }
                  }
                }
                .legend-info-icon {
                  position: relative;
                  top: -2px;
                }
                span {
                  &.btn-link {
                    font-size: 12px;
                  }
                }
                .status-filter-toggler {
                  text-decoration: underline;
                  border-radius: @roundedCorners;

                  &:where(:hover, :focus) {
                    background-color: @black5Color;
                  }
                }
              }
              .apps-pagination {
                text-align: start;

                dir-pagination-controls {
                  display: block;
                }
              }
              .warning-list {
                display: none;
              }
              @media screen and (max-width: 980px) {
                padding: 24px;

                table {
                  &.table {
                    thead {
                      &.responsive-sorting {
                        display: block;
                      }
                      &.heading-with-filters {
                        display: none;
                        background-color: @accentColor10Solid;
                        position: absolute;
                        z-index: 999;
                        border-radius: 0 0 @roundedCorners @roundedCorners;
                        transition-duration: 0.3s;
                        overflow: hidden;

                        tr {
                          display: flex;
                          flex-direction: column;

                          th {
                            flex: 1;
                            width: 100%;
                            background-color: transparent;

                            &:where(.status-col, .status-col + th, .opt-col) {
                              display: none;
                            }
                            input {
                              display: none;
                            }
                            > span {
                              background-color: transparent;
                              border-radius: 0 !important;
                              padding: 10px 12px;

                              &:hover {
                                background-color: @accentColor10;
                              }
                            }
                            .sortorder {
                              display: none;
                            }
                          }
                        }
                      }
                    }
                    &.show-sorting {
                      thead {
                        &.heading-with-filters {
                          display: block;
                          transform: translateY(-10px);
                        }
                        &.responsive-sorting {
                          tr {
                            td {
                              span {
                                &.sorting-label {
                                  border-radius: @roundedCorners @roundedCorners 0 0;
                                  background-color: @accentColor10;
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                    &.show-search {
                      thead {
                        &.heading-with-filters {
                          display: block;
                          transform: translateY(-10px);
                          width: 224px;

                          tr {
                            th {
                              span {
                                display: none;
                              }
                              input {
                                display: block;
                                margin: 12px !important;
                                background: @whiteColor;
                                padding-inline-start: 12px;
                                width: calc(100% - 24px);
                              }
                              &:first-child {
                                input {
                                  margin: 12px 12px 0 !important;
                                }
                              }
                            }
                          }
                        }
                        &.responsive-sorting {
                          tr {
                            td {
                              span {
                                &.cells-filter-toggler {
                                  border-radius: @roundedCorners @roundedCorners 0 0;
                                  background-color: @accentColor10;
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                    tbody {
                      display: grid;
                      grid-template-columns: 1fr 1fr 1fr;
                      max-width: 100%;
                      gap: 12px;

                      tr {
                        display: grid;
                        border-radius: @roundedCorners;
                        border: solid 1px @accentColor10;
                        grid-template-rows: auto auto auto auto auto;
                        grid-template-columns: 1fr 1fr;
                        padding-bottom: 1rem;

                        td {
                          width: auto;
                          border: none;
                          padding-top: 1rem;
                          padding-bottom: 1rem;
                          font-weight: 600;
                          
                          &.app-options {
                            border: none;
                            display: flex;
                            justify-content: flex-end;
                            align-items: flex-end;

                            a {
                              padding: 0;
                            }
                          }
                          &.app-status {
                            font-weight: 600;
                            display: flex;
                            align-items: center;
                            padding-bottom: 0;
                            padding-top: 2rem;

                            &::before {
                              width: 90% !important;
                              height: 4px !important;
                              top: 0 !important;
                              inset-inline-start: 5% !important;
                              border-radius: 0 0 3px 3px !important;
                            }
                          }
                          &.date-col {
                            font-size: 14px;
                            padding-top: 0.3rem;
                            padding-bottom: 0.3rem;

                            .date-value {
                              &:empty {
                                &::before {
                                  content: '-';
                                  display: block;
                                }
                              }
                            }
                          }
                          &.results-col {
                            &.no-results {
                              &::after {
                                content: '-';
                                display: block;
                                font-size: 14px;
                              }
                            }
                          }
                          &.status-col {
                            padding-bottom: 0.3rem;
                          }
                          &:nth-child(1) {
                            grid-row-start: 1;
                            grid-column-start: 1;
                            grid-row-end: 2;
                            grid-column-end: 3;
                          }
                          &:nth-child(2) {
                            grid-row-start: 2;
                            grid-column-start: 1;
                            grid-row-end: 3;
                            grid-column-end: 3;
                          }
                          &:nth-child(3) {
                            grid-row-start: 3;
                            grid-column-start: 1;
                            grid-row-end: 4;
                            grid-column-end: 2;
                          }
                          &:nth-child(4) {
                            grid-row-start: 3;
                            grid-column-start: 2;
                            grid-row-end: 4;
                            grid-column-end: 3;
                          }
                          &:nth-child(5) {
                            grid-row-start: 4;
                            grid-column-start: 1;
                            grid-row-end: 5;
                            grid-column-end: 3;
                          }
                          &:nth-child(6) {
                            grid-row-start: 5;
                            grid-column-start: 1;
                            grid-row-end: 6;
                            grid-column-end: 2;
                          }
                          &:nth-child(7) {
                            grid-row-start: 5;
                            grid-column-start: 2;
                            grid-row-end: 6;
                            grid-column-end: 3;
                          }
                          .responsive-label {
                            &[hidden] {
                              display: block;
                              font-size: 12px;
                              font-weight: 400;
                            }
                          }
                        }
                        &:hover {
                          background-color: @accentColor3;
                        }
                      }
                    }
                  }
                }
              }
              @media screen and (max-width: 880px) {
                table {
                  &.table {
                    tbody {
                      grid-template-columns: 1fr 1fr;
                    }
                  }
                }
              }
              @media screen and (max-width: 640px) {
                table {
                  &.table {
                    tbody {
                      grid-template-columns: 1fr;
                    }
                  }
                }
              }
            }
            #services-list {
              .services-title-search-container {
                margin-bottom: 12px;

                h2 {
                  &.big-title {
                    color: @darkTextColor;
                    font-size: 24px;

                    @media screen and (max-width: 600px) {
                      font-size: 18px;
                    }
                    label {
                      font-weight: inherit;
                    }
                  }
                }
                @media screen and (max-width: 480px) {
                  flex-direction: column;
                  align-items: flex-start;

                  .services-search-icon {
                    margin-top: 25px;
                  }
                  input {
                    &.form-control {
                      margin-top: -25px;
                    }
                  }
                }
              }
              > div {
                &.d-flex {
                  @media screen and (max-width: 880px) {
                    display: block;
                  }
                }
              }
              .service-visibility-switches {
                width: auto;
                border-radius: @roundedCorners;
                border: solid 1px @accentColor10;

                li {
                  display: flex;
                  flex: auto;
                  margin: 0;

                  span {
                    &.service-visibility-switch {
                      white-space: nowrap;
                      display: flex;
                      height: 36px;
                      align-items: center;
                      font-size: 14px;
                      color: @accentColor;
                      font-weight: 400;

                      &.text-uppercase {
                        text-transform: none;
                      }
                      &:where(.active, :hover) {
                        border-radius: @roundedCorners;
                        color: @darkTextColor;
                        background-color: @accentColor10;
                      }
                    }
                  }
                }
                &.no-categs {
                  margin-top: -56px;
                }
                @media screen and (max-width: 880px) {
                  width: fit-content;
                  
                  &.no-categs {
                    margin-top: 0;
                    
                    li {
                      span {
                        height: 36px;
                      }
                    }
                  }
                  li {
                    span {
                      height: 36px;
                    }
                  }
                }
              }
              .service-categories {
                width: auto;

                li {
                  display: flex;
                  flex: auto;
                  margin: 0;
                  margin-inline-end: 10px;

                  span {
                    &.service-category {
                      white-space: nowrap;
                      display: flex;
                      height: 36px;
                      align-items: center;
                      font-size: 14px;
                      color: @accentColor;
                      font-weight: 400;
                      border: solid 1px @accentColor10;
                      border-radius: @roundedCorners;

                      &.text-uppercase {
                        text-transform: none;
                      }
                      &:where(.active, :hover) {
                        background-color: @accentColor10;
                        border-color: transparent;
                      }
                      &.active {
                        &::after {
                          font-family: 'Font Awesome 5 Pro';
                          font-weight: 400;
                          -moz-osx-font-smoothing: grayscale;
                          -webkit-font-smoothing: antialiased;
                          display: inline-block;
                          font-style: normal;
                          font-variant: normal;
                          text-rendering: auto;
                          line-height: 1;
                          content: "\f00d";
                          margin-inline-start: 12px;
                        }
                      }
                    }
                  }
                }
                @media screen and (max-width: 880px) {
                  flex-wrap: wrap;
                  margin-bottom: 12px;

                  > li {
                    flex: 0;
                    margin-bottom: 10px;

                    span {
                      &.service-category {
                        height: 36px;
                      }
                    }
                  }
                }
              }
              .dashboard-tiles {
                a {
                  &.dashboard-tile {
                    background-color: @whiteColor;
                    border-radius: @roundedCorners;
                    padding: calc(@panelPadding - 15px);

                    .service-name-container {
                      padding-inline-end: 2rem;

                      .service-name {
                        color: @darkTextColor;
                        font-size: 17px;
                        font-weight: 600;
                        margin: 0;
                      }
                      .service-description {
                        padding-top: 6px;
                        color: @darkTextColor80;
                        display: -webkit-box;
                        -webkit-line-clamp: 3;
                        -webkit-box-orient: vertical;
                        overflow: hidden;

                        &:hover {
                          -webkit-line-clamp: 100;
                          animation-name: fullClamp;
                          animation-delay: 0.75s;
                          animation-timing-function: ease-in-out;
                          animation-fill-mode: backwards;
                          animation-duration: 0.3s;
                        }
                      }
                    }
                    .service-icon {
                      span {
                        font-weight: 300;
                        color: @accentColor;
                      }
                      img {
                        &.item-img {
                          height: auto;
                          max-height: 40px;
                        }
                      }
                    }
                    &:hover {
                      box-shadow: 0px 0px 20px @black5Color;
                    }
                  }
                }
                @media screen and (max-width: 880px) {
                  grid-template-columns: 1fr 1fr;

                  a {
                    &.dashboard-tile {
                      padding: 24px;
                    }
                  }
                }
                @media screen and (max-width: 480px) {
                  grid-template-columns: 1fr;
                }
              }
            }
            #tab-documents {
              #documents-others {
                .big-title {
                  display: none;
                }
                .document-status {
                  display: none;
                }
                table {
                  &.table {
                    thead {
                      opacity: 1;
  
                      tr {
                        th {
                          padding: 0;
                          vertical-align: top;
  
                          span {
                            &:not(.sortorder) {
                              background-color: @accentColor5;
                              text-transform: none;
                              color: @accentColor;
                              font-size: 14px;
                              font-weight: 500;
                              display: flex;
                              padding: 10px 20px;
                              white-space: nowrap;
                            }
                          }
                          &:first-child {
                            span {
                              &:not(.sortorder) {
                                border-radius: @roundedCorners 0 0 @roundedCorners;
                              }
                            }
                          }
                          &:last-child {
                            span {
                              &:not(.sortorder) {
                                border-radius: 0 @roundedCorners @roundedCorners 0;
                              }
                            }
                          }
                          input {
                            border: solid 1px @accentColor10;
                            background-color: transparent;
                            border-radius: @roundedCorners;
                            margin-top: 18px;
                            height: 34px;
                            box-shadow: none;
                            margin-inline-end: 20px;
                            width: calc(100% - 20px);
                            margin-bottom: 12px;
                            outline: none;
                            padding: 4px 20px;
                            font-weight: 500;
                            font-size: 13px;
                            color: @darkTextColor;

                            &::placeholder {
                              color: @accentColor50;
                              font-weight: 500;
                            }
                          }
                        }
                      }
                    }
                    tbody {
                      tr {
                        background-color: transparent;
  
                        td {
                          background-color: transparent;
                          border: none;
                          border-bottom: solid 1px @accentColor10;
                          padding-inline-start: 20px;
                          padding-inline-end: 20px;
                          font-size: 15px;
                          font-weight: 500;
                          color: @darkTextColor;
  
                          &.doc-status {
                            box-shadow: none;
  
                            &:where(.expired, .expiring) {
                              &::before {
                                content: "";
                                display: block;
                                width: 4px;
                                height: 36px;
                                border-radius: 0 2px 2px 0;
                                position: absolute;
                                inset-inline-start: 0;
                                top: calc(50% - 18px);
                              }
                            }
                            &.expired {
                              &::before {
                                background-color: @documentExpired;
                              }
                            }
                            &.expiring {
                              &::before {
                                background-color: @documentExpiring;
                              }
                            }
                          }
                          &.date-col {
                            font-size: 12px;
                            font-weight: 500;
                            text-align: start;
                          }
                          &.expires-at {
                            &.expired {
                              color: @documentExpired;
                            }
                            &.expiring {
                              color: @documentExpiring;
                            }
                          }
                          &.text-center {
                            text-align: start;
                          }
                          span {
                            &.badge {
                              background: transparent;
                              color: @darkTextColor;
                              padding: 0;
                              display: flex;
                              font-weight: 500;
                            }
                          }
                        }
                      }
                    }
                  }
                }
                .docs-pagination {
                  display: flex;

                  dir-pagination-controls {
                    display: flex;
                  }
                  .document-status-modern {
                    margin-inline-start: auto;
                    padding-top: 32px;

                    .documents-statuses {
                      span {
                        font-weight: 500 !important;
                        border: none;
                        padding: 0 0 6px;
                        text-transform: uppercase;
                        margin-inline-start: 24px;
                        font-size: 11px;
  
                        &:first-child {
                          font-weight: 500;
                          text-transform: none;
                          color: @darkTextColor;
                          letter-spacing: 0.55px;

                          &:hover {
                            border-bottom: none;
                          }
                        }
                        &.status-expiring {
                          color: @documentExpiring;
                        }
                        &.status-expired {
                          color: @documentExpired;
                        }
                        &.active-filter {
                          background-color: transparent;
                          border-bottom: solid 1px;
                        }
                        &:hover {
                          background-color: transparent;
                          border-bottom: solid 1px;
                        }
                      }
                    }
                  }
                }
              }
            }
            #tab-messages {
              .big-title {
                display: none;
              }
              table {
                &.table {
                  thead {
                    opacity: 1;

                    tr {
                      th {
                        padding: 0;
                        vertical-align: top;

                        span {
                          &:not(.sortorder) {
                            background-color: @accentColor5;
                            text-transform: none;
                            color: @accentColor;
                            font-size: 14px;
                            font-weight: 600;
                            display: flex;
                            padding: 10px 20px;
                            white-space: nowrap;
                          }
                        }
                        &:first-child {
                          span {
                            &:not(.sortorder) {
                              border-radius: @roundedCorners 0 0 @roundedCorners;
                            }
                          }
                        }
                        &:last-child {
                          span {
                            &:not(.sortorder) {
                              border-radius: 0 @roundedCorners @roundedCorners 0;
                            }
                          }
                        }
                        input {
                          border: solid 1px @accentColor10;
                          background-color: transparent;
                          border-radius: @roundedCorners;
                          margin-top: 18px;
                          height: 34px;
                          box-shadow: none;
                          margin-inline-end: 20px;
                          width: calc(100% - 20px);
                          margin-bottom: 12px;
                          outline: none;
                          padding: 4px 20px;
                          font-weight: 600;
                          font-size: 13px;
                          color: @darkTextColor;

                          &::placeholder {
                            color: @accentColor50;
                            font-weight: 600;
                          }
                        }
                        &.opt-col {
                          &::before {
                            content: "";
                            display: block;
                            height: 34px;
                            background-color: @accentColor5;
                            border-radius: 0 @roundedCorners @roundedCorners 0;
                          }
                        }
                      }
                    }
                  }
                  tbody {
                    tr {
                      background-color: transparent;

                      td {
                        border-bottom: solid 1px @accentGray10;

                        span {
                          &.badge {
                            background: transparent;
                            color: @darkTextColor;
                            padding: 0;
                            font-weight: 600;
                          }
                        }
                        &.seen {
                          & ~ td {
                            span {
                              &.badge {
                                color: @darkTextColor60;
                              }
                            }
                          }
                        }
                      }
                      &:first-child {
                        td {
                          background-color: transparent;
                          color: @darkTextColor;
                          font-weight: 500;
                          transition-duration: 0.3s;
                          text-align: start;
                          padding: 20px;

                          &.msg-status {
                            box-shadow: none;
                            padding-inline-start: 1.5rem;

                            &::before {
                              content: "";
                              display: block;
                              width: 4px;
                              height: 36px;
                              border-radius: 0 2px 2px 0;
                              position: absolute;
                              inset-inline-start: 0;
                              top: calc(50% - 18px);
                              background-color: @accentColor;
                            }
                            &.seen {
                              &::before {
                                background-color: @darkTextColor30;
                              }
                              & ~ td {
                                color: @darkTextColor60;
                                transition-duration: 0.3s;
                              }
                              > div {  
                                > i {
                                  color: @darkTextColor60;
                                }
                                > span {
                                  color: @darkTextColor60;
                                }
                              }
                            }
                            > div {
                              display: flex;
                              align-items: center;

                              > i {
                                transform: none;
                                font-weight: 400;
                                font-size: 14px;
                                transition-duration: 0.3s;
                                padding: 0 5px;
                                background: transparent;
                                line-height: 8px;
                              }
                              > span {
                                font-size: 15px;
                                color: @darkTextColor;
                                transition-duration: 0.3s;
                              }
                            }
                          }
                          .remove-msg {
                            font-size: 17px;
                            color: @documentExpired;
                            font-weight: 400;
                            transition-duration: 0.3s;
                            display: flex;
                            justify-content: center;

                            &::before {
                              content: "\f00d";
                            }
                          }
                        }
                      }
                      &:where(.active, :hover) {
                        &:first-child {
                          td {
                            background-color: @darkTextColor2;
                            color: @darkTextColor;

                            &.seen {
                              > div {
                                > :where(span, i) {
                                  color: @darkTextColor;
                                }
                              }
                              & ~ td {
                                color: @darkTextColor;

                                span {
                                  &.badge {
                                    color: @darkTextColor;
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                      &.active {
                        td {
                          border-bottom: solid 1px @accentGray10;
                        }
                      }
                      &.message-body {
                        td {
                          padding-inline-start: 5.2rem !important;
                          background-color: @darkTextColor2;
                          color: @darkTextColor;
                          font-size: 14px;
                          font-weight: 400;
                          box-shadow: none;
                          white-space: pre-line;
                        }
                      }
                    }
                  }
                }
              }
              .msgs-pagination {
                display: flex;

                dir-pagination-controls {
                  display: flex;
                }
                .message-status-modern {
                  margin-inline-start: auto;
                  padding-top: 32px;

                  .messages-statuses {
                    span {
                      font-weight: 600 !important;
                      border: none;
                      padding: 0 0 6px;
                      text-transform: uppercase;
                      margin-inline-start: 24px;
                      font-size: 11px;

                      &:first-child {
                        font-weight: 600;
                        text-transform: none;
                        color: @darkTextColor;
                        letter-spacing: 0.55px;

                        &:hover {
                          border-bottom: none;
                        }
                      }
                      &.status-unread {
                        color: @darkTextColor30;
                      }
                      &.status-read {
                        color: @accentColor;
                      }
                      &.active-filter {
                        background-color: transparent;
                        border-bottom: solid 1px;
                      }
                      &:hover {
                        background-color: transparent;
                        border-bottom: solid 1px;
                      }
                    }
                  }
                }
              }
            }
            ul {
              &.pagination {
                background-color: @accentColor5;
                border-radius: @roundedCorners;
                overflow: hidden;
                margin-top: 32px;

                li {
                  display: inline-flex;
                  margin-inline-end: 1px;

                  a {
                    background-color: transparent;
                    height: 46px;
                    display: flex;
                    align-items: center;
                    border-radius: @roundedCorners;
                    color: @darkTextColor;
                    font-weight: 600;
                    font-size: 12px;
                  }
                  &:where(.active, :hover) {
                    a {
                      background-color: @accentColor10;
                    }
                  }
                  &:where(:first-child, :last-child) {
                    a {
                      color: transparent;
                      position: relative;
                      width: 38px;

                      &::before {
                        font-family: 'Font Awesome 5 Pro';
                        font-weight: 300;
                        -moz-osx-font-smoothing: grayscale;
                        -webkit-font-smoothing: antialiased;
                        display: inline-block;
                        font-style: normal;
                        font-variant: normal;
                        text-rendering: auto;
                        line-height: 1;
                        font-size: 22px;
                        color: @darkTextColor;
                        position: absolute;
                      }
                    }
                  }
                  &:first-child {
                    a {
                      &::before {
                        content: "\f104";
                      }
                    }
                  }
                  &:last-child {
                    a {
                      &::before {
                        content: "\f105";
                      }
                    }
                  }
                }
              }
            }
            .doc-preview-modal {
              .doc-preview-modal-grid {
                .doc-preview-modal-header {
                  .doc-preview-service-name {
                    font-weight: 600;
                    color: @accentColor50;
                  }
                  .doc-preview-modal-title {
                    color: @darkTextColor;
                    font-weight: 600;
                  }
                  .doc-preview-modal-opts {
                    .doc-preview-modal-opt-item {
                      background-color: @accentColor5;
                      border-radius: @roundedCorners;
                      box-shadow: none;
                      transition-duration: 0.3s;
                      
                      span {
                        font-weight: 600;
                      }
                      &.results-paging {
                        &:hover {
                          box-shadow: none;
                        }
                        .results-paging-text {
                          font-weight: 600;
                          font-size: 12px;
                          line-height: 12px;
                        }
                        .paging-btn {
                          line-height: 12px;
                          height: 45px;
                          margin-top: -1rem;
                          margin-bottom: -1rem;
                          align-items: center;
                          display: inline-flex;
                          padding: 0 16px;

                          &.disabled {
                            color: @darkTextColor30;

                            &:hover {
                              background-color: transparent;
                            }
                          }
                          &.previous {
                            margin-inline-start: -2rem;
                            border-radius: @roundedCorners 0 0 @roundedCorners;
                          }
                          &.next {
                            margin-inline-end: -2rem;
                            border-radius: 0 @roundedCorners @roundedCorners 0;
                          }
                          &:hover {
                            background-color: @accentColor5;
                          }
                        }
                      }
                      &:not(.results-paging) {
                        &:hover {
                          background-color: @accentColor10;
                          box-shadow: 0 9px 20px @accentColor40 !important;
                        }
                      }
                      & > i {
                        font-weight: 400;
                      }
                    }
                  }
                }
              }
            }
          }
          @media screen and (max-width: 380px) {
            padding-inline-start: 1.4rem;
            padding-inline-end: 1.4rem;
          }
        }
        .homepage-link-text {
          margin-bottom: 20px;
          display: none;
          align-items: center;
          gap: 8px;

          a {
            text-decoration: underline;
            font-weight: 600;

            &:hover {
              text-decoration: none;
              color: @appStatusPending;
            }
          }
          i {
            color: @appStatusPending;
          }
        }
      }
      .footer-copyright {
        color: @accentColor;
        font-weight: 600;
        font-size: 11px;
      }
      &.demo-mode {
        @media screen and (max-width: 768px) {
          #content {
            .demo-page-menu {
              .contain-lg {
                .current-service-container {
                  flex-direction: column;
                  height: 80px;

                  .service-bar-user-lang-menu {
                    margin-inline-start: initial;
                  }
                }
              }
            }
          }
        }
      }
    }
    :where(.demo-page-menu, .modern-theme-dashboard-menu) {
      background-color: @accentColor;

      &[hidden] {
        display: block;
      }
      .contain-lg {
        width: 100%;

        .current-service-container {
          display: flex;
          justify-content: space-between;
          height: 40px;

          .current-service-text {
            color: @whiteColor;
            height: 100%;
            font-weight: 500;
            font-size: 18px;

            a {
              &.home-button {
                color: @whiteColor;
                height: 100%;
                display: flex;
                align-items: center;
                padding: 0 9px;
                margin-block: 0;
                margin-inline-start: -9px;
                margin-inline-end: 6px;

                &:where(:active, :hover) {
                  background-color: @black10Color;
                  text-decoration: none;
                }
              }
            }
            .fa-chevron-right {
              font-size: 10px;
              margin-inline-end: 12px;
            }
          }
          .service-bar-user-lang-menu {
            margin-inline-start: auto;
            margin-inline-end: -1.5rem;

            .headerbar-right {
              height: 40px;
              flex-direction: row-reverse;

              ul {
                &#language_selector_container {
                  > li {
                    &.dropdown-user {
                      a {
                        &.dropdown-toggle {
                          color: @whiteColor;
                          padding: 0 1.5rem;
                          height: 40px;
                          display: flex;
                          align-items: center;
                          gap: 6px;
                          line-height: 1;

                          &:hover {
                            background: @black10Color;
                          }
                          i {
                            font-size: 16px;
                            margin-inline-start: 0.8rem;
                          }
                        }
                      }
                      &.open {
                        a {
                          &.dropdown-toggle {
                            background: @black10Color;
                          }
                        }
                        ul {
                          &.dropdown-menu {
                            background-color: @whiteColor;
                            padding: 0;
                            inset-inline-end: 0;
                            inset-inline-start: initial;
                            border-radius: 0 @roundedCorners @roundedCorners @roundedCorners;
                            min-width: 100%;

                            li {
                              a {
                                color: @accentColor;
                                font-weight: 600;

                                &:hover {
                                  color: @darkTextColor;
                                  background-color: @pageBgColor;
                                }
                              }
                              &:first-child {
                                a {
                                  border-radius: 0 @roundedCorners 0 0;
                                }
                              }
                              &:last-child {
                                a {
                                  border-radius: 0 0 @roundedCorners @roundedCorners;
                                }
                              }
                            }
                          }
                        }
                      }
                      @media screen and (max-width: 768px) {
                        padding: 0;
                      }
                    }
                  }
                }
                &#connect {
                  height: 100%;

                  li {
                    &.dropdown {
                      &.user {
                        height: 100%;

                        a {
                          text-transform: none;
                          font-weight: 600;

                          &.dropdown-toggle {
                            &.left-part {
                              background: transparent;
                              margin: 0;
                              border: none;
                              padding-inline-start: 1.5rem;
                              padding-inline-end: 0;
                              border-radius: 0;
                              cursor: pointer;

                              i {
                                &.user-icon {
                                  display: none;
                                }
                              }
                              span {
                                &.user-name-text {
                                  padding: 0;

                                  .user-initials {
                                    display: none;
                                  }
                                  @media screen and (max-width: 768px) {
                                    display: block;

                                    .user-initials {
                                      display: block;

                                      span {
                                        display: inline;
                                      }
                                    }
                                  }
                                }
                              }
                              &:nth-last-child(2) {
                                padding-inline-end: 1.5rem;
                                cursor: default;
                              }
                            }
                            &.user-dropdown-toggle {
                              background: transparent;
                              border: none;
                              padding-inline-end: 1.3rem;
                              padding-inline-start: 1.3rem;
                              border-radius: 0;
                              cursor: pointer;

                              i {
                                &.angle-icon {
                                  margin-inline-end: 0;
                                  font-weight: 300;
                                  font-size: 16px;
                                }
                              }
                              &:last-child {
                                display: none;
                              }
                            }
                          }
                        }
                        &:where(:hover, .open) {
                          &:not(.no-permissions) {
                            background-color: @black10Color;
                          }
                          a {
                            color: @whiteColor;
                          }
                        }
                        &.open {
                          a {
                            &.dropdown-toggle {
                              &.user-dropdown-toggle {
                                i {
                                  color: @whiteColor;
                                }
                              }
                            }
                          }
                          ul {
                            &.dropdown-menu {
                              width: 100%;
                              min-width: fit-content;
                              background-color: @whiteColor;
                              color: @accentColor;
                              padding: 0;
                              border-radius: 0 0 @roundedCorners @roundedCorners;

                              li {
                                &.divider {
                                  margin: 0;
                                  background-color: @accentColor10;
                                  height: 1px;

                                  &:first-child {
                                    display: none;
                                  }
                                }
                                a {
                                  padding: 7px 20px;
                                  font-size: 13px;
                                  font-weight: 600;
                                  color: @darkTextColor;
                                  
                                  &:hover {
                                    background-color: @black5Color;
                                  }
                                }
                                &:last-child {
                                  a {
                                    border-radius: 0 0 @roundedCorners @roundedCorners !important;
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                    &:not(.dropdown) {
                      height: 100%;

                      a {
                        height: 100%;
                        display: flex;
                        align-items: center;
                        border-radius: 0 !important;
                        background: transparent;
                        margin: 0 !important;
                        padding: 0 2rem;
                        text-transform: none;
                        font-weight: 600;
                        box-shadow: none !important;

                        &:hover {
                          background-color: @black10Color !important;
                        }
                      }
                    }
                  }
                }
              }

              @media screen and (max-width: 768px) {
                min-height: 40px;
              }
            }
            @media screen and (max-width: 1200px) {
              margin-inline-end: 0;
            }
          }

          @media screen and (max-width: 1200px) {
            padding: 0 2.4rem;
          }
        }
      }
    }
    .modern-theme-dashboard-menu {
      position: sticky;
      top: 0;
      z-index: 99999;
    }
    .modern-theme-be-list {
      &[hidden] {
        display: block;
        z-index: 9999;
        height: 0;

        .dropdown {
          .dropdown-toggle {
            background-color: white;
            padding: 6px 12px;
            border-radius: @roundedCorners;
            gap: 12px;
            box-shadow: 0px 9px 20px @black2Color;
            height: 40px;
            position: relative;
            top: 55px;
            transition-duration: 0.3s;

            &:hover {
              background-color: @accentColor5;
              text-decoration: none;
            }
          }
          .dropdown-menu {
            inset-inline-end: 0;
            inset-inline-start: auto;
            padding: 0;
            border-radius: @roundedCorners 0 @roundedCorners @roundedCorners;
            box-shadow: 0px 9px 20px @black5Color;
            border: none;
            overflow: hidden;
            width: fit-content;
            max-width: 320px;
            min-width: 190px;

            .dropdown-menu-scroller {
              overflow-y: auto;
              display: grid;
            }
            .dropdown-item {
              padding: 8px 16px;
              display: flex;
              align-items: center;
              gap: 12px;
              line-height: 1.3;

              &:hover {
                background-color: @accentColor5;
                text-decoration: none;
              }
            }
          }
          &.open {
            .dropdown-menu {
              //animation: beDropdown 0.3s ease-in-out 0.1s;
            }
            .dropdown-toggle {
              background-color: @accentColor5;
              border-radius: @roundedCorners @roundedCorners 0 0;
            }
          }
        }
      }
    }
    .dashboard-tooltip-handler {
      padding-inline-end: 0;

      .dashboard-tooltip {
        background-color: @accentColor10Solid;
        color: @accentColor;
        border-radius: @roundedCorners;

        &::after {
          background-color: @accentColor10Solid;
        }
      }
      .heading-label {
        &::after {
          color: @accentColor;
          font-weight: 900;
        }
      }
    }
      .popover {
        border-color: rgba(0,0,0,0.05);
        border-radius: 6px;

        .popover-title {
          font-size: 14px;
          padding: 14px 14px 0;
          background: none;
          border: none;
        }
        .popover-actions {
          display: flex;
          gap: 12px;

          button {
            border-radius: 6px;
          }
        }
      }
    //}
  }
}
.animate {
  animation-duration: 0.3s;
  -webkit-animation-duration: 0.3s;
  animation-fill-mode: both;
  -webkit-animation-fill-mode: both;
}
@keyframes slideInBE {
  0% {
    transform: translateY(70px);
    opacity: 0;
  }

  100% {
    transform: translateY(55px);
    opacity: 1;
  }

  0% {
    transform: translateY(70px);
    opacity: 0;
  }
}

@-webkit-keyframes slideInBE {
  0% {
    -webkit-transform: transform;
    -webkit-opacity: 0;
  }

  100% {
    -webkit-transform: translateY(55px);
    -webkit-opacity: 1;
  }

  0% {
    -webkit-transform: translateY(70px);
    -webkit-opacity: 0;
  }
}

.slideInBE {
  -webkit-animation-name: slideInBE;
  animation-name: slideInBE;
}

@keyframes slideIn {
  0% {
    transform: translateY(0px);
    opacity: 0;
  }

  100% {
    transform: translateY(-15px);
    opacity: 1;
  }

  0% {
    transform: translateY(0px);
    opacity: 0;
  }
}

@-webkit-keyframes slideIn {
  0% {
    -webkit-transform: transform;
    -webkit-opacity: 0;
  }

  100% {
    -webkit-transform: translateY(-15px);
    -webkit-opacity: 1;
  }

  0% {
    -webkit-transform: translateY(0px);
    -webkit-opacity: 0;
  }
}

.slideIn {
  -webkit-animation-name: slideIn;
  animation-name: slideIn;
}

@keyframes fullClamp {
  0% {
    -webkit-line-clamp: 3;
  }
  100% {
    -webkit-line-clamp: 100;
  }
}
@-webkit-keyframes fullClamp {
  0% {
    -webkit-line-clamp: 3;
  }
  100% {
    -webkit-line-clamp: 100;
  }
}