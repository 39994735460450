@import "./themes/default/_themes-colors.less";

@roundedCorners: 6px;

html {
    &[dir="rtl"] {

        /* Modern Theme */

        body {
            &.modern-theme {
                @panelBodyPadding: 23px;

                // Overrides
                .radio label, .checkbox label {
                    padding-left: initial;
                    padding-inline-start: 20px;
                }
                :where(.radio, .radio-inline, .checkbox, .checkbox-inline) {
                    :where(input[type="radio"], input[type="checkbox"]) {
                        margin-left: initial;
                        margin-inline-start: -20px;
                    }
                }
                &.formio-new #base #content .section-body .contain-lg #formio-form-wizard .formio-form {
                    :is(.wizard-page .formio-component, .formio-component) {
                        &.formio-component-button:is(.field-plus-button) button.btn {
                            border-top-left-radius: @roundedCorners !important;
                            border-bottom-left-radius: @roundedCorners !important;
                            border-top-right-radius: 0 !important;
                            border-bottom-right-radius: 0 !important;
                        }
                        &.formio-component-select.field-plus-button .formio-choices .selection.dropdown {
                            border-radius: 0 @roundedCorners @roundedCorners 0 !important;
                            flex-direction: row;
                        }
                        &.formio-component-textarea.field-plus-button textarea.form-control {
                            border-top-right-radius: @roundedCorners !important;
                            border-top-left-radius: 0 !important;
                        }
                    }
                }

                // RTL changes
                #reportrange {
                    float: left;
                }
                button.button.flying-button {
                    border-top-left-radius: 0 !important;
                    border-bottom-left-radius: 0 !important;
            
                    &.draft-save {
                        transform: rotateZ(90deg) translateX(-50%);
                        transform-origin: left;
                        border-radius: @roundedCorners @roundedCorners 0 0 !important;

                        &:hover {
                            box-shadow: 0px -6px 20px rgba(70, 81, 170, 0.4) !important;
                        }
                    }
                }
                :where(.modern-theme-dashboard-menu, .current-service-container) {
                    .current-service-text {
                        .fa-chevron-right {
                            transform: rotateY(180deg);
                        }
                    }
                }
                .modern-theme-be-list {
                    .contain-lg {
                        .dropdown.ml-auto {
                            .dropdown-menu {
                                border-radius: 0 @roundedCorners @roundedCorners @roundedCorners;
                            }
                        }
                    }
                }
                .management_bar {
                    .contain-lg {
                        > ul.nav-pills {
                            li.nav-item {
                                a.nav-link {
                                    display: flex;
                                    align-items: center;
                                }
                            }
                        }
                    }
                }
                #base {
                    #content {
                        .section-body {
                            .contain-lg {
                                .dashboard {
                                    #tab-applications {
                                        #applications-list {
                                            > table {
                                                thead {
                                                    tr {
                                                        th {
                                                            &:first-child span:not(.sortorder) {
                                                                border-radius: 0 @roundedCorners @roundedCorners 0;
                                                            }
                                                            &.opt-col::before {
                                                                border-radius: @roundedCorners 0 0 @roundedCorners;
                                                            }
                                                        }
                                                    }
                                                }
                                                tbody {
                                                    td {
                                                        &.app-status {
                                                            &::before {
                                                                border-radius: 2px 0 0 2px;
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                            .table-paging-container {
                                                ul.pagination {
                                                    li {
                                                        &:first-of-type,
                                                        &:last-of-type {
                                                            a {
                                                                &::before {
                                                                    transform: rotateZ(180deg);
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                    #tab-documents {
                                        #documents-others {
                                            .alert {
                                                &::after {
                                                    inset-inline-start: initial !important;
                                                    inset-inline-end: 0;
                                                }
                                            }
                                            .table-paging-container {
                                                .docs-pagination {
                                                    ul.pagination {
                                                        li {
                                                            &:first-of-type,
                                                            &:last-of-type {
                                                                a {
                                                                    &::before {
                                                                        transform: rotateZ(180deg);
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                    #tab-messages {
                                        table.table {
                                            tbody tr td {
                                                &.msg-status {
                                                    &::before {
                                                        border-radius: 2px 0 0 2px;
                                                    }
                                                }
                                            }
                                        }
                                        .table-paging-container {
                                            .msgs-pagination {
                                                ul.pagination {
                                                    li {
                                                        &:first-of-type,
                                                        &:last-of-type {
                                                            a {
                                                                &::before {
                                                                    transform: rotateZ(180deg);
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                                .be-list-page {
                                    a.back-link {
                                        i.back-link-icon {
                                            transform: rotateZ(180deg);
                                            position: relative;
                                            top: -1px;
                                        }
                                    }
                                    .list-container {
                                        table.business-list-table {
                                            tbody {
                                                tr {
                                                    td {
                                                        &.row-toggler {
                                                            border-radius: 0 @roundedCorners @roundedCorners 0;
                                                        }
                                                    }
                                                    &.active {
                                                        td {
                                                            &:last-child {
                                                                border-radius: @roundedCorners 0 0 0;
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                                .manage-be-page {
                                    a.back-link {
                                        i.back-link-icon {
                                            transform: rotateZ(180deg);
                                            position: relative;
                                            top: -1px;
                                        }
                                    }
                                    table {
                                        &.users-list-table {
                                            tfoot {
                                                tr {
                                                    th {
                                                        .input-group {
                                                            input.form-control {
                                                                border-bottom-right-radius: @roundedCorners;
                                                                border-top-right-radius: @roundedCorners;
                                                                border-bottom-left-radius: 0;
                                                                border-top-left-radius: 0;
                                                            }
                                                            button.btn {
                                                                border-bottom-left-radius: @roundedCorners;
                                                                border-top-left-radius: @roundedCorners;
                                                                border-bottom-right-radius: 0;
                                                                border-top-right-radius: 0;
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                                #formio-form-wizard {
                                    .formio-form {
                                        .formio-component {
                                            &-select {
                                                .choices__list {
                                                    direction: rtl;
                                                }
                                            }
                                            &-radio {
                                                &:not(.radio-switch) {
                                                    .form-radio {
                                                        .form-check {
                                                            .form-check-label {
                                                                &:is(.label-position-right, .label-position-) {
                                                                    span {
                                                                        width: 100%;
                                                                        display: block;
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                                &.radio-switch {
                                                    .form-radio {
                                                        &.radio {
                                                            .form-check {
                                                                &:first-child {
                                                                    label {
                                                                        &.form-check-label {
                                                                            span {
                                                                                border-radius: 0 @roundedCorners @roundedCorners 0 !important;
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                                &:last-child {
                                                                    label {
                                                                        &.form-check-label {
                                                                            span {
                                                                                border-radius: @roundedCorners 0 0 @roundedCorners !important;
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                            &-editgrid {
                                                > button.btn {
                                                    display: flex !important;
                                                }
                                            }
                                            &-datagrid {
                                                > table.table.datagrid-table {
                                                    thead tr th {
                                                        &:first-child {
                                                            border-radius: 0 @roundedCorners @roundedCorners 0 !important;
                                                        }
                                                        &:last-child {
                                                            border-radius: @roundedCorners 0 0 @roundedCorners !important;
                                                        }
                                                    }
                                                }
                                            }
                                            &-htmlelement {
                                                &.alert.alert-danger {
                                                    border-radius: @roundedCorners 0 0 @roundedCorners !important;
                                                }
                                            }
                                            &-panel {
                                                > .card {
                                                    > .card-header {
                                                        .card-title {
                                                            i.fa-question-circle {
                                                                position: relative;
                                                                display: flex;
                                                            }
                                                        }
                                                    }
                                                }
                                                &:is(.formio-component-FeesPanel, .formio-component-requiredDocumentsPanel) {
                                                    .card {
                                                        .card-body {
                                                            [class*="formio-component-priceRow"] {
                                                                &:not(.formio-hidden) {
                                                                    display: flex;
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                                &.notice {
                                                    > .card {
                                                        border-radius: @roundedCorners 0 0 @roundedCorners !important;
                                    
                                                        > .card-header {
                                                            border-radius: @roundedCorners 0 0 0;
                                                        }
                                                        > .card-body {
                                                            border-radius: 0 0 0 @roundedCorners;

                                                            &:only-child {
                                                                border-radius: @roundedCorners 0 0 @roundedCorners;
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                            &-columns {
                                                &.payment-fee {
                                                    display: flex !important;
                                                }
                                            }
                                            &.formio-component-textfield {
                                                .input-group.formio-multiple-mask-container {
                                                    .formio-multiple-mask-select {
                                                        border-radius: 0 @roundedCorners @roundedCorners 0 !important;
                                                        background-position: left 12px center;

                                                        & + .form-control.formio-multiple-mask-input {
                                                            border-radius: @roundedCorners 0 0 @roundedCorners !important;
                                                        }
                                                    }
                                                }
                                            }
                                            &.formio-component-multiple {
                                                .selection.dropdown {
                                                    flex-direction: row-reverse;
                                                }
                                            }
                                            &:is(.notice, .info-notice, .success-notice, .warning-notice, .error-notice) {
                                                border-radius: @roundedCorners 0 0 @roundedCorners !important;
                                            }
                                            &:is(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-url, .formio-component-address) {
                                                &.field-plus-button {
                                                    input {
                                                        &.form-control {
                                                            &:is([type="text"], [type="phoneNumber"], [type="email"]) {
                                                                border-top-right-radius: @roundedCorners !important;
                                                                border-bottom-right-radius: @roundedCorners !important;
                                                                border-top-left-radius: 0 !important;
                                                                border-bottom-left-radius: 0 !important;
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                        .wizard-page {
                                            .guide-form {
                                                > div[class^="col-md-4"]::before {
                                                    content: "\F104";
                                                }
                                            }
                                            .formio-component {
                                                &:is(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-url, .formio-component-address) {
                                                    .input-group {
                                                        .input-group-prepend {
                                                            border-radius: 0 @roundedCorners @roundedCorners 0 !important;

                                                            & + input.form-control {
                                                                border-top-left-radius: @roundedCorners !important;
                                                                border-bottom-left-radius: @roundedCorners !important;
                                                                border-top-right-radius: 0 !important;
                                                                border-bottom-right-radius: 0 !important;
                                                            }
                                                        }
                                                        input.form-control {
                                                            &:first-child:not(:last-child) {
                                                                border-top-left-radius: 0 !important;
                                                                border-bottom-left-radius: 0 !important;
                                                                border-top-right-radius: @roundedCorners !important;
                                                                border-bottom-right-radius: @roundedCorners !important;

                                                                & + .input-group-append {
                                                                    border-radius: @roundedCorners 0 0 @roundedCorners !important;
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                                &.formio-component-datetime {
                                                    .input-group {
                                                        input.form-control.input {
                                                            border-radius: 0 @roundedCorners @roundedCorners 0 !important;
                                                        }
                                                        .input-group-append {
                                                            border-radius: @roundedCorners 0 0 @roundedCorners !important;

                                                            .input-group-text {
                                                                border-radius: @roundedCorners 0 0 @roundedCorners;
                                                            }
                                                        }
                                                    }
                                                }
                                                &.formio-component-htmlelement {
                                                    &.alert.alert-danger {
                                                        border-radius: @roundedCorners 0 0 @roundedCorners !important;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                    .tabbed-modern-nav > .formio-form > div > nav .pagination li.page-item.active {
                                        inset-inline: auto;
                                    }
                                }
                            }
                            #entreprise-grid {
                                .table-responsive-container {
                                    table.table {
                                        thead {
                                            tr {
                                                th {
                                                    &:first-child {
                                                        border-radius: 0 @roundedCorners @roundedCorners 0;
                                                    }
                                                    &:last-child {
                                                        border-radius: @roundedCorners 0 0 @roundedCorners;
                                                    }
                                                }
                                                &.filters {
                                                    td {
                                                        select.form-control {
                                                            background-position: left 12px center;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                                .table-paging-container {
                                    ul.pagination {
                                        li {
                                            &:first-child {
                                                a::before {
                                                    content: "\F105";
                                                }
                                            }
                                            &:last-child {
                                                a::before {
                                                    content: "\F104";
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                            .new-formio-part-b-history {
                                .panel-heading {
                                    .restart-process-box {
                                        select.form-control {
                                            background-position: left 7px center;
                                        }
                                    }
                                }
                            }
                            .new-formio-part-b {
                                .formio-component-tabs {
                                    .carousel {
                                        .carousel-inner {
                                            .carousel-item {
                                                .documents-validator {
                                                    .tab-description-back-to-list {
                                                        i {
                                                            transform: rotate(180deg);
                                                        }
                                                    }
                                                    .carousel-control {
                                                        span {
                                                            transform: rotate(180deg);
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                    .validator-radio label[for*="-no"] {
                                        border-radius: @roundedCorners 0 0 @roundedCorners;
                                    }
                                    .validator-radio label[for*="-yes"] {
                                        border-radius: 0 @roundedCorners @roundedCorners 0;
                                    }
                                }
                            }
                        }
                    }
                }
                @media (min-width: 992px) {
                    :where(.col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12) {
                        &:not(td) {
                            float: right;
                        }
                    }
                }
                #rolereview {
                    .formio-component-tabs {
                        .card-header {
                            & + .card-body {
                                &.tab-pane.active {
                                    border-radius: @roundedCorners 0 @roundedCorners @roundedCorners !important;
                                }
                            }
                        }
                    }
                }
                .readonly-form-columns {
                    :is(.choices__list--single, .choices__item) {
                        padding-inline: 0;
                    }
                }
                .popover {
                    text-align: start;

                    &.top > .arrow {
                        margin-left: initial;
                        left: initial;
                        inset-inline-start: calc(50% - 11px);
    
                        &::after {
                            inset-inline-start: calc(50% - 10px);
                        }
                    }
                }
                .search-field {
                    background-position: center left 10px;
                }
                .daterangepicker.visible-calendars {
                    .calendar {
                        .calendar-table {
                            table {
                                th {
                                    &:is(.prev, .next) {
                                        i {
                                            transform: rotate(180deg);
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                .vnotify-container {
                    &.vn-bottom-right {
                        left: 16px;
                        right: initial;

                        .vnotify-text {
                            border-radius: calc(@roundedCorners - 1px) 0 0 calc(@roundedCorners - 1px);
                        }

                        .vnotify-item {
                            .vn-close {
                                left: 4px;
                                right: auto;
                            }
                        }
                    }
                }
            }
        }
    }
}