body {
    &.modern-theme {
        height: auto;

        #header {
            .secondary-menu {
                background-color: @accentColor;

                .contain-lg {
                    nav {
                        &.secondary-nav-container {
                            display: flex;
                            background: transparent;
                            align-items: center;

                            :where(.part-b-menu-services, .part-b-menu-roles) {
                                :where(.counter, .active-role-files-count) {
                                    background: rgba(255, 255, 255, 0.3);
                                    border-radius: 20px;
                                    padding-inline: 6px;
                                    padding-block: 2px;
                                    margin-inline-start: 5px;
                                    font-size: 10px;
                                    height: 15px;
                                }
                            }

                            .counter {
                                top: 1px;
                                position: relative;
                            }

                            .part-b-menu-services {
                                background-color: rgba(0, 0, 0, 0.3);
                            }

                            .part-b-menu-roles {
                                background-color: rgba(255, 255, 255, 0.2);

                                .counter {
                                    background: @accentColor70;
                                }

                                &.file-view {
                                    .roles-ui-dropdown {
                                        .menu {
                                            .item {
                                                padding: 0 !important;

                                                a {
                                                    padding: 14px 16px !important;
                                                    display: flex;
                                                    justify-content: space-between;
                                                    gap: 10px;
                                                    align-items: center;
                                                    color: black;

                                                    &:where(:hover, :active, :focus) {
                                                        text-decoration: none;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }

                            .part-b-menu-title {
                                font-size: 10px;
                                text-transform: uppercase;
                                padding-inline-start: 20px;
                                padding-block-start: 10px;
                                margin-block-end: -6px;
                                line-height: 1;
                                position: relative;
                                z-index: 2;
                                display: flex;
                                align-items: center;

                                a {
                                    display: flex;
                                    margin-inline-start: auto;
                                    margin-inline-end: 12px;
                                    align-items: center;
                                    gap: 4px;
                                    border-radius: 2px;
                                    color: white;

                                    &:where(:hover, :active, :focus) {
                                        background-color: rgba(0, 0, 0, 0.2);
                                        box-shadow: 0 0 0 8px rgba(0, 0, 0, 0.2);
                                        text-decoration: none;
                                    }

                                    span {
                                        text-transform: none;
                                    }
                                }
                            }

                            ul {
                                &.secondary-nav {
                                    li {
                                        a {
                                            background: transparent;
                                            height: 40px;
                                            display: flex;
                                            align-items: center;

                                            &:hover {
                                                background-color: @black10Color;
                                            }
                                        }

                                        &.active {
                                            a {
                                                background-color: @black10Color;
                                            }
                                        }
                                    }
                                }
                            }

                            :where(.services-select, .roles-ui-dropdown) {
                                background-color: transparent !important;
                                height: 100%;
                                display: flex;
                                align-items: center;
                                padding-inline-end: 32px !important;
                                padding-inline-start: 20px !important;
                                min-height: 56px !important;
                                min-width: 240px;
                                border-inline-start: solid 1px transparent !important;
                                border-inline-end: solid 1px transparent !important;
                                animation: fadeIn 150ms forwards;
                                margin-top: -24px;
                                padding-top: 22px !important;
                                padding-bottom: 0 !important;
                                border-radius: 0;
                                border: none;
                                float: none;

                                i {
                                    &.dropdown {
                                        padding: 0 12px;
                                        inset-inline-end: 0;
                                        margin: 0;
                                        height: 52px;
                                        font-family: 'Font Awesome 5 Pro';
                                        font-weight: 300;
                                        -moz-osx-font-smoothing: grayscale;
                                        -webkit-font-smoothing: antialiased;
                                        font-style: normal;
                                        font-variant: normal;
                                        text-rendering: auto;
                                        line-height: 1;
                                        font-size: 16px;
                                        align-items: center;
                                        display: inline-flex;
                                        color: white;

                                        &::before {
                                            content: "\F107";
                                        }
                                    }
                                }

                                input {
                                    height: 32px;
                                    top: 20px;
                                    padding-block: 0;
                                    padding-inline-start: 18px;
                                    padding-inline-end: 34px;
                                }

                                .menu {
                                    border: none;
                                    border-radius: @roundedCorners !important;
                                    max-height: 340px;
                                    top: calc(100% + 6px);

                                    .item {
                                        text-align: start;
                                        font-size: 14px;
                                        line-height: 1.3;
                                        padding: 14px 16px !important;
                                        font-weight: 400;
                                        opacity: 0.8;

                                        &.active {
                                            opacity: 1;
                                        }

                                        .files-count {
                                            font-size: 90%;
                                        }
                                    }
                                }

                                &.active {
                                    border: none !important;
                                    border-inline-start: solid 1px transparent !important;
                                    border-inline-end: solid 1px transparent !important;

                                    .text {
                                        color: @whiteColor !important;
                                        opacity: 0.15;
                                    }

                                    input {
                                        color: @whiteColor;
                                    }

                                    i {
                                        color: @whiteColor;
                                    }
                                }

                                .text {
                                    max-width: 300px;
                                    white-space: nowrap;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    line-height: 2;
                                    color: white;

                                    span {
                                        font-weight: 400;
                                    }
                                }

                                @media screen and (max-width: 1080px) {
                                    width: 100%;
                                    padding-inline-start: 18px !important;
                                    margin-inline-end: 0;
                                }
                            }

                            .roles-ui-dropdown {
                                .text {
                                    display: flex;
                                    align-items: center;

                                    > :where(span, a) {
                                        &:only-child {
                                            display: contents;
                                            color: inherit;
                                        }
                                    }
                                }

                                .menu {
                                    .item {
                                        >span {
                                            display: flex;
                                            justify-content: space-between;
                                            align-items: center;
                                        }
                                    }

                                    &.hide-bots {
                                        .item.bot {
                                            display: none;
                                        }

                                        .fa-toggle-off {
                                            display: block;
                                        }

                                        .fa-toggle-on {
                                            display: none;
                                        }
                                    }
                                }

                                :where(.text .item-role-name, .active-role-name) {
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                }

                                :where(.text .files-count, .active-role-files-count) {
                                    height: 14px;
                                    line-height: 1;
                                    background: @accentColor70;
                                    border-radius: 20px;
                                    padding-inline: 6px;
                                    padding-block: 2px;
                                    margin-inline-start: 5px;
                                    font-size: 10px;
                                }
                            }

                            .active-role-files-count {
                                height: 15px;
                            }

                            .bots-toggler {
                                display: flex;
                                padding: 14px 16px;
                                align-items: center;
                                flex: 1;
                                cursor: pointer;
                                font-weight: 500;

                                i {
                                    color: @accentColor;

                                    &.fa-toggle-off {
                                        display: none;
                                    }
                                }

                                span {
                                    margin-inline-start: 1rem;
                                    line-height: 1;
                                    height: 100%;
                                    align-items: center;
                                    display: flex;
                                }

                                &:hover {
                                    background: rgba(0, 0, 0, 0.05);
                                }
                            }

                            .bots-toggler-entry {
                                position: sticky;
                                bottom: 0;
                                background-color: white;
                                z-index: 99;
                                border-top: solid 1px #E8EAE9;
                            }

                            .service-bar-user-lang-menu.partb-newformio {
                                &[hidden] {
                                    display: block;
                                    margin-inline-start: auto;
                                    margin-inline-end: -1.5rem;

                                    .headerbar-right {
                                        height: 52px;
                                        flex-direction: row-reverse;
                                        min-height: 52px;

                                        ul {
                                            &#language_selector_container {
                                                >li {
                                                    &.dropdown-user {
                                                        a {
                                                            &.dropdown-toggle {
                                                                color: @whiteColor;
                                                                padding: 0 1.5rem;
                                                                height: 52px;
                                                                display: flex;
                                                                align-items: center;
                                                                gap: 6px;
                                                                line-height: 1;

                                                                &:hover {
                                                                    background: @black10Color;
                                                                }

                                                                i {
                                                                    font-size: 16px;
                                                                    margin-inline-start: 0.8rem;
                                                                }
                                                            }
                                                        }

                                                        &.open {
                                                            a {
                                                                &.dropdown-toggle {
                                                                    background: @black10Color;
                                                                }
                                                            }

                                                            ul {
                                                                &.dropdown-menu {
                                                                    background-color: @whiteColor;
                                                                    padding: 0;
                                                                    inset-inline-end: inherit;
                                                                    border-radius: 0 @roundedCorners @roundedCorners @roundedCorners;
                                                                    min-width: 100%;

                                                                    li {
                                                                        a {
                                                                            color: @accentColor;
                                                                            font-weight: 600;

                                                                            &:hover {
                                                                                color: @darkTextColor;
                                                                                background-color: @pageBgColor;
                                                                            }
                                                                        }

                                                                        &:first-child {
                                                                            a {
                                                                                border-radius: 0 @roundedCorners 0 0;
                                                                            }
                                                                        }

                                                                        &:last-child {
                                                                            a {
                                                                                border-radius: 0 0 @roundedCorners @roundedCorners;
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }

                                            &#connect {
                                                height: 100%;

                                                li {
                                                    &.dropdown {
                                                        &.user {
                                                            height: 100%;
                                                            padding-inline-start: 12px;

                                                            a {
                                                                text-transform: none;
                                                                font-weight: 600;

                                                                &.dropdown-toggle {
                                                                    &.left-part {
                                                                        background: transparent;
                                                                        margin: 0;
                                                                        border: none;
                                                                        padding-inline-start: 0;
                                                                        padding-inline-end: 0;
                                                                        border-radius: 0;

                                                                        i {
                                                                            &.user-icon {
                                                                                display: none;
                                                                            }
                                                                        }

                                                                        span {
                                                                            &.user-name-text {
                                                                                padding: 0;
                                                                            }
                                                                        }

                                                                        &:hover {
                                                                            text-decoration: underline;
                                                                        }

                                                                        .user-initials {
                                                                            display: none;
                                                                        }

                                                                        @media screen and (max-width: 768px) {
                                                                            display: block;

                                                                            .user-name-text {
                                                                                display: block;

                                                                                .user-initials {
                                                                                    display: block;

                                                                                    span {
                                                                                        display: inline;
                                                                                    }
                                                                                }

                                                                                .user-fullname {
                                                                                    display: none;
                                                                                }
                                                                            }
                                                                        }
                                                                    }

                                                                    &.user-dropdown-toggle {
                                                                        background: transparent;
                                                                        border: none;
                                                                        padding-inline-end: 1.3rem;
                                                                        padding-inline-start: 1.3rem;
                                                                        border-radius: 0;
                                                                        cursor: pointer;

                                                                        &:hover {
                                                                            background-color: @black10Color;
                                                                        }

                                                                        i {
                                                                            &.angle-icon {
                                                                                margin-inline-end: 0;
                                                                                font-weight: 300;
                                                                                font-size: 16px;
                                                                            }
                                                                        }

                                                                        @media screen and (max-width: 768px) {
                                                                            height: 100%;
                                                                        }
                                                                    }
                                                                }
                                                            }

                                                            &:where(:hover, .open) {
                                                                background-color: @black10Color;

                                                                a {
                                                                    color: @whiteColor;
                                                                }
                                                            }

                                                            &.open {
                                                                a {
                                                                    &.dropdown-toggle {
                                                                        &.user-dropdown-toggle {
                                                                            background-color: @black10Color;

                                                                            i {
                                                                                color: @whiteColor;
                                                                            }
                                                                        }
                                                                    }
                                                                }

                                                                ul {
                                                                    &.dropdown-menu {
                                                                        width: 100%;
                                                                        min-width: fit-content;
                                                                        background-color: @whiteColor;
                                                                        color: @accentColor;
                                                                        padding: 0;
                                                                        border-radius: 0 0 @roundedCorners @roundedCorners;

                                                                        li {
                                                                            &.divider {
                                                                                margin: 0;
                                                                                background-color: @accentColor10;
                                                                                height: 1px;

                                                                                &:first-child {
                                                                                    display: none;
                                                                                }
                                                                            }

                                                                            a {
                                                                                padding: 7px 20px;
                                                                                font-size: 13px;
                                                                                font-weight: 600;
                                                                                color: @darkTextColor;

                                                                                &:hover {
                                                                                    background-color: @black5Color;
                                                                                }
                                                                            }

                                                                            &:last-child {
                                                                                a {
                                                                                    border-radius: 0 0 @roundedCorners @roundedCorners !important;
                                                                                }
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                            }

                                                            @media screen and (max-width: 768px) {
                                                                padding: 0 0 0 12px;
                                                                align-items: center;
                                                            }
                                                        }
                                                    }

                                                    &:not(.dropdown) {
                                                        height: 100%;

                                                        a {
                                                            height: 100%;
                                                            display: flex;
                                                            align-items: center;
                                                            border-radius: 0 !important;
                                                            background: transparent;
                                                            margin: 0 !important;
                                                            padding: 0 2rem;
                                                            text-transform: none;
                                                            font-weight: 600;
                                                            box-shadow: none !important;

                                                            &:hover {
                                                                background-color: @black10Color !important;
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                    @media screen and (max-width: 1200px) {
                                        margin-inline-end: 0;
                                    }
                                }
                            }

                            .responsive-menu-toggler {
                                display: none;
                                background-color: transparent;
                                background-image: none;
                                border: none;
                                font-size: 20px;
                                margin-inline-start: auto;
                                width: 62px;
                                padding: 0;
                                height: 57px;
                                min-width: 62px;
                            }

                            &.partb-menubar {
                                flex-wrap: wrap;
                            }

                            @media screen and (max-width: 1080px) {
                                .all-roles-menu {
                                    ul {
                                        &.secondary-nav {
                                            inset-inline-start: 0;
                                        }
                                    }
                                }

                                .service-bar-user-lang-menu {
                                    &.partb-newformio {
                                        margin-inline-start: auto !important;
                                        margin-inline-end: 8px;
                                    }
                                }
                            }

                            @media screen and (max-width: 680px) {
                                .service-bar-user-lang-menu.partb-newformio[hidden] {
                                    display: none;

                                    &.responsive-open {
                                        display: block;
                                        position: absolute;
                                        background: #4651aa;
                                        inset-inline-end: 8px;
                                        top: calc(100% + 4px);
                                        padding-inline: 10px;
                                        border-radius: 6px;

                                        .headerbar-right {
                                            height: 57px;
                                        }
                                    }
                                    .headerbar-right ul#language_selector_container > li.dropdown-user {
                                        padding-block: 0;

                                        a.dropdown-toggle {
                                            height: 57px;
                                        }
                                    }
                                }
                                .responsive-menu-toggler {
                                    display: grid;
                                    place-content: center;
                                }
                            }

                            @media screen and (max-width: 640px) {
                                .services-select {
                                    margin-inline-end: auto;
                                    margin-inline-start: 0;
                                    max-height: 200px;
                                }

                                .service-bar-user-lang-menu {
                                    &.partb-newformio {
                                        position: relative;

                                        .headerbar-right {
                                            ul {
                                                &#language_selector_container {
                                                    li {
                                                        &.dropdown-user {
                                                            padding: 0;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        #base {
            #content {
                .section-body {
                    .contain-lg {
                        >.row {
                            >.col-lg-12 {
                                >.panel {
                                    padding: @panelPadding;
                                    background: @whiteColor;
                                    border: none;
                                    border-radius: @roundedCorners;
                                    box-shadow: 0 0 20px @black5Color;

                                    >.panel-body {
                                        .table-responsive-container {
                                            table {
                                                thead {
                                                    opacity: 1;

                                                    tr {
                                                        &.responsive-sort {
                                                            display: none;

                                                            td {
                                                                padding: 0 0 10px;
                                                                display: flex;
                                                                align-items: center;

                                                                .sorting-label {
                                                                    background: @accentColor5Solid;
                                                                    color: @accentColor;
                                                                    font-weight: 600;
                                                                    padding: 6px 12px;
                                                                    border-radius: @roundedCorners;
                                                                    display: inline-flex;
                                                                    white-space: nowrap;
                                                                    transition-duration: 0.3s;

                                                                    .sort_by_label {
                                                                        &~span {
                                                                            margin-inline-start: 6px;
                                                                            font-weight: 600;
                                                                            position: relative;

                                                                            &::after {
                                                                                content: "\f354";
                                                                                font-family: 'Font Awesome 5 Pro';
                                                                                font-weight: 900;
                                                                                -moz-osx-font-smoothing: grayscale;
                                                                                -webkit-font-smoothing: antialiased;
                                                                                display: inline-block;
                                                                                font-style: normal;
                                                                                font-variant: normal;
                                                                                text-rendering: auto;
                                                                                line-height: 1;
                                                                                font-size: 1.1rem;
                                                                                margin-inline-start: 6px;
                                                                                border: none;
                                                                                opacity: 0.8;
                                                                            }

                                                                            &.reverse-ordering {
                                                                                &::after {
                                                                                    content: "\f357";
                                                                                    color: #4a6288;
                                                                                    font-weight: 900;
                                                                                }
                                                                            }
                                                                        }
                                                                    }

                                                                    &:hover {
                                                                        background: @accentColor10Solid;
                                                                    }
                                                                }

                                                                .cells-filter-toggler {
                                                                    background: @accentColor5Solid;
                                                                    color: @accentColor;
                                                                    padding: 6px 12px;
                                                                    border-radius: @roundedCorners;
                                                                    transition-duration: 0.3s;
                                                                    display: inline-flex;
                                                                    height: 37px;
                                                                    align-items: center;
                                                                    margin-inline-start: 12px;
                                                                    cursor: pointer;

                                                                    &:hover {
                                                                        background: @accentColor10Solid;
                                                                    }
                                                                }
                                                            }
                                                        }

                                                        &:first-child+tr {
                                                            th {
                                                                background-color: @accentColor5;
                                                                text-transform: none;
                                                                color: @accentColor;
                                                                font-size: 14px;
                                                                font-weight: 500;
                                                                padding: 10px 12px;
                                                                border: none !important;

                                                                &:first-child {
                                                                    border-radius: @roundedCorners 0 0 @roundedCorners;
                                                                }

                                                                &:last-child {
                                                                    border-radius: 0 @roundedCorners @roundedCorners 0;
                                                                }

                                                                a {
                                                                    text-transform: none;
                                                                    color: @accentColor;
                                                                    font-size: 14px;
                                                                    letter-spacing: 0;
                                                                }

                                                                .sortorder {
                                                                    top: 0;
                                                                    height: 100%;
                                                                    inset-inline-end: auto;
                                                                    margin-inline-start: 12px;

                                                                    &::after {
                                                                        content: "\f176";
                                                                        font-weight: 600;
                                                                        font-size: 14px;
                                                                        color: @accentColor;
                                                                        top: calc(50% - 7.5px);
                                                                    }

                                                                    &.reverse {
                                                                        &::after {
                                                                            content: "\f175";
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }

                                                        &.filters {
                                                            td {
                                                                border-top: none;
                                                                padding: 0;

                                                                :where(select, input) {
                                                                    border: solid 1px @accentColor10;
                                                                    background-color: transparent;
                                                                    border-radius: @roundedCorners;
                                                                    margin-top: 18px;
                                                                    height: 34px;
                                                                    box-shadow: none;
                                                                    margin-inline-end: 20px;
                                                                    width: calc(100% - 20px);
                                                                    margin-bottom: 12px;
                                                                    font-weight: 600;
                                                                    font-size: 13px;
                                                                    color: @darkTextColor;
                                                                }

                                                                select {
                                                                    padding-inline-start: 8px;
                                                                }
                                                            }
                                                        }
                                                    }
                                                }

                                                tbody {
                                                    tr {
                                                        td {
                                                            color: @darkTextColor;
                                                            font-size: 15px;
                                                            padding: 15px 12px;

                                                            span {
                                                                &:where(.label-status, .label) {
                                                                    font-weight: 600;
                                                                    background-color: transparent;

                                                                    &:not(:empty) {
                                                                        display: flex;
                                                                    }

                                                                    &:where(.success, .label-success) {
                                                                        color: @appStatusProcessed;
                                                                    }

                                                                    &:where(.info, .label-info) {
                                                                        color: @appStatusPending;
                                                                    }

                                                                    &:where(.warning, .label-warning) {
                                                                        color: @appStatusAction;
                                                                    }

                                                                    &:where(.danger, .label-danger) {
                                                                        color: @appStatusRejected;
                                                                    }
                                                                }
                                                            }

                                                            &.date-column {
                                                                font-size: 12px;
                                                            }

                                                            file-status {
                                                                display: inline-flex;
                                                                margin-inline-end: 0;
                                                                position: relative;
                                                                top: -1px;

                                                                span {
                                                                    &.label {
                                                                        padding: 3px 5px !important;
                                                                        font-size: 12px;
                                                                        margin-inline-end: 6px;
                                                                        font-weight: 600;
                                                                    }
                                                                }

                                                                &:empty {
                                                                    display: none !important;
                                                                }
                                                            }
                                                        }

                                                        &:nth-child(odd) {
                                                            background-color: transparent;

                                                            td {
                                                                background-color: transparent;
                                                            }
                                                        }

                                                        &:hover {
                                                            td {
                                                                background-color: @darkTextColor2;
                                                            }
                                                        }
                                                    }
                                                }

                                                @media screen and (max-width: 768px) {
                                                    thead {
                                                        display: block !important;
                                                        position: absolute;
                                                        z-index: 99;

                                                        tr {
                                                            &.responsive-sort {
                                                                display: block;

                                                                &+tr {
                                                                    display: none;
                                                                    background-color: @accentColor10Solid;
                                                                    position: absolute;
                                                                    z-index: 999;
                                                                    border-radius: 0 0 @roundedCorners @roundedCorners;
                                                                    transition-duration: 0.3s;
                                                                    overflow: hidden;
                                                                    flex-direction: column;

                                                                    th {
                                                                        flex: 1;
                                                                        width: 100%;
                                                                        background-color: transparent;
                                                                        padding: 0;

                                                                        &:where(.reg-status, :last-child) {
                                                                            display: none;
                                                                        }

                                                                        input {
                                                                            display: none;
                                                                        }

                                                                        >a {
                                                                            display: block;
                                                                            padding: 10px 12px;

                                                                            &:hover {
                                                                                background-color: @accentColor10;
                                                                            }
                                                                        }

                                                                        >span {
                                                                            background-color: transparent;
                                                                            border-radius: 0 !important;
                                                                            padding: 10px 12px;

                                                                            &:hover {
                                                                                background-color: @accentColor10;
                                                                            }
                                                                        }

                                                                        .sortorder {
                                                                            display: none;
                                                                        }
                                                                    }
                                                                }
                                                            }

                                                            &.filters {
                                                                display: none;
                                                            }
                                                        }
                                                    }

                                                    &.show-sorting {
                                                        thead {
                                                            tr {
                                                                &.responsive-sort+tr {
                                                                    display: flex;
                                                                    transform: translateY(-10px);
                                                                }

                                                                &.responsive-sort {
                                                                    display: block;

                                                                    td {
                                                                        span {
                                                                            &.sorting-label {
                                                                                border-radius: @roundedCorners @roundedCorners 0 0;
                                                                                background-color: @accentColor10Solid;
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }

                                                    &.show-search {
                                                        thead {
                                                            tr {
                                                                &.filters {
                                                                    display: flex;
                                                                    transform: translateY(-10px);
                                                                    width: 244px;
                                                                    flex-direction: column;
                                                                    background-color: @accentColor10Solid;
                                                                    border-radius: @roundedCorners 0 @roundedCorners @roundedCorners;

                                                                    td {
                                                                        span {
                                                                            display: none;
                                                                        }

                                                                        select {
                                                                            margin: 12px 12px 0;
                                                                            background-color: @whiteColor;
                                                                        }

                                                                        input {
                                                                            display: block;
                                                                            margin: 12px !important;
                                                                            background: @whiteColor;
                                                                            padding-inline-start: 12px;
                                                                            width: calc(100% - 24px);
                                                                        }

                                                                        &:first-child {
                                                                            input {
                                                                                margin: 12px 12px 0 !important;
                                                                            }
                                                                        }

                                                                        &:last-child {
                                                                            display: none;
                                                                        }
                                                                    }
                                                                }

                                                                &.responsive-sort {
                                                                    td {
                                                                        span {
                                                                            &.cells-filter-toggler {
                                                                                border-radius: @roundedCorners @roundedCorners 0 0;
                                                                                background-color: @accentColor10;
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }

                                                    tbody {
                                                        display: grid;
                                                        grid-auto-flow: column;
                                                        max-width: 100%;
                                                        gap: 12px;
                                                        margin-top: 50px;

                                                        tr {
                                                            display: grid;
                                                            border-radius: @roundedCorners;
                                                            border: solid 1px @accentColor10;
                                                            grid-template-rows: auto auto auto auto auto;
                                                            grid-template-columns: 1fr;
                                                            min-width: 240px;

                                                            td {
                                                                width: auto;
                                                                border: none;
                                                                padding-top: 1rem;
                                                                padding-bottom: 1rem;
                                                                font-weight: 600;

                                                                .responsive-label {
                                                                    &[hidden] {
                                                                        display: block;
                                                                        font-size: 12px;
                                                                        font-weight: 400;
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }

                                            .entry-status-legend {
                                                gap: 20px;

                                                >div {
                                                    display: flex;
                                                    align-items: center;
                                                    gap: 5px;

                                                    span {
                                                        line-height: 1;
                                                    }
                                                }
                                            }
                                        }

                                        .table-paging-container {
                                            background-color: transparent;

                                            dir-pagination-controls {
                                                display: flex;
                                            }

                                            ul {
                                                &.pagination {
                                                    background-color: @accentColor5;
                                                    border-radius: @roundedCorners;
                                                    overflow: hidden;
                                                    margin-top: 32px;
                                                    margin-bottom: 0;

                                                    li {
                                                        display: inline-flex;
                                                        margin-inline-end: 1px;

                                                        a {
                                                            background-color: transparent;
                                                            height: 46px;
                                                            display: flex;
                                                            align-items: center;
                                                            border-radius: @roundedCorners;
                                                            color: @darkTextColor;
                                                            font-weight: 600;
                                                            font-size: 12px;
                                                        }

                                                        &:where(.active, :hover) {
                                                            a {
                                                                background-color: @accentColor10;
                                                            }
                                                        }

                                                        &:where(:first-child, :last-child) {
                                                            a {
                                                                color: transparent;
                                                                position: relative;
                                                                width: 38px;

                                                                &::before {
                                                                    font-family: 'Font Awesome 5 Pro';
                                                                    font-weight: 300;
                                                                    -moz-osx-font-smoothing: grayscale;
                                                                    -webkit-font-smoothing: antialiased;
                                                                    display: inline-block;
                                                                    font-style: normal;
                                                                    font-variant: normal;
                                                                    text-rendering: auto;
                                                                    line-height: 1;
                                                                    font-size: 22px;
                                                                    color: @darkTextColor;
                                                                    position: absolute;
                                                                }
                                                            }
                                                        }

                                                        &:first-child {
                                                            a {
                                                                &::before {
                                                                    content: "\f104";
                                                                }
                                                            }
                                                        }

                                                        &:last-child {
                                                            a {
                                                                &::before {
                                                                    content: "\f105";
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }

                                        #entreprise-grid {
                                            overflow: hidden;
                                        }

                                        .status-legend-filter {
                                            margin-top: 32px;

                                            .application-status {
                                                margin-inline-end: -2000px;
                                                height: 0;
                                                transition-duration: 0.4s;
                                                transition-timing-function: cubic-bezier(0, 0.18, 0, 1);

                                                .applications-statuses {
                                                    display: flex;

                                                    span {
                                                        color: @darkTextColor;
                                                        font-size: 11px;
                                                        padding: 13px 12px;
                                                        line-height: 20px;

                                                        &.label-status {
                                                            font-weight: 600;
                                                            display: flex;

                                                            &.success {
                                                                color: @appStatusProcessed;
                                                            }

                                                            &.info {
                                                                color: @appStatusPending;
                                                            }

                                                            &.warning {
                                                                color: @appStatusAction;
                                                            }

                                                            &.danger {
                                                                color: @appStatusRejected;
                                                            }

                                                            &:hover {
                                                                box-shadow: inset 0 -1px 0;
                                                            }
                                                        }

                                                        &.active-filter {
                                                            .label-status {
                                                                box-shadow: inset 0 -1px 0;
                                                            }
                                                        }
                                                    }
                                                }

                                                &.show-legend {
                                                    margin-inline-end: 0;
                                                    height: auto;

                                                    @media screen and (max-width: 480px) {
                                                        .applications-statuses {
                                                            display: grid;

                                                            span {
                                                                text-align: start;
                                                            }
                                                        }
                                                    }
                                                }
                                            }

                                            .btn-link {
                                                font-size: 12px;
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        >div {
                            >div {
                                >div {
                                    &.panel {
                                        padding: @panelPadding;
                                        background: @whiteColor;
                                        border: none;
                                        border-radius: @roundedCorners;
                                        box-shadow: 0 0 20px @black5Color;

                                        .panel-body {
                                            table {
                                                thead {
                                                    opacity: 1;

                                                    tr {
                                                        &:first-child {
                                                            th {
                                                                background-color: @accentColor5;
                                                                text-transform: none;
                                                                color: @accentColor;
                                                                font-size: 14px;
                                                                font-weight: 500;
                                                                padding: 10px 12px;
                                                                border: none !important;

                                                                &:first-child {
                                                                    border-radius: @roundedCorners 0 0 @roundedCorners;
                                                                }

                                                                &:last-child {
                                                                    border-radius: 0 @roundedCorners @roundedCorners 0;
                                                                }

                                                                a {
                                                                    text-transform: none;
                                                                    color: @accentColor;
                                                                    font-size: 14px;
                                                                    letter-spacing: 0;
                                                                }
                                                            }
                                                        }
                                                    }
                                                }

                                                @media screen and (max-width: 768px) {
                                                    thead {
                                                        display: none;
                                                    }

                                                    tbody {
                                                        display: block;

                                                        tr {
                                                            display: grid;

                                                            td {
                                                                display: flex;
                                                                flex-direction: column;
                                                                text-align: start !important;
                                                                padding-bottom: 1rem;

                                                                &:first-child {
                                                                    padding-top: 1.5rem;
                                                                }

                                                                span {
                                                                    text-align: start;

                                                                    &.partb-file-resume-responsive-table-label {
                                                                        &[hidden] {
                                                                            display: block;
                                                                            color: @accentColor;
                                                                            font-size: 14px;
                                                                            font-weight: 600;
                                                                            letter-spacing: 0.125rem;
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                }

                                                &.part-b-single-file-resume {
                                                    thead {
                                                        tr {
                                                            th {
                                                                &:where(.date-col, .status-col) {
                                                                    width: 200px;
                                                                }
                                                            }
                                                        }
                                                    }

                                                    tbody {
                                                        tr {
                                                            td {
                                                                padding: 15px 12px;
                                                                vertical-align: middle;
                                                                line-height: 1;
                                                                font-size: 15px;

                                                                &.date-col {
                                                                    font-size: 12px;
                                                                }

                                                                span {
                                                                    &.label {
                                                                        &.label-status {
                                                                            font-weight: 500;
                                                                            padding-bottom: 0 !important;

                                                                            &.filepending {
                                                                                color: @appStatusPending;
                                                                            }

                                                                            &.filevalidated {
                                                                                color: @appStatusProcessed;
                                                                            }

                                                                            &.filedecline {
                                                                                color: @appStatusAction;
                                                                            }

                                                                            &.filereject {
                                                                                color: @appStatusRejected;
                                                                            }
                                                                        }
                                                                    }
                                                                }

                                                                file-status {
                                                                    display: inline-flex;
                                                                    margin-inline-end: 0;
                                                                    position: relative;
                                                                    top: 1px;

                                                                    span {
                                                                        &.label {
                                                                            padding: 3px 5px 3px 0px !important;
                                                                            font-size: 12px;
                                                                            margin-inline-end: 6px;
                                                                            font-weight: 500;
                                                                        }
                                                                    }

                                                                    &:empty {
                                                                        display: none !important;
                                                                    }
                                                                }

                                                                strong {
                                                                    font-weight: 500;
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }

                                    &.row {
                                        .panel {
                                            padding: @panelPadding;
                                            background: @whiteColor;
                                            border: none;
                                            border-radius: @roundedCorners;
                                            box-shadow: 0 0 20px @black5Color;

                                            .panel-heading {
                                                padding: 0 !important;
                                                align-items: center;

                                                h3 {
                                                    padding: 0;
                                                }

                                                .restart-process-box {
                                                    margin-top: 0 !important;

                                                    @media screen and (max-width: 560px) {
                                                        display: grid;
                                                        margin-inline-start: 0 !important;
                                                        grid-template-columns: 1fr 1fr;
                                                        width: 100%;

                                                        button+button {
                                                            margin-inline-end: 0 !important;
                                                        }

                                                        label {
                                                            grid-column-start: 1;
                                                            grid-column-end: 3;
                                                        }
                                                    }
                                                }

                                                @media screen and (max-width: 768px) {
                                                    flex-direction: column;
                                                    align-items: flex-start !important;

                                                    h3 {
                                                        margin-bottom: 12px;
                                                    }
                                                }
                                            }

                                            .panel-body {
                                                padding: 2rem 0 0 !important;

                                                >.row {
                                                    margin: 0;

                                                    >.col-sm-12 {
                                                        padding: 0;
                                                    }
                                                }

                                                @media screen and (max-width: 768px) {
                                                    max-width: calc(100vw - 118px);
                                                }
                                            }
                                        }
                                    }
                                }

                                #tabs-zone {
                                    >.panel {
                                        >.part-b-forms-tabs {
                                            ul {
                                                &.nav.nav-tabs {
                                                    >li {
                                                        box-shadow: none;
                                                        height: auto;

                                                        a {
                                                            font-size: 23px;
                                                            color: @accentColor40;
                                                            font-weight: 600;
                                                            padding-inline-start: 42px;
                                                            padding-inline-end: 42px;
                                                            border-top: none;
                                                            border-top-left-radius: @roundedCorners;
                                                            border-top-right-radius: @roundedCorners;
                                                        }

                                                        &.active {
                                                            a {
                                                                background-color: @accentColor10;
                                                                color: @accentColor;
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }

                                        >.panel-body {
                                            &.tab-content {
                                                padding: @panelPadding !important;
                                                background: @whiteColor;
                                                border: none;
                                                border-radius: 0 @roundedCorners @roundedCorners @roundedCorners;
                                                box-shadow: 0 0 20px @black5Color;

                                                #form-type-B6 {
                                                    background: transparent;
                                                    box-shadow: none;

                                                    .tab-description {
                                                        margin: 0;
                                                        padding: 0 0 20px;
                                                        line-height: 1;

                                                        span {
                                                            color: @darkTextColor;

                                                            &.tab-description-back-to-list {
                                                                &::before {
                                                                    top: 0;
                                                                    line-height: 15px;
                                                                }
                                                            }
                                                        }
                                                    }

                                                    .bg-gray {
                                                        background-color: transparent;

                                                        &.px-2 {
                                                            &.pb-1 {
                                                                padding: 0 !important;
                                                            }
                                                        }
                                                    }

                                                    .document-revision-list {
                                                        .validation-headers {
                                                            form {
                                                                &.formio-form {
                                                                    .form-group {
                                                                        &.form-field-type-columns {
                                                                            margin-top: 0;

                                                                            .form-field-type-button {
                                                                                button {
                                                                                    background-color: transparent;

                                                                                    &:hover {
                                                                                        box-shadow: none !important;
                                                                                    }
                                                                                }
                                                                            }

                                                                            .form-field-type-mycounter {
                                                                                span {
                                                                                    &.bullet {
                                                                                        font-size: 13px;
                                                                                        display: block;
                                                                                        line-height: 1.3em;
                                                                                        background: @accentColor;
                                                                                    }
                                                                                }
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }

                                                    #carouselRevision {
                                                        .carousel-inner {
                                                            .item {
                                                                .documents-validator {
                                                                    .carousel-selected-index {
                                                                        inset-inline-start: 80px;
                                                                    }

                                                                    form {
                                                                        >.form-group {
                                                                            &.form-field-type-panel {
                                                                                margin-top: 0;

                                                                                >.panel {
                                                                                    box-shadow: none !important;

                                                                                    >.panel-heading {
                                                                                        margin: 0;
                                                                                        border-radius: @roundedCorners;
                                                                                    }

                                                                                    >.panel-body {
                                                                                        padding: 0;
                                                                                        box-shadow: none;

                                                                                        .formio-component-rejectionrow {
                                                                                            margin: 2rem 0 !important;

                                                                                            .form-field-type-checkbox {
                                                                                                &.check-switch {
                                                                                                    .checkbox {
                                                                                                        margin-top: 15px;
                                                                                                    }
                                                                                                }
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                }
                                                                            }
                                                                        }
                                                                    }
                                                                }

                                                                .carousel-controls {
                                                                    .carousel-control {
                                                                        &.left {
                                                                            inset-inline-start: 0;
                                                                            border-radius: @roundedCorners 0 0 @roundedCorners;
                                                                        }

                                                                        &.right {
                                                                            inset-inline-start: 34px;
                                                                        }
                                                                    }
                                                                }

                                                                .img-thumbnail {
                                                                    object {
                                                                        display: block;
                                                                    }
                                                                }

                                                                .revision-doc-info-column {
                                                                    form {
                                                                        >.form-group {
                                                                            &.form-field-type-panel {
                                                                                &:first-of-type {
                                                                                    margin-top: 20px;
                                                                                }

                                                                                >.panel {
                                                                                    box-shadow: none !important;

                                                                                    >.panel-body {
                                                                                        padding: 1rem;
                                                                                        box-shadow: none !important;
                                                                                    }
                                                                                }
                                                                            }
                                                                        }
                                                                    }
                                                                }

                                                                &.active {
                                                                    &.single-doc {
                                                                        .carousel-selected-index {
                                                                            inset-inline-start: 23px !important;
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                }

                                                .part-b-tabs-tab-data {
                                                    margin: -35px;
                                                    background: transparent;
                                                    box-shadow: none;
                                                    border-radius: @roundedCorners;
                                                    overflow: hidden;

                                                    .zone_revision {
                                                        .revision-block {
                                                            form {
                                                                .form-group {
                                                                    &.form-field-type-mytabs {
                                                                        margin-top: 0;

                                                                        .tabs-above {
                                                                            .nav-tabs {
                                                                                background-color: transparent;

                                                                                li {
                                                                                    &.active {
                                                                                        a {
                                                                                            background-color: transparent;
                                                                                            box-shadow: inset 0 -2px 0 @accentColor !important;
                                                                                        }
                                                                                    }

                                                                                    a {
                                                                                        color: @accentColor;
                                                                                        text-decoration: none;
                                                                                        font-weight: 600;
                                                                                        padding: 0 35px;
                                                                                    }

                                                                                    &:hover {
                                                                                        a {
                                                                                            border-bottom: none;
                                                                                            box-shadow: inset 0 -2px 0 @accentColor !important;
                                                                                        }
                                                                                    }
                                                                                }
                                                                            }

                                                                            .tab-content {
                                                                                .tab-pane {
                                                                                    .card {
                                                                                        .card-body {
                                                                                            >.form-field-type-panel {
                                                                                                box-shadow: none;
                                                                                                margin-top: 0;
                                                                                                margin-bottom: 0 !important;

                                                                                                >.panel {
                                                                                                    background-color: transparent;
                                                                                                    padding: 0 35px;

                                                                                                    >.panel-heading {
                                                                                                        padding-inline-start: 0;
                                                                                                        padding-inline-end: 0;
                                                                                                    }

                                                                                                    >.panel-body {
                                                                                                        padding-inline-start: 0;
                                                                                                        padding-inline-end: 0;

                                                                                                        .form-field-type-panel {
                                                                                                            background-color: @whiteColor;
                                                                                                            box-shadow: none;
                                                                                                            padding: 0;

                                                                                                            .panel {
                                                                                                                padding: 0;
                                                                                                                background-color: @whiteColor;

                                                                                                                .panel-heading {
                                                                                                                    background-color: @whiteColor;

                                                                                                                    h3 {
                                                                                                                        &.panel-title {
                                                                                                                            font-weight: 600;
                                                                                                                            text-transform: none;
                                                                                                                        }
                                                                                                                    }
                                                                                                                }

                                                                                                                .panel-body {
                                                                                                                    .form-field-type-mycardtable {
                                                                                                                        table {
                                                                                                                            tbody {
                                                                                                                                tr {
                                                                                                                                    td {
                                                                                                                                        h2 {
                                                                                                                                            margin: 0;
                                                                                                                                        }
                                                                                                                                    }
                                                                                                                                }
                                                                                                                            }
                                                                                                                        }
                                                                                                                    }
                                                                                                                }
                                                                                                            }

                                                                                                            &:last-of-type {
                                                                                                                >.panel {
                                                                                                                    >.panel-body {
                                                                                                                        .form-field-type-mycardtable {
                                                                                                                            &:last-of-type {
                                                                                                                                margin-bottom: 3rem;
                                                                                                                            }
                                                                                                                        }
                                                                                                                    }
                                                                                                                }
                                                                                                            }
                                                                                                        }

                                                                                                        >.form-field-type-mycardtable {
                                                                                                            &:last-of-type {
                                                                                                                margin-bottom: 35px;
                                                                                                            }
                                                                                                        }
                                                                                                    }
                                                                                                }

                                                                                                &.small-padding {
                                                                                                    padding: 0;
                                                                                                }

                                                                                                &+.form-field-type-button {
                                                                                                    display: none;
                                                                                                }
                                                                                            }

                                                                                            >.form-field-type-button {
                                                                                                display: none;
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                }
                                                                            }
                                                                        }
                                                                    }
                                                                }

                                                                >.form-field-type-button {
                                                                    margin-top: 11px !important;
                                                                    margin-inline-end: 35px !important;

                                                                    button {
                                                                        line-height: 1;

                                                                        &:hover {
                                                                            box-shadow: none;
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }

                                                    #callout-data {
                                                        form {
                                                            .form-field-type-table {
                                                                margin-top: 35px !important;
                                                                margin-bottom: 0 !important;
                                                                margin-inline-start: 35px !important;
                                                                margin-inline-end: 0 !important;

                                                                tr {
                                                                    display: flex;
                                                                    flex-direction: row-reverse;
                                                                    width: 100%;
                                                                    flex: auto;
                                                                    gap: 2rem;

                                                                    td {
                                                                        padding: 0;

                                                                        .form-field-type-myswitch {
                                                                            &.formio-component-FORMDATAVALIDATIONSTATUS {
                                                                                margin-top: 0 !important;
                                                                            }
                                                                        }

                                                                        &:first-child {
                                                                            flex: auto;
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                }

                                                #form-type-role {
                                                    div {
                                                        &[form="conclusion"] {
                                                            padding: 0 !important;
                                                            background: transparent;
                                                            box-shadow: none !important;

                                                            .form-field-type-columns {
                                                                margin: 0 !important;
                                                            }
                                                        }
                                                    }

                                                    .processing-tab {
                                                        form {
                                                            box-shadow: none;
                                                            background: transparent;

                                                            .form-field-type-hidden {
                                                                &:first-of-type {
                                                                    &+.form-field-type-panel {
                                                                        margin-top: 0;
                                                                    }
                                                                }
                                                            }

                                                            .form-field-type-panel {
                                                                >.panel {
                                                                    box-shadow: none !important;
                                                                }
                                                            }
                                                        }
                                                    }
                                                }

                                                #documents {
                                                    background: transparent;
                                                    box-shadow: none;
                                                    padding: 0 !important;
                                                }
                                            }
                                        }
                                    }
                                }

                                .new-formio-part-b {
                                    .formio-component-modal-wrapper,
                                    .formio-component-form {
                                        z-index: 999;

                                        .formio-form {
                                            .formio-component-tabs {
                                                .card {
                                                    .card-header {
                                                        ul {
                                                            &.card-header-tabs {
                                                                background-color: transparent;

                                                                >li {
                                                                    box-shadow: none;
                                                                    height: auto;

                                                                    a {
                                                                        font-size: 23px;
                                                                        color: @accentColor40;
                                                                        font-weight: 600;
                                                                        padding-inline-start: @panelBodyPadding;
                                                                        padding-inline-end: @panelBodyPadding;
                                                                        border-top: none;
                                                                        border-top-left-radius: @roundedCorners;
                                                                        border-top-right-radius: @roundedCorners;
                                                                        box-shadow: none;
                                                                    }

                                                                    &.active {
                                                                        a {
                                                                            background-color: @whiteColor;
                                                                            color: @accentColor;
                                                                        }
                                                                    }

                                                                    @media screen and (max-width: 680px) {
                                                                        a {
                                                                            padding: 2rem;
                                                                            line-height: 1;
                                                                            font-size: 18px;
                                                                        }
                                                                    }

                                                                    @media screen and (max-width: 480px) {
                                                                        a {
                                                                            padding: 1rem;
                                                                            font-size: 16px;
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }

                                                    .card-body {
                                                        &.tab-pane {
                                                            padding: @panelBodyPadding !important;
                                                            background: @whiteColor;
                                                            border: none;
                                                            border-radius: @roundedCorners;
                                                            box-shadow: 0 11px 20px @black5Color;
                                                            margin-top: 0;

                                                            &:nth-child(2) {
                                                                &.active {
                                                                    border-radius: 0 @roundedCorners @roundedCorners @roundedCorners;
                                                                }
                                                            }

                                                            >.formio-component-part-b-processing-tab {
                                                                &~.formio-component-table {
                                                                    margin: 0 !important;

                                                                    &.formio-component-validationtable {
                                                                        margin: 0 !important;

                                                                        .formio-component-textarea {
                                                                            textarea {
                                                                                &.form-control {
                                                                                    border: solid 1px @borderColor;
                                                                                    border-radius: @roundedCorners !important;

                                                                                    &:where(:hover, :focus) {
                                                                                        outline: none;
                                                                                    }

                                                                                    &::-webkit-resizer {
                                                                                        display: none;
                                                                                    }
                                                                                }
                                                                            }
                                                                        }
                                                                    }
                                                                }

                                                                &~.formio-component-conclusionRow {
                                                                    padding: 0 !important;
                                                                    margin-bottom: 0 !important;

                                                                    .formio-component-modal-wrapper-panel {
                                                                        margin-bottom: 0;

                                                                        button {
                                                                            border-radius: @roundedCorners !important;
                                                                            border: none;
                                                                            box-shadow: none;

                                                                            &:hover {
                                                                                outline: none;
                                                                            }
                                                                        }
                                                                    }

                                                                    @media screen and (max-width: 768px) {
                                                                        display: grid;
                                                                        grid-template-columns: 1fr 1fr;
                                                                        gap: 12px;

                                                                        &::before,
                                                                        &::after {
                                                                            display: none;
                                                                        }

                                                                        .col-md-3 {
                                                                            &:first-child {
                                                                                grid-column-start: 1;
                                                                                grid-column-end: 3;
                                                                                padding-inline-end: 0;

                                                                                &+.col-md-3 {
                                                                                    display: none;

                                                                                    &~.col-md-3 {
                                                                                        padding: 0;
                                                                                    }
                                                                                }
                                                                            }
                                                                        }
                                                                    }

                                                                    > [class^="col-"] {
                                                                        &:first-child {
                                                                            padding-inline-start: 0px !important;
                                                                        }
                                                                        &.cols-grid-end {
                                                                            padding-inline-end: 0px !important;
                                                                        }
                                                                    }
                                                                }

                                                                &~.formio-component-panel {
                                                                    > .card {
                                                                        background: @pageBgColor !important;
                                                                        padding: 20px;
                                                                        border-radius: @roundedCorners;

                                                                        .card-header {
                                                                            padding: 0;
                                                                            width: 100%;

                                                                            .card-title {
                                                                                color: @darkTextColor;
                                                                                font-weight: 600;

                                                                                span.formio-collapse-text {
                                                                                    position: absolute;
                                                                                    inset-inline-end: 28px;
                                                                                    font-size: 14px;
                                                                                    font-weight: normal;
                                                                                }
                                                                                i:not(.fa-question-circle) {
                                                                                    inset-inline-end: 0px;
                                                                                }
                                                                            }
                                                                        }

                                                                        > .card-body {
                                                                            padding: 2rem 0 0 !important;

                                                                            &:first-child {
                                                                                padding-top: 0 !important;
                                                                            }

                                                                            .formio-component-panel {
                                                                                margin-bottom: 0px;

                                                                                > .card {
                                                                                    > .card-header + .card-body {
                                                                                        padding-top: 1.5rem !important;
                                                                                    }
                                                                                }
                                                                            }

                                                                            .formio-component + .formio-component {
                                                                                margin-top: 2rem;
                                                                            }
                                                                        }

                                                                        .card {
                                                                            padding: 20px 0 0;
                                                                        }

                                                                        .formio-component-tabs {
                                                                            .card-body.tab-pane {
                                                                                padding: 20px !important;
                                                                            }
                                                                        }

                                                                        .formio-component-selectboxes {
                                                                            label.form-check-label {
                                                                                input.form-check-input {
                                                                                    z-index: -1;
                                                                                    width: 0;
                                                                                    margin: 0;
                                                                                }
                                                                            }
                                                                        }
                                                                    }
                                                                }

                                                                &~ :is(.formio-component-editgrid),
                                                                &~ :is(.formio-component) .formio-component-editgrid {
                                                                    min-width: 100%;

                                                                    .list-group {
                                                                        border: none;
                                                                        border-radius: @roundedCorners;

                                                                        .list-group-item {
                                                                            &.list-group-header {
                                                                                border-bottom: none;
                                                                                background-color: @accentColor10;

                                                                                .row {
                                                                                    box-shadow: 230px 0 0 @accentColor10Solid, 230px 1px 0 @accentColor10Solid;

                                                                                    >div {
                                                                                        border-inline-end: none;
                                                                                        color: @accentColor;
                                                                                        font-weight: 600;
                                                                                        font-size: 13px;
                                                                                        padding-top: 10px;
                                                                                        padding-bottom: 6px;
                                                                                    }
                                                                                }

                                                                                &+.list-group-footer {
                                                                                    margin-top: 8px;
                                                                                }

                                                                                &~.list-group-item {
                                                                                    border-top: 1px solid @accentColor10;

                                                                                    >.row {
                                                                                        >div {
                                                                                            color: @darkTextColor;
                                                                                            font-weight: 600;
                                                                                            padding-top: 16px;
                                                                                            padding-bottom: 16px;

                                                                                            .btn-icon-toggle {
                                                                                                color: @accentColor40;

                                                                                                &:hover {
                                                                                                    color: @accentColor;
                                                                                                }
                                                                                            }

                                                                                            &:last-child {
                                                                                                flex: 0;
                                                                                                min-width: 64px;
                                                                                            }
                                                                                        }
                                                                                    }

                                                                                    &.list-group-footer {
                                                                                        >.row {
                                                                                            >div {
                                                                                                color: @darkTextColor;
                                                                                                font-weight: 600;
                                                                                                font-size: 13px;
                                                                                                padding-top: 6px;
                                                                                                padding-bottom: 6px;

                                                                                                &.alert {
                                                                                                    flex: 1;
                                                                                                    margin-bottom: 8px !important;
                                                                                                    min-height: 20px;
                                                                                                    display: flex;
                                                                                                    align-items: center;

                                                                                                    &::after {
                                                                                                        height: calc(100% - 4px) !important;
                                                                                                        top: 2px !important;
                                                                                                    }
                                                                                                }
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                }

                                                                                &+.list-group-item {
                                                                                    border-top: none;
                                                                                }
                                                                            }

                                                                            >.row:not(.formio-component-columns)>div {
                                                                                max-width: initial;
                                                                            }

                                                                            &.list-group-footer {
                                                                                .alert {
                                                                                    &::after {
                                                                                        display: none !important;
                                                                                    }
                                                                                }
                                                                            }
                                                                        }

                                                                        &.has-open-row {
                                                                            overflow: initial;

                                                                            .list-group-item {
                                                                                max-width: 100%;

                                                                                &:where(.list-group-header, .list-group-footer) {
                                                                                    overflow: hidden;
                                                                                    max-width: 100%;

                                                                                    >.row {
                                                                                        >[class^="col-"] {
                                                                                            max-width: 100%;

                                                                                            &:last-child {
                                                                                                display: none;
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                }

                                                                                &:not(.list-group-footer) {
                                                                                    &:not(.list-group-header) {
                                                                                        &:not(:last-child) {
                                                                                            &:not(:nth-last-child(2)) {
                                                                                                overflow: hidden;
                                                                                                max-width: 100%;

                                                                                                >.row {
                                                                                                    margin-inline-start: -12px;
                                                                                                    margin-inline-end: -12px;

                                                                                                    >[class^="col-"] {
                                                                                                        &:first-of-type {
                                                                                                            padding-inline-start: 12px;
                                                                                                        }
                                                                                                    }
                                                                                                }
                                                                                            }
                                                                                        }

                                                                                        &:last-child {
                                                                                            >.row {
                                                                                                margin-inline-start: 0;
                                                                                                margin-inline-end: 0;

                                                                                                >[class^="col-"] {
                                                                                                    &:first-of-type {
                                                                                                        padding-inline-start: 0;
                                                                                                    }
                                                                                                }
                                                                                            }
                                                                                        }

                                                                                        .row {
                                                                                            [class^="col-"] {
                                                                                                padding-block: 0;
                                                                                            }
                                                                                        }

                                                                                        >.row {
                                                                                            display: block;
                                                                                            margin-inline-start: 0;
                                                                                            margin-inline-end: 0;

                                                                                            .formio-errors {
                                                                                                clear: both;
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                }

                                                                                .formio-component-file {
                                                                                    ul.list-group {
                                                                                        li.list-group-item {
                                                                                            >.row {
                                                                                                >.col-md-1 {
                                                                                                    flex: 0;
                                                                                                    min-width: 40px;
                                                                                                }

                                                                                                >.col-md-9 {
                                                                                                    flex: 3;
                                                                                                }

                                                                                                >.col-md-2 {
                                                                                                    flex: 1;
                                                                                                    display: flex;
                                                                                                    justify-content: flex-start;
                                                                                                }
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                }
                                                                            }
                                                                        }
                                                                    }

                                                                    >button {
                                                                        &.btn {
                                                                            border: none;
                                                                            border-radius: @roundedCorners !important;
                                                                            color: @accentColor;
                                                                            background-color: @accentColor10;
                                                                            padding: 0.9rem 2.5rem;
                                                                            font-weight: 600;
                                                                            font-size: 12px;
                                                                            display: inline-flex;
                                                                            align-items: center;
                                                                            transition-duration: 0.3s;
                                                                            height: 40px;

                                                                            >i {
                                                                                display: none;
                                                                            }

                                                                            &:hover {
                                                                                color: @whiteColor;
                                                                                background-color: @accentColor;
                                                                                box-shadow: 0 9px 20px @accentColor40 !important;
                                                                            }
                                                                        }
                                                                    }
                                                                }

                                                                &~ :where(.formio-component-columns, .formio-component-table) {
                                                                    :where(.formio-component-button, .formio-component-modal-wrapper-panel) {
                                                                        button {
                                                                            &.btn {
                                                                                border-radius: @roundedCorners !important;
                                                                                background-color: @accentColor;
                                                                                padding-inline-start: 25px;
                                                                                padding-inline-end: 25px;
                                                                                font-weight: 600;
                                                                                position: relative;
                                                                                z-index: 1;
                                                                                color: @whiteColor;
                                                                                min-height: 44px;
                                                                                text-align: center;
                                                                                line-height: 1;
                                                                                border: none;

                                                                                &.btn-md {
                                                                                    padding: 1.5rem 1rem;
                                                                                }

                                                                                &.btn-sm {
                                                                                    min-height: @fieldHeight;
                                                                                }

                                                                                &.btn-xs {
                                                                                    padding: 0.9rem 1rem;
                                                                                }

                                                                                .fa-cog {
                                                                                    position: absolute;
                                                                                    animation: none;
                                                                                    background: inherit;
                                                                                    width: 100%;
                                                                                    z-index: 9;
                                                                                    color: transparent;

                                                                                    &+span {
                                                                                        position: absolute !important;
                                                                                        top: -7px;
                                                                                        z-index: 99;
                                                                                        border-radius: 50%;
                                                                                        width: 8px;
                                                                                        height: 8px;
                                                                                        -webkit-animation-fill-mode: both;
                                                                                        animation-fill-mode: both;
                                                                                        -webkit-animation: load7 1.8s infinite ease-in-out;
                                                                                        animation: load7 1.8s infinite ease-in-out;
                                                                                        color: #ffffff;
                                                                                        font-size: 10px;
                                                                                        margin: 0 auto;
                                                                                        text-indent: -9999em;
                                                                                        -webkit-transform: translateZ(0);
                                                                                        -ms-transform: translateZ(0);
                                                                                        transform: translateZ(0);
                                                                                        -webkit-animation-delay: -0.16s;
                                                                                        animation-delay: -0.16s;

                                                                                        &::after,
                                                                                        &::before {
                                                                                            border-radius: 50%;
                                                                                            width: 8px;
                                                                                            height: 8px;
                                                                                            -webkit-animation-fill-mode: both;
                                                                                            animation-fill-mode: both;
                                                                                            -webkit-animation: load7 1.8s infinite ease-in-out;
                                                                                            animation: load7 1.8s infinite ease-in-out;
                                                                                            content: '';
                                                                                            position: absolute;
                                                                                            top: 0;
                                                                                        }

                                                                                        &::before {
                                                                                            inset-inline-start: -16px;
                                                                                            -webkit-animation-delay: -0.32s;
                                                                                            animation-delay: -0.32s;
                                                                                        }

                                                                                        &::after {
                                                                                            inset-inline-start: 16px;
                                                                                        }
                                                                                    }
                                                                                }

                                                                                &:hover {
                                                                                    box-shadow: 0 9px 20px @accentColor40 !important;
                                                                                    opacity: 0.9;
                                                                                }

                                                                                &.btn-green {
                                                                                    background-color: @successNotice;
                                                                                }

                                                                                &.btn-blue {
                                                                                    background-color: @infoNotice;
                                                                                }

                                                                                &.btn-orange {
                                                                                    background-color: @warningNotice;
                                                                                }

                                                                                &.btn-red {
                                                                                    background-color: @errorNotice;
                                                                                }
                                                                            }
                                                                        }

                                                                        &.action-progress {
                                                                            &.cog-during-form-submit {
                                                                                button {
                                                                                    font-family: 'Nunito Sans', sans-serif !important;

                                                                                    &::before {
                                                                                        font-family: 'Font Awesome 5 Pro' !important;
                                                                                        font-weight: 300;
                                                                                        -moz-osx-font-smoothing: grayscale;
                                                                                        -webkit-font-smoothing: antialiased;
                                                                                        display: inline-block;
                                                                                        font-style: normal;
                                                                                        font-variant: normal;
                                                                                        text-rendering: auto;
                                                                                        line-height: 1;
                                                                                        -webkit-animation: buttonProgress 2s linear infinite;
                                                                                        animation: buttonProgress 2s linear infinite;
                                                                                        margin-inline-end: 8px;
                                                                                    }
                                                                                }
                                                                            }

                                                                        }
                                                                    }

                                                                    .formio-component-modal-wrapper-panel {
                                                                        margin-bottom: 0;

                                                                        >div {
                                                                            &.formio-dialog {
                                                                                .formio-dialog-content {
                                                                                    border-radius: @roundedCorners;
                                                                                    background: @whiteColor;
                                                                                    padding: 24px;
                                                                                    overflow: initial;
                                                                                    max-width: 640px;

                                                                                    .formio-component {
                                                                                        &.formio-component-panel {
                                                                                            margin: 0;

                                                                                            .card {
                                                                                                margin: 0 !important;
                                                                                                background-color: transparent;

                                                                                                .card-header {
                                                                                                    padding: 0;

                                                                                                    .card-title {
                                                                                                        color: @darkTextColor;
                                                                                                        font-weight: 600;
                                                                                                    }
                                                                                                }

                                                                                                .card-body {
                                                                                                    margin-top: 2rem;

                                                                                                    .formio-component {
                                                                                                        &.formio-component-select {
                                                                                                            .formio-choices {
                                                                                                                .selection {
                                                                                                                    &.dropdown {
                                                                                                                        border: solid 1px @borderColor;
                                                                                                                        border-radius: @roundedCorners !important;
                                                                                                                        height: @fieldHeight;

                                                                                                                        &:where(:hover, :focus) {
                                                                                                                            outline: none;
                                                                                                                        }
                                                                                                                    }
                                                                                                                }

                                                                                                                &.is-open {
                                                                                                                    .selection {
                                                                                                                        &.dropdown {
                                                                                                                            border-radius: @roundedCorners @roundedCorners 0 0 !important;
                                                                                                                        }
                                                                                                                    }
                                                                                                                }

                                                                                                                &::after {
                                                                                                                    color: @accentColor40;
                                                                                                                    content: "\F078";
                                                                                                                    font-family: 'Font Awesome 5 Pro';
                                                                                                                    font-weight: 300;
                                                                                                                    -moz-osx-font-smoothing: grayscale;
                                                                                                                    -webkit-font-smoothing: antialiased;
                                                                                                                    display: inline-block;
                                                                                                                    font-style: normal;
                                                                                                                    font-variant: normal;
                                                                                                                    text-rendering: auto;
                                                                                                                    font-size: 16px;
                                                                                                                    width: auto;
                                                                                                                    border: none;
                                                                                                                    margin-top: 0;
                                                                                                                    height: 8px;
                                                                                                                    line-height: 7px;
                                                                                                                    top: calc(50% - 4px);
                                                                                                                }

                                                                                                                &:hover {
                                                                                                                    &::after {
                                                                                                                        color: @accentColor;
                                                                                                                    }
                                                                                                                }

                                                                                                                .choices__list {
                                                                                                                    &.choices__list--dropdown {
                                                                                                                        border: solid 1px @borderColor;
                                                                                                                        border-radius: 0 0 @roundedCorners @roundedCorners;

                                                                                                                        input {
                                                                                                                            &.choices__input {
                                                                                                                                border-radius: 0 !important;
                                                                                                                            }
                                                                                                                        }

                                                                                                                        .choices__item {
                                                                                                                            &.choices__item--choice {
                                                                                                                                padding: 10px 20px;
                                                                                                                                font-size: 12px;
                                                                                                                                font-weight: 600;
                                                                                                                                color: @darkTextColor;

                                                                                                                                &:where(.is-selected, :hover) {
                                                                                                                                    background-color: @accentColor5;
                                                                                                                                }
                                                                                                                            }
                                                                                                                        }
                                                                                                                    }
                                                                                                                }
                                                                                                            }
                                                                                                        }

                                                                                                        &.formio-component-button {
                                                                                                            button {
                                                                                                                &.btn {
                                                                                                                    transition-duration: 0s;
                                                                                                                }
                                                                                                            }
                                                                                                        }

                                                                                                        .formio-component-columns {
                                                                                                            .formio-component-button {
                                                                                                                float: right;
                                                                                                            }
                                                                                                        }

                                                                                                        &.formio-component-columns {
                                                                                                            &:last-child {
                                                                                                                >div {
                                                                                                                    &[class*="col-"] {
                                                                                                                        &+[class*="col-"] {
                                                                                                                            min-width: fit-content;
                                                                                                                        }
                                                                                                                    }
                                                                                                                }
                                                                                                            }
                                                                                                        }
                                                                                                    }
                                                                                                }
                                                                                            }
                                                                                        }
                                                                                    }

                                                                                    button {
                                                                                        &.formio-dialog-close {
                                                                                            transition-duration: 0s;
                                                                                            position: absolute;
                                                                                            background: transparent;
                                                                                            padding: 0;
                                                                                            display: flex;
                                                                                            height: 36px;
                                                                                            min-height: 36px;
                                                                                            align-items: center;
                                                                                            width: 36px;
                                                                                            justify-content: center;

                                                                                            &:hover {
                                                                                                background-color: @black2Color;
                                                                                                box-shadow: none;
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                }
                                                                            }
                                                                        }
                                                                    }
                                                                }

                                                                &~.formio-component {

                                                                    &.formio-component-radio,
                                                                    .formio-component-radio {
                                                                        &.radio-switch {
                                                                            .form-radio {
                                                                                &.radio {
                                                                                    height: @fieldHeight;
                                                                                    border-radius: @roundedCorners;
                                                                                    border: solid 1px @borderColor;
                                                                                    width: fit-content !important;
                                                                                    width: -moz-fit-content !important;
                                                                                    padding-top: 0;
                                                                                    display: flex;

                                                                                    &:where(.form-check-inline, .form-check) {
                                                                                        margin: 0;
                                                                                        flex: auto;
                                                                                        height: 100%;
                                                                                        padding: 0;
                                                                                        display: flex;
                                                                                        align-items: center;

                                                                                        label {
                                                                                            &.form-check-label {
                                                                                                display: flex;
                                                                                                justify-content: center;
                                                                                                height: 100%;
                                                                                                padding-inline-start: 0;
                                                                                                margin: 0;

                                                                                                input {
                                                                                                    &[type="radio"] {
                                                                                                        display: none;

                                                                                                        &:checked {
                                                                                                            &+span {
                                                                                                                background-color: @accentColor70Solid;
                                                                                                                color: @whiteColor !important;
                                                                                                            }
                                                                                                        }
                                                                                                    }
                                                                                                }

                                                                                                span {
                                                                                                    margin: 0;
                                                                                                    display: flex;
                                                                                                    height: 100%;
                                                                                                    align-items: center;
                                                                                                    cursor: pointer;
                                                                                                    padding: 1px 15px;
                                                                                                    width: 100%;
                                                                                                    top: 0;
                                                                                                    border-inline-end: solid 1px @borderColor;

                                                                                                    &:hover {
                                                                                                        background-color: @accentColor10;
                                                                                                    }
                                                                                                }
                                                                                            }
                                                                                        }

                                                                                        &:first-child {
                                                                                            label {
                                                                                                &.form-check-label {
                                                                                                    span {
                                                                                                        border-radius: @roundedCorners 0 0 @roundedCorners;
                                                                                                    }
                                                                                                }
                                                                                            }
                                                                                        }

                                                                                        &:last-child {
                                                                                            label {
                                                                                                &.form-check-label {
                                                                                                    span {
                                                                                                        border-radius: 0 @roundedCorners @roundedCorners 0;
                                                                                                        border-inline-end: none;
                                                                                                    }
                                                                                                }
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                }
                                                                            }

                                                                            &.required {
                                                                                label {
                                                                                    &.field-required {
                                                                                        &.control-label--hidden {
                                                                                            display: none;

                                                                                            &::after {
                                                                                                display: none;
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                }

                                                                                .form-radio {
                                                                                    &.radio {
                                                                                        &::before {
                                                                                            content: " ";
                                                                                            display: inline-block;
                                                                                            background-color: @errorColor70;
                                                                                            width: 6px;
                                                                                            height: 6px;
                                                                                            border-radius: 5px;
                                                                                            position: absolute;
                                                                                            inset-inline-end: -16px;
                                                                                            top: calc(50% - 3px);
                                                                                        }
                                                                                    }
                                                                                }
                                                                            }

                                                                            label {
                                                                                &.control-label {
                                                                                    &+.input-group {
                                                                                        .radio-inline {
                                                                                            margin-top: 0;

                                                                                            label {
                                                                                                span {
                                                                                                    top: 0;
                                                                                                }
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                }
                                                                            }
                                                                        }

                                                                        label {
                                                                            &.field-required {
                                                                                &.control-label--hidden {
                                                                                    display: none;

                                                                                    &::after {
                                                                                        display: none;
                                                                                    }

                                                                                    &+.form-radio.radio {
                                                                                        .form-check-inline.radio {
                                                                                            line-height: 16px;
                                                                                            margin-block: 10px;

                                                                                            &:first-child {
                                                                                                margin-inline-end: 2.4rem;
                                                                                            }

                                                                                            label.form-check-label {
                                                                                                &::before {
                                                                                                    content: " ";
                                                                                                    display: inline-block;
                                                                                                    background-color: @errorColor70;
                                                                                                    vertical-align: unset;
                                                                                                    width: 6px;
                                                                                                    height: 6px;
                                                                                                    border-radius: 5px;
                                                                                                    margin-inline-start: 5px;
                                                                                                    min-width: 6px;
                                                                                                    inset-inline-start: 100%;
                                                                                                    inset-block-start: calc(50% - 3px);
                                                                                                    position: absolute;
                                                                                                }
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                }
                                                                            }

                                                                            input[type="radio"].form-check-input {
                                                                                top: calc(50% - 7px);
                                                                            }
                                                                        }

                                                                        &:not(.radio-switch) {
                                                                            .form-radio {
                                                                                &.radio {
                                                                                    .form-check:last-child {
                                                                                        padding-bottom: 0px;
                                                                                    }
                                                                                }
                                                                            }
                                                                            &.required {
                                                                                label.field-required.control-label--hidden + .form-radio.radio {
                                                                                    .radio {
                                                                                        &.form-check {
                                                                                            label.form-check-label {
                                                                                                &::after {
                                                                                                    content: " ";
                                                                                                    display: inline-block;
                                                                                                    background-color: @errorColor70;
                                                                                                    vertical-align: unset;
                                                                                                    width: 6px;
                                                                                                    height: 6px;
                                                                                                    border-radius: 5px;
                                                                                                    margin-inline-start: 5px;
                                                                                                    min-width: 6px;
                                                                                                    align-self: center;
                                                                                                    position: relative;
                                                                                                    top: 1px;
                                                                                                }
                                                                                            }
                                                                                        }
                                                                                        &.form-check-inline {
                                                                                            &:last-child {
                                                                                                label.form-check-label {
                                                                                                    &::after {
                                                                                                        content: " ";
                                                                                                        display: inline-block;
                                                                                                        background-color: @errorColor70;
                                                                                                        vertical-align: unset;
                                                                                                        width: 6px;
                                                                                                        height: 6px;
                                                                                                        border-radius: 5px;
                                                                                                        margin-inline-start: 5px;
                                                                                                        min-width: 6px;
                                                                                                        position: initial;
                                                                                                    }
                                                                                                }
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                }
                                                                                &.formio-modified {
                                                                                    label.field-required.control-label--hidden + .form-radio.radio {
                                                                                        .radio {
                                                                                            &.form-check {
                                                                                                label.form-check-label {
                                                                                                    &::after {
                                                                                                        content: none;
                                                                                                    }
                                                                                                }
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                }
                                                                            }
                                                                        }
                                                                    }

                                                                    &.formio-component-button,
                                                                    :where(.formio-component-button, .formio-component-modal-wrapper) {
                                                                        button {
                                                                            &.btn {
                                                                                border-radius: @roundedCorners !important;
                                                                                background-color: @accentColor;
                                                                                padding-inline-start: 25px;
                                                                                padding-inline-end: 25px;
                                                                                font-weight: 600;
                                                                                position: relative;
                                                                                z-index: 1;
                                                                                color: @whiteColor;
                                                                                min-height: 44px;
                                                                                text-align: center;
                                                                                line-height: 1;
                                                                                border: none;
                                                                                font-size: 16px;

                                                                                &.btn-md {
                                                                                    padding: 1.2rem 1rem;
                                                                                    min-height: 40px;
                                                                                }

                                                                                &.btn-sm {
                                                                                    min-height: @fieldHeight;
                                                                                }

                                                                                &.btn-xs {
                                                                                    padding: 0.9rem 1rem;
                                                                                }

                                                                                .fa-cog {
                                                                                    position: absolute;
                                                                                    animation: none;
                                                                                    background: inherit;
                                                                                    width: 100%;
                                                                                    z-index: 9;
                                                                                    color: transparent;

                                                                                    &+span {
                                                                                        position: absolute !important;
                                                                                        top: -7px;
                                                                                        z-index: 99;
                                                                                        border-radius: 50%;
                                                                                        width: 8px;
                                                                                        height: 8px;
                                                                                        -webkit-animation-fill-mode: both;
                                                                                        animation-fill-mode: both;
                                                                                        -webkit-animation: load7 1.8s infinite ease-in-out;
                                                                                        animation: load7 1.8s infinite ease-in-out;
                                                                                        color: #ffffff;
                                                                                        font-size: 10px;
                                                                                        margin: 0 auto;
                                                                                        text-indent: -9999em;
                                                                                        -webkit-transform: translateZ(0);
                                                                                        -ms-transform: translateZ(0);
                                                                                        transform: translateZ(0);
                                                                                        -webkit-animation-delay: -0.16s;
                                                                                        animation-delay: -0.16s;

                                                                                        &::after,
                                                                                        &::before {
                                                                                            border-radius: 50%;
                                                                                            width: 8px;
                                                                                            height: 8px;
                                                                                            -webkit-animation-fill-mode: both;
                                                                                            animation-fill-mode: both;
                                                                                            -webkit-animation: load7 1.8s infinite ease-in-out;
                                                                                            animation: load7 1.8s infinite ease-in-out;
                                                                                            content: '';
                                                                                            position: absolute;
                                                                                            top: 0;
                                                                                        }

                                                                                        &::before {
                                                                                            inset-inline-start: -16px;
                                                                                            -webkit-animation-delay: -0.32s;
                                                                                            animation-delay: -0.32s;
                                                                                        }

                                                                                        &::after {
                                                                                            inset-inline-start: 16px;
                                                                                        }
                                                                                    }
                                                                                }

                                                                                &:hover {
                                                                                    box-shadow: 0 9px 20px @accentColor40 !important;
                                                                                    opacity: 0.9;
                                                                                }

                                                                                &.btn-green {
                                                                                    background-color: @successNotice;
                                                                                }

                                                                                &.btn-blue {
                                                                                    background-color: @infoNotice;
                                                                                }

                                                                                &.btn-orange {
                                                                                    background-color: @warningNotice;
                                                                                }

                                                                                &.btn-red {
                                                                                    background-color: @errorNotice;
                                                                                }
                                                                            }
                                                                        }

                                                                        &.action-progress {
                                                                            &.cog-during-form-submit {
                                                                                button {
                                                                                    font-family: 'Nunito Sans', sans-serif !important;

                                                                                    &::before {
                                                                                        font-family: 'Font Awesome 5 Pro' !important;
                                                                                        font-weight: 300;
                                                                                        -moz-osx-font-smoothing: grayscale;
                                                                                        -webkit-font-smoothing: antialiased;
                                                                                        display: inline-block;
                                                                                        font-style: normal;
                                                                                        font-variant: normal;
                                                                                        text-rendering: auto;
                                                                                        line-height: 1;
                                                                                        -webkit-animation: buttonProgress 2s linear infinite;
                                                                                        animation: buttonProgress 2s linear infinite;
                                                                                        margin-inline-end: 8px;
                                                                                    }
                                                                                }
                                                                            }

                                                                        }
                                                                    }

                                                                    &.formio-component-htmlelement,
                                                                    .formio-component-htmlelement {
                                                                        p {
                                                                            font-size: 16px;
                                                                        }
                                                                    }

                                                                    &.formio-component-table,
                                                                    .formio-component-table {
                                                                        table {
                                                                            &.table {
                                                                                tbody {
                                                                                    tr {
                                                                                        &.row {
                                                                                            td {
                                                                                                flex: 1;
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                }
                                                                            }
                                                                        }
                                                                    }

                                                                    &.top-space {
                                                                        padding-top: 3rem;
                                                                    }

                                                                    .formio-component {
                                                                        &.top-space {
                                                                            padding-top: 3rem;
                                                                        }
                                                                    }

                                                                    input {
                                                                        &.form-control {
                                                                            &:where([type="text"], [type="search"], [type="phoneNumber"], [type="email"]) {
                                                                                height: @fieldHeight !important;
                                                                                border: solid 1px @borderColor;
                                                                                border-radius: @roundedCorners !important;
                                                                                font-weight: 600;
                                                                                font-size: 16px;

                                                                                &:where(:hover, :focus) {
                                                                                    outline: none;
                                                                                }

                                                                                &:disabled {
                                                                                    background-color: @partbDisabledFieldBgColor !important;
                                                                                }
                                                                            }
                                                                        }
                                                                    }

                                                                    textarea {
                                                                        &.form-control {
                                                                            border: solid 1px @borderColor;
                                                                            border-radius: @roundedCorners !important;

                                                                            &:where(:hover, :focus) {
                                                                                outline: none;
                                                                            }

                                                                            &::-webkit-resizer {
                                                                                display: none;
                                                                            }
                                                                        }
                                                                    }

                                                                    .formio-component-select {
                                                                        .formio-choices {
                                                                            .selection {
                                                                                &.dropdown {
                                                                                    border: solid 1px @borderColor;
                                                                                    border-radius: @roundedCorners !important;
                                                                                    height: @fieldHeight;

                                                                                    &:where(:hover, :focus) {
                                                                                        outline: none;
                                                                                    }
                                                                                }
                                                                            }

                                                                            &.is-open {
                                                                                .selection {
                                                                                    &.dropdown {
                                                                                        border-radius: @roundedCorners @roundedCorners 0 0 !important;
                                                                                    }
                                                                                }
                                                                            }

                                                                            &::after {
                                                                                color: @accentColor40;
                                                                                content: "\F078";
                                                                                font-family: 'Font Awesome 5 Pro';
                                                                                font-weight: 300;
                                                                                -moz-osx-font-smoothing: grayscale;
                                                                                -webkit-font-smoothing: antialiased;
                                                                                display: inline-block;
                                                                                font-style: normal;
                                                                                font-variant: normal;
                                                                                text-rendering: auto;
                                                                                font-size: 16px;
                                                                                width: auto;
                                                                                border: none;
                                                                                margin-top: 0;
                                                                                height: 8px;
                                                                                line-height: 7px;
                                                                                top: calc(50% - 4px);
                                                                            }

                                                                            &:hover {
                                                                                &::after {
                                                                                    color: @accentColor;
                                                                                }
                                                                            }

                                                                            .choices__list {
                                                                                &.choices__list--dropdown {
                                                                                    border: solid 1px @borderColor;
                                                                                    border-radius: 0 0 @roundedCorners @roundedCorners;

                                                                                    input {
                                                                                        &.choices__input {
                                                                                            border-radius: 0 !important;
                                                                                        }
                                                                                    }

                                                                                    .choices__item {
                                                                                        &.choices__item--choice {
                                                                                            padding: 10px 20px;
                                                                                            font-size: 12px;
                                                                                            font-weight: 600;
                                                                                            color: @darkTextColor;

                                                                                            &:where(.is-selected, :hover) {
                                                                                                background-color: @accentColor5;
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                }
                                                                            }
                                                                        }
                                                                    }

                                                                    .formio-component {
                                                                        &.formio-component-currency {
                                                                            .input-group {
                                                                                >div {
                                                                                    border: solid 1px @borderColor;
                                                                                    background-color: @whiteColor !important;

                                                                                    &.input-group-prepend {
                                                                                        border-radius: @roundedCorners 0 0 @roundedCorners !important;
                                                                                        height: 40px;

                                                                                        &+input {
                                                                                            &.form-control {
                                                                                                border-top-left-radius: 0 !important;
                                                                                                border-bottom-left-radius: 0 !important;
                                                                                                border-inline-start: none !important;
                                                                                            }
                                                                                        }
                                                                                    }

                                                                                    &.input-group-append {
                                                                                        border-radius: 0 @roundedCorners @roundedCorners 0 !important;
                                                                                    }
                                                                                }

                                                                                input {
                                                                                    &.form-control {
                                                                                        &:first-child {
                                                                                            &:not(:last-child) {
                                                                                                border-top-right-radius: 0 !important;
                                                                                                border-bottom-right-radius: 0 !important;
                                                                                                border-inline-end: 0;
                                                                                            }
                                                                                        }

                                                                                        &:not(:first-child) {
                                                                                            &:not(:last-child) {
                                                                                                border-top-right-radius: 0 !important;
                                                                                                border-bottom-right-radius: 0 !important;
                                                                                                border-inline-end: none !important;
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                }
                                                                            }
                                                                        }

                                                                        &.formio-component-table {
                                                                            table {
                                                                                &.table {
                                                                                    tbody {
                                                                                        tr {
                                                                                            td {
                                                                                                &:first-child {
                                                                                                    padding-inline-start: 0;
                                                                                                }

                                                                                                &:last-child {
                                                                                                    padding-inline-end: 0;
                                                                                                }
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                }
                                                                            }

                                                                            &.formio-component-costtable {
                                                                                table {
                                                                                    &.table {
                                                                                        tbody {
                                                                                            tr {
                                                                                                td {
                                                                                                    vertical-align: middle;

                                                                                                    .formio-component-htmlelement {
                                                                                                        h4 {
                                                                                                            margin: 0;
                                                                                                        }
                                                                                                    }
                                                                                                }
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                }
                                                                            }
                                                                        }

                                                                        &:first-child {
                                                                            &.formio-component-hidden {
                                                                                &+.formio-component {
                                                                                    margin-top: 0;
                                                                                }
                                                                            }
                                                                        }

                                                                        &.formio-component-columns {
                                                                            &[class*="formio-component-costTableRow"] {
                                                                                margin-top: 0;
                                                                            }

                                                                            &[class*="formio-component-totalPrice"] {
                                                                                .formio-component-htmlelement {
                                                                                    :where(h4, p) {
                                                                                        font-weight: 600;

                                                                                        span {
                                                                                            &.currency-prefixed {
                                                                                                margin-inline-end: 5px;
                                                                                            }

                                                                                            &.currency-suffixed {
                                                                                                &:not(:empty) {
                                                                                                    margin-inline-start: 5px;
                                                                                                }
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                }
                                                                            }
                                                                        }
                                                                    }

                                                                    .formio-component-datetime {
                                                                        .input-group {
                                                                            input {
                                                                                &.form-control.input {
                                                                                    border: solid 1px @borderColor;
                                                                                    border-radius: @roundedCorners 0 0 @roundedCorners !important;
                                                                                    border-inline-end: none;
                                                                                    padding-inline-end: 0;
                                                                                }
                                                                            }

                                                                            .input-group-append {
                                                                                border: solid 1px @borderColor;
                                                                                border-radius: 0 @roundedCorners @roundedCorners 0 !important;
                                                                                height: @fieldHeight;
                                                                                border-inline-start: none;
                                                                                cursor: pointer;

                                                                                .input-group-text {
                                                                                    display: flex;
                                                                                    height: 100%;
                                                                                    align-items: center;
                                                                                    border-radius: 0 6px 6px 0;
                                                                                    border: none;

                                                                                    i {
                                                                                        margin-inline-end: 0;

                                                                                        &::before {
                                                                                            font-family: "Font Awesome 5 Pro";
                                                                                            font-weight: 400;
                                                                                            -moz-osx-font-smoothing: grayscale;
                                                                                            -webkit-font-smoothing: antialiased;
                                                                                            display: inline-block;
                                                                                            font-style: normal;
                                                                                            font-variant: normal;
                                                                                            text-rendering: auto;
                                                                                            line-height: 1;
                                                                                            content: "\f073";
                                                                                            color: @accentColor40;
                                                                                        }
                                                                                    }
                                                                                }
                                                                            }
                                                                        }

                                                                        &:hover {
                                                                            .input-group {
                                                                                .input-group-append {
                                                                                    .input-group-text {
                                                                                        i {
                                                                                            &::before {
                                                                                                color: @accentColor;
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                }
                                                                            }
                                                                        }

                                                                        &.has-error {
                                                                            .input-group {
                                                                                input {
                                                                                    &.form-control.input {
                                                                                        border-color: @errorColor30 !important;
                                                                                    }
                                                                                }

                                                                                .input-group-append {
                                                                                    border-color: @errorColor30 !important;
                                                                                }
                                                                            }
                                                                        }

                                                                        &.value-label-style {
                                                                            input {
                                                                                &:where(.form-control, .input) {
                                                                                    border: none !important;

                                                                                    &:where([disabled], :disabled) {
                                                                                        background-color: transparent !important;
                                                                                    }
                                                                                }
                                                                            }

                                                                            .input-group-append {
                                                                                display: none;
                                                                            }
                                                                        }
                                                                    }

                                                                    .formio-component-button {
                                                                        button {
                                                                            &.btn {
                                                                                border-radius: @roundedCorners !important;
                                                                                background-color: @accentColor;
                                                                                padding-inline-start: 25px;
                                                                                padding-inline-end: 25px;
                                                                                font-weight: 600;
                                                                                position: relative;
                                                                                z-index: 1;
                                                                                color: @whiteColor;

                                                                                &.btn-md {
                                                                                    padding: 1.2rem 1rem;
                                                                                    min-height: 40px;
                                                                                }

                                                                                &.btn-sm {
                                                                                    min-height: @fieldHeight;
                                                                                }

                                                                                &.btn-xs {
                                                                                    padding: 0.9rem 1rem;
                                                                                }

                                                                                .fa-cog {
                                                                                    position: absolute;
                                                                                    animation: none;
                                                                                    background: inherit;
                                                                                    width: 100%;
                                                                                    z-index: 9;
                                                                                    color: transparent;

                                                                                    &+span {
                                                                                        position: absolute !important;
                                                                                        top: -7px;
                                                                                        z-index: 99;
                                                                                        border-radius: 50%;
                                                                                        width: 8px;
                                                                                        height: 8px;
                                                                                        -webkit-animation-fill-mode: both;
                                                                                        animation-fill-mode: both;
                                                                                        -webkit-animation: load7 1.8s infinite ease-in-out;
                                                                                        animation: load7 1.8s infinite ease-in-out;
                                                                                        color: #ffffff;
                                                                                        font-size: 10px;
                                                                                        margin: 0 auto;
                                                                                        text-indent: -9999em;
                                                                                        -webkit-transform: translateZ(0);
                                                                                        -ms-transform: translateZ(0);
                                                                                        transform: translateZ(0);
                                                                                        -webkit-animation-delay: -0.16s;
                                                                                        animation-delay: -0.16s;

                                                                                        &::after,
                                                                                        &::before {
                                                                                            border-radius: 50%;
                                                                                            width: 8px;
                                                                                            height: 8px;
                                                                                            -webkit-animation-fill-mode: both;
                                                                                            animation-fill-mode: both;
                                                                                            -webkit-animation: load7 1.8s infinite ease-in-out;
                                                                                            animation: load7 1.8s infinite ease-in-out;
                                                                                            content: '';
                                                                                            position: absolute;
                                                                                            top: 0;
                                                                                        }

                                                                                        &::before {
                                                                                            inset-inline-start: -16px;
                                                                                            -webkit-animation-delay: -0.32s;
                                                                                            animation-delay: -0.32s;
                                                                                        }

                                                                                        &::after {
                                                                                            inset-inline-start: 16px;
                                                                                        }
                                                                                    }
                                                                                }

                                                                                &:hover {
                                                                                    box-shadow: 0 9px 20px @accentColor40 !important;
                                                                                    opacity: 0.9;
                                                                                }

                                                                                &.btn-green {
                                                                                    background-color: @successNotice;
                                                                                }

                                                                                &.btn-blue {
                                                                                    background-color: @infoNotice;
                                                                                }

                                                                                &.btn-orange {
                                                                                    background-color: @warningNotice;
                                                                                }

                                                                                &.btn-red {
                                                                                    background-color: @errorNotice;
                                                                                }
                                                                            }
                                                                        }

                                                                        &.action-progress {
                                                                            button {
                                                                                font-family: 'Nunito Sans', sans-serif !important;
                                                                            }

                                                                            &.cog-during-form-submit {
                                                                                button {
                                                                                    font-family: 'Nunito Sans', sans-serif !important;

                                                                                    &::before {
                                                                                        font-family: 'Font Awesome 5 Pro' !important;
                                                                                        font-weight: 300;
                                                                                        -moz-osx-font-smoothing: grayscale;
                                                                                        -webkit-font-smoothing: antialiased;
                                                                                        display: inline-block;
                                                                                        font-style: normal;
                                                                                        font-variant: normal;
                                                                                        text-rendering: auto;
                                                                                        line-height: 1;
                                                                                        -webkit-animation: buttonProgress 2s linear infinite;
                                                                                        animation: buttonProgress 2s linear infinite;
                                                                                        margin-inline-end: 8px;
                                                                                        content: "\f013";
                                                                                    }
                                                                                }
                                                                            }

                                                                            &:not(.cog-during-form-submit) {
                                                                                button {
                                                                                    &::before {
                                                                                        display: none;
                                                                                    }
                                                                                }
                                                                            }
                                                                        }
                                                                    }

                                                                    .formio-component-datagrid {
                                                                        >table {
                                                                            &.table {
                                                                                &.datagrid-table {
                                                                                    border: none;

                                                                                    thead {
                                                                                        opacity: 1;

                                                                                        tr {
                                                                                            th {
                                                                                                border: none;
                                                                                                background-color: @accentColor10;
                                                                                                color: @accentColor;
                                                                                                font-weight: 600;
                                                                                                font-size: 13px;
                                                                                                letter-spacing: initial;
                                                                                                text-transform: none;
                                                                                                padding-block: 1rem;
                                                                                                padding-inline-start: 28px;
                                                                                                padding-inline-end: 0;
                                                                                                vertical-align: middle;

                                                                                                &:first-child {
                                                                                                    border-radius: @roundedCorners 0 0 @roundedCorners;
                                                                                                    padding-block: 1rem;
                                                                                                    padding-inline-start: 14px;
                                                                                                    padding-inline-end: 0;
                                                                                                }

                                                                                                &:last-child {
                                                                                                    border-radius: 0 @roundedCorners @roundedCorners 0;
                                                                                                }

                                                                                                i {
                                                                                                    &.fa-question-circle {
                                                                                                        &::before {
                                                                                                            font-size: 15px;
                                                                                                        }
                                                                                                    }
                                                                                                }

                                                                                                &:not([class]) {
                                                                                                    width: 50px;
                                                                                                }
                                                                                            }
                                                                                        }
                                                                                    }

                                                                                    tbody {
                                                                                        tr {
                                                                                            background-color: transparent;

                                                                                            td {
                                                                                                background-color: transparent;
                                                                                                border: none;
                                                                                                padding: 0.75rem 0 0.75rem 14px;
                                                                                                vertical-align: middle;
                                                                                                text-align: center;

                                                                                                >.formio-component {
                                                                                                    &:first-child {
                                                                                                        margin-top: 0;
                                                                                                    }
                                                                                                }

                                                                                                &:not([ref]) {
                                                                                                    width: 50px;
                                                                                                    text-align: center;

                                                                                                    .formio-button-remove-row {
                                                                                                        background-color: transparent;
                                                                                                        padding: 0 5px;
                                                                                                        margin-inline-start: auto;
                                                                                                        display: flex;
                                                                                                        margin-inline-end: auto;

                                                                                                        i {
                                                                                                            color: @errorColor30;
                                                                                                            font-weight: 400;
                                                                                                            font-size: 16px;
                                                                                                            font-family: 'Font Awesome 5 Pro';

                                                                                                            &::before {
                                                                                                                content: "\F00D";
                                                                                                            }
                                                                                                        }

                                                                                                        &:hover {
                                                                                                            i {
                                                                                                                color: @errorNotice;
                                                                                                            }
                                                                                                        }
                                                                                                    }
                                                                                                }

                                                                                                &:first-child {
                                                                                                    padding-inline-start: 0;
                                                                                                }
                                                                                            }

                                                                                            &:first-child {
                                                                                                td {
                                                                                                    padding-top: 1.5rem;
                                                                                                }
                                                                                            }
                                                                                        }
                                                                                    }

                                                                                    tfoot {
                                                                                        tr {
                                                                                            td {
                                                                                                padding: 0.75rem 0 0;
                                                                                                border: none;

                                                                                                button {
                                                                                                    &.btn {
                                                                                                        &.formio-button-add-row {
                                                                                                            border: solid 1px @accentColor;
                                                                                                            border-radius: @roundedCorners !important;
                                                                                                            background-color: transparent;
                                                                                                            color: @accentColor;
                                                                                                            padding-inline-start: 25px;
                                                                                                            padding-inline-end: 25px;
                                                                                                            font-weight: 600;
                                                                                                            font-size: 12px;
                                                                                                            height: 40px;
                                                                                                            display: inline-flex;
                                                                                                            align-items: center;
                                                                                                            transition-duration: 0.3s;

                                                                                                            >i {
                                                                                                                display: none;
                                                                                                            }

                                                                                                            &:hover {
                                                                                                                color: @whiteColor;
                                                                                                                background-color: @accentColor;
                                                                                                                box-shadow: 0 9px 20px @accentColor40 !important;
                                                                                                                z-index: 999;
                                                                                                                position: relative;
                                                                                                            }
                                                                                                        }
                                                                                                    }
                                                                                                }
                                                                                            }
                                                                                        }
                                                                                    }

                                                                                    &.table-striped {
                                                                                        tbody {
                                                                                            tr {
                                                                                                &:nth-of-type(odd) {
                                                                                                    td {
                                                                                                        background-color: @accentColor5;
                                                                                                    }
                                                                                                }
                                                                                            }
                                                                                        }
                                                                                    }

                                                                                    &.table-hover {
                                                                                        tbody {
                                                                                            tr {
                                                                                                &:hover {
                                                                                                    td {
                                                                                                        background-color: @accentColor5;
                                                                                                    }
                                                                                                }
                                                                                            }
                                                                                        }
                                                                                    }

                                                                                    &.table-sm {
                                                                                        thead {
                                                                                            tr {
                                                                                                th {
                                                                                                    &:not(:first-child) {
                                                                                                        padding-inline-start: 14px;
                                                                                                    }
                                                                                                }
                                                                                            }
                                                                                        }

                                                                                        tbody {
                                                                                            tr {
                                                                                                td {
                                                                                                    padding: 1px 0;
                                                                                                    text-align: start;
                                                                                                    vertical-align: middle;

                                                                                                    button {
                                                                                                        &:hover {
                                                                                                            box-shadow: none !important;
                                                                                                        }
                                                                                                    }
                                                                                                }
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                }
                                                                            }
                                                                        }

                                                                        &.has-footer {
                                                                            tfoot {
                                                                                tr {
                                                                                    td {
                                                                                        button {
                                                                                            &.btn {
                                                                                                &.formio-button-add-row {
                                                                                                    margin-bottom: -80px;
                                                                                                }
                                                                                            }
                                                                                        }

                                                                                        &[colspan] {
                                                                                            display: block;
                                                                                        }
                                                                                    }
                                                                                }
                                                                            }

                                                                            &+.formio-component-columns {
                                                                                .datagrid-custom-footer {
                                                                                    margin-top: -32px;
                                                                                }
                                                                            }
                                                                        }
                                                                    }

                                                                    :where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-url, .formio-component-address) {
                                                                        .input-group {
                                                                            input.form-control:first-child:not(:last-child) {
                                                                                border-top-right-radius: 0 !important;
                                                                                border-bottom-right-radius: 0 !important;
                                                                                border-inline-end: none;
                                                                            }

                                                                            :where(.input-group-prepend, .input-group-append) {
                                                                                border: solid 1px @borderColor;
                                                                                height: @fieldHeight;
                                                                                color: @darkTextColor;
                                                                                font-weight: 600;

                                                                                .input-group-text {
                                                                                    display: flex;
                                                                                    height: 100%;
                                                                                    align-items: center;
                                                                                    border: none;
                                                                                }
                                                                            }

                                                                            .input-group-prepend {
                                                                                border-radius: @roundedCorners 0 0 @roundedCorners !important;
                                                                                border-inline-end: none;

                                                                                .input-group-text {
                                                                                    border-radius: 6px 0 0 6px;
                                                                                }

                                                                                &+input {
                                                                                    &.form-control {
                                                                                        border-top-left-radius: 0 !important;
                                                                                        border-bottom-left-radius: 0 !important;
                                                                                        border-inline-start: none !important;

                                                                                        &:nth-last-child(2) {
                                                                                            border-top-right-radius: 0 !important;
                                                                                            border-bottom-right-radius: 0 !important;
                                                                                            border-inline-end: none;
                                                                                        }
                                                                                    }
                                                                                }
                                                                            }

                                                                            .input-group-append {
                                                                                border-radius: 0 @roundedCorners @roundedCorners 0 !important;
                                                                                border-inline-start: none;

                                                                                .input-group-text {
                                                                                    border-radius: 0 6px 6px 0;
                                                                                }
                                                                            }
                                                                        }
                                                                    }

                                                                    &.formio-component-fieldset,
                                                                    .formio-component-fieldset {
                                                                        fieldset {
                                                                            padding: 54px 20px 20px;
                                                                            position: relative;
                                                                            border-radius: @roundedCorners;
                                                                            border: solid 4px @accentColor10;
                                                                            background-color: @whiteColor;

                                                                            legend {
                                                                                margin: 0;
                                                                                text-transform: initial;
                                                                                font-size: 18px;
                                                                                font-weight: 600;
                                                                                color: @darkTextColor;
                                                                                background: transparent;
                                                                                padding: 0;
                                                                                position: absolute;
                                                                                top: 12px;
                                                                            }

                                                                            @media screen and (max-width: 580px) {
                                                                                padding: 54px 20px 20px;
                                                                            }

                                                                            >.fieldset-body {
                                                                                &:first-child {
                                                                                    margin-top: -23px;
                                                                                }

                                                                                >.formio-component {
                                                                                    &.top-label-space {
                                                                                        &.required {
                                                                                            label {
                                                                                                &.field-required {
                                                                                                    &.control-label--hidden {
                                                                                                        top: 27px !important;
                                                                                                    }
                                                                                                }
                                                                                            }
                                                                                        }
                                                                                    }

                                                                                    &:first-child {
                                                                                        margin-top: 0 !important;
                                                                                    }
                                                                                }
                                                                            }
                                                                        }
                                                                    }

                                                                    &.formio-component-file,
                                                                    .formio-component-file {
                                                                        border: dashed 2px rgba(27, 37, 60, 0.1);
                                                                        border-radius: @roundedCorners;
                                                                        padding: @panelBodyPadding;
                                                                        position: relative;

                                                                        &:not(:first-of-type) {
                                                                            margin-top: 3rem;
                                                                        }

                                                                        label {
                                                                            &.col-form-label {
                                                                                font-size: 16px;
                                                                                color: @darkTextColor;
                                                                                margin-bottom: 10px;

                                                                                &+div {
                                                                                    margin-bottom: 20px;
                                                                                    position: relative;
                                                                                    z-index: 9;

                                                                                    div:last-child {
                                                                                        margin-bottom: 36px;
                                                                                    }

                                                                                    span {
                                                                                        i {
                                                                                            cursor: pointer;
                                                                                            color: rgba(255, 0, 59, 1);
                                                                                        }
                                                                                    }
                                                                                }

                                                                                .fa-question-circle {
                                                                                    position: relative;
                                                                                    z-index: 9;
                                                                                }
                                                                            }
                                                                        }

                                                                        ul {
                                                                            &.list-group {
                                                                                border: none;
                                                                                width: 100%;
                                                                                position: relative;
                                                                                z-index: 9;
                                                                                margin-bottom: 20px;

                                                                                &:nth-last-child(2) {
                                                                                    margin-bottom: -10px;
                                                                                }

                                                                                &:nth-last-child(n+4) {
                                                                                    &+.fileSelector {
                                                                                        i.fa-cloud-upload {
                                                                                            top: initial;
                                                                                        }
                                                                                    }
                                                                                }

                                                                                li {
                                                                                    &.list-group-item {
                                                                                        border: none;
                                                                                        box-shadow: inset 0 -2px 0 #E2E7E7;
                                                                                        padding: 0;
                                                                                        background-color: transparent;
                                                                                        border-radius: 0;

                                                                                        .row {
                                                                                            border: none;
                                                                                            display: grid;
                                                                                            grid-template-columns: auto auto 1fr;
                                                                                            font-size: 12px;

                                                                                            &::before,
                                                                                            &::after {
                                                                                                display: none;
                                                                                            }

                                                                                            >div {
                                                                                                padding: 10px 12px;
                                                                                                text-transform: none;
                                                                                                color: @accentColor;

                                                                                                &.col-md-1 {
                                                                                                    order: 3;
                                                                                                    width: auto;
                                                                                                    justify-content: flex-end;
                                                                                                    color: rgba(255, 0, 59, 1);
                                                                                                }

                                                                                                &:where(.col-md-9, .col-md-2) {
                                                                                                    white-space: nowrap;
                                                                                                    width: auto !important;

                                                                                                    a[href] {
                                                                                                        display: block;
                                                                                                    }
                                                                                                }

                                                                                                &.col-md-9 {
                                                                                                    a {
                                                                                                        white-space: normal;
                                                                                                        line-break: anywhere;
                                                                                                    }
                                                                                                }

                                                                                                &:first-child {
                                                                                                    display: flex;
                                                                                                    align-items: center;

                                                                                                    i {
                                                                                                        &.fa-remove {
                                                                                                            font-family: 'Font Awesome 5 Pro';
                                                                                                            font-weight: 300;
                                                                                                            cursor: pointer;
                                                                                                            margin: 0;
                                                                                                            font-size: 16px;
                                                                                                        }
                                                                                                    }

                                                                                                    &+.col-md-9 {
                                                                                                        width: 60%;
                                                                                                    }
                                                                                                }
                                                                                            }

                                                                                            &:hover {
                                                                                                background: @black5Color;
                                                                                                border-radius: @roundedCorners;
                                                                                            }
                                                                                        }

                                                                                        &.list-group-header {
                                                                                            background: @accentColor5;
                                                                                            border: none;
                                                                                            border-radius: @roundedCorners;
                                                                                            padding: 0;
                                                                                            display: none;

                                                                                            .row {
                                                                                                >div {
                                                                                                    font-size: 14px;
                                                                                                    font-weight: 600;
                                                                                                    line-height: 1;

                                                                                                    strong {
                                                                                                        font-weight: 600;
                                                                                                    }
                                                                                                }
                                                                                            }
                                                                                        }

                                                                                        &:hover {
                                                                                            box-shadow: none;
                                                                                        }

                                                                                        &:last-child {
                                                                                            box-shadow: none;
                                                                                        }
                                                                                    }
                                                                                }

                                                                                tbody {
                                                                                    tr {
                                                                                        background-color: transparent;

                                                                                        td {
                                                                                            background-color: transparent;
                                                                                            border: none;
                                                                                            padding: 1.5rem 12px;

                                                                                            &:first-child {
                                                                                                border-radius: @roundedCorners 0 0 @roundedCorners;
                                                                                            }

                                                                                            &:last-child {
                                                                                                border-radius: 0 @roundedCorners @roundedCorners 0;
                                                                                            }

                                                                                            a {
                                                                                                &.btn {
                                                                                                    background-color: transparent;
                                                                                                    border: none;

                                                                                                    span {
                                                                                                        &.glyphicon-remove {
                                                                                                            font-family: "Font Awesome 5 Pro";
                                                                                                            font-weight: 300;
                                                                                                            -moz-osx-font-smoothing: grayscale;
                                                                                                            -webkit-font-smoothing: antialiased;
                                                                                                            display: inline-block;
                                                                                                            font-style: normal;
                                                                                                            font-variant: normal;
                                                                                                            text-rendering: auto;
                                                                                                            line-height: 1;
                                                                                                            color: @errorColor50;
                                                                                                            font-size: 16px;

                                                                                                            &::before {
                                                                                                                content: "\f00d";
                                                                                                            }

                                                                                                            &:hover {
                                                                                                                color: @errorColor;
                                                                                                            }
                                                                                                        }
                                                                                                    }
                                                                                                }
                                                                                            }
                                                                                        }

                                                                                        &:hover {
                                                                                            td {
                                                                                                background-color: @pageBgColor;
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                }
                                                                            }
                                                                        }

                                                                        .fileSelector {
                                                                            margin-bottom: 0;
                                                                            width: 100%;
                                                                            border: none;
                                                                            border-radius: @roundedCorners;
                                                                            position: absolute;
                                                                            top: 0;
                                                                            inset-inline-start: 0;
                                                                            align-items: flex-end;
                                                                            height: 100%;
                                                                            padding: 23px;
                                                                            gap: 6px;
                                                                            font-size: 12px;
                                                                            transition-duration: 0.2s;

                                                                            &:not([hidden]) {
                                                                                display: flex;
                                                                            }

                                                                            i {
                                                                                &.fa-cloud-upload {
                                                                                    display: block;
                                                                                    position: absolute;
                                                                                    inset-inline-end: 23px;
                                                                                    font-size: 16px;
                                                                                    margin: 0;
                                                                                    top: calc(50% - 8px);

                                                                                    &::before {
                                                                                        font-family: "Font Awesome 5 Pro";
                                                                                        font-weight: 300;
                                                                                        -moz-osx-font-smoothing: grayscale;
                                                                                        -webkit-font-smoothing: antialiased;
                                                                                        display: inline-block;
                                                                                        font-style: normal;
                                                                                        font-variant: normal;
                                                                                        text-rendering: auto;
                                                                                        line-height: 1;
                                                                                        content: "\f56f";
                                                                                        color: @darkTextColor;
                                                                                        font-size: 16px;
                                                                                    }

                                                                                    &~* {
                                                                                        color: @darkTextColor;
                                                                                        font-size: 12px;
                                                                                    }
                                                                                }
                                                                            }

                                                                            &.fileDragOver {
                                                                                box-shadow: 0 0 0 2px @accentColor50;
                                                                                background: @accentColor5;
                                                                                z-index: 99;
                                                                            }

                                                                            &~.file {
                                                                                display: flex;

                                                                                >.row {
                                                                                    &:first-child {
                                                                                        min-width: 100px;

                                                                                        .fileName {
                                                                                            flex: auto;
                                                                                            white-space: nowrap;
                                                                                            overflow: hidden;
                                                                                            text-overflow: ellipsis;
                                                                                            position: relative;

                                                                                            i {
                                                                                                position: absolute;
                                                                                                inset-inline-end: 0;
                                                                                            }
                                                                                        }

                                                                                        .fileSize {
                                                                                            flex: 0;
                                                                                            white-space: nowrap;
                                                                                            margin-inline-end: 10px;
                                                                                        }

                                                                                        &+.row {
                                                                                            .progress {
                                                                                                margin-inline-end: 36px;
                                                                                                height: 4px;
                                                                                                margin-top: 4px;
                                                                                                margin-bottom: 0;
                                                                                                background-color: rgba(27, 37, 60, 0.1);
                                                                                                box-shadow: none;

                                                                                                .progress-bar {
                                                                                                    background-color: rgba(41, 188, 143, 1);
                                                                                                    border-radius: 5px;
                                                                                                }
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                }

                                                                                &:nth-last-child(2) {
                                                                                    margin-bottom: 40px;
                                                                                }
                                                                            }
                                                                        }

                                                                        &.formio-component-multiple {
                                                                            .fileSelector {
                                                                                i {
                                                                                    &.fa-cloud-upload {
                                                                                        top: initial;
                                                                                    }
                                                                                }
                                                                            }
                                                                        }

                                                                        &.has-files {
                                                                            background-color: rgba(41, 188, 143, 0.03);

                                                                            &:not(.formio-component-multiple) {
                                                                                ul {
                                                                                    &.list-group {
                                                                                        margin-bottom: 0;
                                                                                    }
                                                                                }
                                                                            }
                                                                        }

                                                                        &.has-message {
                                                                            .fileSelector {
                                                                                padding-bottom: 44px;
                                                                            }

                                                                            .formio-errors {
                                                                                margin-top: 34px;
                                                                            }
                                                                        }
                                                                    }

                                                                    :where(label, label.col-form-label) {
                                                                        color: @darkTextColor70;
                                                                        font-size: 13px;
                                                                        font-weight: 600;
                                                                        margin-bottom: 0;
                                                                        line-height: 19px;

                                                                        :where(input, select) {
                                                                            border-radius: @roundedCorners !important;
                                                                            border-color: transparent;
                                                                            display: block;
                                                                            width: 100%;
                                                                            margin-top: 10px;
                                                                            padding: 2px 4px;

                                                                            & + .formio-button-reset {
                                                                            color: transparent;
                                                                            font-size: 12px;
                                                                            font-weight: 400;
                                                                            padding: 0;
                                                                            background: transparent;
                                                                            position: absolute;
                                                                            inset-inline-end: 0;
                                                                            bottom: 6px;
                                                                            height: auto;
                                                                            line-height: 1;

                                                                            &::before {
                                                                                font-family: 'Font Awesome 5 Pro';
                                                                                font-weight: 300;
                                                                                -moz-osx-font-smoothing: grayscale;
                                                                                -webkit-font-smoothing: antialiased;
                                                                                display: inline-block;
                                                                                font-style: normal;
                                                                                font-variant: normal;
                                                                                text-rendering: auto;
                                                                                line-height: 1;
                                                                                content: "\f057";
                                                                                color: @accentColor40;
                                                                            }
                                                                            }
                                                                        }

                                                                        &.radio {
                                                                            padding-inline-start: 0;
                                                                        }

                                                                        &.field-required {
                                                                            &::after {
                                                                                content: " ";
                                                                                display: inline-block;
                                                                                background-color: @errorColor70;
                                                                                vertical-align: unset;
                                                                                width: 6px;
                                                                                height: 6px;
                                                                                border-radius: 5px;
                                                                                position: relative;
                                                                                top: -1px;
                                                                                inset-inline-start: 2px;
                                                                                min-width: 6px;
                                                                            }

                                                                            i {
                                                                                margin-inline-start: 11px;

                                                                                &::before {
                                                                                    margin-inline-start: 0.3rem;
                                                                                }
                                                                            }

                                                                            &.control-label--hidden {
                                                                                position: absolute;

                                                                                &::after {
                                                                                    inset-inline-end: 10px;
                                                                                    inset-inline-start: initial;
                                                                                    position: absolute;
                                                                                    top: 10px;
                                                                                }
                                                                            }
                                                                        }

                                                                        i {
                                                                            &.fa-question-circle {
                                                                                margin-top: -4px;
                                                                                margin-inline-end: -33px;
                                                                                transform: translateY(1px);
                                                                            }
                                                                        }
                                                                    }
                                                                }

                                                                &+.formio-component {
                                                                    margin-top: 0;

                                                                    &.top-space {
                                                                        padding-top: 3rem;
                                                                    }
                                                                }
                                                            }

                                                            >.formio-component-part-b-data-tab {
                                                                &~ :where(.formio-component-panel, .formio-component-container > div > .formio-component-panel) {
                                                                    .card {
                                                                        background-color: transparent;

                                                                        .card-header {
                                                                            padding: 0 0 2.5rem;
                                                                            background-color: transparent;
                                                                        }

                                                                        .card-body {
                                                                            padding: 0;

                                                                            .formio-component-rejectionrow {
                                                                                margin: 0 !important;

                                                                                .formio-component-checkbox {
                                                                                    .form-check {
                                                                                        margin-top: 0;

                                                                                        label {
                                                                                            span {
                                                                                                font-weight: 600;
                                                                                                font-size: 15px;
                                                                                                line-height: 12px;
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                }

                                                                                .formio-component-select {
                                                                                    label {
                                                                                        font-weight: 600 !important;
                                                                                        color: @darkTextColor70;
                                                                                        font-size: 13px;
                                                                                    }

                                                                                    .formio-choices {
                                                                                        .selection {
                                                                                            &.dropdown {
                                                                                                border: solid 1px @borderColor;
                                                                                                border-radius: @roundedCorners !important;
                                                                                                height: @fieldHeight;

                                                                                                &:where(:hover, :focus) {
                                                                                                    outline: none;
                                                                                                }
                                                                                            }
                                                                                        }

                                                                                        &.is-open {
                                                                                            .selection {
                                                                                                &.dropdown {
                                                                                                    border-radius: @roundedCorners @roundedCorners 0 0 !important;
                                                                                                }
                                                                                            }
                                                                                        }

                                                                                        &::after {
                                                                                            color: @accentColor40;
                                                                                            content: "\F078";
                                                                                            font-family: 'Font Awesome 5 Pro';
                                                                                            font-weight: 300;
                                                                                            -moz-osx-font-smoothing: grayscale;
                                                                                            -webkit-font-smoothing: antialiased;
                                                                                            display: inline-block;
                                                                                            font-style: normal;
                                                                                            font-variant: normal;
                                                                                            text-rendering: auto;
                                                                                            font-size: 16px;
                                                                                            width: auto;
                                                                                            border: none;
                                                                                            margin-top: 0;
                                                                                            height: 8px;
                                                                                            line-height: 7px;
                                                                                            top: calc(50% - 4px);
                                                                                        }

                                                                                        &:hover {
                                                                                            &::after {
                                                                                                color: @accentColor;
                                                                                            }
                                                                                        }

                                                                                        .choices__list {
                                                                                            &.choices__list--dropdown {
                                                                                                border: solid 1px @borderColor;
                                                                                                border-radius: 0 0 @roundedCorners @roundedCorners;

                                                                                                input {
                                                                                                    &.choices__input {
                                                                                                        border-radius: 0 !important;
                                                                                                    }
                                                                                                }

                                                                                                .choices__item {
                                                                                                    &.choices__item--choice {
                                                                                                        padding: 10px 20px;
                                                                                                        font-size: 12px;
                                                                                                        font-weight: 600;
                                                                                                        color: @darkTextColor;

                                                                                                        &:where(.is-selected, :hover) {
                                                                                                            background-color: @accentColor5;
                                                                                                        }
                                                                                                    }
                                                                                                }
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                }
                                                                            }

                                                                            .formio-component-panel {
                                                                                .card {
                                                                                    .card-body {
                                                                                        >.formio-component-checkbox {
                                                                                            display: none;
                                                                                        }
                                                                                    }
                                                                                }
                                                                            }
                                                                        }
                                                                    }

                                                                    &:last-child {
                                                                        .card {
                                                                            margin-bottom: 0 !important;
                                                                        }
                                                                    }
                                                                }

                                                                &+.formio-component-button {
                                                                    &[class*="print"] {
                                                                        margin: 2rem 0 0;
                                                                        display: flex;
                                                                        justify-content: flex-end;

                                                                        button {
                                                                            border-radius: @roundedCorners !important;
                                                                            background-color: @accentColor;
                                                                            padding-inline-start: 25px;
                                                                            padding-inline-end: 25px;
                                                                            font-weight: 600;
                                                                            text-transform: none;
                                                                            position: relative;
                                                                            z-index: 1;
                                                                            height: 26px;
                                                                            padding-top: 0;
                                                                            padding-bottom: 0;
                                                                            font-size: 12px;

                                                                            &:hover {
                                                                                box-shadow: 0 9px 20px @accentColor40 !important;
                                                                                opacity: 0.9;
                                                                            }
                                                                        }

                                                                        &+.formio-component {
                                                                            &:where(.formio-component-table, .formio-component-container > div > .formio-component-table) {
                                                                                &.formio-component-validationtable {
                                                                                    margin: 0 !important;

                                                                                    table {
                                                                                        &.table {
                                                                                            tbody {
                                                                                                tr {
                                                                                                    td {
                                                                                                        &:first-child {
                                                                                                            flex: 2;

                                                                                                            .formio-component-htmlelement {
                                                                                                                .formio-component-checkbox {
                                                                                                                    &.check-switch {
                                                                                                                        margin-top: calc(-4rem + 5px);
                                                                                                                    }
                                                                                                                }
                                                                                                            }
                                                                                                        }
                                                                                                    }
                                                                                                }
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                }
                                                                            }
                                                                        }
                                                                    }

                                                                    &+.formio-component-container {
                                                                        &.formio-component-previewcontainer {
                                                                            margin-top: calc(-2rem - 3px);

                                                                            &+.formio-component {
                                                                                &.formio-component-panel {
                                                                                    margin-top: 3rem;
                                                                                }
                                                                            }
                                                                        }
                                                                    }
                                                                }

                                                                &~.formio-component-container>div>.formio-component-panel {

                                                                    .card,
                                                                    &~.formio-component.formio-component-panel .card {
                                                                        background-color: transparent;
                                                                        border: none;
                                                                        margin-top: 0;

                                                                        .card-header {
                                                                            padding: 0;
                                                                            margin: 0;
                                                                        }

                                                                        >.card-body {
                                                                            &:not(:first-child) {
                                                                                padding-top: 1rem !important;
                                                                            }

                                                                            .formio-component-panel {
                                                                                .card-header {
                                                                                    &+.card-body {
                                                                                        .formio-component-panel {
                                                                                            .card {
                                                                                                margin: 0;

                                                                                                .card-body {
                                                                                                    &:first-child {
                                                                                                        padding-top: 0 !important;
                                                                                                    }
                                                                                                }
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                }
                                                                            }
                                                                        }
                                                                    }

                                                                    &:last-child {
                                                                        margin-bottom: 0;
                                                                    }
                                                                }

                                                                &~.formio-component {

                                                                    &.formio-component-table,
                                                                    &.formio-component-container>div>.formio-component-table {
                                                                        &.formio-component-validationtable {
                                                                            margin: 0 !important;

                                                                            table {
                                                                                &.table {
                                                                                    table-layout: auto;

                                                                                    tbody {
                                                                                        tr {
                                                                                            td {
                                                                                                &:first-child {
                                                                                                    flex: 2;
                                                                                                    padding: 0;
                                                                                                    width: 90%;

                                                                                                    .formio-component-htmlelement {
                                                                                                        margin-top: 6px;

                                                                                                        .formio-component-checkbox {
                                                                                                            &.check-switch {
                                                                                                                margin-top: 0;

                                                                                                                >div {
                                                                                                                    &:first-child {
                                                                                                                        margin-top: 0;
                                                                                                                        margin-inline-end: 32px;

                                                                                                                        &+div {
                                                                                                                            flex: auto;

                                                                                                                            textarea {
                                                                                                                                width: 100%;
                                                                                                                                min-height: 64px;
                                                                                                                                padding: 4px 10px;
                                                                                                                                border: solid 1px @borderColor;
                                                                                                                                border-radius: @roundedCorners !important;

                                                                                                                                &:where(:hover, :focus) {
                                                                                                                                    outline: none;
                                                                                                                                }

                                                                                                                                &::-webkit-resizer {
                                                                                                                                    display: none;
                                                                                                                                }
                                                                                                                            }

                                                                                                                            &.required {
                                                                                                                                label {
                                                                                                                                    &::after {
                                                                                                                                        content: " ";
                                                                                                                                        display: inline-block;
                                                                                                                                        background-color: @errorColor70;
                                                                                                                                        vertical-align: unset;
                                                                                                                                        width: 6px;
                                                                                                                                        height: 6px;
                                                                                                                                        border-radius: 5px;
                                                                                                                                        position: relative;
                                                                                                                                        top: -1px;
                                                                                                                                        inset-inline-start: 2px;
                                                                                                                                        min-width: 6px;
                                                                                                                                    }
                                                                                                                                }

                                                                                                                                textarea {
                                                                                                                                    color: @darkTextColor;

                                                                                                                                    &:empty {
                                                                                                                                        border-color: @errorColor70 !important;
                                                                                                                                    }
                                                                                                                                }
                                                                                                                            }
                                                                                                                        }

                                                                                                                        &.form-check {
                                                                                                                            display: flex;
                                                                                                                            align-items: center;
                                                                                                                            gap: 12px;

                                                                                                                            >span {
                                                                                                                                font-size: 18px;
                                                                                                                                font-weight: 600;
                                                                                                                                color: @warningNotice;
                                                                                                                                line-height: 1;
                                                                                                                            }

                                                                                                                            &.mt-3 {
                                                                                                                                margin-top: 0 !important;
                                                                                                                            }

                                                                                                                            &.mb-3 {
                                                                                                                                margin-bottom: 0 !important;
                                                                                                                            }
                                                                                                                        }
                                                                                                                    }
                                                                                                                }

                                                                                                                @media screen and (max-width: 768px) {
                                                                                                                    flex-direction: column;

                                                                                                                    >div {
                                                                                                                        &#datarejectionreason {
                                                                                                                            display: flex;
                                                                                                                            flex-direction: column;
                                                                                                                            margin-top: 3rem;
                                                                                                                            width: 200%;
                                                                                                                        }
                                                                                                                    }
                                                                                                                }
                                                                                                            }
                                                                                                        }
                                                                                                    }

                                                                                                    &~td {
                                                                                                        flex: 1;
                                                                                                    }
                                                                                                }
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                }
                                                                            }

                                                                            @media screen and (max-width: 768px) {
                                                                                overflow: visible;
                                                                                border: none;
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                            }

                                                            >.formio-component-part-b-documents-tab {
                                                                &+.formio-component-panel {
                                                                    .card {
                                                                        background-color: transparent;

                                                                        .card-header {
                                                                            padding: 0;
                                                                            background-color: transparent;
                                                                        }

                                                                        .card-body {
                                                                            padding: 2.7rem 0 0 !important;

                                                                            .formio-component-textarea {
                                                                                .card-body {
                                                                                    padding-top: 0 !important;
                                                                                }
                                                                            }

                                                                            textarea {
                                                                                &.required {
                                                                                    color: @darkTextColor;

                                                                                    &:empty {
                                                                                        border-color: @errorColor70 !important;
                                                                                    }

                                                                                    &::after {
                                                                                        content: " ";
                                                                                        display: inline-block;
                                                                                        background-color: @errorColor70;
                                                                                        vertical-align: unset;
                                                                                        width: 6px;
                                                                                        height: 6px;
                                                                                        border-radius: 5px;
                                                                                        position: relative;
                                                                                        top: -1px;
                                                                                        inset-inline-start: 2px;
                                                                                        min-width: 6px;
                                                                                    }
                                                                                }
                                                                            }
                                                                        }
                                                                    }

                                                                    &:last-child {
                                                                        .card {
                                                                            margin-bottom: 0 !important;

                                                                            &::after {
                                                                                display: none;
                                                                            }
                                                                        }
                                                                    }

                                                                    &[class*="formio-component-documentTab"] {
                                                                        >.card {
                                                                            >.card-body {
                                                                                padding-top: 0 !important;

                                                                                >.formio-component-panel {
                                                                                    >.card {
                                                                                        >.card-body {
                                                                                            padding-top: 0 !important;

                                                                                            >.formio-component-htmlelement {
                                                                                                margin-top: 0;

                                                                                                .validation-headers {
                                                                                                    .form-field-type-columns {
                                                                                                        .row {
                                                                                                            align-items: center;
                                                                                                            line-height: 1;
                                                                                                            transition-duration: 0.3s;

                                                                                                            .col-sm-1 {
                                                                                                                flex: 0;
                                                                                                                width: auto;

                                                                                                                .form-field-type-mycounter {
                                                                                                                    .bullet {
                                                                                                                        font-size: 14px;
                                                                                                                        line-height: 17px;
                                                                                                                        display: block;
                                                                                                                        background-color: @accentColor;
                                                                                                                    }
                                                                                                                }
                                                                                                            }

                                                                                                            .col-sm-8 {
                                                                                                                flex: auto;
                                                                                                                z-index: 2;

                                                                                                                .form-field-type-button {
                                                                                                                    button {
                                                                                                                        background: transparent;
                                                                                                                        box-shadow: none !important;
                                                                                                                        font-weight: 600;
                                                                                                                        text-transform: none;
                                                                                                                        width: calc(100% + 110px);
                                                                                                                        z-index: 9;
                                                                                                                        display: flex;
                                                                                                                        max-width: calc(100% + 110px);
                                                                                                                        padding-inline-end: 110px;

                                                                                                                        &:where(:hover, :focus) {
                                                                                                                            color: initial;
                                                                                                                        }
                                                                                                                    }
                                                                                                                }
                                                                                                            }

                                                                                                            .col-sm-3 {
                                                                                                                z-index: 1;
                                                                                                                min-width: fit-content;

                                                                                                                .form-field-type-htmlelement {
                                                                                                                    margin: 0;
                                                                                                                    line-height: 1;

                                                                                                                    .label {
                                                                                                                        display: inline-block;
                                                                                                                        padding: 5px 8px 2px;
                                                                                                                    }
                                                                                                                }
                                                                                                            }

                                                                                                            &:hover {
                                                                                                                background: @accentColor10;
                                                                                                                padding: 0 15px;
                                                                                                            }

                                                                                                            @media screen and (max-width: 768px) {
                                                                                                                flex-direction: row;

                                                                                                                >div {
                                                                                                                    &+div {
                                                                                                                        margin-top: 0;
                                                                                                                    }
                                                                                                                }
                                                                                                            }
                                                                                                        }
                                                                                                    }

                                                                                                    &.document-open {
                                                                                                        .tab-description-back-to-list {
                                                                                                            display: flex;
                                                                                                            margin-bottom: 1rem;
                                                                                                        }

                                                                                                        >.form-field-type-columns {
                                                                                                            display: none;
                                                                                                        }

                                                                                                        &+.carousel {
                                                                                                            &#carouselRevision {
                                                                                                                display: block;
                                                                                                            }
                                                                                                        }
                                                                                                    }
                                                                                                }

                                                                                                .tab-description-back-to-list {
                                                                                                    font-size: 18px;
                                                                                                    align-items: center;
                                                                                                    cursor: pointer;
                                                                                                    border-radius: 6px;
                                                                                                    display: flex;
                                                                                                    height: 100%;

                                                                                                    &:hover {
                                                                                                        background: @accentColor10;
                                                                                                        color: #222222;

                                                                                                        i {
                                                                                                            padding: 0 10px;
                                                                                                        }
                                                                                                    }

                                                                                                    i {
                                                                                                        transition-duration: 0.15s;
                                                                                                        display: block;
                                                                                                    }
                                                                                                }

                                                                                                .carousel {
                                                                                                    &#carouselRevision {
                                                                                                        display: none;

                                                                                                        .carousel-inner {
                                                                                                            .carousel-item {
                                                                                                                flex-wrap: wrap;
                                                                                                                gap: 3rem 0;
                                                                                                                align-items: center;

                                                                                                                >.row {
                                                                                                                    &:first-child {
                                                                                                                        flex: initial;
                                                                                                                        max-width: 50%;
                                                                                                                        padding-inline-end: 20px;
                                                                                                                    }

                                                                                                                    &:last-child {
                                                                                                                        flex: 0 0 100%;
                                                                                                                    }
                                                                                                                }

                                                                                                                .documents-validator {
                                                                                                                    min-height: 37px;
                                                                                                                    align-items: center;
                                                                                                                    display: flex;
                                                                                                                    gap: 8px;
                                                                                                                    font-weight: 500;
                                                                                                                    font-size: 18px;
                                                                                                                    line-height: 1.3;

                                                                                                                    .carousel-selected-index {
                                                                                                                        inset-inline-start: 0;
                                                                                                                        top: 0;
                                                                                                                        position: relative;
                                                                                                                    }
                                                                                                                }

                                                                                                                >.row {
                                                                                                                    .formio-component-checkbox {
                                                                                                                        &.check-switch {
                                                                                                                            margin-top: 0;

                                                                                                                            .checkbox {
                                                                                                                                input {
                                                                                                                                    &[type="checkbox"] {
                                                                                                                                        &+span {
                                                                                                                                            &::before {
                                                                                                                                                top: 0;
                                                                                                                                            }
                                                                                                                                        }
                                                                                                                                    }
                                                                                                                                }
                                                                                                                            }

                                                                                                                            .form-check {
                                                                                                                                display: flex;
                                                                                                                                align-items: center;
                                                                                                                                gap: 12px;

                                                                                                                                &.mt-3 {
                                                                                                                                    margin-top: 0 !important;
                                                                                                                                }

                                                                                                                                &.mb-3 {
                                                                                                                                    margin-bottom: 0 !important;
                                                                                                                                }

                                                                                                                                >span {
                                                                                                                                    font-size: 18px;
                                                                                                                                    font-weight: 600;
                                                                                                                                    color: @warningNotice;
                                                                                                                                    line-height: 1;
                                                                                                                                }
                                                                                                                            }

                                                                                                                            textarea {
                                                                                                                                border: solid 1px @borderColor;
                                                                                                                                border-radius: @roundedCorners !important;

                                                                                                                                &:where(:hover, :focus) {
                                                                                                                                    outline: none;
                                                                                                                                }

                                                                                                                                &::-webkit-resizer {
                                                                                                                                    display: none;
                                                                                                                                }
                                                                                                                            }

                                                                                                                            label {
                                                                                                                                width: 100%;
                                                                                                                                max-width: 320px;

                                                                                                                                span {
                                                                                                                                    font-weight: 600;
                                                                                                                                    font-size: 16px;
                                                                                                                                    margin-bottom: 7px;
                                                                                                                                    display: block;
                                                                                                                                    margin-top: 2px;
                                                                                                                                }
                                                                                                                            }

                                                                                                                            select {
                                                                                                                                border: solid 1px @borderColor;
                                                                                                                                border-radius: @roundedCorners !important;
                                                                                                                                height: 34px;
                                                                                                                                padding: 0 6px;
                                                                                                                                width: 100%;
                                                                                                                                max-width: 320px;

                                                                                                                                &:focus {
                                                                                                                                    outline: none;
                                                                                                                                    box-shadow: none;
                                                                                                                                }
                                                                                                                            }

                                                                                                                            >div {
                                                                                                                                &:first-child {
                                                                                                                                    margin-top: 8px;
                                                                                                                                    margin-inline-end: 32px;

                                                                                                                                    &+div {
                                                                                                                                        flex: auto;
                                                                                                                                        display: flex;
                                                                                                                                        flex-direction: column;
                                                                                                                                        gap: 1rem;

                                                                                                                                        textarea {
                                                                                                                                            flex: 1 1 auto;
                                                                                                                                            padding: 4px 10px;
                                                                                                                                        }
                                                                                                                                    }
                                                                                                                                }
                                                                                                                            }

                                                                                                                            @media screen and (max-width: 768px) {
                                                                                                                                flex-direction: column;

                                                                                                                                >div {
                                                                                                                                    &+div {
                                                                                                                                        margin-top: 2rem;

                                                                                                                                        select {
                                                                                                                                            width: 100%;
                                                                                                                                        }
                                                                                                                                    }
                                                                                                                                }
                                                                                                                            }
                                                                                                                        }
                                                                                                                    }

                                                                                                                    >.col-md-6 {
                                                                                                                        &:last-child {
                                                                                                                            .formio-component {
                                                                                                                                &.formio-component-panel {
                                                                                                                                    &:first-child {
                                                                                                                                        margin-top: 0;
                                                                                                                                    }

                                                                                                                                    >.card {
                                                                                                                                        border: none;
                                                                                                                                        margin-top: 0;

                                                                                                                                        >.card-body {
                                                                                                                                            >.formio-component-panel {
                                                                                                                                                .card {
                                                                                                                                                    margin: 0;
                                                                                                                                                    border: none;

                                                                                                                                                    .card-header {
                                                                                                                                                        margin: 0 0 1rem;
                                                                                                                                                        background-color: transparent;
                                                                                                                                                    }

                                                                                                                                                    .card-body {
                                                                                                                                                        padding-top: 0 !important;

                                                                                                                                                        .formio-component-panel {
                                                                                                                                                            &:first-child {
                                                                                                                                                                .card {
                                                                                                                                                                    .card-body {
                                                                                                                                                                        padding-top: 0 !important;
                                                                                                                                                                    }
                                                                                                                                                                }
                                                                                                                                                            }
                                                                                                                                                        }
                                                                                                                                                    }
                                                                                                                                                }
                                                                                                                                            }
                                                                                                                                        }
                                                                                                                                    }
                                                                                                                                }
                                                                                                                            }

                                                                                                                            .block-previewcontainer {
                                                                                                                                flex: 1;

                                                                                                                                >.formio-component-container {
                                                                                                                                    &:first-child {
                                                                                                                                        margin-top: 0;
                                                                                                                                    }
                                                                                                                                }

                                                                                                                                &:empty {
                                                                                                                                    display: none;
                                                                                                                                }
                                                                                                                            }
                                                                                                                        }
                                                                                                                    }

                                                                                                                    &:nth-child(3) {
                                                                                                                        &~.row {
                                                                                                                            margin-top: 3rem;
                                                                                                                        }
                                                                                                                    }

                                                                                                                    @media screen and (max-width: 991px) {
                                                                                                                        flex-direction: column;
                                                                                                                    }

                                                                                                                    &:last-child {
                                                                                                                        margin: 0;
                                                                                                                        gap: 3rem;

                                                                                                                        &::after,
                                                                                                                        &::before {
                                                                                                                            display: none;
                                                                                                                        }

                                                                                                                        >.col-md-6 {
                                                                                                                            padding: 0;
                                                                                                                            flex: 1;

                                                                                                                            &:last-child {
                                                                                                                                display: contents;
                                                                                                                            }
                                                                                                                        }
                                                                                                                    }
                                                                                                                }

                                                                                                                &.item {
                                                                                                                    .img-thumbnail {
                                                                                                                        img {
                                                                                                                            margin: 0 auto;
                                                                                                                        }
                                                                                                                    }
                                                                                                                }

                                                                                                                &:where(.active, .prev, .next, .right, .left) {
                                                                                                                    display: flex;
                                                                                                                }
                                                                                                            }

                                                                                                            .carousel-control {
                                                                                                                display: flex;
                                                                                                                align-items: center;
                                                                                                                justify-content: center;
                                                                                                                position: relative;
                                                                                                                width: 40px;
                                                                                                                transition-duration: 0.15s;
                                                                                                                border-radius: @roundedCorners;

                                                                                                                span {
                                                                                                                    display: block;
                                                                                                                    font-size: 34px;
                                                                                                                    color: #333333;
                                                                                                                    text-shadow: none;
                                                                                                                    width: 100%;
                                                                                                                }

                                                                                                                &:hover {
                                                                                                                    background: @accentColor10;
                                                                                                                    padding: 0 10px;
                                                                                                                    color: #222222;
                                                                                                                }
                                                                                                            }
                                                                                                        }
                                                                                                    }
                                                                                                }
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                }
                                                                            }
                                                                        }
                                                                    }
                                                                }

                                                                &+.formio-component-table {
                                                                    &.formio-component-validationtable {
                                                                        margin: 0 !important;
                                                                    }
                                                                }
                                                            }

                                                            >.formio-component {
                                                                &.formio-component-applicantPaymentFees {
                                                                    .card {
                                                                        background-color: transparent;

                                                                        .card-header {
                                                                            padding: 0;
                                                                        }

                                                                        .card-body {
                                                                            padding-top: 2.5rem !important;

                                                                            .formio-component-columns {
                                                                                margin-top: 0;

                                                                                .formio-component-htmlelement {
                                                                                    margin-top: 0;
                                                                                }

                                                                                &.formio-component-totalPrice {
                                                                                    align-items: center;

                                                                                    .formio-component-htmlelement {
                                                                                        h4 {
                                                                                            font-weight: 600;
                                                                                        }

                                                                                        p {
                                                                                            span {
                                                                                                font-weight: 600;
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                }
                                                                            }
                                                                        }
                                                                    }
                                                                }

                                                                &.formio-component-applicantPaymentPayments {
                                                                    margin-bottom: 0;

                                                                    .card {
                                                                        background-color: transparent;
                                                                        margin-bottom: 0 !important;

                                                                        .card-header {
                                                                            padding: 0;
                                                                        }

                                                                        .card-body {
                                                                            padding-top: 2.5rem !important;

                                                                            .formio-component-columns {
                                                                                .col-md-1 {
                                                                                    display: none;
                                                                                }

                                                                                .col-md-11 {
                                                                                    padding-inline-start: 0;
                                                                                }

                                                                                .formio-component-checkbox {
                                                                                    input {
                                                                                        &[disabled] {
                                                                                            display: none;
                                                                                        }
                                                                                    }
                                                                                }
                                                                            }
                                                                        }
                                                                    }

                                                                    &+.formio-component-button {
                                                                        display: none;
                                                                    }
                                                                }

                                                                &.formio-component-panel {
                                                                    .card {
                                                                        background-color: transparent;

                                                                        .card-header {
                                                                            padding: 0;
                                                                        }

                                                                        .card-body {
                                                                            .formio-component-panel {
                                                                                .card-header {
                                                                                    &+.card-body {
                                                                                        .formio-component-panel {
                                                                                            .card {
                                                                                                margin: 0;

                                                                                                .card-body {
                                                                                                    &:first-child {
                                                                                                        padding-top: 0 !important;
                                                                                                    }
                                                                                                }
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                }
                                                                            }
                                                                        }
                                                                    }
                                                                }

                                                                &.formio-component-htmlelement {
                                                                    &.alert {
                                                                        font-size: 14px !important;
                                                                        color: #d56d72 !important;

                                                                        p {
                                                                            color: #222222;
                                                                            font-weight: 600;
                                                                        }
                                                                        &+.formio-component-panel {
                                                                            margin-top: 0;

                                                                            .card {
                                                                                background-color: transparent;

                                                                                .card-header {
                                                                                    padding: 0;
                                                                                }

                                                                                .card-body {
                                                                                    padding-top: 2.5rem !important;
                                                                                }
                                                                            }

                                                                            &~.formio-component-panel {
                                                                                .card {
                                                                                    background-color: transparent;

                                                                                    .card-header {
                                                                                        padding: 0;
                                                                                    }

                                                                                    .card-body {
                                                                                        padding-top: 2.5rem !important;
                                                                                    }
                                                                                }

                                                                                &:last-child {
                                                                                    margin-bottom: 0;

                                                                                    .card {
                                                                                        margin-bottom: 0 !important;
                                                                                    }
                                                                                }
                                                                            }
                                                                        }
                                                                    }
                                                                }

                                                                .formio-component-file {
                                                                    ul {
                                                                        &.list-group {
                                                                            margin-bottom: 0;
                                                                        }
                                                                    }
                                                                }

                                                                &.formio-component-table {
                                                                    &.formio-component-documentsTabTable {
                                                                        margin-top: 0;

                                                                        table {
                                                                            tbody {
                                                                                tr {
                                                                                    &.row {
                                                                                        .formio-component-datagrid {
                                                                                            margin-top: 0px;

                                                                                            table {
                                                                                                &.datagrid-table {
                                                                                                    tbody {
                                                                                                        tr {
                                                                                                            td {
                                                                                                                padding: 0;

                                                                                                                .formio-component-panel {
                                                                                                                    margin-bottom: 0;

                                                                                                                    .card {
                                                                                                                        margin-bottom: 0 !important;
                                                                                                                        border-radius: @roundedCorners;
                                                                                                                        padding: 12px;

                                                                                                                        .card-body {
                                                                                                                            .formio-component-htmlelement {
                                                                                                                                p {
                                                                                                                                    line-height: 1.2;

                                                                                                                                    a {
                                                                                                                                        display: block;
                                                                                                                                        margin-bottom: 2rem;

                                                                                                                                        img {
                                                                                                                                            max-width: 100%;
                                                                                                                                            margin: 12px 0;
                                                                                                                                        }

                                                                                                                                        &:last-child {
                                                                                                                                            margin-bottom: 0;
                                                                                                                                        }
                                                                                                                                    }

                                                                                                                                    span {
                                                                                                                                        &.bullet {
                                                                                                                                            vertical-align: initial;
                                                                                                                                        }
                                                                                                                                    }
                                                                                                                                }
                                                                                                                            }
                                                                                                                        }
                                                                                                                    }
                                                                                                                }
                                                                                                            }
                                                                                                        }
                                                                                                    }
                                                                                                }
                                                                                            }
                                                                                        }
                                                                                    }

                                                                                    &:not(.row) {
                                                                                        >td {
                                                                                            padding-inline-start: 0;

                                                                                            .formio-component-datagrid {
                                                                                                margin-top: 0;
                                                                                            }
                                                                                            >.formio-component-panel {
                                                                                                margin-bottom: 0;
                                                                                                margin-top: 0;

                                                                                                >.card {
                                                                                                    margin-bottom: 0 !important;
                                                                                                    border-radius: @roundedCorners;
                                                                                                    padding: 12px;

                                                                                                    .card-body {
                                                                                                        .formio-component-htmlelement {
                                                                                                            margin-top: 0;

                                                                                                            p {
                                                                                                                line-height: 1.2;

                                                                                                                a {
                                                                                                                    display: block;
                                                                                                                    margin-bottom: 2rem;

                                                                                                                    img, i {
                                                                                                                        max-width: 100%;
                                                                                                                        margin: 12px 0 0;
                                                                                                                    }

                                                                                                                    &:last-child {
                                                                                                                        margin-bottom: 0;
                                                                                                                    }
                                                                                                                }

                                                                                                                span {
                                                                                                                    &.bullet {
                                                                                                                        vertical-align: initial;
                                                                                                                    }
                                                                                                                }
                                                                                                            }
                                                                                                        }
                                                                                                    }
                                                                                                }
                                                                                            }
                                                                                            &.dg-action-col {
                                                                                                display: none;
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                }
                                                                            }
                                                                        }

                                                                        @media screen and (max-width: 768px) {
                                                                            table {
                                                                                &.table {
                                                                                    tbody {
                                                                                        tr {
                                                                                            &.row {
                                                                                                flex-direction: column;
                                                                                                margin: 0;
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                }
                                                                            }
                                                                        }
                                                                    }
                                                                }

                                                                .formio-component-checkbox {
                                                                    .checkbox {
                                                                        label {
                                                                            input {
                                                                                &[type="checkbox"] {
                                                                                    transform: scale(0);
                                                                                }
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                            }

                                                            #datarejectionreason {
                                                                textarea {
                                                                    font-size: 14px;
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }

                                    .validator-radio {
                                        display: inline-flex;
                                        align-items: center;
                                        flex: 0;
                                        width: fit-content;
                                        cursor: pointer;

                                        input {
                                            display: none;

                                            &.valid {
                                                &:checked {
                                                    &~label {
                                                        &[for*="-yes"] {
                                                            background-color: #80c49b;
                                                            color: white;
                                                            opacity: 1;

                                                            &+label {
                                                                background-color: @accentColor10;
                                                                opacity: 0.6;

                                                                &:hover {
                                                                    background-color: #d56d72;
                                                                    color: white;
                                                                    opacity: 1;
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }

                                            &.invalid {
                                                &:checked {
                                                    &~label {
                                                        &[for*="-yes"] {
                                                            opacity: 0.6;

                                                            &+label {
                                                                background-color: #d56d72;
                                                                color: white;
                                                                opacity: 1;
                                                            }

                                                            &:hover {
                                                                opacity: 1;
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }

                                        label {
                                            margin-bottom: 0 !important;
                                            padding: 0.6rem 1rem;
                                            cursor: pointer !important;
                                            transition-duration: 0.3s;
                                            opacity: 0.6;
                                            white-space: nowrap;

                                            &[for*="-yes"] {
                                                background-color: @accentColor10;
                                                border-radius: 6px 0 0 6px;

                                                &:hover {
                                                    background-color: #80c49b;
                                                    color: white;
                                                }
                                            }

                                            &[for*="-no"] {
                                                background-color: @accentColor5;
                                                border-radius: 0 6px 6px 0;

                                                &:hover {
                                                    background-color: #d56d72;
                                                    color: white;
                                                }
                                            }

                                            &:hover {
                                                opacity: 1;
                                            }
                                        }
                                    }

                                    >.alert {
                                        border-inline-start: solid 3px @errorNotice !important;
                                        border-radius: 0 @roundedCorners @roundedCorners 0;
                                        padding: 0.5rem 1rem !important;
                                        background-color: @errorNotice5Solid !important;
                                        max-width: 100%;
                                        font-weight: 600 !important;
                                        color: @errorNotice !important;

                                        &::after {
                                            display: none !important;
                                        }

                                        &.alert-success {
                                            position: fixed !important;
                                            top: 19px;
                                            z-index: 999;
                                            width: auto;
                                            max-width: 320px;
                                            background: @successNotification !important;
                                            color: @whiteColor !important;
                                            padding: 1rem 2rem !important;
                                            font-size: 16px !important;
                                            inset-inline-end: 16px;

                                            &::after {
                                                display: none !important;
                                            }
                                        }

                                        &.alert-danger {
                                            bottom: auto;
                                            top: 12px;
                                            z-index: 999;
                                        }

                                        &:hover {
                                            opacity: 1;
                                        }

                                        p+ul {
                                            margin-top: 1rem;
                                        }

                                        button.close-button {
                                            color: transparent;
                                            position: relative;
                                            top: -5px;
                                            inset-inline-end: -10px;
                                            border-radius: 0 6px 0 0;
                                            height: 37px;

                                            &::before {
                                                font-family: 'Font Awesome 5 Pro';
                                                font-weight: 300;
                                                -moz-osx-font-smoothing: grayscale;
                                                -webkit-font-smoothing: antialiased;
                                                display: inline-block;
                                                font-style: normal;
                                                font-variant: normal;
                                                text-rendering: auto;
                                                line-height: 1;
                                                content: "\F00D";
                                                color: #1b2331;
                                                position: absolute;
                                                font-size: 16px;
                                                top: calc(50% - 8px);
                                            }

                                            &:hover {
                                                background: rgba(70, 81, 170, 0.1);
                                            }
                                        }

                                        ul {
                                            li {
                                                list-style: none;
                                            }
                                        }
                                    }
                                }

                                /* Part B Role form preview new Formio */
                                &.part-b-preview-new-formio {
                                    #tabs-zone {
                                        .panel {
                                            .part-b-forms-tabs {
                                                z-index: 1;
                                                position: relative;

                                                ul {
                                                    &.card-head {
                                                        background-color: transparent;

                                                        >li {
                                                            box-shadow: none;
                                                            height: auto;

                                                            a {
                                                                font-size: 23px;
                                                                color: @accentColor40;
                                                                font-weight: 600;
                                                                padding-inline-start: @panelBodyPadding;
                                                                padding-inline-end: @panelBodyPadding;
                                                                border-top: none;
                                                                border-top-left-radius: @roundedCorners;
                                                                border-top-right-radius: @roundedCorners;
                                                                box-shadow: none;
                                                            }

                                                            &.active {
                                                                a {
                                                                    background-color: @whiteColor;
                                                                    color: @accentColor;
                                                                    box-shadow: 0 11px 20px @black5Color;
                                                                }
                                                            }

                                                            @media screen and (max-width: 680px) {
                                                                a {
                                                                    padding: 2rem;
                                                                    line-height: 1;
                                                                    font-size: 18px;
                                                                }
                                                            }

                                                            @media screen and (max-width: 480px) {
                                                                a {
                                                                    padding: 1rem;
                                                                    font-size: 16px;
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }

                                            .panel-body {
                                                &.tab-content {
                                                    position: relative;
                                                    z-index: 2;
                                                    box-shadow: 0 11px 20px @black5Color;
                                                    padding: 23px !important;

                                                    #form-type-role {
                                                        #formio-form-role {
                                                            .formio-component {
                                                                &.formio-component-form {
                                                                    margin-top: 0;

                                                                    .formio-component-panel {
                                                                        > .card {
                                                                            background: @pageBgColor !important;
                                                                            padding: 20px;
                                                                            border-radius: @roundedCorners;
                                                                            box-shadow: none !important;

                                                                            .card-header {
                                                                                padding: 0;

                                                                                .card-title {
                                                                                    color: @darkTextColor;
                                                                                    font-weight: 600;

                                                                                    .formio-collapse-text {
                                                                                        display: none;
                                                                                    }
                                                                                }
                                                                            }

                                                                            > .card-body {
                                                                                padding: 3rem 0 0 !important;

                                                                                &:first-child {
                                                                                    padding-top: 0 !important;
                                                                                }
                                                                            }
                                                                        }
                                                                    }

                                                                    input {
                                                                        &.form-control {
                                                                            &:where([type="text"], [type="search"], [type="phoneNumber"], [type="email"]) {
                                                                                height: @fieldHeight !important;
                                                                                border: solid 1px @borderColor;
                                                                                border-radius: @roundedCorners !important;
                                                                                font-weight: 600;
                                                                                font-size: 16px;

                                                                                &:where(:hover, :focus) {
                                                                                    outline: none;
                                                                                }

                                                                                &:disabled {
                                                                                    background-color: @partbDisabledFieldBgColor !important;
                                                                                }
                                                                            }
                                                                        }
                                                                    }

                                                                    textarea {
                                                                        &.form-control {
                                                                            border: solid 1px @borderColor;
                                                                            border-radius: @roundedCorners !important;

                                                                            &:where(:hover, :focus) {
                                                                                outline: none;
                                                                            }

                                                                            &::-webkit-resizer {
                                                                                display: none;
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        @buttonAccentColor: @accentColor;

                        :is(.new-formio-part-b .formio-component-modal-wrapper .formio-component, .new-formio-part-b .formio-component-part-b-processing-tab ~ .formio-component, .new-formio-part-b .formio-component-part-b-processing-tab ~ .formio-component .formio-component, .part-b-preview-new-formio #form-type-role #formio-form-role .formio-component.formio-component-form .formio-component) {
                            :where(label, label.col-form-label) {
                                color: @darkTextColor70;
                                font-size: 13px;
                                font-weight: 600;
                                margin-bottom: 0;
                                line-height: 19px;
                                position: relative;
                                padding-left: 0;

                                &.field-required {
                                    &::after {
                                        content: " ";
                                        display: inline-block;
                                        background-color: @errorColor70;
                                        vertical-align: unset;
                                        width: 6px;
                                        height: 6px;
                                        border-radius: 5px;
                                        position: relative;
                                        top: -1px;
                                        inset-inline-start: 5px;
                                        min-width: 6px;
                                    }

                                    i {
                                        margin-inline-start: 11px;

                                        &::before {
                                            margin-inline-start: 0.3rem;
                                        }
                                    }

                                    &.control-label--hidden {
                                        position: absolute;

                                        &::after {
                                            inset-inline-end: 10px;
                                            inset-inline-start: initial;
                                            position: absolute;
                                            top: 10px;
                                        }
                                    }
                                }

                                i {
                                    &.fa-question-circle {
                                        margin-top: -4px;
                                        margin-inline-end: -33px;
                                        transform: translateY(1px);
                                    }
                                }
                            }

                            input {
                                &.form-control {
                                    &:where([type="text"], [type="search"], [type="phoneNumber"], [type="email"]) {
                                        height: @fieldHeight;

                                        &:where(:hover, :focus) {
                                            outline: none;
                                        }

                                        &:disabled {
                                            background-color: @partbDisabledFieldBgColor !important;
                                        }
                                    }
                                }
                            }

                            .fa-question-circle {
                                &::before {
                                    font-weight: 400;
                                    font-size: 15px;
                                    color: @accentColor;
                                }
                            }

                            .form-text {
                                &.text-muted {
                                    &:not(:empty) {
                                        margin-top: 0.6rem;
                                        margin-bottom: 1rem;
                                        font-size: 1.2rem;
                                        text-align: start;
                                        display: block;
                                        line-height: 1.2;
                                        color: @accentColor;
                                    }
                                }
                            }

                            &:where(.has-error, .formio-error-wrapper) {
                                border-radius: @roundedCorners;

                                .formio-errors {
                                    .form-text {
                                        font-weight: 400;
                                        margin-top: 0.6rem;
                                        margin-bottom: 0;
                                        font-size: 1.1rem;
                                        text-align: start;
                                        display: block;
                                        line-height: 1.2;
                                        white-space: break-spaces;

                                        &.error {
                                            color: @errorColor50;
                                        }
                                    }
                                }

                                &:focus-within {
                                    animation: hglightComp 1s ease-out 0.15s;
                                }
                            }

                            .field-wrapper {
                                align-items: center;

                                label {
                                    &.col-form-label {
                                        margin-bottom: 0 !important;
                                    }
                                }
                            }

                            &:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-url, .formio-component-address, .formio-component-datetime) {
                                input {
                                    &.form-control {
                                        &:where([type="text"], [type="phoneNumber"], [type="email"]) {
                                            border: solid 1px @borderColor;
                                            border-radius: @roundedCorners !important;
                                            font-weight: 600;

                                            &:where(:hover, :focus) {
                                                outline: none !important;
                                            }
                                        }

                                        &+.form-text.text-right {
                                            font-size: 1.2rem;
                                            padding-top: 4px;
                                        }
                                    }
                                }

                                .input-group {
                                    height: @fieldHeight;

                                    >div {
                                        border: solid 1px @borderColor;
                                        background-color: @whiteColor !important;

                                        &.input-group-prepend {
                                            border-radius: @roundedCorners 0 0 @roundedCorners !important;
                                            color: @darkTextColor;
                                            font-weight: 600;

                                            &+input {
                                                &.form-control {
                                                    border-top-left-radius: 0 !important;
                                                    border-bottom-left-radius: 0 !important;
                                                    border-inline-start: none !important;
                                                }
                                            }
                                        }

                                        &.input-group-append {
                                            border-radius: 0 @roundedCorners @roundedCorners 0 !important;
                                            color: @darkTextColor;
                                            font-weight: 600;
                                        }
                                    }

                                    input {
                                        &.form-control {
                                            &:first-child {
                                                &:not(:last-child) {
                                                    border-top-right-radius: 0 !important;
                                                    border-bottom-right-radius: 0 !important;
                                                    border-inline-end: 0;
                                                }
                                            }

                                            &:not(:first-child) {
                                                &:not(:last-child) {
                                                    border-top-right-radius: 0 !important;
                                                    border-bottom-right-radius: 0 !important;
                                                    border-inline-end: none !important;
                                                }
                                            }
                                        }
                                    }

                                    &.formio-multiple-mask-container {
                                        display: flex;

                                        .formio-multiple-mask-select {
                                            width: fit-content;
                                            max-width: 50%;
                                            height: 40px !important;
                                            border-radius: @roundedCorners 0 0 @roundedCorners !important;
                                            border-inline-end: none;

                                            &+.formio-multiple-mask-input {
                                                border-radius: 0 @roundedCorners @roundedCorners 0 !important;
                                            }

                                            &:where(:hover, :focus, :active) {
                                                box-shadow: none;
                                                outline: none;
                                                background-color: @accentColor5;
                                            }
                                        }
                                    }
                                }

                                &.value-label-style {
                                    input {
                                        &.form-control {
                                            border: none;
                                            background-color: transparent !important;
                                        }
                                    }
                                }

                                &.field-plus-button {
                                    input {
                                        &.form-control {
                                            border-top-right-radius: 0 !important;
                                            border-bottom-right-radius: 0 !important;
                                        }
                                    }
                                }

                                &.has-error {
                                    .input-group {
                                        >div {
                                            border-color: @errorColor30;
                                        }

                                        &.formio-multiple-mask-container {
                                            .formio-multiple-mask-select {
                                                border-color: @errorColor30 !important;
                                            }
                                        }
                                    }

                                    input {
                                        &.form-control {
                                            border-color: @errorColor30 !important;
                                        }
                                    }
                                }

                                label.col-form-label {
                                    &:not(:first-child) {
                                        margin-bottom: 0;
                                        margin-top: 6px;
                                    }
                                }
                            }

                            &.formio-component-day {
                                input.formio-select-autocomplete-input {
                                    position: absolute;
                                }

                                .row {
                                    .col {
                                        flex: 1;

                                        .form-control {
                                            border-radius: @roundedCorners !important;
                                        }
                                    }
                                }
                            }

                            &.formio-component-select {
                                .formio-choices {
                                    .selection {
                                        &.dropdown {
                                            border: solid 1px @borderColor;
                                            border-radius: @roundedCorners !important;
                                            height: @fieldHeight;
                                            display: flex;
                                            align-items: center;

                                            &:where(:hover, :focus) {
                                                outline: none;
                                            }

                                            &[disabled] {
                                                background-color: #EFEFEF;

                                                .choices__item--selectable {
                                                    padding-inline-end: 0;
                                                }
                                            }
                                        }
                                    }

                                    &.is-open {
                                        .selection {
                                            &.dropdown {
                                                border-radius: @roundedCorners @roundedCorners 0 0 !important;
                                            }
                                        }
                                    }

                                    &::after {
                                        color: @accentColor40;
                                        content: "\F078";
                                        font-family: 'Font Awesome 5 Pro';
                                        font-weight: 300;
                                        -moz-osx-font-smoothing: grayscale;
                                        -webkit-font-smoothing: antialiased;
                                        display: inline-block;
                                        font-style: normal;
                                        font-variant: normal;
                                        text-rendering: auto;
                                        font-size: 16px;
                                        width: auto;
                                        border: none;
                                        margin-top: 0;
                                        height: 8px;
                                        line-height: 7px;
                                        top: calc(50% - 4px);
                                    }

                                    &:hover {
                                        &::after {
                                            color: @accentColor;
                                        }
                                    }

                                    .choices__list {
                                        &.choices__list--dropdown {
                                            border: solid 1px @borderColor;
                                            border-radius: 0 0 @roundedCorners @roundedCorners;

                                            input {
                                                &.choices__input {
                                                    border-radius: 0 !important;
                                                    padding: 10px 14px;
                                                    font-size: 12px;

                                                    &:placeholder-shown {
                                                        opacity: 0.5;
                                                    }
                                                }
                                            }

                                            .choices__item {
                                                &.choices__item--choice {
                                                    padding: 10px 14px;
                                                    font-size: 13px;
                                                    font-weight: 400;
                                                    color: @darkTextColor;
                                                    word-break: break-word;
                                                    white-space: break-spaces;
                                                    text-align: start;

                                                    &:where(.is-selected, :hover) {
                                                        background-color: @accentColor5;
                                                    }

                                                    span {
                                                        line-height: 1.2;

                                                        mark {
                                                            background-color: #FEFF00;
                                                            padding: 0;
                                                            display: inline-block;
                                                            line-height: 1.1;
                                                        }
                                                    }

                                                    >span {
                                                        display: flex;
                                                        gap: 12px;

                                                        span {
                                                            min-width: 30px;
                                                            flex: 1;

                                                            &+span {
                                                                width: auto;
                                                                flex: 10;
                                                            }
                                                        }

                                                        &.value {
                                                            >span {
                                                                width: 100%;
                                                            }
                                                        }
                                                    }
                                                }
                                            }

                                            .choices__list {
                                                max-height: 22vh;
                                            }
                                        }

                                        &--single {
                                            .choices__item {
                                                .value:not(:last-child) {
                                                    padding-inline-end: 15px;
                                                }

                                                &--selectable {
                                                    padding-right: initial;
                                                    padding-inline-end: 30px;

                                                    span.key {
                                                        display: none;
                                                    }
                                                }
                                            }
                                        }
                                    }

                                    &.is-disabled {
                                        &::after {
                                            display: none;
                                        }
                                    }
                                }

                                input.formio-select-autocomplete-input {
                                    position: absolute;
                                }

                                &.has-error {
                                    .formio-choices {
                                        .selection {
                                            &.dropdown {
                                                border-color: @errorColor30 !important;
                                            }
                                        }
                                    }
                                }

                                &:not(.formio-modified) {
                                    .control-label--hidden {
                                        &.field-required {
                                            inset-inline-end: 26px;
                                        }
                                    }
                                }

                                &.formio-component-multiple {
                                    .formio-choices {
                                        .selection {
                                            &.dropdown {
                                                min-height: 40px;
                                                height: auto;

                                                .choices__input {
                                                    flex: 1;
                                                    background: transparent;
                                                    height: 28px !important;
                                                    margin-bottom: 0;
                                                }

                                                .choices__list--multiple {
                                                    margin-bottom: -6px;
                                                    max-width: 70%;
                                                    flex: auto;

                                                    &:empty {
                                                        flex: 0;
                                                    }

                                                    :where(.choices__item--selectable, .choices__item.is-highlighted) {
                                                        overflow: hidden;
                                                        max-width: calc(100% - 28px);
                                                        min-width: 0;
                                                        position: relative;
                                                        background-color: @accentColor10Solid;
                                                        color: @accentColor;
                                                        font-weight: 400;
                                                        border-radius: @roundedCorners;
                                                        padding-block: 4px;
                                                        padding-inline-start: 7px;
                                                        padding-inline-end: 28px;
                                                        border-color: @accentColor20;

                                                        >span {
                                                            text-overflow: ellipsis;
                                                            font-size: 15px;
                                                        }

                                                        button.choices__button {
                                                            position: absolute;
                                                            inset-inline-end: 0;
                                                            margin: 0;
                                                            border: none;
                                                            border-radius: 0 4px 4px 0;
                                                            display: flex;
                                                            top: 0;
                                                            height: 100%;
                                                            box-shadow: -1px 0 0 @accentColor40;
                                                            filter: brightness(0.5);
                                                        }

                                                        &[data-value="[]"] {
                                                            display: none;
                                                        }
                                                    }

                                                    .choices__item.is-highlighted {
                                                        background-color: @accentColor;
                                                        color: @whiteColor;

                                                        button.choices__button {
                                                            filter: brightness(1);
                                                        }
                                                    }
                                                }
                                            }
                                        }

                                        &::after {
                                            position: absolute;
                                            inset-inline-end: 14px;
                                        }
                                    }
                                }

                                &.field-plus-button {
                                    .formio-choices {
                                        .selection {
                                            &.dropdown {
                                                border-radius: @roundedCorners 0 0 @roundedCorners !important;
                                            }
                                        }
                                    }
                                }

                                &.align-value-right {
                                    .formio-choices .selection.dropdown {
                                        flex-direction: row-reverse;

                                        .choices__list--multiple {
                                            text-align: end;
                                            padding-inline-end: 25px;
                                        }
                                    }

                                    .choices__item {
                                        text-align: end;
                                    }
                                }
                            }

                            &.formio-component-datetime {
                                .input-group {
                                    input {
                                        &.form-control.input {
                                            &:not([disabled]) {
                                                border: solid 1px @borderColor;
                                                border-radius: @roundedCorners 0 0 @roundedCorners !important;
                                                border-inline-end: none;
                                                padding-inline-end: 0;
                                            }

                                            &[type="text"][disabled] {
                                                border-radius: @roundedCorners !important;
                                                border-inline-end: solid 1px @borderColor !important;

                                                &~.input-group-append {
                                                    display: none;
                                                }
                                            }
                                        }
                                    }

                                    .input-group-append {
                                        border: solid 1px @borderColor;
                                        border-radius: 0 @roundedCorners @roundedCorners 0 !important;
                                        height: @fieldHeight;
                                        border-inline-start: none;
                                        cursor: pointer;

                                        .input-group-text {
                                            display: flex;
                                            height: 100%;
                                            align-items: center;
                                            border-radius: 0 6px 6px 0;
                                            border: none;

                                            i {
                                                &::before {
                                                    font-family: "Font Awesome 5 Pro";
                                                    font-weight: 400;
                                                    -moz-osx-font-smoothing: grayscale;
                                                    -webkit-font-smoothing: antialiased;
                                                    display: inline-block;
                                                    font-style: normal;
                                                    font-variant: normal;
                                                    text-rendering: auto;
                                                    line-height: 1;
                                                    content: "\f073";
                                                    color: @accentColor40;
                                                }
                                            }
                                        }
                                    }
                                }

                                &:hover {
                                    .input-group {
                                        .input-group-append {
                                            .input-group-text {
                                                i {
                                                    &::before {
                                                        color: @accentColor;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }

                                &.has-error {
                                    .input-group {
                                        input {
                                            &.form-control.input {
                                                border-color: @errorColor30 !important;
                                            }
                                        }

                                        .input-group-append {
                                            border-color: @errorColor30 !important;
                                        }
                                    }
                                }

                                &.value-label-style {
                                    input {
                                        &:where(.form-control, .input) {
                                            border: none !important;

                                            &:where([disabled], :disabled) {
                                                background-color: transparent !important;
                                            }
                                        }
                                    }

                                    .input-group-append {
                                        display: none;
                                    }
                                }
                            }

                            &.formio-component-radio {
                                .form-radio {
                                  &.radio {
                                    padding-top: 13px;
                                    margin-top: 0;
          
                                    :where(.form-check, .form-check-inline) {
                                      cursor: pointer;
                                      padding-bottom: 10px;
                                      margin-top: 0;
                                      margin-bottom: 0;
          
                                      label {
                                        margin-top: 0;
                                        padding-inline-start: 20px;
          
                                        &::before {
                                          display: none;
                                        }
                                        input {
                                          &[type="radio"] {
                                            appearance: inherit;
                                            padding: 0;
                                            margin: 0;
          
                                            &::before {
                                              -webkit-font-smoothing: antialiased;
                                              display: inline-block;
                                              font-style: normal;
                                              font-variant: normal;
                                              text-rendering: auto;
                                              line-height: 1;
                                              font-family: "Font Awesome 5 Pro";
                                              font-weight: 400;
                                              font-size: @defaultTextSize;
                                              content: "\f111";
                                              color: @accentColor50 !important;
                                            }
                                            & + span {
                                              font-size: @defaultTextSize;
                                              font-weight: 500;
                                              color: @accentColor50 !important;
                                              margin-inline-start: 6px;
                                              position: relative;
                                              top: 2px;
                                              line-height: 14px;
                                            }
                                            &:where([checked], :checked) {
                                              &::before {
                                                content: "\f192";
                                                font-weight: 900;
                                                color: @accentColor !important;
                                              }
                                              & + span {
                                                color: @accentColor !important;
                                              }
                                            }
                                            &:focus {
                                              outline: none;
                                            }
                                            &[disabled] {
                                              border-radius: 50%;
                                            }
                                          }
                                        }
                                        &:hover {
                                          input {
                                            &[type="radio"] {
                                              appearance: inherit;
          
                                              &::before {
                                                color: @accentColor !important;
                                              }
                                              & + span {
                                                color: @accentColor !important;
                                              }
                                            }
                                          }
                                        }
                                        &.label-position-left {
                                          span {
                                            margin-inline-end: 3px;
                                          }
                                          input.form-check-input {
                                            position: initial;
                                          }
                                        }
                                      }
                                    }
                                    .form-check {
                                        display: block;
                                    }
                                    .form-check-inline {
                                        display: inline-block;
                                    }
                                  }
                                }
                                &.radio-switch {
                                  .form-radio {
                                    &.radio {
                                      height: @fieldHeight;
                                      border-radius: @roundedCorners;
                                      border: solid 1px @borderColor;
                                      width: fit-content !important;
                                      width: -moz-fit-content !important;
                                      padding-top: 0;
                                      display: flex;
          
                                      :where(.form-check-inline, .form-check) {
                                        margin: 0;
                                        flex: auto;
                                        height: 100%;
                                        padding: 0;
                                        display: flex;
                                        align-items: center;
          
                                        label {
                                          &.form-check-label {
                                            display: flex;
                                            justify-content: center;
                                            height: 100%;
                                            padding-inline-start: 0;
          
                                            input {
                                              &[type="radio"] {
                                                display: none;
          
                                                &:checked {
                                                  & + span {
                                                    background-color: @accentColor70Solid;
                                                    color: @whiteColor !important;
                                                    border: solid 1px @accentColor70Solid;
                                                    margin: -1px;
                                                    height: calc(100% + 2px);
                                                    width: calc(100% + 2px);
                                                  }
                                                }
                                              }
                                            }
                                            span {
                                              margin: 0;
                                              display: flex;
                                              height: 100%;
                                              align-items: center;
                                              cursor: pointer;
                                              padding: 1px 15px;
                                              width: 100%;
                                              top: 0;
                                              border-inline-end: solid 1px @borderColor;
          
                                              &:hover {
                                                background-color: @accentColor10;
                                              }
                                            }
                                          }
                                        }
                                        &:first-child {
                                          label {
                                            &.form-check-label {
                                              span {
                                                border-radius: @roundedCorners 0 0 @roundedCorners;
                                              }
                                            }
                                          }
                                        }
                                        &:last-child {
                                          label {
                                            &.form-check-label {
                                              span {
                                                border-radius: 0 @roundedCorners @roundedCorners 0;
                                                border-inline-end: none;
                                              }
                                            }
                                          }
                                        }
                                        &.radio-selected {
                                          label {
                                            &.form-check-label span {
                                              background-color: @accentColor70;
                                              color: @whiteColor !important;
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                  &.required {
                                    label {
                                      &.field-required {
                                        &.control-label--hidden {
                                          display: none;
          
                                          &::after {
                                            display: none;
                                          }
                                        }
                                        &:not(.control-label--hidden) {
                                          & + .form-radio.radio {
                                            &::before {
                                              display: none;
                                            }
                                          }
                                        }
                                      }
                                    }
                                    .form-radio {
                                      &.radio {
                                        &::before {
                                          content: " ";
                                          display: inline-block;
                                          background-color: @errorColor70;
                                          width: 6px;
                                          height: 6px;
                                          border-radius: 5px;
                                          position: absolute;
                                          inset-inline-end: -16px;
                                          top: calc(50% - 3px);
                                        }
                                        &::after {
                                          display: none !important;
                                        }
                                      }
                                    }
                                  }
                                  label {
                                    &.control-label {
                                      & + .input-group {
                                        .radio-inline {
                                          margin-top: 0;
          
                                          label {
                                            span {
                                              top: 0;
                                            }
                                          }
                                        }
                                      }
                                    }
                                    &.form-check-label {
                                      margin-bottom: 0;
                                    }
                                  }
                                }
                                &:not(.radio-switch) {
                                  .form-radio {
                                    &.radio {
                                      .form-check:last-child {
                                        padding-bottom: 0px;
                                      }
                                    }
                                  }
                                }
                                &.top-label-space {
                                  &.formio-component-label-hidden {
                                    padding-top: 13px;
                                  }
                                }
                                &.remove-top-space {
                                  .form-radio {
                                    padding-top: 0;
                                  }
                                }
                                .field-wrapper {
                                  .field-label--right {
                                    label.col-form-label {
                                      display: flex;
                                      justify-content: flex-end;
                                    }
                                  }
                                }
                              }

                            &.formio-component-button {
                                button {
                                    &.btn {
                                        border-radius: @roundedCorners !important;
                                        background-color: @buttonAccentColor;
                                        padding-inline-start: 25px;
                                        padding-inline-end: 25px;
                                        font-weight: 500;
                                        text-transform: none;
                                        position: relative;
                                        z-index: 1;
                                        padding-top: 12px;
                                        padding-bottom: 12px;
                                        line-height: 1;
                                        color: @whiteColor;
                                        text-align: center;
                                        font-size: 16px;
                                        gap: 8px;

                                        &.btn-sm {
                                            min-height: @fieldHeight;
                                        }

                                        &.btn-xs {
                                            padding: 0.9rem 1rem;
                                        }

                                        .fa-cog {
                                            position: absolute;
                                            animation: none;
                                            background: inherit;
                                            width: 100%;
                                            z-index: 9;
                                            color: transparent;

                                            &+span {
                                                position: absolute !important;
                                                top: -7px;
                                                z-index: 99;
                                                border-radius: 50%;
                                                width: 8px;
                                                height: 8px;
                                                -webkit-animation-fill-mode: both;
                                                animation-fill-mode: both;
                                                -webkit-animation: load7 1.8s infinite ease-in-out;
                                                animation: load7 1.8s infinite ease-in-out;
                                                color: #ffffff;
                                                font-size: 10px;
                                                margin: 0 auto;
                                                text-indent: -9999em;
                                                -webkit-transform: translateZ(0);
                                                -ms-transform: translateZ(0);
                                                transform: translateZ(0);
                                                -webkit-animation-delay: -0.16s;
                                                animation-delay: -0.16s;

                                                &::after,
                                                &::before {
                                                    border-radius: 50%;
                                                    width: 8px;
                                                    height: 8px;
                                                    -webkit-animation-fill-mode: both;
                                                    animation-fill-mode: both;
                                                    -webkit-animation: load7 1.8s infinite ease-in-out;
                                                    animation: load7 1.8s infinite ease-in-out;
                                                    content: '';
                                                    position: absolute;
                                                    top: 0;
                                                }

                                                &::before {
                                                    inset-inline-start: -16px;
                                                    -webkit-animation-delay: -0.32s;
                                                    animation-delay: -0.32s;
                                                }

                                                &::after {
                                                    inset-inline-start: 16px;
                                                }
                                            }
                                        }

                                        &:hover {
                                            box-shadow: 0 9px 20px @accentColor40 !important;
                                            opacity: 0.9;
                                        }

                                        &.btn-green {
                                            background-color: @successNotice;
                                        }

                                        &.btn-blue {
                                            background-color: @infoNotice;
                                        }

                                        &.btn-orange {
                                            background-color: @warningNotice;
                                        }

                                        &.btn-red {
                                            background-color: @errorNotice;
                                        }

                                        .fa-question-circle {
                                            color: @whiteColor;

                                            &::before {
                                                color: inherit;
                                            }
                                        }
                                    }
                                }

                                &:is(.field-plus-button) {
                                    button {
                                        &.btn {
                                            border-top-left-radius: 0 !important;
                                            border-bottom-left-radius: 0 !important;
                                            margin-inline-start: -24px;
                                            transition-duration: 0s;
                                        }
                                    }
                                }

                                &.action-progress {
                                    button {
                                        font-family: 'Nunito Sans', sans-serif !important;
                                    }

                                    &.cog-during-form-submit {
                                        button {
                                            font-family: 'Nunito Sans', sans-serif !important;

                                            &::before {
                                                font-family: 'Font Awesome 5 Pro' !important;
                                                font-weight: 300;
                                                -moz-osx-font-smoothing: grayscale;
                                                -webkit-font-smoothing: antialiased;
                                                display: inline-block;
                                                font-style: normal;
                                                font-variant: normal;
                                                text-rendering: auto;
                                                line-height: 1;
                                                -webkit-animation: buttonProgress 2s linear infinite;
                                                animation: buttonProgress 2s linear infinite;
                                                margin-inline-end: 8px;
                                                content: "\f013";
                                            }
                                        }
                                    }

                                    &:not(.cog-during-form-submit) {
                                        button {
                                            &::before {
                                                display: none;
                                            }
                                        }
                                    }
                                }

                                &:where(.action-success, .action-error) {
                                    button {
                                        &::after {
                                            font-family: 'Font Awesome 5 Pro' !important;
                                            font-weight: 400;
                                            -moz-osx-font-smoothing: grayscale;
                                            -webkit-font-smoothing: antialiased;
                                            display: inline-block;
                                            font-style: normal;
                                            font-variant: normal;
                                            text-rendering: auto;
                                            line-height: 1;
                                            position: absolute;
                                            inset-inline-end: -26px;
                                            font-size: 19px;
                                            -webkit-animation: buttonFeedback 0.2s ease-in-out;
                                            animation: buttonFeedback 0.2s ease-in-out;
                                        }
                                    }
                                }

                                &.action-success {
                                    button {
                                        box-shadow: 0 0 0 2px @successColor60 !important;

                                        &::after {
                                            content: "\f058";
                                            color: @successColor;
                                        }
                                    }

                                    &.success-disabled {
                                        button {
                                            cursor: not-allowed;
                                            color: @dark;
                                            background: @main-bg;
                                            pointer-events: none;
                                        }
                                    }
                                }

                                &.action-error {
                                    button {
                                        box-shadow: 0 0 0 2px @errorColor60 !important;

                                        &::after {
                                            content: "\f06a";
                                            color: @errorColor;
                                        }
                                    }
                                }

                                &.btn-secondary {
                                    button {
                                        &.btn {
                                            background-color: @accentColor10;
                                            color: @accentColor;

                                            &:hover {
                                                background-color: @accentColor5;
                                                color: @accentColor;
                                            }
                                        }
                                    }
                                }
                            }

                            &.formio-component-htmlelement {
                                :is(h1, h2, h3, h4, h5, h6) {
                                    line-height: 1.3;
                                    padding: 0;
                                    margin: 0;
                                }

                                h2 {
                                    font-size: calc(12pt * 2);
                                }

                                h3 {
                                    font-size: calc(12pt * 1.8);
                                }

                                h4 {
                                    font-size: calc(12pt * 1.6);
                                }

                                p {
                                    font-size: @defaultTextSize !important;
                                    color: @darkTextColor !important;
                                    margin-top: 0;
                                    margin-bottom: 0;

                                    span {
                                        font-size: @defaultTextSize;
                                        color: @darkTextColor;

                                        &.text-tiny {
                                            font-size: 9px;

                                            * {
                                                font-size: 9px;
                                            }

                                            span {
                                                background-color: transparent !important;
                                            }
                                        }

                                        &.text-small {
                                            font-size: 11px;

                                            * {
                                                font-size: 11px;
                                            }
                                        }

                                        &.text-big {
                                            font-size: 18px;

                                            * {
                                                font-size: 18px;
                                            }
                                        }

                                        &.text-huge {
                                            font-size: 24px;

                                            * {
                                                font-size: 24px;
                                            }
                                        }

                                        &.currency-prefixed {
                                            margin-inline-end: 5px;
                                        }

                                        &.currency-suffixed {
                                            &:not(:empty) {
                                                margin-inline-start: 5px;
                                            }
                                        }
                                    }

                                    &:empty+p {
                                        margin-top: 0;
                                    }

                                    &:first-child {
                                        margin-top: 0;
                                    }
                                }

                                strong {
                                    font-weight: 900;
                                }

                                :is(ul, ol) {
                                    margin-top: 0;
                                    margin-bottom: 0;
                                }

                                ol {
                                    list-style: none;
                                    counter-reset: li;

                                    li {
                                        font-size: @defaultTextSize;
                                        color: @darkTextColor;
                                        padding-inline-start: 2.4rem;
                                        position: relative;
                                        line-height: 1.5;

                                        &::before {
                                            content: counter(li) + ".";
                                            display: inline-block;
                                            width: 1em;
                                            inset-inline-start: 0;
                                            counter-increment: li;
                                            position: absolute;
                                        }

                                        span {
                                            font-size: @defaultTextSize;
                                            color: @darkTextColor;

                                            &.text-tiny {
                                                font-size: 12px;

                                                * {
                                                    font-size: 12px;
                                                }

                                                span {
                                                    background-color: transparent !important;
                                                }
                                            }

                                            &.text-small {
                                                font-size: 14px;

                                                * {
                                                    font-size: 14px;
                                                }
                                            }

                                            &.text-big {
                                                font-size: 20px;

                                                * {
                                                    font-size: 20px;
                                                }
                                            }

                                            &.text-huge {
                                                font-size: 24px;

                                                * {
                                                    font-size: 24px;
                                                }
                                            }
                                        }
                                    }
                                }

                                ul {
                                    margin-top: 0;
                                    padding-inline-start: 1.8rem;

                                    li {
                                        font-size: @defaultTextSize;
                                        color: @darkTextColor;
                                        line-height: 1.5;
                                    }
                                }

                                p:not(:empty)+ul,
                                p:not(:empty)+ol {
                                    margin-top: 0;
                                }

                                table {
                                    width: 100%;

                                    tr {
                                        td {
                                            font-size: @defaultTextSize;
                                            color: @darkTextColor;
                                            border: solid 1px #ebebeb;
                                            padding: 0 10px;

                                            span {
                                                &.text-tiny {
                                                    font-size: 9px;

                                                    * {
                                                        font-size: 9px;
                                                    }

                                                    span {
                                                        background-color: transparent !important;
                                                    }
                                                }

                                                &.text-small {
                                                    font-size: 11px;

                                                    * {
                                                        font-size: 11px;
                                                    }
                                                }

                                                &.text-big {
                                                    font-size: 18px;

                                                    * {
                                                        font-size: 18px;
                                                    }
                                                }

                                                &.text-huge {
                                                    font-size: 24px;

                                                    * {
                                                        font-size: 24px;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }

                                blockquote {
                                    background: @whiteColor !important;
                                    border-inline-start: solid 3px;
                                    border-radius: 0 @roundedCorners @roundedCorners 0;
                                    padding: 0 2rem !important;
                                    margin: 0;
                                    border-left-color: @accentColor !important;

                                    &::after {
                                        display: none;
                                    }
                                }

                                &:where(.notice, .info-notice, .success-notice, .warning-notice, .error-notice) {
                                    background: @darkTextColor3 !important;
                                    border-inline-start: solid 4px;
                                    border-radius: 0 @roundedCorners @roundedCorners 0;
                                    padding: 1.5rem 2rem;
                                }

                                &.notice {
                                    border-inline-start-color: @accentColor;

                                    &.fill-background {
                                        background-color: @accentColor5 !important;

                                        * {
                                            color: @accentColor !important;
                                        }
                                    }
                                }

                                &.info-notice {
                                    border-inline-start-color: @infoNotice;

                                    &.fill-background {
                                        background-color: @infoNotice5 !important;

                                        * {
                                            color: @infoNotice !important;
                                        }
                                    }
                                }

                                &.success-notice {
                                    border-inline-start-color: @successNotice;

                                    &.fill-background {
                                        background-color: @successNotice5 !important;

                                        * {
                                            color: @successNotice !important;
                                        }
                                    }
                                }

                                &.warning-notice {
                                    border-inline-start-color: @warningNotice;

                                    &.fill-background {
                                        background-color: @warningNotice5 !important;

                                        * {
                                            color: @warningNotice !important;
                                        }
                                    }
                                }

                                &.error-notice {
                                    border-inline-start-color: @errorNotice;

                                    &.fill-background {
                                        background-color: @errorNotice5 !important;

                                        * {
                                            color: @errorNotice !important;
                                        }
                                    }
                                }

                                &:not(.notice) {
                                    &:not(.info-notice) {
                                        &:not(.success-notice) {
                                            &:not(.warning-notice) {
                                                &:not(.error-notice) {
                                                    &:first-child {
                                                        margin: -5px 0;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }

                                &.alert {
                                    &.formio-hidden {
                                        &+.formio-component {
                                            &:not(.formio-hidden) {
                                                margin-top: 0;
                                            }
                                        }
                                    }

                                    &[class*="reason"] {
                                        margin-top: 5px;
                                        width: fit-content;
                                        max-width: 100%;

                                        * {
                                            color: @errorNotice !important;
                                            font-size: 14px !important;
                                            font-weight: 600;
                                        }

                                        &.editgrid-validation-reason {
                                            margin-bottom: -18px !important;
                                            box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);

                                            &::before {
                                                content: "" !important;
                                                display: block !important;
                                                width: 0;
                                                height: 0;
                                                border-width: 10px 8px 0 8px;
                                                border-color: @errorNotice5Solid transparent transparent;
                                                border-style: solid solid none;
                                                position: absolute;
                                                z-index: 9;
                                                bottom: -10px;
                                                filter: drop-shadow(0px 3px 2px rgba(0, 0, 0, 0.15));
                                            }
                                        }

                                        &:first-child {
                                            margin: 0 !important;
                                        }

                                        &:not(.formio-hidden)~.formio-component-file {
                                            margin-top: 1rem !important;
                                        }
                                    }

                                    &.alert-danger {
                                        border-inline-start: solid 3px @errorNotice !important;
                                        border-radius: 0 @roundedCorners @roundedCorners 0;
                                        padding: 0.5rem 1rem !important;
                                        background-color: @errorNotice5Solid !important;
                                        max-width: 100%;
                                        font-weight: 600 !important;
                                        color: @errorNotice !important;

                                        &.general-rejection-reason {
                                            padding: 2rem !important;
                                            width: 100%;

                                            :where(p, p span) {
                                                font-weight: 600;
                                                font-size: 16px !important;
                                                color: @errorNotice !important;
                                            }

                                            p+div {
                                                font-weight: 600;
                                                margin: 5px 0;
                                            }

                                            div {
                                                line-height: 1.4;
                                                margin-bottom: 4px;

                                                &:empty {
                                                    display: none;
                                                }

                                                &:last-of-type {
                                                    margin-bottom: 0;
                                                }
                                            }
                                        }

                                        &[class*="RejectionReasons"] {
                                            padding: 2rem !important;
                                            color: @errorNotice !important;

                                            div+div:not(:empty) {
                                                margin-top: 0.6rem;
                                            }
                                        }

                                        &[class*="documentRejectionReasons"] {
                                            p+div {
                                                font-size: 16px;
                                                font-weight: 600;
                                            }
                                        }

                                        &::after {
                                            display: none !important;
                                        }
                                    }
                                }
                            }

                            &.formio-component-textarea {
                                textarea {
                                    &.form-control {
                                        border: solid 1px @borderColor;
                                        border-radius: @roundedCorners !important;

                                        &:where(:hover, :focus) {
                                            outline: none;
                                        }

                                        &::-webkit-resizer {
                                            display: none;
                                        }
                                    }
                                }

                                &.has-error {
                                    textarea {
                                        &.form-control {
                                            border-color: @errorColor30 !important;
                                        }
                                    }
                                }

                                .card.card-body {
                                    margin-bottom: 0 !important;
                                    padding: 0 !important;
                                    background-color: transparent;
                                    box-shadow: none;

                                    .formio-editor-read-only-content {
                                        min-height: @fieldHeight;
                                        line-height: 1.84615385;
                                        padding: 4.5px 14px;
                                        font-size: 14px;
                                        background-color: #EFEFEF;
                                        letter-spacing: 0.4pt !important;
                                        color: #313335;
                                        border: solid 1px @borderColor;
                                        border-radius: @roundedCorners !important;
                                    }
                                }

                                &.field-plus-button {
                                    textarea {
                                        &.form-control {
                                            border-top-right-radius: 0 !important;
                                        }
                                    }
                                }
                            }

                            &.formio-component-selectboxes {
                                .form-radio {
                                    &.radio {
                                        padding-top: 13px;
                                        margin-top: 0;

                                        :where(.form-check, .form-check-inline) {
                                            cursor: pointer;
                                            padding-bottom: 10px;
                                            margin-top: 0;
                                            margin-bottom: 0;

                                            label {
                                                margin-top: 0;

                                                input {
                                                    &[type="checkbox"] {
                                                        appearance: inherit;
                                                        inset-inline-start: initial !important;

                                                        &::before {
                                                            -webkit-font-smoothing: antialiased;
                                                            display: inline-block;
                                                            font-style: normal;
                                                            font-variant: normal;
                                                            text-rendering: auto;
                                                            line-height: 1;
                                                            font-family: "Font Awesome 5 Pro";
                                                            font-weight: 400;
                                                            font-size: @defaultTextSize;
                                                            content: "\F0C8";
                                                            color: @accentColor50;
                                                            margin-top: 1px;
                                                        }

                                                        &+span {
                                                            font-size: @defaultTextSize;
                                                            font-weight: 600;
                                                            color: @accentColor50;
                                                            margin-inline-start: 3px;
                                                            position: relative;
                                                            top: 1px;
                                                            line-height: 14px;
                                                            padding-inline-start: 0;

                                                            &::before {
                                                                display: none;
                                                            }
                                                        }

                                                        &:checked {
                                                            &::before {
                                                                content: "\F14A";
                                                                font-weight: 900;
                                                                color: @accentColor;
                                                            }

                                                            &+span {
                                                                color: @accentColor;
                                                            }
                                                        }

                                                        &:focus {
                                                            outline: none;
                                                        }
                                                    }

                                                    &[disabled] {
                                                        background-color: transparent !important;
                                                    }
                                                }

                                                &:hover {
                                                    input {
                                                        &[type="radio"] {
                                                            appearance: inherit;

                                                            &::before {
                                                                color: @accentColor;
                                                            }

                                                            &+span {
                                                                color: @accentColor;
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }

                                &.top-label-space {
                                    &.formio-component-label-hidden {
                                        padding-top: 13px;
                                    }
                                }

                                &.remove-top-space {
                                    .form-radio {
                                        padding-top: 0;
                                    }
                                }
                            }

                            &.formio-component-editgrid {
                                min-width: 100%;

                                :where(label, label.col-form-label) {
                                    color: @darkTextColor70;
                                    font-size: 13px;
                                    font-weight: 600;
                                    margin-bottom: 0;
                                    line-height: 19px;

                                    :where(input, select) {
                                        border-radius: @roundedCorners !important;
                                        border-color: transparent;
                                        display: block;
                                        width: 100%;
                                        margin-top: 10px;
                                        padding: 2px 4px;

                                        & + .formio-button-reset {
                                        color: transparent;
                                        font-size: 12px;
                                        font-weight: 400;
                                        padding: 0;
                                        background: transparent;
                                        position: absolute;
                                        inset-inline-end: 0;
                                        bottom: 6px;
                                        height: auto;
                                        line-height: 1;

                                        &::before {
                                            font-family: 'Font Awesome 5 Pro';
                                            font-weight: 300;
                                            -moz-osx-font-smoothing: grayscale;
                                            -webkit-font-smoothing: antialiased;
                                            display: inline-block;
                                            font-style: normal;
                                            font-variant: normal;
                                            text-rendering: auto;
                                            line-height: 1;
                                            content: "\f057";
                                            color: @accentColor40;
                                        }
                                        }
                                    }

                                    &.radio {
                                        padding-inline-start: 0;
                                    }

                                    &.field-required {
                                        &::after {
                                            content: " ";
                                            display: inline-block;
                                            background-color: @errorColor70;
                                            vertical-align: unset;
                                            width: 6px;
                                            height: 6px;
                                            border-radius: 5px;
                                            position: relative;
                                            top: -1px;
                                            inset-inline-start: 2px;
                                            min-width: 6px;
                                        }

                                        i {
                                            margin-inline-start: 11px;

                                            &::before {
                                                margin-inline-start: 0.3rem;
                                            }
                                        }

                                        &.control-label--hidden {
                                            position: absolute;

                                            &::after {
                                                inset-inline-end: 10px;
                                                inset-inline-start: initial;
                                                position: absolute;
                                                top: 10px;
                                            }
                                        }
                                    }

                                    i {
                                        &.fa-question-circle {
                                            margin-top: -4px;
                                            margin-inline-end: -33px;
                                            transform: translateY(1px);
                                        }
                                    }
                                }

                                .list-group {
                                    border: none;
                                    border-radius: @roundedCorners;

                                    .list-group-item {
                                        &.list-group-header {
                                            border-bottom: none;
                                            background-color: @accentColor10;
                                            border-radius: @roundedCorners !important;

                                            .row {
                                                box-shadow: 230px 0 0 @accentColor10Solid, 230px 0 0 @accentColor10Solid;

                                                >div {
                                                    border-inline-end: none;
                                                    color: @accentColor;
                                                    font-weight: 600;
                                                    font-size: 13px;
                                                    padding-top: 10px;
                                                    padding-bottom: 6px;

                                                    &.radio {
                                                        label {
                                                            padding-inline-start: 0;
                                                            min-height: auto;
                                                        }
                                                    }

                                                    .formio-button-more {
                                                        // width: 100%;
                                                        background: transparent;
                                                        text-align: center;
                                                        padding: 4px 12px;
                                                        .fa {
                                                            margin: 0;
                                                        }
                                                    }
                                                    &:last-of-type[class="col-sm-2"] {
                                                        border-inline-end: none;
                                                        flex: 0;
                                                        min-width: 64px;
                                                        width: 64px;
                                                        padding: 0;
                                                        max-width: fit-content;
                                                        text-align: end;
                                                    }

                                                    &.checkbox {
                                                        label {
                                                           text-align: center !important;
                                                        }
                                                        &:is(.datagrid-checkbox-hide-label, .datagrid-hide-column-label) {
                                                            padding-inline: 7px;
                                                            text-align: center;
                                                        }
                                                        input {
                                                            &[type="checkbox"] {
                                                                width: auto;
                                                                margin-inline-start: calc(50% - 13px/2);
                                                                position: relative;
                                                                height: 27px;
                                                            }
                                                        }
                                                    }
                                                }
                                            }

                                            &+.list-group-footer {
                                                margin-top: 8px;
                                            }

                                            &~.list-group-item {
                                                >.row {
                                                    >div {
                                                        padding-top: 16px;
                                                        padding-bottom: 16px;

                                                        .btn-icon-toggle {
                                                            color: @accentColor40;

                                                            &:hover {
                                                                color: @accentColor;
                                                            }
                                                        }

                                                        &:last-child {
                                                            flex: 0;
                                                            min-width: 64px;
                                                        }
                                                    }
                                                }

                                                &.list-group-footer {
                                                    >.row {
                                                        >div {
                                                            color: @darkTextColor;
                                                            font-weight: 600;
                                                            padding-top: 6px;
                                                            padding-bottom: 6px;

                                                            &.alert {
                                                                flex: 1;
                                                                margin-bottom: 8px !important;
                                                                min-height: 20px;
                                                                display: flex;
                                                                align-items: center;

                                                                &::after {
                                                                    height: calc(100% - 4px) !important;
                                                                    top: 2px !important;
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }

                                            &+.list-group-item {
                                                border-top: none;
                                            }
                                        }

                                        >.row:not(.formio-component-columns)>div {
                                            max-width: initial;
                                            margin: 0;
                                        }

                                        &.list-group-footer {
                                            .alert {
                                                &::after {
                                                    display: none !important;
                                                }
                                            }

                                            >[class="row"] {
                                                >[class*="col-"] {
                                                    border-bottom: none;
                                                }
                                            }
                                        }

                                        >[class="row"] {
                                            display: grid;
                                            grid-auto-flow: column;
                                            grid-template-columns: repeat(auto-fit, ~"max(calc((100% - 64px) / (var(--eg-cols) - 1)), 200px)") !important;

                                            >[class*="col-"] {
                                                border-bottom: 1px solid #ebebeb;
                                                height: 100%;
                                            }
                                        }

                                        &:not(.list-group-header, .list-group-footer) {
                                            >[class="row"] {
                                                >[class*="col-"] {
                                                    min-height: 50px;

                                                    &.select {
                                                        span.key {
                                                            display: none;
                                                        }

                                                        span.value {
                                                            display: -webkit-box;
                                                            -webkit-line-clamp: 3;
                                                            -webkit-box-orient: vertical;
                                                            overflow: hidden;

                                                            &:hover {
                                                                -webkit-line-clamp: 100;
                                                                animation-name: fullClamp;
                                                                animation-delay: 0.75s;
                                                                animation-timing-function: ease-in-out;
                                                                animation-fill-mode: backwards;
                                                                animation-duration: 0.3s;
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }

                                    &.has-open-row {
                                        overflow: initial;

                                        .list-group-item {
                                            max-width: 100%;

                                            &:is(.list-group-header, .list-group-footer) {
                                                overflow: hidden;
                                                max-width: 100%;

                                                >.row {
                                                    >[class^="col-"] {
                                                        max-width: 100%;
                                                    }
                                                }
                                            }

                                            &:not(.list-group-footer) {
                                                &:not(.list-group-header) {
                                                    &:not(:last-child) {
                                                        &:not(:nth-last-child(2)) {
                                                            overflow: hidden;
                                                            max-width: 100%;
                                                            min-height: 50px;

                                                            >.row {
                                                                margin-inline-start: -12px;
                                                                margin-inline-end: -12px;

                                                                >[class^="col-"] {
                                                                    &:first-of-type {
                                                                        padding-inline-start: 12px;
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }

                                                    &:last-child {
                                                        >.row {
                                                            margin-inline-start: -12px;
                                                            margin-inline-end: -12px;

                                                            >[class^="col-"] {
                                                                &:first-of-type {
                                                                    padding-inline-start: 12px;
                                                                }
                                                            }
                                                        }
                                                    }

                                                    .row {
                                                        [class^="col-"] {
                                                            padding-block: 0;
                                                        }
                                                    }
                                                }
                                            }

                                            .formio-component-file {
                                                ul.list-group {
                                                    li.list-group-item {
                                                        >.row {
                                                            >.col-md-1 {
                                                                flex: 0;
                                                                min-width: 40px;
                                                            }

                                                            >.col-md-9 {
                                                                flex: 3;
                                                            }

                                                            >.col-md-2 {
                                                                flex: 1;
                                                                display: flex;
                                                                justify-content: flex-start;
                                                            }
                                                        }
                                                    }
                                                }
                                            }

                                            &:not(.list-group-header, .list-group-footer) {
                                                >[class="row"] {
                                                    overflow: hidden;

                                                    >[class*="col-"] {
                                                        display: flex;
                                                        align-items: center;
                                                        flex-wrap: wrap;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }

                                >button {
                                    &.btn {
                                        border: none;
                                        border-radius: @roundedCorners !important;
                                        color: @accentColor;
                                        background-color: @accentColor10;
                                        padding: 0.9rem 2.5rem;
                                        font-weight: 600;
                                        font-size: 12px;
                                        display: inline-flex;
                                        align-items: center;
                                        transition-duration: 0.3s;
                                        height: 40px;

                                        >i {
                                            display: none;
                                        }

                                        &:hover {
                                            color: @whiteColor;
                                            background-color: @accentColor;
                                            box-shadow: 0 9px 20px @accentColor40 !important;
                                        }
                                    }
                                }

                                &.search-result-list {
                                    .editgrid-listgroup {
                                        .list-group-item {
                                            .row {
                                                .col-sm-2:last-child {
                                                    .btn-group {
                                                        display: none;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }

                                &.disabled-eg {
                                    ul.list-group {
                                        li.list-group-item {
                                            > [class="row"] {
                                                grid-template-columns: repeat(auto-fit, ~"max(calc(100% / var(--eg-cols)))") !important;
                                                display: grid;

                                                > div:last-of-type {
                                                    max-width: initial;
                                                    width: initial;
                                                    text-align: start;
                                                }
                                            }
                                            &.list-group-header {
                                                > [class="row"] {
                                                    > div:last-of-type {
                                                        padding-inline: 12px !important;
                                                        padding-block: 10px !important;
                                                    }
                                                }
                                            }
                                            &:not(.list-group-header, .list-group-footer) {
                                                > [class="row"] {
                                                    > div:last-of-type {
                                                        padding-inline: 12px !important;
                                                        padding-block: 16px !important;
                                                        position: initial;
                                                        display: initial;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }

                            &.formio-component-datagrid {
                                :where(label, label.col-form-label) {
                                    color: @darkTextColor70;
                                    font-size: 13px;
                                    font-weight: 600;
                                    margin-bottom: 0;
                                    line-height: 19px;

                                    :where(input, select) {
                                        border-radius: @roundedCorners !important;
                                        border-color: transparent;
                                        display: block;
                                        width: 100%;
                                        margin-top: 10px;
                                        padding: 2px 4px;

                                        & + .formio-button-reset {
                                        color: transparent;
                                        font-size: 12px;
                                        font-weight: 400;
                                        padding: 0;
                                        background: transparent;
                                        position: absolute;
                                        inset-inline-end: 0;
                                        bottom: 6px;
                                        height: auto;
                                        line-height: 1;

                                        &::before {
                                            font-family: 'Font Awesome 5 Pro';
                                            font-weight: 300;
                                            -moz-osx-font-smoothing: grayscale;
                                            -webkit-font-smoothing: antialiased;
                                            display: inline-block;
                                            font-style: normal;
                                            font-variant: normal;
                                            text-rendering: auto;
                                            line-height: 1;
                                            content: "\f057";
                                            color: @accentColor40;
                                        }
                                        }
                                    }

                                    &.radio {
                                        padding-inline-start: 0;
                                    }

                                    &.field-required {
                                        &::after {
                                            content: " ";
                                            display: inline-block;
                                            background-color: @errorColor70;
                                            vertical-align: unset;
                                            width: 6px;
                                            height: 6px;
                                            border-radius: 5px;
                                            position: relative;
                                            top: -1px;
                                            inset-inline-start: 2px;
                                            min-width: 6px;
                                        }

                                        i {
                                            margin-inline-start: 11px;

                                            &::before {
                                                margin-inline-start: 0.3rem;
                                            }
                                        }

                                        &.control-label--hidden {
                                            position: absolute;

                                            &::after {
                                                inset-inline-end: 10px;
                                                inset-inline-start: initial;
                                                position: absolute;
                                                top: 10px;
                                            }
                                        }
                                    }

                                    i {
                                        &.fa-question-circle {
                                            margin-top: -4px;
                                            margin-inline-end: -33px;
                                            transform: translateY(1px);
                                        }
                                    }
                                }
                                >table {
                                    &.table {
                                        &.datagrid-table {
                                            border: none;
                                            width: 100%;

                                            thead {
                                                opacity: 1;

                                                tr {
                                                    th {
                                                        border: none;
                                                        background-color: @accentColor10;
                                                        color: @accentColor;
                                                        font-weight: 600;
                                                        font-size: 12px;
                                                        letter-spacing: initial;
                                                        text-transform: none;
                                                        padding-block: 1rem;
                                                        padding-inline-start: 14px;
                                                        padding-inline-end: 0;
                                                        vertical-align: bottom;
                                                        display: table-cell;
                                                        min-width: calc(100% / var(--eg-cols));

                                                        &:first-child {
                                                            border-radius: @roundedCorners 0 0 @roundedCorners;
                                                            padding-block: 1rem;
                                                            padding-inline-start: 14px;
                                                            padding-inline-end: 0;

                                                            &:empty {
                                                                padding: 0 !important;
                                                                width: 32px;
                                                            }
                                                        }

                                                        &:last-child {
                                                            border-radius: 0 @roundedCorners @roundedCorners 0;
                                                            padding-inline-end: 10px;
                                                        }

                                                        i {
                                                            &.fa-question-circle {
                                                                &::before {
                                                                    font-size: 15px;
                                                                }
                                                            }
                                                        }

                                                        &:not([class]) {
                                                            width: 50px;
                                                        }

                                                        &.dg-action-col {
                                                            width: 50px;
                                                            padding: 0;
                                                            vertical-align: top;
                                                            text-align: end;

                                                            .formio-button-more {
                                                                // width: 100%;
                                                                padding: 4px 12px;
                                                                background: transparent;
                                                            }
                                                        }

                                                        &.select {
                                                            min-width: 100px;
                                                        }

                                                        &.checkbox {
                                                            label {
                                                               text-align: center !important;
                                                            }
                                                            &:is(.datagrid-checkbox-hide-label, .datagrid-hide-column-label) {
                                                                padding-inline: 7px;
                                                                text-align: center;
                                                            }
                                                            input {
                                                                &[type="checkbox"] {
                                                                    width: auto;
                                                                    margin-inline-start: calc(50% - 13px/2);
                                                                    position: relative;
                                                                    height: 27px;
                                                                }
                                                            }
                                                        }

                                                        &.radio {
                                                            label {
                                                            padding-left: 0;
                                                            min-height: auto;
                                                            }
                                                        }

                                                        &:is(.datagrid-hide-column-label, .button) {
                                                            text-indent: -100vw;
                                                            overflow: hidden;
                                                        }

                                                        &.field-required {
                                                            &::after {
                                                                display: none;
                                                            }
                                                        }
                                                    }
                                                }
                                            }

                                            tbody {
                                                tr {
                                                    background-color: transparent;

                                                    td {
                                                        background-color: transparent;
                                                        border: none;
                                                        padding: 0.75rem 0 0.75rem 14px;
                                                        vertical-align: top;

                                                        >.formio-component {
                                                            &:first-child {
                                                                margin-top: 0;
                                                            }
                                                        }

                                                        .formio-component {
                                                            &.value-label-style {
                                                                .input-group {
                                                                    height: auto;
                                                                    padding-inline-start: 14px;

                                                                    :where(.input-group-append, .input-group-prepend) {
                                                                        border: none;
                                                                        height: 15px;
                                                                        line-height: 15px;

                                                                        .input-group-text {
                                                                            padding: 0;
                                                                        }
                                                                    }

                                                                    .input-group-prepend {
                                                                        .input-group-text {
                                                                            padding-inline-end: 4px;
                                                                        }
                                                                    }

                                                                    input {
                                                                        &.form-control {
                                                                            padding-inline-start: 0;
                                                                        }
                                                                    }
                                                                }

                                                                input {
                                                                    &.form-control {
                                                                        height: 15px;
                                                                        border: none;
                                                                        border-radius: 0;
                                                                        padding-inline-start: 14px;

                                                                        &:disabled {
                                                                            background-color: transparent !important;
                                                                        }
                                                                    }
                                                                }

                                                                &.formio-component-datetime {
                                                                    .input-group {
                                                                        .input-group-append {
                                                                            display: none;
                                                                        }
                                                                    }
                                                                }
                                                            }

                                                            &-checkbox {
                                                                &:is(.datagrid-checkbox-hide-label, .datagrid-hide-column-label) {
                                                                    .form-check-label {
                                                                        span {
                                                                            color: transparent;
                                                                            padding: 0;
                                                                            white-space: nowrap;
                                                                            overflow: hidden;
                                                                            width: 100%;

                                                                            &::before {
                                                                                inset-inline-start: calc(50% - 7px);
                                                                            }
                                                                        }

                                                                        input.form-check-input:checked+span {
                                                                            color: transparent !important;
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }

                                                        &:not([ref]) {
                                                            width: 50px;
                                                            text-align: center;
                                                            :where(.formio-button-edit-row) {
                                                                background-color: transparent;
                                                                padding: 0 5px;
                                                                margin-inline-start: auto;
                                                                display: flex;
                                                                margin-inline-end: 14px;
                                                                height: 40px;
                                                                align-items: center;
                                                                border: none;

                                                                i {
                                                                    color: @accentColor40;
                                                                    font-size: 16px;
                                                                    font-weight: 400;
                                                                }
                                                                &:hover {
                                                                    i {
                                                                    color: @accentColor;
                                                                    }
                                                                }
                                                            }
                                                            :where(.formio-button-remove-row, .formio-drag-button) {
                                                                background-color: transparent;
                                                                padding: 0 5px;
                                                                margin-inline-start: auto;
                                                                display: flex;
                                                                margin-inline-end: auto;
                                                                height: 40px;
                                                                align-items: center;
                                                                border: none;

                                                                i {
                                                                    color: @errorColor30;
                                                                    font-size: 16px;
                                                                    font-family: 'Font Awesome 5 Pro';
                                                                    font-weight: 400;

                                                                    &::before {
                                                                        content: "\F00D";
                                                                    }
                                                                }

                                                                &:hover {
                                                                    i {
                                                                        color: @errorNotice;
                                                                    }
                                                                }
                                                            }
                                                        }

                                                        &:first-child {
                                                            padding-inline-start: 0;
                                                        }

                                                        .formio-component-textarea {
                                                            .card.card-body {
                                                                margin-bottom: 0 !important;
                                                                box-shadow: none;
                                                                border: solid 1px rgba(110, 114, 150, 0.15);
                                                                border-radius: 6px !important;
                                                                font-weight: 600;
                                                                min-height: 40px;
                                                                letter-spacing: 0.4pt;
                                                                text-align: start;
                                                                background: #efefef !important;
                                                                padding: 8.5px 14px;
                                                                line-height: 1.5;
                                                                font-size: 14px;
                                                            }
                                                        }

                                                        &.checkbox {
                                                            // padding-inline-start: 28px !important;

                                                            &:is(.datagrid-checkbox-hide-label, .datagrid-hide-column-label) {
                                                                padding-inline: 7px !important;
                                                                text-align: center;
                                                            }
                                                        }

                                                        &.datagrid-hide-column-label {
                                                            text-indent: -100vw;
                                                            overflow: hidden;
                                                        }
                                                        
                                                        &.select {
                                                            min-width: 100px;
                                                        }

                                                        &.vertical-align-center {
                                                            vertical-align: middle;
                                                        }
                                                    }

                                                    &:first-child {
                                                        td {
                                                            padding-top: 1.5rem;
                                                        }
                                                    }
                                                }
                                            }

                                            tfoot {
                                                tr {
                                                    td {
                                                        padding: 0.75rem 0 0;
                                                        border: none;

                                                        button {
                                                            &.btn {
                                                                &.formio-button-add-row {
                                                                    border: none;
                                                                    border-radius: @roundedCorners !important;
                                                                    color: @accentColor;
                                                                    background-color: @accentColor10;
                                                                    padding: 0.9rem 14px;
                                                                    height: 40px;
                                                                    font-weight: 600;
                                                                    font-size: 12px;
                                                                    display: inline-flex;
                                                                    align-items: center;
                                                                    transition-duration: 0.3s;

                                                                    >i {
                                                                        display: none;
                                                                    }

                                                                    &:hover {
                                                                        color: @whiteColor;
                                                                        background-color: @accentColor;
                                                                        box-shadow: 0 9px 20px @accentColor40 !important;
                                                                        z-index: 999;
                                                                        position: relative;
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }

                                            &.table-striped {
                                                tbody {
                                                    tr {
                                                        &:nth-of-type(odd) {
                                                            td {
                                                                background-color: @accentColor5;
                                                            }
                                                        }
                                                    }
                                                }
                                            }

                                            &.table-hover {
                                                tbody {
                                                    tr {
                                                        &:hover {
                                                            td {
                                                                background-color: @accentColor5;
                                                            }
                                                        }
                                                    }
                                                }
                                            }

                                            &.table-sm {
                                                thead {
                                                    tr {
                                                        th {
                                                            padding-inline-start: 10px;
                                                        }
                                                    }
                                                }

                                                tbody {
                                                    tr {
                                                        td {
                                                            padding: 1px 0;
                                                            text-align: start;
                                                            vertical-align: middle;

                                                            button:not(.choices__button) {
                                                                width: 100%;
                                                                max-width: 100%;
                                                                white-space: normal;

                                                                &:hover {
                                                                    box-shadow: none !important;
                                                                }
                                                            }

                                                            .formio-component {
                                                                &.value-label-style {
                                                                    .input-group {
                                                                        :where(.input-group-append, .input-group-prepend) {
                                                                            margin-top: 16px;
                                                                        }
                                                                    }

                                                                    input {
                                                                        &.form-control {
                                                                            margin-top: 16px;
                                                                        }
                                                                    }
                                                                }
                                                                .form-control {
                                                                    padding-inline: 10px;
                                                                    font-size: 14px;
                                                                }
                                                                &.formio-component-checkbox .checkbox {
                                                                    padding-top: 7px;
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }

                                            &[style*="table-layout: fixed;"] {
                                                thead {
                                                    tr {
                                                        th {
                                                            &:not([class*="width"], .dg-action-col) {
                                                                width: calc(100% / var(--eg-cols));
                                                            }
                                                            &.dg-action-col {
                                                                width: 32px;
                                                                padding: 0;
                                                            }
                                                        }
                                                    }
                                                }
                                            }

                                            :is(th, td):is(.checkbox, .radio, .button) {
                                                display: table-cell;
                                                box-shadow: none !important;
                                            }
                                        }
                                    }
                                }

                                &.has-footer {
                                    table {
                                        &.table {
                                            &.table-sm {
                                                tfoot {
                                                    tr {
                                                        td {
                                                            button {
                                                                &.btn {
                                                                    &.formio-button-add-row {
                                                                        margin-bottom: -115px;
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }

                                    tfoot {
                                        tr {
                                            td {
                                                button {
                                                    &.btn {
                                                        &.formio-button-add-row {
                                                            margin-bottom: -125px;
                                                        }
                                                    }
                                                }

                                                &[colspan] {
                                                    display: block;
                                                }
                                            }
                                        }
                                    }
                                }

                                .generate-columns(100);
                                .generate-columns(@n, @i: 5) when (@i =< @n) {
                                    :is(th, td).width@{i} {
                                        width: (@i * 100% / @n);
                                        min-width: (@i * 100% / @n);
                                        max-width: (@i * 100% / @n);
                                    }
                                    .generate-columns(@n, (@i + 5));
                                }
                            }

                            &.formio-component-tabs {
                                >.card {
                                    background-color: transparent;
                                    box-shadow: none;
                                    border-radius: @roundedCorners !important;
                                    padding: 0 !important;

                                    >.card-header {
                                        height: auto;
                                        margin-bottom: -1px;
                                        z-index: 99;
                                        position: relative;

                                        ul {
                                            &.card-header-tabs {
                                                background-color: transparent;
                                                height: auto;
                                                max-width: 100%;
                                                min-width: 100px;
                                                overflow-y: auto;

                                                li {
                                                    &.nav-item {
                                                        flex: 0;
                                                        height: auto;
                                                        margin-bottom: 0;

                                                        &::before {
                                                            display: none;
                                                        }

                                                        a {
                                                            &.nav-link {
                                                                font-size: 21px;
                                                                color: @inactiveTabTextColor;
                                                                font-weight: 600;
                                                                padding-inline-start: 23px;
                                                                padding-inline-end: 23px;
                                                                border-top: none;
                                                                border-top-left-radius: @roundedCorners;
                                                                border-top-right-radius: @roundedCorners;
                                                                line-height: 1;
                                                                padding-top: 1.4rem;
                                                                padding-bottom: 1.4rem;
                                                                text-decoration: none;
                                                                white-space: nowrap;
                                                                text-transform: none;

                                                                &.active {
                                                                    font-size: @guidePanelHeadingFontSize;
                                                                    font-weight: 600;
                                                                    color: @partbTabActiveTextColor;
                                                                    background-color: @partbTabsBg;
                                                                    border-radius: @roundedCorners @roundedCorners 0 0;
                                                                    box-shadow: none !important;
                                                                    border: none;
                                                                }
                                                            }
                                                        }

                                                        &.active {
                                                            box-shadow: none;
                                                        }
                                                    }
                                                }

                                                &::-webkit-scrollbar {
                                                    width: 6px;
                                                    height: 6px;
                                                }

                                                &::-webkit-scrollbar-thumb {
                                                    border-radius: 4px;
                                                    background-color: @accentColor40;
                                                }

                                                &::-webkit-scrollbar-track {
                                                    background: @black5Color;
                                                }

                                                &:hover {
                                                    &::-webkit-scrollbar-thumb {
                                                        background-color: @accentColor70;
                                                    }
                                                }
                                            }
                                        }

                                        &+.card-body {
                                            &.tab-pane {
                                                &.active {
                                                    border-top-left-radius: 0;
                                                }
                                            }
                                        }
                                    }

                                    >.card-body {
                                        &.tab-pane {
                                            background-color: @partbTabsBg;
                                            box-shadow: none;
                                            border-radius: @roundedCorners;
                                            padding: 23px !important;
                                            margin-top: 0;
                                            flex-direction: column;
                                            gap: 3rem;

                                            >.formio-component {
                                                margin-top: 0 !important;

                                                &.formio-component-panel {
                                                    margin-top: 4rem;
                                                    margin-bottom: 0;

                                                    &:first-child {
                                                        margin-top: 0;
                                                    }

                                                    >.card {
                                                        box-shadow: none;
                                                    }
                                                }
                                            }

                                            .formio-component-tabs {
                                                >.card {
                                                    box-shadow: none;
                                                }
                                            }

                                            .formio-component-panel {
                                                >.card {
                                                    box-shadow: none;

                                                    .card-body > .formio-component:first-child {
                                                        margin-top: 3rem !important;
                                                    }
                                                }
                                            }

                                            > .formio-component-panel,
                                            > .formio-component-columns > .formio-component-panel {
                                                > .card {
                                                    background: @whiteColor;
                                                    padding: 2rem;
                                                    border-radius: @roundedCorners;
                                                }
                                            }

                                            &.active {
                                                display: flex !important;
                                                border: none;
                                                box-shadow: none;
                                            }

                                            &::before,
                                            &::after {
                                                display: none;
                                            }
                                        }
                                    }
                                }
                            }

                            &.formio-component-fieldset {
                                fieldset {
                                    padding: 54px 20px 20px;
                                    position: relative;
                                    border-radius: @roundedCorners;
                                    border: solid 4px @accentColor10;
                                    background-color: @whiteColor;

                                    legend {
                                        margin: 0;
                                        text-transform: initial;
                                        font-size: 18px;
                                        font-weight: 600;
                                        color: @darkTextColor;
                                        background: transparent;
                                        padding: 0;
                                        position: absolute;
                                        top: 12px;
                                    }

                                    @media screen and (max-width: 580px) {
                                        padding: 54px 20px 20px;
                                    }

                                    >.fieldset-body {
                                        &:first-child {
                                            margin-top: -35px;
                                        }

                                        >.formio-component {
                                            &.top-label-space {
                                                &.required {
                                                    label {
                                                        &.field-required {
                                                            &.control-label--hidden {
                                                                top: 27px !important;
                                                            }
                                                        }
                                                    }
                                                }
                                            }

                                            &:first-child {
                                                margin-top: 0 !important;
                                            }
                                        }
                                    }
                                }
                            }

                            &.formio-component-file {
                                border: dashed 2px rgba(27, 37, 60, 0.1);
                                border-radius: @roundedCorners;
                                padding: @panelBodyPadding;
                                position: relative;

                                &:not(:first-of-type) {
                                    margin-top: 3rem;
                                }

                                label {
                                    &.col-form-label {
                                        font-size: 16px;
                                        color: @darkTextColor;
                                        margin-bottom: 10px;

                                        &+div {
                                            margin-bottom: 20px;
                                            position: relative;
                                            z-index: 9;

                                            div:last-child {
                                                margin-bottom: 36px;
                                            }

                                            span {
                                                i {
                                                    cursor: pointer;
                                                    color: rgba(255, 0, 59, 1);
                                                }
                                            }
                                        }

                                        .fa-question-circle {
                                            position: relative;
                                            z-index: 9;
                                        }
                                    }
                                }

                                ul {
                                    &.list-group {
                                        border: none;
                                        width: 100%;
                                        position: relative;
                                        z-index: 9;

                                        &:nth-last-child(2) {
                                            margin-bottom: -10px;
                                        }

                                        &:nth-last-child(n+4) {
                                            &+.fileSelector {
                                                i.fa-cloud-upload {
                                                    top: initial;
                                                }
                                            }
                                        }

                                        li {
                                            &.list-group-item {
                                                border: none;
                                                box-shadow: inset 0 -2px 0 #E2E7E7;
                                                padding: 0;
                                                background-color: transparent;
                                                border-radius: 0;

                                                .row {
                                                    border: none;
                                                    display: grid;
                                                    grid-template-columns: auto auto 1fr;
                                                    font-size: 12px;

                                                    &::before,
                                                    &::after {
                                                        display: none;
                                                    }

                                                    >div {
                                                        padding: 10px 12px;
                                                        text-transform: none;
                                                        color: @accentColor;

                                                        &.col-md-1 {
                                                            order: 3;
                                                            width: auto;
                                                            justify-content: flex-end;
                                                            color: rgba(255, 0, 59, 1);
                                                        }

                                                        &:where(.col-md-9, .col-md-2) {
                                                            white-space: nowrap;
                                                            width: auto !important;

                                                            a[href] {
                                                                display: block;
                                                            }
                                                        }

                                                        &.col-md-9 {
                                                            a {
                                                                white-space: normal;
                                                                line-break: anywhere;
                                                            }
                                                        }

                                                        &:first-child {
                                                            display: flex;
                                                            align-items: center;

                                                            i {
                                                                &.fa-remove {
                                                                    font-family: 'Font Awesome 5 Pro';
                                                                    font-weight: 300;
                                                                    cursor: pointer;
                                                                    margin: 0;
                                                                    font-size: 16px;
                                                                }
                                                            }

                                                            &+.col-md-9 {
                                                                width: 60%;
                                                            }
                                                        }
                                                    }

                                                    &:hover {
                                                        background: @black5Color;
                                                        border-radius: @roundedCorners;
                                                    }
                                                }

                                                &.list-group-header {
                                                    background: @accentColor5;
                                                    border: none;
                                                    border-radius: @roundedCorners;
                                                    padding: 0;
                                                    display: none;

                                                    .row {
                                                        >div {
                                                            font-size: 14px;
                                                            font-weight: 600;
                                                            line-height: 1;

                                                            strong {
                                                                font-weight: 600;
                                                            }
                                                        }
                                                    }
                                                }

                                                &:hover {
                                                    box-shadow: none;
                                                }

                                                &:last-child {
                                                    box-shadow: none;
                                                }
                                            }
                                        }

                                        tbody {
                                            tr {
                                                background-color: transparent;

                                                td {
                                                    background-color: transparent;
                                                    border: none;
                                                    padding: 1.5rem 12px;

                                                    &:first-child {
                                                        border-radius: @roundedCorners 0 0 @roundedCorners;
                                                    }

                                                    &:last-child {
                                                        border-radius: 0 @roundedCorners @roundedCorners 0;
                                                    }

                                                    a {
                                                        &.btn {
                                                            background-color: transparent;
                                                            border: none;

                                                            span {
                                                                &.glyphicon-remove {
                                                                    font-family: "Font Awesome 5 Pro";
                                                                    font-weight: 300;
                                                                    -moz-osx-font-smoothing: grayscale;
                                                                    -webkit-font-smoothing: antialiased;
                                                                    display: inline-block;
                                                                    font-style: normal;
                                                                    font-variant: normal;
                                                                    text-rendering: auto;
                                                                    line-height: 1;
                                                                    color: @errorColor50;
                                                                    font-size: 16px;

                                                                    &::before {
                                                                        content: "\f00d";
                                                                    }

                                                                    &:hover {
                                                                        color: @errorColor;
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                }

                                                &:hover {
                                                    td {
                                                        background-color: @pageBgColor;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }

                                .fileSelector {
                                    margin-bottom: 0;
                                    width: 100%;
                                    border: none;
                                    border-radius: @roundedCorners;
                                    position: absolute;
                                    top: 0;
                                    inset-inline-start: 0;
                                    align-items: flex-end;
                                    height: 100%;
                                    padding: 23px;
                                    gap: 6px;
                                    font-size: 12px;
                                    transition-duration: 0.2s;
                                    line-height: 1;

                                    &:not([hidden]) {
                                        display: flex;
                                    }

                                    i {
                                        &.fa-cloud-upload {
                                            display: block;
                                            position: absolute;
                                            inset-inline-end: 23px;
                                            font-size: 16px;
                                            margin: 0;
                                            top: calc(50% - 8px);

                                            &::before {
                                                font-family: "Font Awesome 5 Pro";
                                                font-weight: 300;
                                                -moz-osx-font-smoothing: grayscale;
                                                -webkit-font-smoothing: antialiased;
                                                display: inline-block;
                                                font-style: normal;
                                                font-variant: normal;
                                                text-rendering: auto;
                                                line-height: 1;
                                                content: "\f56f";
                                                color: @darkTextColor;
                                                font-size: 16px;
                                            }

                                            &~* {
                                                color: @darkTextColor;
                                                font-size: 12px;
                                            }
                                        }
                                    }

                                    &.fileDragOver {
                                        box-shadow: 0 0 0 2px @accentColor50;
                                        background: @accentColor5;
                                        z-index: 99;
                                    }

                                    &~.file {
                                        display: flex;

                                        >.row {
                                            &:first-child {
                                                min-width: 100px;

                                                .fileName {
                                                    flex: auto;
                                                    white-space: nowrap;
                                                    overflow: hidden;
                                                    text-overflow: ellipsis;
                                                    position: relative;

                                                    i {
                                                        position: absolute;
                                                        inset-inline-end: 0;
                                                    }
                                                }

                                                .fileSize {
                                                    flex: 0;
                                                    white-space: nowrap;
                                                    margin-inline-end: 10px;
                                                }

                                                &+.row {
                                                    .progress {
                                                        margin-inline-end: 36px;
                                                        height: 4px;
                                                        margin-top: 4px;
                                                        margin-bottom: 0;
                                                        background-color: rgba(27, 37, 60, 0.1);
                                                        box-shadow: none;

                                                        .progress-bar {
                                                            background-color: rgba(41, 188, 143, 1);
                                                            border-radius: 5px;
                                                        }
                                                    }
                                                }
                                            }
                                        }

                                        &:nth-last-child(2) {
                                            margin-bottom: 40px;
                                        }
                                    }
                                }

                                &.formio-component-multiple {
                                    .fileSelector {
                                        i {
                                            &.fa-cloud-upload {
                                                top: initial;
                                            }
                                        }
                                    }
                                }

                                &.has-files {
                                    background-color: rgba(41, 188, 143, 0.03);

                                    &:not(.formio-component-multiple) {
                                        ul {
                                            &.list-group {
                                                margin-bottom: 36px;
                                            }
                                        }
                                    }
                                }

                                &.has-message {
                                    .fileSelector {
                                        padding-bottom: 44px;
                                    }

                                    .formio-errors {
                                        margin-top: 34px;
                                    }
                                }
                            }

                            &.formio-component-checkbox {
                                .checkbox {
                                    margin-top: 0;
                                    padding-top: 13px;

                                    label {
                                        margin-top: 1px;
                                        padding-inline-start: 0;

                                        span {
                                            color: @accentColor50;
                                            font-size: @defaultTextSize;
                                            font-weight: 600;
                                            padding-inline-start: 24px;
                                            line-height: 19px;
                                            margin-top: -2px;

                                            &::before {
                                                color: @accentColor50;
                                                font-size: @defaultTextSize;
                                                font-weight: 400;
                                                top: 2px;
                                            }
                                        }

                                        input {
                                            &[type="radio"] {
                                                appearance: inherit;

                                                &::before {
                                                    -webkit-font-smoothing: antialiased;
                                                    display: inline-block;
                                                    font-style: normal;
                                                    font-variant: normal;
                                                    text-rendering: auto;
                                                    line-height: 1;
                                                    font-family: "Font Awesome 5 Pro";
                                                    font-weight: 400;
                                                    font-size: @defaultTextSize;
                                                    content: "\f111";
                                                    color: @accentColor50;
                                                }

                                                &+span {
                                                    font-size: @defaultTextSize;
                                                    font-weight: 600;
                                                    color: @accentColor50;
                                                    margin-inline-start: 3px;
                                                    position: relative;
                                                    line-height: 14px;
                                                    padding-inline-start: 0;
                                                }

                                                &:checked {
                                                    &::before {
                                                        content: "\f192";
                                                        font-weight: 900;
                                                        color: @accentColor;
                                                    }

                                                    &+span {
                                                        color: @accentColor;
                                                    }
                                                }

                                                &:focus {
                                                    outline: none;
                                                }
                                            }

                                            &:checked {
                                                &+span {
                                                    color: @accentColor;

                                                    &::before {
                                                        color: @accentColor;
                                                        font-weight: 600;
                                                    }
                                                }
                                            }

                                            &[type="checkbox"] {
                                                transform: scale(0);
                                            }
                                        }

                                        &:hover {
                                            span {
                                                color: @accentColor;

                                                &::before {
                                                    color: @accentColor;
                                                }
                                            }

                                            input {
                                                &[type="radio"] {
                                                    appearance: inherit;

                                                    &::before {
                                                        color: @accentColor;
                                                    }

                                                    &+span {
                                                        color: @accentColor;
                                                    }
                                                }
                                            }
                                        }

                                        &.field-required {
                                            &::after {
                                                content: " ";
                                                display: inline-block;
                                                background-color: @errorColor70;
                                                vertical-align: unset;
                                                width: 6px;
                                                height: 6px;
                                                border-radius: 5px;
                                                position: relative;
                                                top: -1px;
                                                inset-inline-start: 2px;
                                                min-width: 6px;
                                            }
                                        }
                                    }
                                }

                                &.remove-top-space {
                                    .checkbox {
                                        padding-top: 0;
                                    }
                                }

                                &.required {
                                    label.field-required {
                                        &::after {
                                            display: none;
                                        }

                                        span {
                                            &::after {
                                                content: " ";
                                                display: inline-block;
                                                background-color: @errorColor70;
                                                vertical-align: unset;
                                                width: 6px;
                                                height: 6px;
                                                border-radius: 5px;
                                                position: relative;
                                                top: -1px;
                                                inset-inline-start: 2px;
                                                min-width: 6px;
                                            }
                                        }
                                    }
                                }

                                &.formio-component-label-hidden {
                                    .checkbox label {
                                        margin-top: 0;

                                        input.form-check-input {
                                            transform: scale(1);
                                            appearance: none;
                                            position: initial;
                                            margin-inline-start: 0;
                                            margin: 0;

                                            &::after {
                                                -webkit-font-smoothing: antialiased;
                                                display: inline-block;
                                                font-style: normal;
                                                font-variant: normal;
                                                text-rendering: auto;
                                                line-height: 1;
                                                font-family: "Font Awesome 5 Pro";
                                                font-weight: 400;
                                                font-size: 16px;
                                                content: "\F0C8";
                                                color: rgba(70, 81, 170, 0.5);
                                            }

                                            &:focus {
                                                outline: none;
                                            }

                                            &:checked {
                                                &::after {
                                                    content: "\F14A";
                                                    font-weight: 900;
                                                    color: @accentColor;
                                                }
                                            }
                                        }
                                    }
                                }
                            }

                            &.formio-component-table {
                                margin-inline-start: -12px;
                                margin-inline-end: -12px;

                                table {
                                    &.table {
                                        >tbody {
                                            >tr {
                                                >td {
                                                    input {
                                                        &:where([type="text"], [type="email"], [type="phoneNumber"]) {
                                                            /*border-radius: @roundedCorners !important;*/
                                                            height: @fieldHeight !important;
                                                        }
                                                    }

                                                    .form-group {
                                                        &.form-field-type-radio {
                                                            &.radio-switch {
                                                                .input-group {
                                                                    ng-form {
                                                                        .radio-inline {
                                                                            margin-top: 0;
                                                                        }
                                                                    }
                                                                }

                                                                label {
                                                                    &.control-label {
                                                                        &+.input-group {
                                                                            .radio-inline {
                                                                                margin-top: 0;
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                            }

                                                            label {
                                                                &.control-label {
                                                                    &+.input-group {
                                                                        .radio-inline {
                                                                            margin-top: 0;
                                                                        }

                                                                        .radio {
                                                                            &:first-child {
                                                                                margin-top: 0;
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                            }

                                                            .input-group {
                                                                &:first-child {
                                                                    .radio-inline {
                                                                        margin-top: 0;
                                                                    }
                                                                }
                                                            }

                                                            &.remove-space-top {
                                                                .input-group {
                                                                    .radio-inline {
                                                                        margin-top: 12px;
                                                                    }

                                                                    .radio {
                                                                        margin-top: 12px;

                                                                        &:first-child {
                                                                            margin-top: 13px;
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }

                                                        &:where(.form-field-type-textfield, .form-field-type-number, .form-field-type-email, .form-field-type-phoneNumber) {
                                                            &.field-plus-button {
                                                                input {
                                                                    &.form-control {
                                                                        border-top-right-radius: 0 !important;
                                                                        border-bottom-right-radius: 0 !important;
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }

                                                    fieldset {
                                                        legend {
                                                            line-height: 34px;
                                                            display: block;
                                                        }
                                                    }
                                                }

                                                &.row {
                                                    display: table-row;

                                                    &::after,
                                                    &::before {
                                                        display: none;
                                                    }

                                                    td {
                                                        display: table-cell;

                                                        &:first-child {
                                                            padding-inline-start: 0;
                                                        }

                                                        &:last-child {
                                                            padding-inline-end: 0;
                                                        }
                                                    }
                                                }

                                                &:first-child {
                                                    >td {
                                                        >.form-group {
                                                            &:first-child {
                                                                &:not(.ng-hide) {
                                                                    margin-top: 0 !important;

                                                                    &.top-label-space {
                                                                        padding-top: 28px !important;
                                                                    }
                                                                }

                                                                &.form-field-type-radio {
                                                                    &.top-label-space {
                                                                        margin-top: 0 !important;
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }

                                                    &~tr {
                                                        >td {
                                                            >.form-group {
                                                                &:first-child {
                                                                    margin-top: 3rem !important;

                                                                    &.top-label-space {
                                                                        margin-top: 3rem !important;
                                                                        padding-top: 28px !important;
                                                                    }
                                                                }

                                                                &.formio-hidden {
                                                                    &+.formio-component:not(.formio-hidden) {
                                                                        margin-top: 3rem !important;
                                                                    }
                                                                }

                                                                &.form-field-type-checkbox {
                                                                    .checkbox {
                                                                        margin-top: 0;
                                                                    }

                                                                    &.top-label-space {
                                                                        .checkbox {
                                                                            margin-top: 0;
                                                                        }
                                                                    }
                                                                }

                                                                &.form-field-type-radio {
                                                                    .input-group {
                                                                        &:first-child {
                                                                            margin-top: 0;
                                                                        }
                                                                    }

                                                                    &.top-label-space {
                                                                        margin-top: 3rem;

                                                                        .input-group {
                                                                            &:first-child {
                                                                                padding-top: 34px;
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                }

                                                &:last-child {
                                                    >td {
                                                        .formio-component {
                                                            &.formio-component-panel {
                                                                &:last-child {
                                                                    margin-bottom: 0;
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }

                                &.table-responsive {
                                    @media screen and (max-width: 768px) {
                                        border: none;
                                    }

                                    @media screen and (max-width: 580px) {
                                        table {
                                            &.table {
                                                tbody {
                                                    tr {
                                                        td {
                                                            display: table;
                                                            width: 100%;

                                                            &+td {
                                                                margin-top: 3rem;

                                                                >.formio-component {
                                                                    &:first-child {
                                                                        margin-top: 0;
                                                                    }
                                                                }
                                                            }

                                                            .formio-component-textfield {
                                                                &.field-plus-button {
                                                                    input {
                                                                        &.form-control {
                                                                            border-radius: @roundedCorners !important;
                                                                        }
                                                                    }
                                                                }
                                                            }

                                                            .formio-component-button {
                                                                &.field-plus-button {
                                                                    button {
                                                                        &.btn {
                                                                            border-radius: @roundedCorners !important;
                                                                            margin-inline-start: 0;
                                                                        }
                                                                    }
                                                                }

                                                                &.top-label-space,
                                                                &:first-child:not(.ng-hide).top-label-space {
                                                                    padding-top: 0 !important;
                                                                }
                                                            }

                                                            &:empty {
                                                                display: none;
                                                            }
                                                        }

                                                        &+tr {
                                                            td {
                                                                &:first-child {
                                                                    margin-top: 3rem;
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }

                            &.formio-component-columns {
                                clear: both;
                                margin-inline-start: -12px;
                                margin-inline-end: -12px;

                                &:not(.formio-hidden) {
                                    display: flex;
                                    flex-wrap: wrap;
                                }

                                .formio-component-button {
                                    &:first-child {
                                        &.top-label-space {
                                            margin-top: 0 !important;
                                        }
                                    }
                                }

                                &.datagrid-custom-footer {
                                    margin-top: -26px !important;
                                    margin-bottom: 79px;
                                    padding-inline-end: 0;
                                    margin-inline-start: 0;
                                    margin-inline-end: 50px;
                                    border: solid 1px @borderColor;
                                    border-radius: @roundedCorners;

                                    >div[class^="col-"] {
                                        padding: 0;
                                        flex: 1;

                                        :where(.formio-component-textfield, .formio-component-number) {
                                            label {
                                                display: none;
                                            }

                                            input {
                                                border: none;
                                                font-weight: 600;
                                                background-color: transparent;
                                                background-image: none;

                                                &[disabled] {
                                                    background-color: transparent !important;
                                                }
                                            }
                                        }

                                        &:not(:first-child) {
                                            input {
                                                padding-inline-start: 28px;
                                            }
                                        }
                                    }

                                    &.datagrid-add-remove-disabled {
                                        padding-inline-end: 0;
                                        margin-top: 6px !important;
                                        margin-bottom: 0;
                                        margin-inline-end: 0;
                                    }

                                    &.datagrid-condensed {
                                        margin-top: -32px !important;

                                        >div {
                                            &[class^="col-"] {
                                                &:not(:first-child) {
                                                    input {
                                                        padding-inline-start: 14px;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }

                                >div {
                                    &[class^="col-"] {
                                        padding-inline-start: 12px;
                                        padding-inline-end: 12px;

                                        .formio-component {
                                            &:first-of-type {
                                                &.top-label-space {
                                                    margin-top: 0 !important;
                                                    padding-top: 29px !important;

                                                    &.required {
                                                        label {
                                                            &.field-required {
                                                                &.control-label--hidden {
                                                                    top: 28px;
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }

                                        &.col-md-12 {
                                            &~[class^="col-"] {
                                                .formio-component {
                                                    &:first-of-type {
                                                        margin-top: 3rem;
                                                    }
                                                }
                                            }
                                        }

                                        &.col-md-6 {
                                            &:first-of-type {
                                                .formio-component {
                                                    &:first-of-type {
                                                        margin-top: 0;
                                                    }
                                                }

                                                &+ :where(.col-md-6, .col-md-3 + .col-md-3) {
                                                    &~[class^="col-"] {
                                                        .formio-component {
                                                            &:first-of-type {
                                                                margin-top: 3rem;
                                                            }
                                                        }
                                                    }
                                                }

                                                &+.col-md-6 {
                                                    .formio-component {
                                                        &:first-of-type {
                                                            margin-top: 0;
                                                        }
                                                    }
                                                }
                                            }
                                        }

                                        &.col-md-3 {
                                            &:first-of-type {
                                                &+ :where(.col-md-9, .col-md-3 + .col-md-3 + .col-md-3) {
                                                    &~[class^="col-"] {
                                                        >.formio-component:not(.formio-hidden, .hide, .formio-component-hidden) {
                                                            &:first-of-type {
                                                                margin-top: 3rem;
                                                            }

                                                            &.formio-component-columns {
                                                                >div[class^="col-"] {
                                                                    >.formio-component:not(.formio-hidden, .hide, .formio-component-hidden) {
                                                                        &:first-of-type {
                                                                            margin-top: 0;
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }

                                        &.col-md-4 {
                                            &:first-of-type {

                                                &+.col-md-8,
                                                &+.col-md-4+.col-md-4 {
                                                    &~[class^="col-"] {
                                                        .formio-component {
                                                            &:first-of-type {
                                                                margin-top: 3rem;
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }

                                &.formio-component-totalPrice {
                                    display: flex;

                                    .formio-component-htmlelement {
                                        &.panel-title {
                                            h4 {
                                                font-size: 16px;
                                                font-weight: 600;
                                            }
                                        }
                                    }
                                }

                                &.i-swear {
                                    .form-check.checkbox {
                                        padding-top: 0;
                                    }

                                    .col-md-1 {
                                        flex: 0;
                                        width: auto;
                                        padding-inline-end: 0;
                                    }
                                }

                                &.vertical-align-center {
                                    align-items: center;
                                }
                            }

                            &.formio-component-panel {
                                &:first-child {
                                    margin-top: 0;
                                }

                                &[class*="formio-component-paymentPagePayments"] {
                                    .card {
                                        .card-body {
                                            .formio-component-columns {
                                                &.default-pay-form-payments {
                                                    padding-bottom: 0;
                                                    margin-bottom: 12px;
                                                    display: flex;

                                                    .formio-component {
                                                        &.formio-component-checkbox {
                                                            .checkbox {
                                                                padding-top: 0;
                                                                cursor: pointer;

                                                                input {
                                                                    &::before {
                                                                        cursor: pointer;
                                                                    }
                                                                }
                                                            }
                                                        }

                                                        &.formio-component-htmlelement {
                                                            margin: 0;

                                                            .tile-text {
                                                                label {
                                                                    margin: 0;
                                                                    display: flex;
                                                                }
                                                            }
                                                        }
                                                    }

                                                    &:nth-last-child(2) {
                                                        margin-bottom: 0;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }

                                &.payment-form-component {
                                    .formio-component-totalPrice {
                                        border-top: none;

                                        &::before {
                                            display: block;
                                            height: 1px;
                                            background-color: @borderColor;
                                            position: absolute;
                                            top: 0;
                                            width: calc(100% - 24px);
                                            inset-inline-start: 12px;
                                        }
                                    }
                                }

                                &:last-child {
                                    margin-bottom: 0 !important;
                                }

                                >.card.mb-2 {
                                    margin-bottom: 0 !important;
                                }

                                >.card {
                                    >.card-body {
                                        >.formio-component {
                                            &:first-child {
                                                margin-top: 0 !important;
                                            }

                                            &.formio-component-editgrid,
                                            &.formio-component-columns>[class*="col-"]>.formio-component-editgrid {
                                                .list-group {
                                                    &.has-open-row {
                                                        .list-group-item {
                                                            &:only-child {
                                                                >.formio-component {
                                                                    &:first-child {
                                                                        margin-top: 0 !important;
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }

                                .formio-component-tabs {
                                    >.card {
                                        padding: 0;
                                        box-shadow: none;

                                        > .card-body.tab-pane.active {
                                            padding: 23px !important;
                                        }
                                    }
                                }
                            }

                            &.formio-component-hidden {
                                &:first-child {
                                    &+.formio-component {
                                        margin-top: 0;
                                    }
                                }
                            }

                            &.margin-top-zero {
                                margin-top: 0 !important;
                            }

                            &.top-space {
                                padding-top: 3rem;
                            }

                            &.top-space-2x {
                                padding-top: 6rem;
                            }

                            &.top-space-3x {
                                padding-top: 9rem;
                            }

                            &.top-label-space {
                                margin-top: 3rem;
                                padding-top: 29px !important;

                                &.required {
                                    label {
                                        &.field-required {
                                            &.control-label--hidden {
                                                top: 28px;
                                            }
                                        }
                                    }
                                }
                            }

                            &.formio-hidden+.formio-component:not(.formio-hidden) {
                                margin-top: 0;
                            }

                            &:not(.formio-hidden)~.formio-hidden+.formio-component:not(.formio-hidden) {
                                margin-top: 3rem;
                            }

                            &:not(.formio-hidden) {
                                &:first-child {
                                    &~.alert+.formio-component:not(.formio-hidden) {
                                        margin-top: 3rem;
                                    }
                                }
                            }

                            // Multiple
                            &:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-datetime, .formio-component-textarea, .formio-component-url, .formio-component-address) {
                                &.formio-component-multiple {
                                    table {
                                        &.table {
                                            table-layout: auto;
                                            border: none;

                                            tbody {
                                                tr {
                                                    td {
                                                        padding: 12px 0 0;
                                                        border: none;

                                                        &:first-child {
                                                            width: 100%;

                                                            &+td {
                                                                button {
                                                                    &.btn {
                                                                        background-color: transparent;
                                                                        height: 40px;
                                                                        font-size: 20px;
                                                                        line-height: 1;
                                                                        border: none;

                                                                        &[ref="removeRow"] {
                                                                            color: @errorNotice;

                                                                            i {
                                                                                font-family: 'Font Awesome 5 Pro';
                                                                                font-weight: 300;

                                                                                &::before {
                                                                                    content: "\F057";
                                                                                }
                                                                            }

                                                                            &:hover {
                                                                                background-color: @errorNotice5;
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }

                                                    &:last-child {
                                                        td {
                                                            padding-top: 0;

                                                            button {
                                                                &.btn.formio-button-add-another {
                                                                    border: solid 1px @accentColor;
                                                                    background-color: transparent;
                                                                    border-radius: @roundedCorners !important;
                                                                    color: @accentColor;
                                                                    padding-inline-start: 25px;
                                                                    padding-inline-end: 25px;
                                                                    font-weight: 600;
                                                                    font-size: 12px;
                                                                    height: 40px;
                                                                    display: inline-flex;
                                                                    align-items: center;
                                                                    transition-duration: 0.3s;
                                                                    margin-top: 10px;

                                                                    >i {
                                                                        display: none;
                                                                    }

                                                                    &:hover {
                                                                        color: @whiteColor;
                                                                        background-color: @accentColor;
                                                                        box-shadow: 0 9px 20px @accentColor40 !important;
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }

                                                    &:first-child {
                                                        td {
                                                            padding-top: 0;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }

                            // Fields used as notice
                            &:is(.formio-component-textfield, .formio-component-number, .formio-component-textarea) {
                                &:is(.notice, .info-notice, .success-notice, .warning-notice, .error-notice) {
                                    background: @darkTextColor3 !important;
                                    border-inline-start: solid 3px;
                                    border-radius: 0 @roundedCorners @roundedCorners 0;
                                    padding: 2rem;

                                    :is(input, textarea) {
                                        background-color: transparent;
                                        border: none !important;
                                        padding: 0;
                                        height: auto;
                                        font-weight: 600;

                                        &[disabled] {
                                            background-color: transparent !important;
                                            margin-bottom: -13px;
                                        }
                                    }
                                }

                                &.notice {
                                    border-left-color: @accentColor;

                                    &.fill-background {
                                        background-color: @accentColor5 !important;

                                        * {
                                            color: @accentColor !important;
                                        }
                                    }
                                }

                                &.info-notice {
                                    border-left-color: @infoNotice;

                                    &.fill-background {
                                        background-color: @infoNotice5 !important;

                                        * {
                                            color: @infoNotice !important;
                                        }
                                    }
                                }

                                &.success-notice {
                                    border-left-color: @successNotice;

                                    &.fill-background {
                                        background-color: @successNotice5 !important;

                                        * {
                                            color: @successNotice !important;
                                        }
                                    }
                                }

                                &.warning-notice {
                                    border-left-color: @warningNotice;

                                    &.fill-background {
                                        background-color: @warningNotice5 !important;

                                        * {
                                            color: @warningNotice !important;
                                        }
                                    }
                                }

                                &.error-notice {
                                    border-left-color: @errorNotice;

                                    &.fill-background {
                                        background-color: @errorNotice5 !important;

                                        * {
                                            color: @errorNotice !important;
                                        }
                                    }
                                }
                            }

                            &.text-right {
                                :where(label, input, textarea) {
                                    text-align: end !important;
                                }

                                &.formio-component-panel {
                                    >.card {
                                        >.card-header {
                                            .card-title {
                                                justify-content: flex-end;

                                                .formio-collapse-icon {
                                                    inset-inline-start: 0;
                                                    inset-inline-end: initial;
                                                }

                                                .formio-collapse-text {
                                                    inset-inline-start: 28px;
                                                    inset-inline-end: initial !important;
                                                }
                                            }
                                        }
                                    }
                                }
                            }

                            &.align-value-right {
                                :where(input, textarea) {
                                    &:not(.choices__input) {
                                        text-align: end !important;
                                    }
                                }
                            }

                            // Celia's revamp
                            @menuBarColor: @accentColor;
                            @primaryColor: @accentColor;
                            
                            label {
                                &.col-form-label {
                                    font-size: 14px !important;
                                    margin-bottom: 10px !important;
                                    color: @labelColor !important;
                                    font-weight: 500 !important;
                                }
                            }
                            input.form-control {
                                letter-spacing: 0 !important;
                                font-size: 16px;
                                font-weight: 400 !important;
                            }
                            &.formio-component-button {
                                button {
                                    &.btn {
                                        background-color: @primaryColor;
                                    }
                                }
                            }
                            &.formio-component-editgrid {
                                > button {
                                    &.btn {
                                        background-color: @accentColor10;
                
                                        &:hover {
                                            background-color: @accentColor;
                                        }
                                    }
                                }
                                .list-group .list-group-item > .row:not(.formio-component-columns) > div {
                                    padding-bottom: 10px;
                                }
                            }
                            &.formio-component-datagrid {
                                > table {
                                    &.table {
                                        &.datagrid-table {
                                            tfoot {
                                                tr {
                                                    td {
                                                        button {
                                                            &.btn {
                                                                &.formio-button-add-row {
                                                                    background-color: @accentColor10;
                                
                                                                    &:hover {
                                                                        background-color: @accentColor;
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                            &.formio-component-checkbox {
                                .form-check.checkbox {
                                    label.form-check-label {
                                        span {
                                            font-size: 16px !important;
                                            color: @darkTextColor;
                                            font-weight: 400 !important;
                                        }
                                        input.form-check-input:checked + span {
                                            color: @darkTextColor !important;
                    
                                            &::before {
                                                color: @menuBarColor !important;
                                            }
                                        }
                                    }
                                }
                            }
                            &.formio-component-radio {
                                .form-radio.radio {
                                    label.form-check-label {
                                        span {
                                            font-size: 16px !important;
                                            color: @darkTextColor !important;
                                            font-weight: 400 !important;
                                        }
                                        input.form-check-input[type="radio"] {
                                            top: 1px;
                    
                                            &::before {
                                                color: @menuBarColor !important;
                                            }
                                            &:checked + span {
                                                color: @darkTextColor !important;
                                            }
                                        }
                                    }
                                }
                                &.radio-switch {
                                    .form-radio.radio {
                                        label.form-check-label {
                                            input.form-check-input[type="radio"] {
                                                &:checked + span {
                                                    color: @whiteColor !important;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                            &.formio-component-panel {
                                > .card {
                                    > .card-header {
                                        > .card-title {
                                            color: @primaryColor !important;
                                            font-weight: 600 !important;
                                            font-size: 20px;
                                        }
                                    }
                                }
                            }
                            &.formio-component-select {
                                span.value {
                                    font-size: 16px;
                                    letter-spacing: 0;
                                }
                            }
                            button {
                                &.btn.formio-button-add-another {
                                    border: solid 1px @darkTextColor !important;
                                    color: @darkTextColor !important;
            
                                    &:hover {
                                        color: @whiteColor !important;
                                        background-color: @darkTextColor !important;
                                        box-shadow: 0 9px 20px @darkTextColor40 !important;
                                    }
                                }
                            }
                            &.top-label-space {
                                padding-top: 29px !important;
            
                                &.required {
                                    label {
                                        &.field-required {
                                            &.control-label--hidden {
                                                top: 32px;
                                            }
                                        }
                                    }
                                }
                            }
                            // Overrides
                            &.formio-component-columns > div[class^="col-"] .formio-component:first-of-type.top-label-space {
                                padding-top: 29px !important;
                            }
                        }

                        [class*="formio-component-panelsendbacktocorrections"] + .formio-dialog-buttons button[ref="modalSave"] {
                            display: none;
                        }

                        #refreshJwt {
                            &+.row {
                                &.mt-2 {
                                    @media screen and (max-width: 992px) {
                                        margin-top: 0 !important;
                                        justify-content: flex-end;
                                    }

                                    &+.row {
                                        >div {
                                            &[class^="col-"] {
                                                width: 100%;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .popup {
            font-size: 14px;
        }

        .ui.popup {
            border-radius: @roundedCorners
        }
    }
}

@-webkit-keyframes fadeOut {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@keyframes fadeOut {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@-webkit-keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes buttonProgress {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(1turn);
        transform: rotate(1turn);
    }
}

html[dir="rtl"] body.modern-theme #header .secondary-menu .contain-lg nav.secondary-nav-container :where(.services-select, .roles-ui-dropdown) i.dropdown {
    left: 0;
    right: auto;
}