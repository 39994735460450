body {
    &.modern-theme {
        #base {
            background-color: @pageBgColor;
      
            #content {
                .section-body {
                    .contain-lg {
                        .current-service-container {
                            &.finished-service-bar {
                                background: @accentColor;
                                box-shadow: -400px 0 0 @accentColor, 
                                            -800px 0 0 @accentColor, 
                                            -1200px 0 0 @accentColor, 
                                            -1800px 0 0 @accentColor, 
                                            -3000px 0 0 @accentColor, 
                                            400px 0 0 @accentColor, 
                                            800px 0 0 @accentColor, 
                                            1200px 0 0 @accentColor, 
                                            1800px 0 0 @accentColor, 
                                            3000px 0 0 @accentColor;
                                height: 40px;

                                .current-service-text {
                                    color: @whiteColor;
                                    height: 100%;
                                    font-weight: 600;
                                    min-width: 100px;

                                    a {
                                        &.home-button {
                                            color: @whiteColor;
                                            height: 100%;
                                            display: flex;
                                            align-items: center;

                                            &:hover,
                                            &:active {
                                            background-color: @black10Color;
                                            text-decoration: none;
                                            }
                                        }
                                    }
                                    > span {
                                        &.be-title {
                                            background-color: @black10Color !important;
                                            font-size: 70%;
                                            border-radius: @roundedCorners;
                                            color: @whiteColor;
                                            padding: 0 10px;
                                            margin-inline-start: 6px;
                                        }
                                        &.current-service-title,
                                        &.be-title {
                                            flex: auto;
                                            white-space: nowrap;
                                            overflow: hidden;
                                            text-overflow: ellipsis;
                                        }
                                    }
                                }
                                .service-bar-user-lang-menu {
                                    margin-inline-start: auto;
                                    margin-inline-end: -1.5rem;

                                    .headerbar-right {
                                        height: 40px;
                                        flex-direction: row-reverse;

                                        ul {
                                            &#language_selector_container {
                                            > li {
                                                &.dropdown-user {
                                                    a {
                                                        &.dropdown-toggle {
                                                            color: @whiteColor;
                                                            padding: 0 1.5rem;
                                                            height: 40px;
                                                            display: flex;
                                                            align-items: center;
                                                            gap: 6px;
                                                            line-height: 1;

                                                            &:hover {
                                                                background: @black10Color;
                                                            }
                                                            i {
                                                                font-size: 16px;
                                                                margin-inline-start: 0.8rem;
                                                            }
                                                        }
                                                    }
                                                    &.open {
                                                        a {
                                                            &.dropdown-toggle {
                                                                background: @black10Color;
                                                            }
                                                        }
                                                        ul {
                                                            &.dropdown-menu {
                                                                background-color: @whiteColor;
                                                                padding: 0;
                                                                inset-inline-end: inherit;
                                                                border-radius: 0 @roundedCorners @roundedCorners @roundedCorners;
                                                                min-width: 100%;

                                                                li {
                                                                    a {
                                                                        color: @accentColor;
                                                                        font-weight: 600;

                                                                        &:hover {
                                                                            color: @darkTextColor;
                                                                            background-color: @pageBgColor;
                                                                        }
                                                                    }
                                                                    &:first-child {
                                                                        a {
                                                                            border-radius: 0 @roundedCorners 0 0;
                                                                        }
                                                                    }
                                                                    &:last-child {
                                                                        a {
                                                                            border-radius: 0 0 @roundedCorners @roundedCorners;
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                    @media screen and (max-width: 768px) {
                                                        padding: 0;
                                                    }
                                                }
                                            }
                                            }
                                            &#connect {
                                                height: 100%;

                                                li {
                                                    &.dropdown {
                                                        &.user {
                                                            height: 100%;

                                                            a {
                                                                text-transform: none;
                                                                font-weight: 600;

                                                                &.dropdown-toggle {
                                                                    &.left-part {
                                                                        background: transparent;
                                                                        margin: 0;
                                                                        border: none;
                                                                        padding-inline-start: 1.5rem;
                                                                        padding-inline-end: 0;
                                                                        border-radius: 0;
                                                                        cursor: pointer;

                                                                        i {
                                                                            &.user-icon {
                                                                                display: none;
                                                                            }
                                                                        }
                                                                        span {
                                                                            &.user-name-text {
                                                                                padding: 0;

                                                                                @media screen and (max-width: 768px) {
                                                                                    display: block;

                                                                                    .user-initials {
                                                                                        &[hidden] {
                                                                                            display: inline-flex;

                                                                                            span {
                                                                                                display: block;
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                }
                                                                            }
                                                                        }
                                                                    }
                                                                    &.user-dropdown-toggle {
                                                                        background: transparent;
                                                                        border: none;
                                                                        padding-inline-end: 1.3rem;
                                                                        padding-inline-start: 1.3rem;
                                                                        border-radius: 0;
                                                                        cursor: pointer;

                                                                        &:hover {
                                                                            background-color: @black10Color;
                                                                        }
                                                                        i {
                                                                            &.angle-icon {
                                                                                margin-inline-end: 0;
                                                                                font-weight: 300;
                                                                                font-size: 16px;
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                            &:hover,
                                                            &.open {
                                                                background-color: @black10Color;
                                                                
                                                                a {
                                                                    color: @whiteColor;
                                                                }
                                                            }
                                                            &.open {
                                                                a {
                                                                    &.dropdown-toggle {
                                                                        &.user-dropdown-toggle {
                                                                            background-color: @black10Color;

                                                                            i {
                                                                                color: @whiteColor;
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                                ul {
                                                                    &.dropdown-menu {
                                                                        width: 100%;
                                                                        min-width: fit-content;
                                                                        background-color: @whiteColor;
                                                                        color: @accentColor;
                                                                        padding: 0;
                                                                        border-radius: 0 0 @roundedCorners @roundedCorners;

                                                                        li {
                                                                            &.divider {
                                                                                margin: 0;
                                                                                background-color: @accentColor10;
                                                                                height: 1px;

                                                                                &:first-child {
                                                                                    display: none;
                                                                                }
                                                                            }
                                                                            a {
                                                                                padding: 7px 20px;
                                                                                font-size: 13px;
                                                                                font-weight: 600;
                                                                                color: @darkTextColor;
                                                                            
                                                                                &:hover {
                                                                                    background-color: @black5Color;
                                                                                }
                                                                            }
                                                                            &:last-child {
                                                                                a {
                                                                                    border-radius: 0 0 @roundedCorners @roundedCorners !important;
                                                                                }
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                            @media screen and (max-width: 768px) {
                                                                padding: 0;
                                                            }
                                                        }
                                                    }
                                                    &:not(.dropdown) {
                                                        height: 100%;

                                                        a {
                                                            height: 100%;
                                                            display: flex;
                                                            align-items: center;
                                                            border-radius: 0 !important;
                                                            background: transparent;
                                                            margin: 0 !important;
                                                            padding: 0 2rem;
                                                            text-transform: none;
                                                            font-weight: 600;
                                                            box-shadow: none !important;

                                                            &:hover {
                                                                background-color: @black10Color !important;
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                        @media screen and (max-width: 768px) {
                                            min-height: 40px;
                                        }
                                    }
                                    @media screen and (max-width: 1200px) {
                                        margin-inline-end: 0;
                                    }
                                }
                                &.affix {
                                    z-index: 1050;
                                    top: 0;
                                    margin: 0;
                                    width: 100%;
                                    max-width: inherit;

                                    & + div {
                                        padding-top: 40px;
                                    }
                                }
                            }
                        }
                        .finished-service-forms,
                        div {
                            > .row {
                                > .col-sm-12 {
                                    > .panel {
                                        padding: @panelBodyPadding;
                                        background: @whiteColor;
                                        border: none;
                                        border-radius: @roundedCorners;
                                        box-shadow: 0px 0px 20px @black5Color;

                                        .panel-heading {
                                            padding: 0 !important;
                                            align-items: center;

                                            h3 {
                                                padding: 0;
                                                font-size: 21px;
                                                color: @accentColor;
                                                font-weight: 600;
                                            }
                                            .process-box-toggler {
                                                cursor: pointer;
                                                display: flex;
                                                align-items: center;
                                                gap: 6px;
                                                
                                                .fa-times {
                                                    display: none;
                                                    color: @errorNotice;
                                                }
                                                span {
                                                    color: @infoNotice;
                                                }
                                                .fa-cog {
                                                    color: @infoNotice;
                                                }
                                                &:not(.closed) {
                                                    .fa-times {
                                                        display: inline-block;
                                                    }
                                                    .fa-cog {
                                                        display: none;
                                                    }
                                                    span {
                                                        display: none;
                                                    }
                                                }
                                            }
                                            .restart-process-box {
                                                margin-top: 0 !important;

                                                &.closed {
                                                    display: none;
                                                }
                                            }
                                        }
                                        .panel-body {
                                            padding: 2rem 0 0 !important;

                                            > .row {
                                                margin: 0;

                                                > .col-sm-12 {
                                                    padding: 0;

                                                    table {
                                                        &.table {
                                                            &.table-history {
                                                                display: block;

                                                                tbody {
                                                                    display: flex;
                                                                    padding: 1rem 0;

                                                                    tr {
                                                                        flex: 1;
                                                                        display: grid;
                                                                        position: relative;
                                                                        padding: 20px;
                                                                        transition-duration: 0.3s;
                                                                        max-width: 190px;
                                                                        min-width: 150px;

                                                                        td {
                                                                            text-align: center;
                                                                            padding: 0 !important;
                                                                            overflow: hidden;

                                                                            &:nth-child(2) {
                                                                                margin-bottom: 4rem;
                                                                                font-size: 12px;
                                                                                font-weight: 500;
                                                                                color: @darkTextColor80;
                                                                                min-height: 18px;

                                                                                &::before {
                                                                                    content: "";
                                                                                    display: block;
                                                                                    position: absolute;
                                                                                    top: calc(50% - 1px);
                                                                                    inset-inline-start: 0;
                                                                                    z-index: 1;
                                                                                    width: 100%;
                                                                                    height: 2px;
                                                                                    background-color: @grayAColor;
                                                                                    transition-duration: 0.3s;
                                                                                }
                                                                                &::after {
                                                                                    content: "";
                                                                                    display: block;
                                                                                    position: absolute;
                                                                                    top: calc(50% - 6px);
                                                                                    inset-inline-start: calc(50% - 6px);
                                                                                    z-index: 2;
                                                                                    border: solid 2px @grayAColor;
                                                                                    background-color: @whiteColor;
                                                                                    width: 12px;
                                                                                    height: 12px;
                                                                                    border-radius: 8px;
                                                                                    transition-duration: 0.3s;
                                                                                }
                                                                                small {
                                                                                    font-size: 100%;
                                                                                }
                                                                            }
                                                                            &:nth-child(3) {
                                                                                grid-row: 4;
                                                                                min-height: 20px;
                                                                            }
                                                                            span {
                                                                                &.label {
                                                                                    background-color: transparent;
                                                                                    text-transform: none;
                                                                                    font-size: 14px;
                                                                                    font-weight: 500;
                                                                                    color: @darkTextColor;
                                                                                    overflow: hidden;
                                                                                    white-space: nowrap;
                                                                                    text-overflow: ellipsis;
                                                                                    display: block;
                                                                                }
                                                                                &.text-light {
                                                                                    font-size: 12px;
                                                                                    font-weight: 500;
                                                                                    color: @darkTextColor80;
                                                                                    margin-top: 2px;
                                                                                    display: block;
                                                                                    text-transform: capitalize;
                                                                                }
                                                                            }
                                                                            &[class*="status-"] {
                                                                                overflow: hidden;
                                                                                white-space: nowrap;
                                                                                text-overflow: ellipsis;
                                                                                transition-duration: 0.3s;

                                                                                span {
                                                                                    background-color: @darkTextColor5;
                                                                                    color: @darkTextColor;
                                                                                    padding: 6px 10px;
                                                                                    border-radius: @roundedCorners;
                                                                                    font-size: 13px;
                                                                                    font-weight: 500;
                                                                                    overflow: hidden;
                                                                                    white-space: nowrap;
                                                                                    text-overflow: ellipsis;
                                                                                    display: block;

                                                                                    &:empty {
                                                                                        display: none;
                                                                                    }
                                                                                    &.status-tooltip-toggler {
                                                                                        cursor: pointer;
                                                                                    }
                                                                                    &.status-tooltip {
                                                                                        position: absolute;
                                                                                        opacity: 0;
                                                                                        z-index: -99;
                                                                                        background: white !important;
                                                                                        border: solid 1px;
                                                                                        font-size: 12px;
                                                                                        font-weight: 600;
                                                                                        inset-inline-start: 2%;
                                                                                        width: 96%;
                                                                                        text-overflow: initial;
                                                                                        transition-duration: 0.5s;
                                                                                        max-height: 51px;
                                                                                        overflow-y: auto;
                                                                                        overflow-x: hidden;
                                                                                        top: -50px;
                                                                                        padding: 5px;

                                                                                        &.open {
                                                                                            opacity: 1;
                                                                                            z-index: 99;
                                                                                            transition-duration: 0.5s;
                                                                                            overflow-y: hidden;
                                                                                            overflow-x: hidden;
                                                                                            top: 2%;
                                                                                            max-height: 100%;
                                                                                            display: flex;
                                                                                        }
                                                                                        div {
                                                                                            white-space: break-spaces;
                                                                                            overflow-y: auto;
                                                                                            overflow-x: auto;

                                                                                            &::-webkit-scrollbar {
                                                                                                width: 6px;
                                                                                                height: 6px;
                                                                                            }
                                                                                            &::-webkit-scrollbar-track {
                                                                                                background: rgba(255,255,255,0.3);
                                                                                                border-radius: 6px;
                                                                                            }
                                                                                            &::-webkit-scrollbar-thumb {
                                                                                                border-radius: 4px;
                                                                                                background-color: rgba(0,0,0,0.4);
                                                                                                width: 100%;
                                                                                            }
                                                                                        }
                                                                                        i {
                                                                                            position: absolute;
                                                                                            width: 20px;
                                                                                            height: 20px;
                                                                                            line-height: 1;
                                                                                            top: 0;
                                                                                            inset-inline-end: 0;
                                                                                            display: block;
                                                                                            color: @errorNotice;
                                                                                            background: @whiteColor;
                                                                                            border-radius: 50%;
                                                                                            display: flex;
                                                                                            z-index: 99;
                                                                                            align-items: center;
                                                                                            justify-content: center;
                                                                                            cursor: pointer;

                                                                                            &:hover {
                                                                                                background-color: #FEEFEF;
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                    &.white-text {
                                                                                        color: white;
                                                                                    }
                                                                                    &:not(.status-tooltip) {
                                                                                        &:hover {
                                                                                            box-shadow: inset 0 0 0px 1px;
                                                                                        }
                                                                                    }
                                                                                }
                                                                            }
                                                                        }
                                                                        &:first-child {
                                                                            td {
                                                                                &:nth-child(2) {    
                                                                                    &::before {
                                                                                        inset-inline-start: 50%;
                                                                                        width: 50%;
                                                                                    }
                                                                                }
                                                                            }
                                                                        }
                                                                        &:last-child {
                                                                            box-shadow: 0px 0px 10px @black5Color;
                                                                            border: 1px solid @grayAColor3;
                                                                            border-radius: @roundedCorners;

                                                                            td {
                                                                                &:nth-child(2) {    
                                                                                    &::before {
                                                                                        inset-inline-start: -1px;
                                                                                        width: 50%;
                                                                                    }
                                                                                    &::after {
                                                                                        background-color: @grayAColor;
                                                                                    }
                                                                                }
                                                                            }
                                                                        }
                                                                        &:hover {
                                                                            background-color: transparent;
                                                                            box-shadow: 0px 0px 10px @black5Color;
                                                                            border-radius: @roundedCorners;

                                                                            td {
                                                                                background-color: transparent;

                                                                                /*&:nth-child(2) {    
                                                                                    &::before {
                                                                                        opacity: 0;
                                                                                    }
                                                                                    &::after {
                                                                                        opacity: 0;
                                                                                    }
                                                                                }*/
                                                                                /*&[class*="status-"] {
                                                                                    overflow: visible;
                                                                                    white-space: unset;
                                                                                    margin-top: -2rem;
                                                                                }*/
                                                                            }
                                                                        }
                                                                        &:not(:first-child) {
                                                                            td {
                                                                                &[class*="status-"] {
                                                                                    &.status-filevalidated {
                                                                                        span {
                                                                                            background-color: @successNotice5;
                                                                                            color: @successNotice;
                                                                                        }
                                                                                    }
                                                                                    &.status-filepending {
                                                                                        span {
                                                                                            background-color: @accentBlue5;
                                                                                            color: @accentBlue;
                                                                                        }
                                                                                    }
                                                                                    &.status-filedecline {
                                                                                        color: @warningNotice;

                                                                                        span {
                                                                                            background-color: @warningNotice5;
                                                                                            color: @warningNotice;
                                                                                        }
                                                                                    }
                                                                                    &.status-filereject {
                                                                                        color: @errorNotice;

                                                                                        span {
                                                                                            background-color: @errorNotice5;
                                                                                            color: @errorNotice;
                                                                                        }
                                                                                    }
                                                                                    &.status-cancelled {
                                                                                        color: @errorNotice;

                                                                                        span {
                                                                                            background-color: @errorNotice5;
                                                                                            color: @errorNotice;
                                                                                        }
                                                                                    }
                                                                                    span.white-text {
                                                                                        color: white;
                                                                                    }
                                                                                }
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                            &.collapse {
                                                padding-top: 0 !important;
                                                display: none;

                                                &.in {
                                                    padding-top: 2rem !important;
                                                    display: flex;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                            #rolereview {
                                .formio-component-form {
                                    .formio-form {
                                        > .formio-component-tabs {
                                            .card {
                                                box-shadow: none;

                                                .card-header {
                                                    ul {
                                                        &.nav.nav-tabs.card-header-tabs {
                                                            background-color: transparent;

                                                            > li {
                                                                box-shadow: none;
                                                                height: auto;
                                                                flex: initial;
                                                                margin-bottom: 0;
                        
                                                                a {
                                                                    font-size: 21px;
                                                                    color: @accentColor40;
                                                                    font-weight: 600;
                                                                    padding-inline-start: 42px;
                                                                    padding-inline-end: 42px;
                                                                    border-top: none;
                                                                    border-top-left-radius: @roundedCorners;
                                                                    border-top-right-radius: @roundedCorners;
                                                                }
                                                                &.active {
                                                                    a {
                                                                        background-color: @accentColor10;
                                                                        color: @accentColor;
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                    & + .card-body {
                                                        &.tab-pane {
                                                            &.active {
                                                                border-radius: 0 @roundedCorners @roundedCorners @roundedCorners;
                                                            }
                                                        }
                                                    }
                                                }
                                                .card-body {
                                                    &.tab-pane {
                                                        background: @whiteColor;
                                                        border: none;
                                                        border-radius: @roundedCorners;
                                                        box-shadow: 0px 0px 20px @black5Color;
                                                        margin-top: 0;
                                                        padding: 24px;

                                                        > .formio-component-panel,
                                                        > .formio-component-container > div > .formio-component-panel {
                                                            margin-top: 0;

                                                            .card {
                                                                background-color: transparent;

                                                                .card-header {
                                                                    padding: 0;
                                                                }
                                                                .card-body {
                                                                    &:not(:first-child) {
                                                                        padding: 1rem 0 0 !important;
                                                                    }
                                                                    &:first-child {
                                                                        padding: 0 !important
                                                                    }

                                                                    .formio-component-file {
                                                                        .list-group {
                                                                            margin-bottom: 0;
                                                                        }
                                                                    }
                                                                    
                                                                    > .formio-component-panel {
                                                                        .card {
                                                                            margin: 0;
                                                                            border: none;

                                                                            .card-header {
                                                                                margin: 0 0 1rem;
                                                                                background-color: transparent;
                                                                            }
                                                                            .card-body {
                                                                                padding-top: 0;

                                                                                .formio-component-panel {
                                                                                    &:first-child {
                                                                                        .card {
                                                                                            .card-body {
                                                                                                padding-top: 0;
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                }
                                                                            }
                                                                        }
                                                                    }
                                                                    .readonly-form-columns {
                                                                        .formio-component-textarea {
                                                                            .card {
                                                                                &.card-body {
                                                                                    padding-top: 0 !important;
                                                                                }
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                                &::after {
                                                                    display: none;
                                                                }
                                                            }
                                                            &:last-child {
                                                                margin-bottom: 0;

                                                                > .card {
                                                                    margin-bottom: 0 !important;
                                                                }
                                                            }
                                                        }
                                                        > .formio-component-container {
                                                            margin-top: 0;

                                                            .readonly-forms-block ~ .readonly-forms-block {
                                                                margin-top: 2.5rem;
                                                            }
                                                        }
                                                        > .formio-component-table {
                                                            &.formio-component-documentsTabTable {
                                                                margin-top: 0px;
                                                                > table {
                                                                    > tbody {
                                                                        > tr {
                                                                            > td {
                                                                                &:first-child {
                                                                                    padding-inline-start: 0;
                                                                                }
                                                                                &:last-child {
                                                                                    padding-inline-end: 0;
                                                                                }
                                                                                td {
                                                                                    padding: 1.2rem;

                                                                                    .formio-component-panel {
                                                                                        > .card {
                                                                                            margin-bottom: 0 !important;

                                                                                            .card-body {
                                                                                                padding: 0 !important;

                                                                                                .alert:where(.formio-hidden, .hide) {
                                                                                                    & ~ .formio-component:not(.formio-hidden, .hide, .alert) {
                                                                                                        margin-top: 0 !important;
                                                                                                    }
                                                                                                    & ~ .formio-component:not(.formio-hidden, .hide).alert {
                                                                                                        & ~ .formio-component:not(.formio-hidden, .hide, .alert) {
                                                                                                            margin-top: 0 !important;
                                                                                                        }
                                                                                                    }
                                                                                                }
                                                                                            }
                                                                                        }
                                                                                        & + .formio-component-panel {
                                                                                            margin-top: 2rem;
                                                                                        }
                                                                                    }
                                                                                }
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                                tbody {
                                                                    tr {
                                                                        td {
                                                                            .formio-component-panel {
                                                                                .card {
                                                                                    .card-body {
                                                                                        .formio-component-htmlelement {
                                                                                            p {
                                                                                                span {
                                                                                                    &.bullet {
                                                                                                        vertical-align: initial;
                                                                                                    }
                                                                                                }
                                                                                                a {
                                                                                                    display: block;
                                                                                                    margin-top: 1rem;

                                                                                                    img {
                                                                                                        display: block;
                                                                                                        max-width: 100%;
                                                                                                    }
                                                                                                }
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                }
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                                td {
                                                                    .formio-component-datagrid {
                                                                        &:only-child {
                                                                            margin-top: 0px;
                                                                        }
                                                                        .card {
                                                                            margin-bottom: 0px;
                                                                        }
                                                                        .card-body {
                                                                            padding: 0 1.5rem;
                                                                        }
                                                                    }
                                                                    &.dg-action-col {
                                                                        display: none;
                                                                    }
                                                                }
                                                                table.datagrid-table {
                                                                    .formio-component-panel:only-child {
                                                                        margin-block: 0px;
                                                                    }
                                                                }
                                                            }
                                                        }
                                                        > .formio-component-button {
                                                            &.formio-component-printEntirePartAButton {
                                                                text-align: end;
                                                                margin-bottom: -24px !important;
                                                                
                                                                button {
                                                                    &.btn {
                                                                        border-radius: @roundedCorners !important;
                                                                        background-color: @accentColor;
                                                                        padding-inline-start: 25px;
                                                                        padding-inline-end: 25px;
                                                                        font-weight: 600;
                                                                        text-transform: none;
                                                                        position: relative;
                                                                        z-index: 1;
                                                                        height: 30px;
                                
                                                                        &:hover {
                                                                            box-shadow: 0 9px 20px @accentColor40 !important;
                                                                            opacity: 0.9;
                                                                        }
                                                                    }
                                                                }
                                                                & + .formio-component-container {
                                                                    > [ref="nested-previewcontainer"] {
                                                                        > .readonly-forms-block:first-child {
                                                                            > .card {
                                                                                > .card-body {
                                                                                    &:first-child {
                                                                                        margin-top: 24px;
                                                                                    }
                                                                                }
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }
                                                        > .alert.alert-danger {
                                                            padding: 2rem !important;
                                                            border-inline-start: solid 3px @errorNotice !important;
                                                            border-radius: 0 @roundedCorners @roundedCorners 0;
                                                            background-color: @errorNotice5Solid !important;
                                                            max-width: 100%;
                                                            font-weight: 600 !important;
                                                            color: @errorNotice !important;

                                                            &::after {
                                                                display: none !important;
                                                            }
                                                            &.formio-hidden {
                                                                & ~ .alert.alert-danger:not(.formio-hidden) {
                                                                    margin-top: 0 !important;

                                                                    & ~ .alert.alert-danger:not(.formio-hidden) {
                                                                        margin-top: 3rem !important;
                                                                    }
                                                                }
                                                            }
                                                            &:not(.formio-hidden) {
                                                                & ~ .alert.alert-danger:not(.formio-hidden) {
                                                                    margin-top: 3rem !important;
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                            .panel {
                                &.overview-panel {
                                    padding: @panelBodyPadding;
                                    border: solid 1px white;

                                    .panel-heading {
                                        padding: 0;
                                        cursor: pointer;

                                        .panel-title {
                                            padding: 0;
                                            font-size: 21px;
                                            color: @accentColor;
                                            font-weight: 600;
                                            font-family: inherit;

                                            i {
                                                height: 26px;
                                                font-size: 27px;
                                            }
                                        }
                                        & + .panel-body {
                                            &.collapsing,
                                            &.in {
                                                padding-top: 2rem !important;
                                            }
                                        }
                                    }
                                    &:hover {
                                        border: solid 1px #4CAAE5 !important;
                                    }
                                    .part-b-single-file-resume {

                                    }
                                }
                            }
                        }
                        .finished-service-tabs {
                            ul {
                                &.nav.nav-tabs.card-head {
                                    > li {
                                        box-shadow: none;
                                        height: auto;

                                        a {
                                            font-size: 21px;
                                            color: @accentColor40;
                                            font-weight: 600;
                                            padding-inline-start: 42px;
                                            padding-inline-end: 42px;
                                            border-top: none;
                                            border-top-left-radius: @roundedCorners;
                                            border-top-right-radius: @roundedCorners;
                                        }
                                        &.active {
                                            a {
                                                background-color: @accentColor10;
                                                color: @accentColor;
                                            }
                                        }
                                    }
                                }
                            }
                            .panel-body {
                                &.tab-content {
                                    background: @whiteColor;
                                    border: none;
                                    border-radius: @roundedCorners;
                                    box-shadow: 0px 0px 20px @black5Color;

                                    .tab-pane {
                                        box-shadow: none;
                                        background-color: @whiteColor;

                                        &#documents {
                                            padding: 35px !important;
                                            border-radius: 0 @roundedCorners @roundedCorners @roundedCorners;
    
                                            #documents-list,
                                            #certificats-list {
                                                h3 {
                                                    margin-top: 0;
                                                    padding-top: 0;
                                                }
                                            }
                                        }
                                        &#file_cata {
                                            border-radius: @roundedCorners;

                                            .zone_revision {
                                                .revision-block {
                                                    form {
                                                        > .form-field-type-button {
                                                            margin-inline-end: 35px;
                                                            margin-top: 21px;
                                                        }
                                                        > .form-field-type-mytabs {
                                                            .tabs-above {
                                                                ul {
                                                                    &.nav-tabs {
                                                                        background-color: @whiteColor;
                                                                        border-radius: @roundedCorners @roundedCorners 0 0;
                                                                        margin-top: 14px;
    
                                                                        li {
                                                                            &:first-of-type {
                                                                                a {
                                                                                    border-radius: @roundedCorners 0 0 0;
                                                                                }
                                                                            }
                                                                            a {
                                                                                color: @accentColor;
                                                                                text-decoration: none;
                                                                                font-weight: 600;
                                                                                padding: 0 35px;
                                                                            }
                                                                            &:hover {
                                                                                a {
                                                                                    border-bottom: none;
                                                                                    box-shadow: inset 0 -2px 0 @accentColor !important;
                                                                                }
                                                                            }
                                                                            &.active {
                                                                                box-shadow: none;    
    
                                                                                a {
                                                                                    background-color: transparent;
                                                                                    box-shadow: inset 0 -2px 0 @accentColor !important;
                                                                                }
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                                .tab-content {
                                                                    .tab-pane {
                                                                        .card {
                                                                            .card-body {
                                                                                > .form-field-type-panel {
                                                                                    > .panel {
                                                                                        > .panel-body {
                                                                                            padding: 35px;
                                                                                            background: @whiteColor;

                                                                                            .form-field-type-panel {
                                                                                                .panel {
                                                                                                    background-color: @whiteColor;

                                                                                                    .panel-heading {
                                                                                                        background-color: transparent;
                                                                                                    }
                                                                                                }
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                }
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                        > .card {
                                            > .card-body {
                                                > .form-field-type-panel {
                                                    .panel {
                                                        background-color: @whiteColor;

                                                        .form-field-type-panel {
                                                            .panel {
                                                                margin: 0;
                                                                padding: 0;
                                                                border: none;

                                                                .panel-heading {
                                                                    margin: 0;
                                                                    padding: 0;
                                                                }
                                                                .panel-body {
                                                                    padding: 2.7rem 0 2.5rem;
                                                                }
                                                            }
                                                        }
                                                        > .panel-heading {
                                                            padding-inline-start: 35px;
                                                            padding-inline-end: 35px;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}