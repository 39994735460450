body {
  &.modern-theme {
    .vnotify-container {
      &.vn-bottom-right {
        top: 12px !important;
        z-index: 99999;
        width: calc((calc(100vw - 1200px) / 2) - 24px);
        right: 16px;
        bottom: 24px;
        min-width: 320px;
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        gap: 10px;
        flex-direction: column;

        .vnotify-item {
          margin: 0;
          width: fit-content;
          max-width: 320px;
          padding-inline-start: 3rem;
          padding: 0px;
          padding-inline-start: 56px;
          background-color: rgba(var(--toastr-color), 0.85);
          transition-duration: 0.15s;
          border: solid 1px rgb(var(--toastr-color));
          border-radius: @roundedCorners;
          --shadow-color-rgb: var(--toastr-color);
          --shadow-opacity: .5;
          box-shadow: 0px 6px 20px rgba(var(--toastr-color), 0.5);
          -webkit-backdrop-filter: blur(3px);
          backdrop-filter: blur(3px);
          min-height: 56px;
          overflow: hidden;

          &::before {
            -moz-osx-font-smoothing: grayscale;
            -webkit-font-smoothing: antialiased;
            display: inline-flex;
            font-style: normal;
            font-variant: normal;
            text-rendering: auto;
            line-height: 1;
            font-family: 'Font Awesome 5 Pro';
            font-weight: 700;
            position: absolute;
            inset-inline-start: 18px;
            top: calc(50% - 10px);
            font-size: 20px;
          }
          &.vnotify-success {
            --toastr-color: 56, 159, 143;

            &:hover {
              --toastr-color: 26, 137, 120;
            }
            &::before {
              content: "\F058";
            }
          }
          &.vnotify-error {
            --toastr-color: 247, 95, 95;

            &:hover {
              --toastr-color: 244, 47, 47;
            }
            &::before {
              content: "\F057";
            }
          }
          &.vnotify-warning {
            --toastr-color: 255, 160, 118;

            &:hover {
              --toastr-color: 255, 124, 66;
            }
            &::before {
              content: "\F071";
            }
          }
          &.vnotify-info {
            --toastr-color: 76, 170, 229;

            &:hover {
              --toastr-color: 6, 156, 249;
            }
            &::before {
              content: "\F06A";
            }
          }
          .vnotify-text {
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            display: flex;
            height: 100%;
            align-items: center;
            padding-inline: 20px;
            padding-block: 10px;
            background: @whiteColor;
            color: rgb(var(--toastr-color));
          }
          .vn-close {
            top: 0;
            right: 4px;
            padding: 0;
            height: 28px;
            -moz-osx-font-smoothing: grayscale;
            -webkit-font-smoothing: antialiased;
            display: inline-flex;
            font-style: normal;
            font-variant: normal;
            text-rendering: auto;
            line-height: 1;
            font-family: 'Font Awesome 5 Pro';
            font-weight: 400;
            align-items: center;
            opacity: 0;
            transition-duration: 0.15s;
            color: rgb(247, 95, 95);

            &::before {
              content: "\f00d";
              line-height: 1;
              transform: rotate(0deg);
              background: transparent;
              top: initial;
              height: auto;
            }
            &::after {
              display: none;
            }
          }
          &:hover {
            opacity: 1 !important;

            .vn-close {
              opacity: 1;
            }
          }
        }
        .vnotify-text {
          border-radius: 0 calc(@roundedCorners - 1px) calc(@roundedCorners - 1px) 0;
        }
      }
      &:empty {
        display: none;
      }
    }
    .step-page-container {
      #formBtn {
        border-radius: @roundedCorners !important;
        background-color: @accentColor;
        padding-inline-start: 25px;
        padding-inline-end: 25px;
        font-weight: 600;
        text-transform: none;
      }
      #verify-email-to-send {
        .modal-dialog {
          margin: 210px auto 0;
        }
      }
    }
    /* Guide */
    form {
      &.formio-form {
        .form-group {
          &.form-field-type-panel {
            box-shadow: none;
          }
        }
      }
    }
    #form-type-GUIDE {
      form {
        .formio-component-questionscolumns {
          margin-top: 2rem;

          > .row {
            > .col-sm-4 {
              > .form-field-type-panel {
                > .panel {
                  background-color: @whiteColor;
                  box-shadow: 0px 0px 20px @black5Color;
                  border-radius: @roundedCorners !important;
                  padding: @panelPadding;

                  > .panel-heading {
                    padding-top: 0;
                    padding-inline-start: 0;
                    padding-inline-end: 0;
                    padding-bottom: 35px;

                    > .panel-title {
                      font-size: 18px;
                      font-weight: 600;
                      color: @darkTextColor;
                    }
                  }
                  > .panel-body {
                    padding: 0;

                    .panel {
                      background-color: transparent;
                    }
                  }
                }
              }
              & + .col-sm-4 {
                .form-field-type-htmlelement {
                  margin-bottom: 0;

                  &:first-of-type {
                    div {
                      line-height: 1;
                    }
                    span {
                      &.card-title {
                        margin: 0;
                        font-size: 18px;
                        font-weight: 600;
                        color: @darkTextColor;
                        padding-bottom: 35px;
                      }
                    }
                  }
                  & + .form-field-type-checkbox {
                    margin-top: 0;

                    .checkbox {
                      padding-top: 0;
                    }
                  }
                }
                .form-field-type-checkbox {
                  .checkbox {
                    margin-top: 0;

                    label {
                      span {
                        line-height: 1.2;
                        padding-inline-start: 3rem;
                        margin-top: -2px;

                        &::before {
                          top: 2px;
                        }
                      }
                    }
                  }
                }
              }
            }
            .block-fees {
              background-color: @whiteColor;
              box-shadow: 0px 0px 20px @black5Color;
              border-radius: @roundedCorners !important;

              .form-field-type-button {
                button {
                  background-color: transparent;
                  padding: @panelPadding;
                  color: @darkTextColor;
                  font-size: 18px;
                  font-weight: 600;
                  line-height: 1;
                }
              }
              .formio-component-feeTitleColumns {
                > .row {
                  > .col-sm-6 {
                    &:last-of-type {
                      display: flex;
                      align-items: center;

                      .formio-component-feeTitleContent {
                        color: @darkTextColor;
                        font-size: 18px;
                        font-weight: 600;
                        line-height: 1;
                      }
                    }
                  }
                }
              }
            }
            .block-requirements {
              background-color: @whiteColor;
              box-shadow: 0px 0px 20px @black5Color;
              border-radius: @roundedCorners !important;
              padding: @panelPadding;
              color: @darkTextColor;
              margin-top: 2rem !important;

              .formio-component-requirementTitleColumns {
                .formio-component-requirementOpenCloseButton {
                  line-height: 1;

                  &::before {
                    top: 0.4rem;
                    inset-inline-end: -2px;
                  }
                  button {
                    background-color: transparent;
                    padding: 0 0 30px;
                    line-height: 1;
                    font-size: 18px;
                    font-weight: 600;
                  }
                  &.closed {
                    button {
                      padding: 0;
                    }
                  }
                }
                .formio-component-requirementTitleCount {
                  top: 0;
                  line-height: 1;
                  inset-inline-end: 3rem;
                  font-size: 18px;
                  font-weight: 600;
                }
              }
              .formio-component-requirementsRows {
                padding: 0;

                .formio-container-component {
                  > .form-field-type-htmlelement {
                    &:first-child {
                      &:not(.ng-hide) {
                        li {
                          border-top: none;
                        }
                      }
                      &.ng-hide {
                        & + .form-field-type-htmlelement {
                          li {
                            border-top: none;
                          }
                        }
                      }
                    }
                  }
                }
              }
              & ~ .form-field-type-button {
                margin-top: 2rem !important;
              }
            }
          }
        }
      }
    }
    /* New Formio */
    @buttonAccentColor: @accentColor;
    &.formio-new {
      #header {
        z-index: 999;
        max-height: 80px;
      }
      #base {
        #content {
          section {
            &.affix-menu-size-container {
              height: auto;

              #shopping-cart-container {
                display: none;
              }
              .service-bar-user-lang-menu {
                &[hidden] {
                  display: block;
                }
              }
              &.affix ~ .section-body .contain-lg #formio-form-wizard {
                .formio-component-modal-wrapper.formio-component-modal-wrapper-datagrid .formio-dialog.formio-dialog-theme-default {
                  padding-top: 55px;
                  transition-duration: 0.15s;
                  transition-property: padding-top;
                }
              }
            }
          }
          .section-body {
            .contain-lg {
              .transactions-statuses {
                position: relative;
                z-index: 1040;
                background: @whiteColor;
                box-shadow: 50vw -3rem 0 @whiteColor, -50vw -3rem 0 @whiteColor, -50vw 0 0 @whiteColor, 50vw 0 0 @whiteColor;

                .alert {
                  margin-top: 1rem !important;
                  margin-bottom: 0 !important;
                  border-radius: 0 @roundedCorners @roundedCorners 0;
                  box-shadow: none;
                  border-inline-start: solid 4px rgb(var(--alert-color-rgb)) !important;
                  background-color: rgba(var(--alert-color-rgb), 0.1) !important;
                  color: rgb(var(--alert-color-rgb)) !important;
                  padding: 1.5rem 2rem !important;

                  &::after {
                    display: none !important;
                  }
                  &.alert-success {
                    --alert-color-rgb: @successNoticeRGB;
                  }
                  &.alert-warning {
                    --alert-color-rgb: @warningNoticeRGB;
                  }
                  &.alert-danger {
                    --alert-color-rgb: @errorNoticeRGB;
                  }
                }
              }
              #formio-form-wizard {
                @panelHeadingBottomBorderColor: #F3F4F5;

                &.tabbed-modern-nav {
                  position: relative;
                  z-index: 1030;

                  > .formio-component-modal-wrapper,
                  > .formio-form {
                    > div {
                      > nav {
                        &::before {
                          content: "";
                          display: block;
                          position: absolute;
                          width: calc(100% + 200vw);
                          bottom: 30px;
                          background: @whiteColor;
                          z-index: -1;
                          top: 0;
                          inset-inline-start: -100vw;
                        }
                        .pagination {
                          border: none;
                          flex-wrap: nowrap;
                          overflow-x: auto;
                          border-radius: 0;
                          overflow-y: hidden;

                          li {
                            &.page-item {
                              flex: 1;
                              max-width: fit-content;
                              min-width: fit-content;

                              span {
                                &.page-link {
                                  padding: 1.7rem 60px;
                                }
                              }
                              &.active {
                                background-color: @pageBgColor;
                                border-radius: 10px 10px 0 0;
                                position: sticky;
                                inset-inline-start: 0px;
                                inset-inline-end: 0px;
                                z-index: 9;
                              }
                            }
                          }
                        }
                        .regs-docs-fees-cart {
                          border: solid 1px @accentColor30;
                          background-color: @whiteColor;
                          align-self: center;
                          margin-top: 0;
                          height: 36px;
                          box-shadow: none;
                          position: relative;

                          &::before {
                            border-inline-end: none;
                            background-color: @accentColor10;
                          }
                          &.open {
                            &::before {
                              border-inline-end: none;
                              background-color: transparent;
                            }
                          }
                        }
                        .regs-docs-fees-drawer {
                          top: calc(50% + 25px);
                          box-shadow: 0 6px 18px rgba(0, 0, 0, 0.1);
                        }
                      }
                    }
                  }
                }
                :where(.formio-component-modal-wrapper, .formio-form) {
                  &.formio-component-modal-wrapper-datagrid {
                    .formio-dialog.formio-dialog-theme-default {
                      padding-top: 136px;
                      transition-duration: 0.15s;
                      transition-property: padding-top;
                    }
                  }
                  :where(.wizard-page .formio-component, .formio-component, .formio-dialog) {
                    color: @darkTextColor;

                    a {
                      &[href] {
                        color: @accentColor;
                        text-decoration: underline;

                        &:hover {
                          text-decoration: none;
                        }
                      }
                    }
                    :where(label, label.col-form-label) {
                      color: @darkTextColor70;
                      font-size: 13px;
                      font-weight: 600;
                      margin-bottom: 0px;
                      line-height: 19px;
                      position: relative;

                      :where(input, select) {
                        border-radius: @roundedCorners !important;
                        border-color: transparent;
                        display: block;
                        width: 100%;
                        margin-top: 10px;
                        padding: 2px 4px;

                        & + .formio-button-reset {
                          color: transparent;
                          font-size: 12px;
                          font-weight: 400;
                          padding: 0;
                          background: transparent;
                          position: absolute;
                          inset-inline-end: 0;
                          bottom: 6px;
                          height: auto;
                          line-height: 1;

                          &::before {
                            font-family: 'Font Awesome 5 Pro';
                            font-weight: 300;
                            -moz-osx-font-smoothing: grayscale;
                            -webkit-font-smoothing: antialiased;
                            display: inline-block;
                            font-style: normal;
                            font-variant: normal;
                            text-rendering: auto;
                            line-height: 1;
                            content: "\f057";
                            color: @accentColor40;
                          }
                        }
                      }

                      &.radio {
                        padding-inline-start: 0;
                      }

                      &.field-required {
                        &::after {
                          content: " ";
                          display: inline-block;
                          background-color: @errorColor70;
                          vertical-align: unset;
                          width: 6px;
                          height: 6px;
                          border-radius: 5px;
                          position: relative;
                          top: -1px;
                          inset-inline-start: 2px;
                          min-width: 6px;
                        }
                        i {
                          margin-inline-start: 11px;

                          &::before {
                            margin-inline-start: 0.3rem;
                          }
                        }
                        &.control-label--hidden {
                          position: absolute;

                          &::after {
                            inset-inline-end: 10px;
                            inset-inline-start: initial;
                            position: absolute;
                            top: 10px;
                          }
                        }
                      }
                      i {
                        &.fa-question-circle {
                          margin-top: -4px;
                          margin-inline-end: -33px;
                          transform: translateY(1px);
                        }
                      }
                    }
                    input {
                      &.form-control {
                        &:where([type="text"], [type="search"], [type="phoneNumber"], [type="email"]) {
                          height: @fieldHeight;

                          &:where(:hover, :focus) {
                            outline: none;
                          }
                          &:disabled {
                            background-color: @pageBgColor;
                          }
                        }
                      }
                    }
                    .fa-question-circle {
                      &::before {
                        font-weight: 400;
                        font-size: 15px;
                        color: @accentColor;
                      }
                    }
                    .form-text {
                      &.text-muted {
                        &:not(:empty) {
                          margin-top: 0.6rem;
                          margin-bottom: 1rem;
                          font-size: 1.2rem;
                          text-align: start;
                          display: block;
                          line-height: 1.2;
                          color: @accentColor;
                        }
                      }
                    }
                    &:where(.has-error, .formio-error-wrapper) {
                      border-radius: @roundedCorners;

                      .formio-errors {
                        .form-text {
                          font-weight: 400;
                          margin-top: 0.6rem;
                          margin-bottom: 0;
                          font-size: 1.1rem;
                          text-align: start;
                          display: block;
                          line-height: 1.2;
                          white-space: break-spaces;

                          &.error {
                            color: @errorColor50;
                          }
                        }
                      }
                      &:focus-within {
                        animation: hglightComp 1s ease-out 0.15s;
                      }
                    }
                    .field-wrapper {
                      align-items: center;

                      label {
                        &.col-form-label {
                          margin-bottom: 0 !important;
                        }
                      }
                    }
                    &:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-url, .formio-component-address, .formio-component-datetime) {
                      input {
                        &.form-control {
                          &:where([type="text"], [type="phoneNumber"], [type="email"]) {
                            border: solid 1px @borderColor;
                            border-radius: @roundedCorners !important;
                            font-weight: 400;

                            &:where(:hover, :focus) {
                              outline: none !important;
                            }
                          }
                          & + .form-text.text-right {
                            font-size: 1.2rem;
                            padding-top: 4px;
                          }
                        }
                      }
                      .input-group {
                        height: @fieldHeight;

                        > div {
                          border: solid 1px @borderColor;
                          background-color: @whiteColor !important;

                          &.input-group-prepend {
                            border-radius: @roundedCorners 0 0 @roundedCorners !important;
                            color: @darkTextColor;
                            font-weight: 600;

                            & + input {
                              &.form-control {
                                border-top-left-radius: 0 !important;
                                border-bottom-left-radius: 0 !important;
                                border-inline-start: none !important;
                              }
                            }
                          }
                          &.input-group-append {
                            border-radius: 0 @roundedCorners @roundedCorners 0 !important;
                            color: @darkTextColor;
                            font-weight: 600;
                          }
                        }
                        input {
                          &.form-control {
                            &:first-child {
                              &:not(:last-child) {
                                border-top-right-radius: 0 !important;
                                border-bottom-right-radius: 0 !important;
                                border-inline-end: 0;
                              }
                            }
                            &:not(:first-child) {
                              &:not(:last-child) {
                                border-top-right-radius: 0 !important;
                                border-bottom-right-radius: 0 !important;
                                border-inline-end: none !important;
                              }
                            }
                          }
                        }
                        &.formio-multiple-mask-container {
                          display: flex;

                          .formio-multiple-mask-select {
                            width: fit-content;
                            max-width: 50%;
                            height: 40px !important;
                            border-radius: @roundedCorners 0 0 @roundedCorners !important;
                            border-inline-end: none;

                            & + .formio-multiple-mask-input {
                              border-radius: 0 @roundedCorners @roundedCorners 0 !important;
                            }
                            &:where(:hover, :focus, :active) {
                              box-shadow: none;
                              outline: none;
                              background-color: @accentColor5;
                            }
                          }
                        }
                      }
                      &.value-label-style {
                        input {
                          &.form-control {
                            border: none;
                            background-color: transparent !important;
                          }
                        }
                      }
                      &.field-plus-button {
                        input {
                          &.form-control {
                            border-top-right-radius: 0 !important;
                            border-bottom-right-radius: 0 !important;
                          }
                        }
                      }
                      &.has-error {
                        .input-group {
                          > div {
                            border-color: @errorColor30;
                          }
                          &.formio-multiple-mask-container {
                            .formio-multiple-mask-select {
                              border-color: @errorColor30 !important;
                            }
                          }
                        }
                        input {
                          &.form-control {
                            border-color: @errorColor30 !important;
                          }
                        }
                      }
                      label.col-form-label {
                        &:not(:first-child) {
                          margin-bottom: 0;
                          margin-top: 6px;
                        }
                      }
                    }
                    &.formio-component-day {
                      input.formio-select-autocomplete-input {
                        position: absolute;
                      }
                      .row {
                        .col {
                          flex: 1;

                          .form-control {
                            border-radius: @roundedCorners !important;
                          }
                        }
                      }
                    }
                    &.formio-component-select {
                      .formio-choices {
                        .selection {
                          &.dropdown {
                            border: solid 1px @borderColor;
                            border-radius: @roundedCorners !important;
                            height: @fieldHeight;
                            display: flex;
                            align-items: center;

                            &:where(:hover, :focus) {
                              outline: none;
                            }
                            &[disabled] {
                              background-color: #EFEFEF;

                              .choices__item--selectable {
                                padding-inline-end: 0px;
                              }
                            }
                          }
                        }
                        &.is-open {
                          .selection {
                            &.dropdown {
                              border-radius: @roundedCorners @roundedCorners 0 0 !important;
                            }
                          }
                        }
                        &::after {
                          color: @accentColor40;
                          content: "\F078";
                          font-family: 'Font Awesome 5 Pro';
                          font-weight: 300;
                          -moz-osx-font-smoothing: grayscale;
                          -webkit-font-smoothing: antialiased;
                          display: inline-block;
                          font-style: normal;
                          font-variant: normal;
                          text-rendering: auto;
                          font-size: 16px;
                          width: auto;
                          border: none;
                          margin-top: 0;
                          height: 8px;
                          line-height: 7px;
                          top: calc(50% - 4px);
                        }
                        &:hover {
                          &::after {
                            color: @accentColor;
                          }
                        }
                        .choices__list {
                          &.choices__list--dropdown {
                            border: solid 1px @borderColor;
                            border-radius: 0 0 @roundedCorners @roundedCorners;

                            input {
                              &.choices__input {
                                border-radius: 0 !important;
                                padding: 10px 14px;
                                font-size: 12px;

                                &:placeholder-shown {
                                  opacity: 0.5;
                                }
                              }
                            }
                            .choices__item {
                              &.choices__item--choice {
                                padding: 10px 14px;
                                font-size: 13px;
                                font-weight: 400;
                                color: @darkTextColor;
                                word-break: break-word;
                                white-space: break-spaces;
                                text-align: start;

                                &:where(.is-selected, :hover) {
                                  background-color: @accentColor5;
                                }
                                span {
                                  line-height: 1.2;

                                  mark {
                                    background-color: #FEFF00;
                                    padding: 0;
                                    display: inline-block;
                                    line-height: 1.1;
                                  }
                                }
                                > span {
                                  display: flex;
                                  gap: 12px;

                                  span {
                                    min-width: 30px;
                                    flex: 1;

                                    & + span {
                                      width: auto;
                                      flex: 10;
                                    }
                                  }
                                  &.value {
                                    > span {
                                      width: 100%;
                                    }
                                  }
                                }
                              }
                            }
                            .choices__list {
                              max-height: 22vh;
                            }
                          }
                          &--single {
                            .choices__item {
                              .value:not(:last-child) {
                                padding-inline-end: 15px;
                              }
                              &--selectable {
                                padding-right: initial;
                                padding-inline-end: 30px;

                                span.key {
                                  display: none;
                                }
                              }
                            }
                          }
                        }
                        &.is-disabled {
                          &::after {
                            display: none;
                          }
                        }
                      }
                      input.formio-select-autocomplete-input {
                        position: absolute;
                      }
                      &.has-error {
                        .formio-choices {
                          .selection {
                            &.dropdown {
                              border-color: @errorColor30 !important;
                            }
                          }
                        }
                      }
                      &:not(.formio-modified) {
                        .control-label--hidden {
                          &.field-required {
                            inset-inline-end: 26px;
                          }
                        }
                      }
                      &.formio-component-multiple {
                        .formio-choices {
                          .selection {
                            &.dropdown {
                              min-height: 40px;
                              height: auto;

                              .choices__input {
                                flex: 1;
                                background: transparent;
                                height: 28px !important;
                                margin-bottom: 0;
                              }
                              .choices__list--multiple {
                                margin-bottom: -6px;
                                max-width: 70%;
                                flex: auto;

                                &:empty {
                                  flex: 0;
                                }
                                :where(.choices__item--selectable, .choices__item.is-highlighted) {
                                  overflow: hidden;
                                  max-width: calc(100% - 28px);
                                  min-width: 0px;
                                  position: relative;
                                  background-color: @accentColor10Solid;
                                  color: @accentColor;
                                  font-weight: 400;
                                  border-radius: @roundedCorners;
                                  padding-block: 4px;
                                  padding-inline-start: 7px;
                                  padding-inline-end: 28px;
                                  border-color: @accentColor20;

                                  > span {
                                    text-overflow: ellipsis;
                                    font-size: 15px;
                                  }
                                  button.choices__button {
                                    position: absolute;
                                    inset-inline-end: 0;
                                    margin: 0;
                                    border: none;
                                    border-radius: 0 4px 4px 0;
                                    display: flex;
                                    top: 0;
                                    height: 100%;
                                    box-shadow: -1px 0 0 @accentColor40;
                                    filter: brightness(0.5);
                                  }
                                  &[data-value="[]"] {
                                    display: none;
                                  }
                                }
                                .choices__item.is-highlighted {
                                  background-color: @accentColor;
                                  color: @whiteColor;

                                  button.choices__button {
                                    filter: brightness(1);
                                  }
                                }
                              }
                            }
                          }
                          &::after {
                            position: absolute;
                            inset-inline-end: 14px;
                          }
                        }
                      }
                      &.field-plus-button {
                        .formio-choices {
                          .selection {
                            &.dropdown {
                              border-radius: @roundedCorners 0 0 @roundedCorners !important;
                            }
                          }
                        }
                      }
                      &.align-value-right {
                        .formio-choices .selection.dropdown {
                          flex-direction: row-reverse;

                          .choices__list--multiple {
                            text-align: end;
                            padding-inline-end: 25px;
                          }
                        }
                        .choices__item {
                          text-align: end;
                        }
                      }
                    }
                    &.formio-component-datetime {
                      .input-group {
                        input {
                          &.form-control.input {
                            &:not([disabled]) {
                              border: solid 1px @borderColor;
                              border-radius: @roundedCorners 0 0 @roundedCorners !important;
                              border-inline-end: none;
                              padding-inline-end: 0;
                            }
                            &[type="text"][disabled] {
                              border-radius: @roundedCorners !important;
                              border-inline-end: solid 1px @borderColor !important;

                              & ~ .input-group-append {
                                display: none;
                              }
                            }
                          }
                        }
                        .input-group-append {
                          border: solid 1px @borderColor;
                          border-radius: 0 @roundedCorners @roundedCorners 0 !important;
                          height: @fieldHeight;
                          border-inline-start: none;
                          cursor: pointer;

                          .input-group-text {
                            display: flex;
                            height: 100%;
                            align-items: center;
                            border-radius: 0 6px 6px 0;
                            border: none;

                            i {
                              &::before {
                                font-family: "Font Awesome 5 Pro";
                                font-weight: 400;
                                -moz-osx-font-smoothing: grayscale;
                                -webkit-font-smoothing: antialiased;
                                display: inline-block;
                                font-style: normal;
                                font-variant: normal;
                                text-rendering: auto;
                                line-height: 1;
                                content: "\f073";
                                color: @accentColor40;
                              }
                            }
                          }
                        }
                      }
                      &:hover {
                        .input-group {
                          .input-group-append {
                            .input-group-text {
                              i {
                                &::before {
                                  color: @accentColor;
                                }
                              }
                            }
                          }
                        }
                      }
                      &.has-error {
                        .input-group {
                          input {
                            &.form-control.input {
                              border-color: @errorColor30 !important;
                            }
                          }
                          .input-group-append {
                            border-color: @errorColor30 !important;
                          }
                        }
                      }
                      &.value-label-style {
                        input {
                          &:where(.form-control, .input) {
                            border: none !important;

                            &:where([disabled], :disabled) {
                              background-color: transparent !important;
                            }
                          }
                        }
                        .input-group-append {
                          display: none;
                        }
                      }
                    }
                    &.formio-component-radio {
                      .form-radio {
                        &.radio {
                          padding-top: 10px;
                          margin-top: 0;

                          :where(.form-check, .form-check-inline) {
                            cursor: pointer;
                            padding-bottom: 11px;
                            margin-top: 0;
                            margin-bottom: 0;

                            label {
                              margin-top: 0;

                              &::before {
                                display: none;
                              }
                              input {
                                &[type="radio"] {
                                  appearance: inherit;
                                  padding: 0;
                                  margin: 3px 0 0;

                                  &::before {
                                    -webkit-font-smoothing: antialiased;
                                    display: inline-block;
                                    font-style: normal;
                                    font-variant: normal;
                                    text-rendering: auto;
                                    line-height: 1;
                                    font-family: "Font Awesome 5 Pro";
                                    font-weight: 400;
                                    font-size: @defaultTextSize;
                                    content: "\f111";
                                    color: @accentColor50;
                                  }
                                  & + span {
                                    font-size: @defaultTextSize;
                                    font-weight: 500;
                                    color: @accentColor50;
                                    margin-inline-start: 6px;
                                    position: relative;
                                    top: 2px;
                                    line-height: 14px;
                                  }
                                  &:where([checked], :checked) {
                                    &::before {
                                      content: "\f192" !important;
                                      font-weight: 900;
                                      color: @accentColor !important;
                                    }
                                    & + span {
                                      color: @accentColor !important;
                                    }
                                  }
                                  &:focus {
                                    outline: none;
                                  }
                                  &[disabled] {
                                    border-radius: 50%;
                                  }
                                }
                              }
                              &:hover {
                                input {
                                  &[type="radio"] {
                                    appearance: inherit;

                                    &::before {
                                      color: @accentColor !important;
                                    }
                                    & + span {
                                      color: @accentColor !important;
                                    }
                                  }
                                }
                              }
                              &.label-position-left {
                                span {
                                  margin-inline-end: 3px;
                                }
                                input.form-check-input {
                                  position: initial;
                                }
                              }
                            }
                          }
                          .radio.form-check {
                            label[class*="label-position-"] {
                              display: flex;

                              input.form-check-input {
                                width: initial;
                                margin: 2px;
                              }
                            }
                          }
                        }
                      }
                      &.radio-switch {
                        .form-radio {
                          &.radio {
                            height: @fieldHeight;
                            border-radius: @roundedCorners;
                            border: solid 1px @borderColor;
                            width: fit-content !important;
                            width: -moz-fit-content !important;
                            padding-top: 0;
                            display: flex;

                            :where(.form-check-inline, .form-check) {
                              margin: 0;
                              flex: auto;
                              height: 100%;
                              padding: 0;
                              display: flex;
                              align-items: center;

                              label {
                                &.form-check-label {
                                  display: flex;
                                  justify-content: center;
                                  height: 100%;
                                  padding-inline-start: 0;

                                  input {
                                    &[type="radio"] {
                                      display: none;

                                      &:checked {
                                        & + span {
                                          background-color: @accentColor70Solid;
                                          color: @whiteColor !important;
                                          border: solid 1px @accentColor70Solid;
                                          margin: -1px;
                                          height: calc(100% + 2px);
                                          width: calc(100% + 2px);
                                        }
                                      }
                                    }
                                  }
                                  span {
                                    margin: 0;
                                    display: flex;
                                    height: 100%;
                                    align-items: center;
                                    cursor: pointer;
                                    padding: 1px 15px;
                                    width: 100%;
                                    top: 0;
                                    border-inline-end: solid 1px @borderColor;

                                    &:hover {
                                      background-color: @accentColor10;
                                    }
                                  }
                                }
                              }
                              &:first-child {
                                label {
                                  &.form-check-label {
                                    span {
                                      border-radius: @roundedCorners 0 0 @roundedCorners;
                                    }
                                  }
                                }
                              }
                              &:last-child {
                                label {
                                  &.form-check-label {
                                    span {
                                      border-radius: 0 @roundedCorners @roundedCorners 0;
                                      border-inline-end: none;
                                    }
                                  }
                                }
                              }
                              &.radio-selected {
                                label {
                                  &.form-check-label span {
                                    background-color: @accentColor70;
                                    color: @whiteColor !important;
                                  }
                                }
                              }
                            }
                          }
                        }
                        &.required {
                          label {
                            &.field-required {
                              &.control-label--hidden {
                                display: none;

                                &::after {
                                  display: none;
                                }
                              }
                              &:not(.control-label--hidden) {
                                & + .form-radio.radio {
                                  &::before {
                                    display: none;
                                  }
                                }
                              }
                            }
                          }
                          .form-radio {
                            &.radio {
                              &::before {
                                content: " ";
                                display: inline-block;
                                background-color: @errorColor70;
                                width: 6px;
                                height: 6px;
                                border-radius: 5px;
                                position: absolute;
                                inset-inline-end: -16px;
                                top: calc(50% - 3px);
                              }
                              &::after {
                                display: none !important;
                              }
                            }
                          }
                        }
                        label {
                          &.control-label {
                            & + .input-group {
                              .radio-inline {
                                margin-top: 0;

                                label {
                                  span {
                                    top: 0;
                                  }
                                }
                              }
                            }
                          }
                          &.form-check-label {
                            margin-bottom: 0;
                          }
                        }
                      }
                      &:not(.radio-switch) {
                        .form-radio {
                          &.radio {
                            .form-check:last-child {
                              padding-bottom: 0px;
                            }
                          }
                        }
                        &.required {
                          label.field-required.control-label--hidden + .form-radio.radio {
                            .radio {
                              &.form-check {
                                label.form-check-label {
                                  &::after {
                                    content: " ";
                                    display: inline-block;
                                    background-color: @errorColor70;
                                    vertical-align: unset;
                                    width: 6px;
                                    height: 6px;
                                    border-radius: 5px;
                                    margin-inline-start: 5px;
                                    min-width: 6px;
                                    align-self: center;
                                    position: relative;
                                    top: 1px;
                                  }
                                }
                              }
                              &.form-check-inline {
                                &:last-child {
                                  &::after {
                                    content: " ";
                                    display: inline-block;
                                    background-color: @errorColor70;
                                    vertical-align: unset;
                                    width: 6px;
                                    height: 6px;
                                    border-radius: 5px;
                                    margin-inline-start: 5px;
                                    min-width: 6px;
                                    position: absolute;
                                    inset-inline-end: -12px;
                                    top: 7px;
                                  }
                                }
                              }
                            }
                          }
                          &.formio-modified {
                            label.field-required.control-label--hidden + .form-radio.radio {
                              .radio {
                                &.form-check {
                                  label.form-check-label {
                                    &::after {
                                      content: none;
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                      &.top-label-space {
                        &.formio-component-label-hidden {
                          padding-top: 13px;
                        }
                      }
                      &.remove-top-space {
                        .form-radio {
                          padding-top: 0;
                        }
                      }
                      label {
                        &.field-required {
                          &.control-label--hidden {
                            &::after {
                              display: none;
                            }
                          }
                        }
                      }
                      .field-wrapper {
                        .field-label--right {
                          label.col-form-label {
                            display: flex;
                            justify-content: flex-end;
                          }
                        }
                      }
                    }
                    &.formio-component-button {
                      button {
                        &.btn {
                          border-radius: @roundedCorners !important;
                          background-color: @buttonAccentColor;
                          padding-inline-start: 25px;
                          padding-inline-end: 25px;
                          font-weight: 500;
                          text-transform: none;
                          position: relative;
                          z-index: 1;
                          padding-top: 12px;
                          padding-bottom: 12px;
                          line-height: 1;
                          color: @whiteColor;
                          text-align: center;
                          font-size: 16px;
                          gap: 8px;

                          &.btn-sm {
                            min-height: @fieldHeight;
                          }
                          &.btn-xs {
                            padding: 0.9rem 1rem;
                          }
                          .fa-cog {
                            position: absolute;
                            animation: none;
                            background: inherit;
                            width: 100%;
                            z-index: 9;
                            color: transparent;

                            & + span {
                              position: absolute !important;
                              top: -7px;
                              z-index: 99;
                              border-radius: 50%;
                              width: 8px;
                              height: 8px;
                              -webkit-animation-fill-mode: both;
                              animation-fill-mode: both;
                              -webkit-animation: load7 1.8s infinite ease-in-out;
                              animation: load7 1.8s infinite ease-in-out;
                              color: #ffffff;
                              font-size: 10px;
                              margin: 0 auto;
                              position: relative;
                              text-indent: -9999em;
                              -webkit-transform: translateZ(0);
                              -ms-transform: translateZ(0);
                              transform: translateZ(0);
                              -webkit-animation-delay: -0.16s;
                              animation-delay: -0.16s;

                              &::after,
                              &::before {
                                border-radius: 50%;
                                width: 8px;
                                height: 8px;
                                -webkit-animation-fill-mode: both;
                                animation-fill-mode: both;
                                -webkit-animation: load7 1.8s infinite ease-in-out;
                                animation: load7 1.8s infinite ease-in-out;
                                content: '';
                                position: absolute;
                                top: 0;
                              }
                              &::before {
                                inset-inline-start: -16px;
                                -webkit-animation-delay: -0.32s;
                                animation-delay: -0.32s;
                              }
                              &::after {
                                inset-inline-start: 16px;
                              }
                            }
                          }
                          &:hover {
                            box-shadow: 0 9px 20px @accentColor40 !important;
                            opacity: 0.9;
                          }
                          &.btn-green {
                            background-color: @successNotice;
                          }
                          &.btn-blue {
                            background-color: @infoNotice;
                          }
                          &.btn-orange {
                            background-color: @warningNotice;
                          }
                          &.btn-red {
                            background-color: @errorNotice;
                          }
                          .fa-question-circle {
                            color: @whiteColor;

                            &::before {
                              color: inherit;
                            }
                          }
                        }
                      }
                      &:is(.field-plus-button) {
                        button {
                          &.btn {
                            border-top-left-radius: 0 !important;
                            border-bottom-left-radius: 0 !important;
                            margin-inline-start: -24px;
                            transition-duration: 0s;
                          }
                        }
                      }
                      &.action-progress {
                        button {
                          font-family: 'Nunito Sans', sans-serif !important;
                        }
                        &.cog-during-form-submit {
                          button {
                            font-family: 'Nunito Sans', sans-serif !important;

                            &::before {
                              font-family: 'Font Awesome 5 Pro' !important;
                              font-weight: 300;
                              -moz-osx-font-smoothing: grayscale;
                              -webkit-font-smoothing: antialiased;
                              display: inline-block;
                              font-style: normal;
                              font-variant: normal;
                              text-rendering: auto;
                              line-height: 1;
                              -webkit-animation: buttonProgress 2s linear infinite;
                              animation: buttonProgress 2s linear infinite;
                              margin-inline-end: 8px;
                              content: "\f013";
                            }
                          }
                        }
                        &:not(.cog-during-form-submit) {
                          button {
                            &::before {
                              display: none;
                            }
                          }
                        }
                      }
                      &.action-success {
                        &.success-disabled {
                          button {
                            cursor: not-allowed;
                            color: @dark;
                            background: @main-bg;
                            pointer-events: none;
                          }
                        }
                      }
                      &.btn-secondary {
                        button {
                          &.btn {
                            background-color: @accentColor10;
                            color: @accentColor;

                            &:hover {
                              background-color: @accentColor5;
                              color: @accentColor;
                            }
                          }
                        }
                      }
                    }
                    &.formio-component-htmlelement {
                      :is(h1, h2, h3, h4, h5, h6) {
                        line-height: 1.3;
                        padding: 0;
                        margin: 0;
                      }
                      h2 {
                        font-size: calc(12pt * 2);
                      }
                      h3 {
                        font-size: calc(12pt * 1.8);
                      }
                      h4 {
                        font-size: calc(12pt * 1.6);
                      }
                      p {
                        font-size: @defaultTextSize !important;
                        color: @darkTextColor !important;
                        margin-top: 0;
                        margin-bottom: 0;

                        span {
                          font-size: @defaultTextSize;
                          color: @darkTextColor;

                          &.text-tiny {
                            font-size: 9px;

                            * {
                              font-size: 9px;
                            }
                            span {
                              background-color: transparent !important;
                            }
                          }
                          &.text-small {
                            font-size: 11px;

                            * {
                              font-size: 11px;
                            }
                          }
                          &.text-big {
                            font-size: 18px;

                            * {
                              font-size: 18px;
                            }
                          }
                          &.text-huge {
                            font-size: 24px;

                            * {
                              font-size: 24px;
                            }
                          }
                          &.currency-prefixed {
                            margin-inline-end: 5px;
                          }
                          &.currency-suffixed {
                            &:not(:empty) {
                              margin-inline-start: 5px;
                            }
                          }
                        }
                        &:empty + p {
                          margin-top: 0;
                        }
                        &:first-child {
                          margin-top: 0;
                        }
                      }
                      strong {
                        font-weight: 700;
                      }
                      :is(ul, ol) {
                        margin-top: 0px;
                        margin-bottom: 0px;
                      }
                      ol {
                        list-style: none;
                        counter-reset: li;

                        li {
                          font-size: @defaultTextSize;
                          color: @darkTextColor;
                          padding-inline-start: 2.4rem;
                          position: relative;
                          line-height: 1.5;

                          &::before {
                            content: counter(li) + ".";
                            display: inline-block;
                            width: 1em;
                            inset-inline-start: 0;
                            counter-increment: li;
                            position: absolute;
                          }
                          span {
                            font-size: @defaultTextSize;
                            color: @darkTextColor;

                            &.text-tiny {
                              font-size: 12px;

                              * {
                                font-size: 12px;
                              }
                              span {
                                background-color: transparent !important;
                              }
                            }
                            &.text-small {
                              font-size: 14px;

                              * {
                                font-size: 14px;
                              }
                            }
                            &.text-big {
                              font-size: 20px;

                              * {
                                font-size: 20px;
                              }
                            }
                            &.text-huge {
                              font-size: 24px;

                              * {
                                font-size: 24px;
                              }
                            }
                          }
                        }
                      }
                      ul {
                        margin-top: 0;
                        padding-inline-start: 1.8rem;
                        padding-block: 0.6rem;

                        li {
                          font-size: @defaultTextSize;
                          color: @darkTextColor;
                          line-height: 1.5;
                        }
                      }
                      p:not(:empty) + ul, p:not(:empty) + ol {
                        margin-top: 0px;
                      }
                      table {
                        width: 100%;

                        tr {
                          td {
                            font-size: @defaultTextSize;
                            color: @darkTextColor;
                            border: solid 1px #ebebeb;
                            padding: 0 10px;

                            span {
                              &.text-tiny {
                                font-size: 9px;

                                * {
                                  font-size: 9px;
                                }
                                span {
                                  background-color: transparent !important;
                                }
                              }
                              &.text-small {
                                font-size: 11px;

                                * {
                                  font-size: 11px;
                                }
                              }
                              &.text-big {
                                font-size: 18px;

                                * {
                                  font-size: 18px;
                                }
                              }
                              &.text-huge {
                                font-size: 24px;

                                * {
                                  font-size: 24px;
                                }
                              }
                            }
                          }
                        }
                      }
                      blockquote {
                        background: @whiteColor !important;
                        border-inline-start: solid 3px;
                        border-radius: 0 @roundedCorners @roundedCorners 0;
                        padding: 0 2rem !important;
                        margin: 0;
                        border-left-color: @accentColor !important;

                        &::after {
                          display: none;
                        }
                      }
                      &:where(.notice, .info-notice, .success-notice, .warning-notice, .error-notice) {
                        background: @darkTextColor3 !important;
                        border-inline-start: solid 4px;
                        border-radius: 0 @roundedCorners @roundedCorners 0;
                        padding: 1.5rem 2rem;
                      }
                      &.notice {
                        border-inline-start-color: @accentColor;

                        &.fill-background {
                          background-color: @accentColor5 !important;

                          * {
                            color: @accentColor !important;
                          }
                        }
                      }
                      &.info-notice {
                        border-inline-start-color: @infoNotice;

                        &.fill-background {
                          background-color: @infoNotice5 !important;

                          * {
                            color: @infoNotice !important;
                          }
                        }
                      }
                      &.success-notice {
                        border-inline-start-color: @successNotice;

                        &.fill-background {
                          background-color: @successNotice5 !important;

                          * {
                            color: @successNotice !important;
                          }
                        }
                      }
                      &.warning-notice {
                        border-inline-start-color: @warningNotice;

                        &.fill-background {
                          background-color: @warningNotice5 !important;

                          * {
                            color: @warningNotice !important;
                          }
                        }
                      }
                      &.error-notice {
                        border-inline-start-color: @errorNotice;

                        &.fill-background {
                          background-color: @errorNotice5 !important;

                          * {
                            color: @errorNotice !important;
                          }
                        }
                      }
                      &:not(.notice) {
                        &:not(.info-notice) {
                          &:not(.success-notice) {
                            &:not(.warning-notice) {
                              &:not(.error-notice) {
                                &:first-child {
                                  margin: -5px 0;
                                }
                              }
                            }
                          }
                        }
                      }
                      &.alert {
                        &.formio-hidden {
                          & + .formio-component {
                            &:not(.formio-hidden) {
                              margin-top: 0;
                            }
                          }
                        }
                        &[class*="reason"] {
                          margin-top: 5px;
                          width: fit-content;
                          max-width: 100%;

                          * {
                            color: @errorNotice !important;
                            font-size: 14px !important;
                            font-weight: 600;
                          }
                          &.editgrid-validation-reason {
                            margin-bottom: -18px !important;
                            box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.15);

                            &::before {
                              content: "" !important;
                              display: block !important;
                              width: 0px;
                              height: 0px;
                              border-width: 10px 8px 0px 8px;
                              border-color: @errorNotice5Solid transparent transparent;
                              border-style: solid solid none;
                              position: absolute;
                              z-index: 9;
                              bottom: -10px;
                              filter: drop-shadow(0px 3px 2px rgba(0, 0, 0, 0.15));
                            }
                          }
                          &:first-child {
                            margin: 0 !important;
                          }
                          &:not(.formio-hidden) ~ .formio-component-file {
                            margin-top: 1rem !important;
                          }
                        }
                        &.alert-danger {
                          border-inline-start: solid 3px @errorNotice !important;
                          border-radius: 0 @roundedCorners @roundedCorners 0;
                          padding: 0.5rem 1rem !important;
                          background-color: @errorNotice5Solid !important;
                          max-width: 100%;
                          font-weight: 600 !important;
                          color: @errorNotice !important;

                          &.general-rejection-reason {
                            padding: 2rem !important;
                            width: 100%;

                            :where(p, p span) {
                              font-weight: 600;
                              font-size: 16px !important;
                              color: @errorNotice !important;
                            }
                            p + div {
                              font-weight: 600;
                              margin: 5px 0;
                            }
                            div {
                              line-height: 1.4;
                              margin-bottom: 4px;

                              &:empty {
                                display: none;
                              }
                              &:last-of-type {
                                margin-bottom: 0;
                              }
                            }
                          }
                          &[class*="RejectionReasons"] {
                            padding: 2rem !important;
                            color: @errorNotice !important;

                            div + div:not(:empty) {
                              margin-top: 0.6rem;
                            }
                          }
                          &[class*="documentRejectionReasons"] {
                            p + div {
                              font-size: 16px;
                              font-weight: 600;
                            }
                          }
                          &::after {
                            display: none !important;
                          }
                        }
                      }
                    }
                    &.formio-component-textarea {
                      textarea {
                        &.form-control {
                          border: solid 1px @borderColor;
                          border-radius: @roundedCorners !important;

                          &:where(:hover, :focus) {
                            outline: none;
                          }
                          &::-webkit-resizer {
                            display: none;
                          }
                        }
                      }
                      &.has-error {
                        textarea {
                          &.form-control {
                            border-color: @errorColor30 !important;
                          }
                        }
                      }
                      .card.card-body {
                        margin-bottom: 0 !important;
                        padding: 0 !important;
                        background-color: transparent;
                        box-shadow: none;

                        .formio-editor-read-only-content {
                          min-height: @fieldHeight;
                          line-height: 1.84615385;
                          padding: 4.5px 14px;
                          font-size: 14px;
                          background-color: #EFEFEF;
                          letter-spacing: 0.4pt !important;
                          color: #313335;
                          border: solid 1px @borderColor;
                          border-radius: @roundedCorners !important;
                        }
                      }
                      &.field-plus-button {
                        textarea {
                          &.form-control {
                            border-top-right-radius: 0 !important;
                          }
                        }
                      }
                    }
                    &.formio-component-selectboxes {
                      .form-radio {
                        &.radio {
                          padding-top: 13px;
                          margin-top: 0;

                          :where(.form-check, .form-check-inline) {
                            cursor: pointer;
                            padding-bottom: 10px;
                            margin-top: 0;
                            margin-bottom: 0;

                            label {
                              margin-top: 0;
                              gap: 6px;

                              input {
                                &[type="checkbox"] {
                                  appearance: inherit;
                                  inset-inline-start: initial !important;
                                  width: initial;
                                  padding: 0;
                                  margin: 0;

                                  &::before {
                                    -webkit-font-smoothing: antialiased;
                                    display: inline-block;
                                    font-style: normal;
                                    font-variant: normal;
                                    text-rendering: auto;
                                    line-height: 1;
                                    font-family: "Font Awesome 5 Pro";
                                    font-weight: 400;
                                    font-size: @defaultTextSize;
                                    content: "\F0C8";
                                    color: @accentColor50;
                                    margin-top: 1px;
                                  }
                                  & + span {
                                    font-size: @defaultTextSize;
                                    font-weight: 600;
                                    color: @accentColor50;
                                    margin-inline-start: 3px;
                                    position: relative;
                                    top: 1px;
                                    line-height: 14px;
                                    padding-inline-start: 0;

                                    &::before {
                                      display: none;
                                    }
                                  }
                                  &:checked {
                                    &::before {
                                      content: "\F14A";
                                      font-weight: 900;
                                      color: @accentColor;
                                    }
                                    & + span {
                                      color: @accentColor;
                                    }
                                  }
                                  &:focus {
                                    outline: none;
                                  }
                                }
                                &[disabled] {
                                  background-color: transparent !important;
                                }
                              }
                              &:hover {
                                input {
                                  &[type="radio"] {
                                    appearance: inherit;

                                    &::before {
                                      color: @accentColor;
                                    }
                                    & + span {
                                      color: @accentColor;
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                      &.top-label-space {
                        &.formio-component-label-hidden {
                          padding-top: 13px;
                        }
                      }
                      &.remove-top-space {
                        .form-radio {
                          padding-top: 0;
                        }
                      }
                    }
                    &.formio-component-editgrid {
                      min-width: 100%;

                      .col-form-label {
                        & + .list-group {
                          margin-top: 15px;
                        }
                      }

                      .list-group {
                        border: none;
                        border-radius: @roundedCorners @roundedCorners 0 0;

                        .list-group-item {
                          &.list-group-header {
                            border-bottom: none;
                            background-color: @accentColor10;
                            border-radius: @roundedCorners @roundedCorners 0 0 !important;

                            .row {
                              box-shadow: 230px 0 0 @accentColor10Solid, 230px 0px 0 @accentColor10Solid;
                              margin-inline: 0;

                              > div {
                                border-inline-end: none;
                                color: @accentColor;
                                font-weight: 600;
                                font-size: 13px;
                                padding-top: 10px;
                                padding-bottom: 10px;
                                min-height: 40px;

                                &.radio {
                                  label {
                                    padding-inline-start: 0;
                                    min-height: auto;
                                  }
                                }

                                .formio-button-more {
                                  // width: 100%;
                                  background: transparent;
                                  text-align: center;
                                  padding: 4px 12px;
                                  .fa {
                                    margin: 0;
                                  }
                                }
                              }
                            }
                            & + .list-group-footer {
                              margin-top: 8px;
                            }
                            & ~ .list-group-item {
                              > .row {
                                > div {
                                  padding-top: 16px;
                                  padding-bottom: 16px;

                                  .btn-icon-toggle {
                                    color: @accentColor40;

                                    &:hover {
                                      color: @accentColor;
                                    }
                                  }
                                  &:last-child[class="col-sm-2"] {
                                    flex: 0;
                                    min-width: 64px;
                                    width: 64px;
                                  }
                                  &.number {
                                    text-align: end;
                                  }
                                }
                              }
                              &.list-group-footer {
                                > .row {
                                  margin-inline: 0;
                                  
                                  > div {
                                    color: @darkTextColor;
                                    font-weight: 600;
                                    padding-top: 6px;
                                    padding-bottom: 6px;

                                    &.alert {
                                      flex: 1;
                                      margin-bottom: 8px !important;
                                      min-height: 20px;
                                      display: flex;
                                      align-items: center;

                                      &::after {
                                        height: calc(100% - 4px) !important;
                                        top: 2px !important;
                                      }
                                    }
                                  }
                                }
                              }
                            }
                            & + .list-group-item {
                              border-top: none;
                            }
                          }
                          > .row:not(.formio-component-columns) > div {
                            max-width: initial;
                            margin: 0;
                          }
                          &.list-group-footer {
                            .alert {
                              &::after {
                                display: none !important;
                              }
                            }
                            > [class="row"] {  
                              > [class*="col-"] {
                                border-bottom: none;
                              }
                            }
                          }
                          > [class="row"] {
                            // display: grid;
                            // grid-auto-flow: column;
                            // grid-template-columns: repeat(auto-fit, ~"max(calc((100% - 64px) / (var(--eg-cols) - 1)), 200px)") !important;

                            > [class*="col-"] {
                              height: 100%;
                            }
                          }
                          &:not(.list-group-header, .list-group-footer) {
                            > [class="row"] {
                              margin-inline: 0;
                              border-radius: 0;

                              > [class*="col-"] {
                                min-height: 50px;
                                border-bottom: none;

                                &.select {
                                  span.key {
                                    display: none;
                                  }
                                  span.value {
                                    display: -webkit-box;
                                    -webkit-line-clamp: 3;
                                    -webkit-box-orient: vertical;
                                    overflow: hidden;

                                    &:hover {
                                      -webkit-line-clamp: 100;
                                      animation-name: fullClamp;
                                      animation-delay: 0.75s;
                                      animation-timing-function: ease-in-out;
                                      animation-fill-mode: backwards;
                                      animation-duration: 0.3s;
                                    }
                                  }
                                }
                              }
                              &::after {
                                position: absolute;
                                display: block;
                                content: "";
                                height: 1px;
                                background-color: #ebebeb;
                                bottom: 0;
                                left: 0px;
                                right: 0px;
                              }
                            }
                          }
                        }
                        &.has-open-row {
                          overflow: initial;

                          .list-group-item {
                            max-width: 100%;

                            &:is(.list-group-header, .list-group-footer) {
                              overflow: hidden;
                              max-width: 100%;

                              > .row {
                                > [class^="col-"] {
                                  max-width: 100%;
                                }
                              }
                            }
                            &:not(.list-group-footer) {
                              &:not(.list-group-header) {
                                &:not(:last-child) {
                                  &:not(:nth-last-child(2)) {
                                    overflow: hidden;
                                    max-width: 100%;
                                    min-height: 50px;

                                    > .row {
                                      margin-inline-start: -12px;
                                      margin-inline-end: -12px;

                                      > [class^="col-"] {
                                        &:first-of-type {
                                          padding-inline-start: 12px;
                                        }
                                      }
                                    }
                                  }
                                }
                                &:last-child {
                                  > .row {
                                    margin-inline-start: -12px;
                                    margin-inline-end: -12px;

                                    > [class^="col-"] {
                                      &:first-of-type {
                                        padding-inline-start: 12px;
                                      }
                                    }
                                  }
                                }
                                .row {
                                  [class^="col-"] {
                                    padding-block: 0;
                                  }
                                }
                              }
                            }
                            .formio-component-file {
                              ul.list-group {
                                li.list-group-item {
                                  > .row {
                                    > .col-md-1 {
                                      flex: 0;
                                      min-width: 40px;
                                    }
                                    > .col-md-9 {
                                      flex: 3;
                                    }
                                    > .col-md-2 {
                                      flex: 1;
                                      display: flex;
                                      justify-content: flex-start;
                                    }
                                  }
                                }
                              }
                            }
                            &:not(.list-group-header, .list-group-footer) {
                              > [class="row"] {  
                                overflow: hidden;

                                > [class*="col-"] {
                                  display: flex;
                                  align-items: center;

                                  &.number {
                                    justify-content: flex-end;
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                      > button {
                        &.btn {
                          border: none;
                          border-radius: @roundedCorners !important;
                          color: @accentColor;
                          background-color: @accentColor10;
                          padding: 0.9rem 2.5rem;
                          font-weight: 600;
                          font-size: 12px;
                          display: inline-flex;
                          align-items: center;
                          transition-duration: 0.3s;
                          height: 40px;

                          > i {
                            display: none;
                          }
                          &:hover {
                            color: @whiteColor;
                            background-color: @accentColor;
                            box-shadow: 0 9px 20px @accentColor40 !important;
                          }
                        }
                      }
                      .editgrid-actions {
                        .btn {
                          border: none;
                          border-radius: @roundedCorners !important;
                          padding: 0.9rem 2.5rem;
                          font-weight: 400;
                          font-size: 12px;
                          display: inline-flex;
                          align-items: center;
                          transition-duration: 0.3s;
                          height: 40px;
                          &:hover {
                            box-shadow: 0 9px 20px @accentColor40 !important;
                          }
                        }
                      }
                      &.search-result-list {
                        .editgrid-listgroup {
                          .list-group-item {
                            .row {
                              .col-sm-2:last-child {
                                .btn-group {
                                  // display: none;
                                  background: transparent;
                                }
                              }
                            }
                          }
                        }
                      }
                      &.disabled-eg {
                        ul.list-group {
                          li.list-group-item {
                            > [class="row"] {
                              grid-template-columns: repeat(auto-fit, ~"max(calc(100% / var(--eg-cols)))") !important;
                              display: grid;

                              > div:last-of-type {
                                max-width: initial;
                                width: initial;
                                text-align: start;
                              }
                            }
                            &.list-group-header {
                              > [class="row"] {
                                > div:last-of-type {
                                  padding-inline: 12px !important;
                                  padding-block: 10px !important;
                                }
                              }
                            }
                            &:not(.list-group-header, .list-group-footer) {
                              > [class="row"] {
                                > div:last-of-type {
                                  padding-inline: 12px !important;
                                  padding-block: 16px !important;
                                  position: initial;
                                  display: initial;
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                    &.formio-component-datagrid {
                      > table {
                        &.table {
                          &.datagrid-table {
                            border: none;
                            width: 100%;

                            thead {
                              opacity: 1;

                              tr {
                                th {
                                  border: none;
                                  background-color: @accentColor10;
                                  color: @accentColor;
                                  font-weight: 600;
                                  font-size: 12px;
                                  letter-spacing: initial;
                                  text-transform: none;
                                  padding-block: 1rem;
                                  padding-inline-start: 14px;
                                  padding-inline-end: 0px;
                                  vertical-align: bottom;
                                  min-width: calc(100% / var(--eg-cols));
                                  display: table-cell;

                                  &:first-child {
                                    border-radius: @roundedCorners 0 0 @roundedCorners;
                                    padding-block: 1rem;
                                    padding-inline-start: 14px;
                                    padding-inline-end: 0px;

                                    &:empty {
                                      padding: 0 !important;
                                      width: 32px;
                                    }
                                  }
                                  &:last-child {
                                    border-radius: 0 @roundedCorners @roundedCorners 0;
                                    padding-inline-end: 10px;
                                  }
                                  i {
                                    &.fa-question-circle {
                                      &::before {
                                        font-size: 15px;
                                      }
                                    }
                                  }
                                  &:not([class]) {
                                    width: 50px;
                                  }
                                  &.dg-action-col {
                                    width: 50px;
                                    padding: 0;
                                    vertical-align: top;
                                    text-align: end;

                                    .formio-button-more {
                                      // width: 100%;
                                      padding: 4px 12px;
                                      background: transparent;
                                    }
                                  }
                                  &.checkbox {
                                    label {
                                      text-align: center !important;
                                    }
                                    &:is(.datagrid-checkbox-hide-label, .datagrid-hide-column-label) {
                                      padding-inline: 7px;
                                      text-align: center;
                                    }
                                    input {
                                      &[type="checkbox"] {
                                        width: auto;
                                        margin-inline-start: calc(50% - 13px/2);
                                        position: relative;
                                        height: 27px;
                                      }
                                    }
                                  }
                                  &:is(.datagrid-hide-column-label, .button) {
                                    text-indent: -100vw;
                                    overflow: hidden;
                                  }
                                  &.select {
                                    min-width: 100px;
                                  }
                                  &.radio {
                                    label {
                                      padding-left: 0;
                                      min-height: auto;
                                    }
                                  }
                                  &.field-required {
                                    &::after {
                                      display: none;
                                    }
                                  }
                                  > label {
                                    span {
                                      line-break: anywhere;
                                    }
                                  }
                                }
                              }
                            }
                            tbody {
                              tr {
                                background-color: transparent;

                                td {
                                  background-color: transparent;
                                  border: none;
                                  padding: 0.75rem 0 0.75rem 14px;
                                  vertical-align: top;
                                  display: table-cell;
                                  min-width: calc(100% / var(--eg-cols));
                                  width: 100%;

                                  &:not([class*="width"]) {
                                    width: calc(100% / var(--eg-cols));
                                  }
                                  > .formio-component {
                                    &:first-child {
                                      margin-top: 0;
                                    }
                                  }
                                  &.radio {
                                    .form-radio {
                                      padding-top: 10px;
                                      margin-bottom: 0;
                                    }
                                    label {
                                      input[type="radio"] {
                                        padding: 0;
                                      }
                                    }
                                  }
                                  .formio-component {
                                    &.value-label-style {
                                      .input-group {
                                        height: auto;
                                        padding-inline-start: 14px;

                                        :where(.input-group-append, .input-group-prepend) {
                                          border: none;
                                          height: 15px;
                                          line-height: 15px;

                                          .input-group-text {
                                            padding: 0;
                                          }
                                        }
                                        .input-group-prepend {
                                          .input-group-text {
                                            padding-inline-end: 4px;
                                          }
                                        }
                                        input {
                                          &.form-control {
                                            padding-inline-start: 0;
                                          }
                                        }
                                      }
                                      input {
                                        &.form-control {
                                          height: 15px;
                                          border: none;
                                          border-radius: 0;
                                          padding-inline-start: 14px;

                                          &:disabled {
                                            background-color: transparent !important;
                                          }
                                        }
                                      }
                                      &.formio-component-datetime {
                                        .input-group {
                                          .input-group-append {
                                            display: none;
                                          }
                                        }
                                      }
                                    }
                                    &-checkbox {
                                      &:is(.datagrid-checkbox-hide-label, .datagrid-hide-column-label) {
                                        .form-check-label {
                                          span {
                                            color: transparent;
                                            padding: 0;
                                            white-space: nowrap;
                                            overflow: hidden;
                                            width: 100%;

                                            &::before {
                                              inset-inline-start: calc(50% - 7px);
                                            }
                                          }
                                          input.form-check-input:checked + span {
                                            color: transparent !important;
                                          }
                                        }
                                      }
                                    }
                                    &:is(.formio-component-select) {
                                      span.value {
                                        font-size: 14px;
                                      }
                                    } 
                                  }
                                  &:not([ref]) {
                                    width: 50px;
                                    text-align: center;
                                    :where(.formio-button-edit-row) {
                                      background-color: transparent;
                                      padding: 0 12px;
                                      margin-inline-start: auto;
                                      display: flex;
                                      margin-inline-end: 0;
                                      height: 40px;
                                      align-items: center;
                                      border: none;

                                      i {
                                        color: @accentColor40;
                                        font-size: 16px;
                                        font-weight: 400;
                                      }
                                      &:hover {
                                        i {
                                          color: @accentColor;
                                        }
                                      }
                                    }
                                    :where(.formio-button-remove-row, .formio-drag-button) {
                                      background-color: transparent;
                                      padding: 0 14px;
                                      margin-inline-start: auto;
                                      display: flex;
                                      margin-inline-end: 0;
                                      height: 40px;
                                      align-items: center;
                                      border: none;

                                      i {
                                        color: @errorColor30;
                                        font-size: 16px;
                                        font-family: 'Font Awesome 5 Pro';
                                        font-weight: 400;

                                        &::before {
                                          content: "\F00D";
                                        }
                                      }
                                      &:hover {
                                        i {
                                          color: @errorNotice;
                                        }
                                      }
                                    }
                                  }
                                  &:first-child {
                                    padding-inline-start: 0;
                                  }
                                  .formio-component-textarea {
                                    .card.card-body {
                                      margin-bottom: 0px !important;
                                      box-shadow: none;
                                      border: solid 1px rgba(110, 114, 150, 0.15);
                                      border-radius: 6px !important;
                                      font-weight: 600;
                                      min-height: 40px;
                                      letter-spacing: 0.4pt;
                                      text-align: start;
                                      background: #efefef !important;
                                      padding: 8.5px 14px;
                                      line-height: 1.5;
                                      font-size: 14px;
                                    }
                                  }
                                  &.checkbox {
                                    &:is(.datagrid-checkbox-hide-label, .datagrid-hide-column-label) {
                                      padding-inline: 7px !important;
                                      text-align: center;
                                    }
                                  }
                                  &.vertical-align-center {
                                    vertical-align: middle;
                                  }
                                }
                                &:first-child {
                                  td {
                                    padding-top: 1.5rem;
                                  }
                                }
                              }
                            }
                            tfoot {
                              tr {
                                td {
                                  padding: 0.75rem 0 0;
                                  border: none;

                                  button {
                                    &.btn {
                                      &.formio-button-add-row {
                                        border: none;
                                        border-radius: @roundedCorners !important;
                                        color: @accentColor;
                                        background-color: @accentColor10;
                                        padding: 0.9rem 14px;
                                        height: 40px;
                                        font-weight: 600;
                                        font-size: 12px;
                                        display: inline-flex;
                                        align-items: center;
                                        transition-duration: 0.3s;

                                        > i {
                                          display: none;
                                        }
                                        &:hover {
                                          color: @whiteColor;
                                          background-color: @accentColor;
                                          box-shadow: 0 9px 20px @accentColor40 !important;
                                          z-index: 999;
                                          position: relative;
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                            &.table-striped {
                              tbody {
                                tr {
                                  &:nth-of-type(odd) {
                                    td {
                                      background-color: @accentColor5;
                                    }
                                  }
                                }
                              }
                            }
                            &.table-hover {
                              tbody {
                                tr {
                                  &:hover {
                                    td {
                                      background-color: @accentColor5;
                                    }
                                  }
                                }
                              }
                            }
                            &.table-sm {
                              thead {
                                tr {
                                  th {
                                    padding-inline-start: 10px;
                                  }
                                }
                              }
                              tbody {
                                tr {
                                  td {
                                    padding: 1px 0;
                                    text-align: start;
                                    vertical-align: middle;

                                    button:not(.choices__button) {
                                      width: 100%;
                                      max-width: 100%;
                                      white-space: normal;

                                      &:hover {
                                        box-shadow: none !important;
                                      }
                                    }
                                    .formio-component {
                                      &.value-label-style {
                                        .input-group {
                                          :where(.input-group-append, .input-group-prepend) {
                                            margin-top: 16px;
                                          }
                                        }
                                        input {
                                          &.form-control {
                                            margin-top: 16px;
                                          }
                                        }
                                      }
                                      .form-control {
                                        padding-inline: 10px;
                                        font-size: 14px;
                                      }
                                      &.formio-component-checkbox .checkbox {
                                        padding-top: 7px;
                                      }
                                    }
                                  }
                                }
                              }
                            }
                            &[style*="table-layout: fixed;"] {
                              thead {
                                tr {
                                  th {
                                    &:not([class*="width"], .dg-action-col) {
                                      width: calc(100% / var(--eg-cols));
                                    }
                                    &.dg-action-col {
                                      width: 50px;
                                      padding: 0;
                                      vertical-align: top;
                                    }
                                  }
                                }
                              }
                            }
                            &[style*="--eg-cols:0;"] {
                              table-layout: fixed;

                              tbody tr td[ref] {
                                width: 100%;
                              }
                            }
                          }
                        }
                      }
                      &.has-footer {
                        table {
                          &.table {
                            &.table-sm {
                              tfoot {
                                tr {
                                  td {
                                    button {
                                      &.btn {
                                        &.formio-button-add-row {
                                          margin-bottom: -115px;
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                        tfoot {
                          tr {
                            td {
                              button {
                                &.btn {
                                  &.formio-button-add-row {
                                    margin-bottom: -125px;
                                  }
                                }
                              }
                              &[colspan] {
                                display: block;
                              }
                            }
                          }
                        }
                      }
                      .generate-columns(100);
                      .generate-columns(@n, @i: 5) when (@i =< @n) {
                        :is(th, td).width@{i} {
                          width: (@i * 100% / @n);
                          min-width: (@i * 100% / @n);
                          max-width: (@i * 100% / @n);
                        }
                        .generate-columns(@n, (@i + 5));
                      }
                    }
                    &.formio-component-tabs {
                      > .card {
                        background-color: transparent;
                        box-shadow: none;
                        border-radius: @roundedCorners !important;
                        padding: 0px;

                        > .card-header {
                          height: auto;
                          margin-bottom: -1px;
                          z-index: 99;
                          position: relative;

                          ul {
                            &.card-header-tabs {
                              background-color: transparent;
                              height: auto;
                              max-width: 100%;
                              min-width: 100px;
                              overflow-y: auto;

                              li {
                                &.nav-item {
                                  flex: 0;
                                  height: auto;
                                  margin-bottom: 0;

                                  &::before {
                                    display: none;
                                  }
                                  a {
                                    &.nav-link {
                                      font-size: 21px;
                                      color: @inactiveTabTextColor;
                                      font-weight: 600;
                                      padding-inline-start: 23px;
                                      padding-inline-end: 23px;
                                      border-top: none;
                                      border-top-left-radius: @roundedCorners;
                                      border-top-right-radius: @roundedCorners;
                                      line-height: 1;
                                      padding-top: 1.4rem;
                                      padding-bottom: 1.4rem;
                                      text-decoration: none;
                                      white-space: nowrap;
                                      text-transform: none;

                                      &.active {
                                        font-size: @guidePanelHeadingFontSize;
                                        font-weight: 600;
                                        color: @accentColor;
                                        background-color: @tabsComponentBgColor;
                                        border-radius: @roundedCorners @roundedCorners 0 0;
                                        border-style: solid solid none solid;
                                        border-width: 1px 1px 0 1px;
                                        border-color: @black5Color;
                                      }
                                    }
                                  }
                                  &.active {
                                    box-shadow: 0px 16px 20px @black3Color;
                                  }
                                }
                              }
                              &::-webkit-scrollbar {
                                width: 6px;
                                height: 6px;
                              }
                              &::-webkit-scrollbar-thumb {
                                border-radius: 4px;
                                background-color: @accentColor40;
                              }
                              &::-webkit-scrollbar-track {
                                background: @black5Color;
                              }
                              &:hover {
                                &::-webkit-scrollbar-thumb {
                                  background-color: @accentColor70;
                                }
                              }
                            }
                          }
                          & + .card-body {
                            &.tab-pane {
                              &.active {
                                border-top-left-radius: 0;
                              }
                            }
                          }
                        }
                        > .card-body {
                          &.tab-pane {
                            background-color: @tabsComponentBgColor;
                            box-shadow: none;
                            border-radius: @roundedCorners;
                            padding: 23px;
                            margin-top: 0;
                            flex-direction: column;
                            gap: 3rem;

                            > .formio-component {
                              margin-top: 0 !important;

                              &.formio-component-panel {
                                margin-top: 4rem;
                                margin-bottom: 0;

                                &:first-child {
                                  margin-top: 0;
                                }
                                > .card {
                                  box-shadow: none;
                                  border: none;
                                  background-color: @whiteColor;

                                  > .card-header {
                                    margin: 0;
                                    padding: 0 !important;
                                    background-color: transparent;
                                    width: 100%;

                                    > .card-title {
                                      font-size: @subPanelHeadingFontSize !important;
                                      font-weight: 600;
                                      color: @darkTextColor;
                                      width: 100%;

                                      i {
                                        box-shadow: none !important;

                                        &.fa-question-circle {
                                          position: relative;
                                          inset-inline-end: initial;
                                          top: initial;
                                          display: flex;
                                          margin-inline-start: 8px;
                                        }
                                      }
                                      .formio-collapse-icon {
                                        top: initial;
                                        font-size: 14px;
                                        inset-inline-end: initial;
                                        position: relative;
                                        margin-inline-end: 0;
                                        margin-inline-start: 6px;

                                        &::before {
                                          content: "\F106";
                                        }
                                      }
                                      .formio-collapse-text {
                                        position: relative;
                                        inset-inline-end: 0;
                                        width: fit-content;
                                        white-space: nowrap;
                                        inset-inline-start: initial;
                                        font-size: 14px;
                                        font-weight: normal;
                                        cursor: pointer;
                                        margin-inline-start: auto;
                                      }
                                    }
                                    &::before {
                                      display: none;
                                    }
                                    &::after {
                                      display: none;
                                    }
                                    &:last-child {
                                      padding: 0;
                                      border-radius: @roundedCorners;
                                      transition-duration: 0.3s;
                                      border: none;

                                      &::before {
                                        display: none;
                                      }
                                      > .card-title {
                                        .formio-collapse-icon {
                                          &::before {
                                            content: "\F107";
                                          }
                                        }
                                      }
                                    }
                                  }
                                  > .card-body {
                                    padding: @panelBodyPadding 0 0 !important;
                                  }
                                }
                              }
                            }
                            .formio-component-tabs {
                              > .card {
                                box-shadow: none;
                              }
                            }
                            .formio-component-panel {
                              > .card {
                                box-shadow: none;
                              }
                            }
                            &.active {
                              display: flex !important;
                              box-shadow: 0px 0px 20px @black5Color;
                              border-style: solid;
                              border-width: 1px;
                              border-color: @black5Color;
                            }
                            &::before,
                            &::after {
                              display: none;
                            }
                          }
                        }
                      }
                    }
                    &.formio-component-fieldset {
                      fieldset {
                        padding: 54px 20px 20px;
                        position: relative;
                        border-radius: @roundedCorners;
                        border: solid 4px @accentColor10;
                        background-color: @whiteColor;

                        legend {
                          margin: 0;
                          text-transform: initial;
                          font-size: 18px;
                          font-weight: 600;
                          color: @darkTextColor;
                          background: transparent;
                          padding: 0;
                          position: absolute;
                          top: 12px;
                        }
                        @media screen and (max-width: 580px) {
                          padding: 54px 20px 20px;
                        }
                        > .fieldset-body {
                          &:first-child {
                            margin-top: -35px;
                          }
                          > .formio-component {
                            &.top-label-space {
                              &.required {
                                label {
                                  &.field-required {
                                    &.control-label--hidden {
                                      top: 27px !important;
                                    }
                                  }
                                }
                              }
                            }
                            &:first-child {
                              margin-top: 0 !important;
                            }
                          }
                        }
                      }
                    }
                    &.formio-component-file {
                      border: dashed 2px rgba(27, 37, 60, 0.1);
                      border-radius: @roundedCorners;
                      padding: @panelBodyPadding;
                      position: relative;

                      &:not(:first-of-type) {
                        margin-top: 3rem;
                      }
                      label {
                        &.col-form-label {
                          font-size: 16px;
                          color: @darkTextColor;
                          margin-bottom: 10px;

                          & + div {
                            margin-bottom: 20px;
                            position: relative;
                            z-index: 9;

                            div:last-child {
                              margin-bottom: 36px;
                            }
                            span {
                              i {
                                cursor: pointer;
                                color: rgba(255, 0, 59, 1);
                              }
                            }
                          }
                          .fa-question-circle {
                            position: relative;
                            z-index: 9;
                          }
                        }
                      }
                      ul {
                        &.list-group {
                          border: none;
                          width: 100%;
                          position: relative;
                          z-index: 9;

                          &:nth-last-child(2) {
                            margin-bottom: -10px;
                          }
                          &:nth-last-child(n+4) {
                            & + .fileSelector {
                              i.fa-cloud-upload {
                                top: initial;
                              }
                            }
                          }
                          li {
                            &.list-group-item {
                              border: none;
                              box-shadow: inset 0 -2px 0 #E2E7E7;
                              padding: 0;
                              background-color: transparent;
                              border-radius: 0;

                              .row {
                                border: none;
                                display: grid;
                                grid-template-columns: auto auto 1fr;
                                font-size: 12px;

                                &::before,
                                &::after {
                                  display: none;
                                }
                                > div {
                                  padding: 10px 12px;
                                  text-transform: none;
                                  color: @accentColor;

                                  &.col-md-1 {
                                    order: 3;
                                    width: auto;
                                    justify-content: flex-end;
                                    color: rgba(255, 0, 59, 1);
                                  }
                                  &:where(.col-md-9, .col-md-2) {
                                    white-space: nowrap;
                                    width: auto !important;

                                    a[href] {
                                      display: block;
                                    }
                                  }
                                  &.col-md-9 {
                                    a {
                                      white-space: normal;
                                      line-break: anywhere;
                                    }
                                  }
                                  &:first-child {
                                    display: flex;
                                    align-items: center;

                                    i {
                                      &.fa-remove {
                                        font-family: 'Font Awesome 5 Pro';
                                        font-weight: 300;
                                        cursor: pointer;
                                        margin: 0;
                                        font-size: 16px;
                                      }
                                    }
                                    & + .col-md-9 {
                                      width: 60%;
                                    }
                                  }
                                }
                                &:hover {
                                  background: @black5Color;
                                  border-radius: @roundedCorners;
                                }
                              }
                              &.list-group-header {
                                background: @accentColor5;
                                border: none;
                                border-radius: @roundedCorners;
                                padding: 0;
                                display: none;

                                .row {
                                  > div {
                                    font-size: 14px;
                                    font-weight: 600;
                                    line-height: 1;

                                    strong {
                                      font-weight: 600;
                                    }
                                  }
                                }
                              }
                              &:hover {
                                box-shadow: none;
                              }
                              &:last-child {
                                box-shadow: none;
                              }
                            }
                            &.list-group-header {
                              > .row {
                                > div {
                                  &.radio {
                                    label {
                                      padding-inline-start: 0;
                                    }
                                  }
                                }
                              }
                            }
                          }
                          tbody {
                            tr {
                              background-color: transparent;

                              td {
                                background-color: transparent;
                                border: none;
                                padding: 1.5rem 12px;

                                &:first-child {
                                  border-radius: @roundedCorners 0 0 @roundedCorners;
                                }
                                &:last-child {
                                  border-radius: 0 @roundedCorners @roundedCorners 0;
                                }
                                a {
                                  &.btn {
                                    background-color: transparent;
                                    border: none;

                                    span {
                                      &.glyphicon-remove {
                                        font-family: "Font Awesome 5 Pro";
                                        font-weight: 300;
                                        -moz-osx-font-smoothing: grayscale;
                                        -webkit-font-smoothing: antialiased;
                                        display: inline-block;
                                        font-style: normal;
                                        font-variant: normal;
                                        text-rendering: auto;
                                        line-height: 1;
                                        color: @errorColor50;
                                        font-size: 16px;

                                        &::before {
                                          content: "\f00d";
                                        }
                                        &:hover {
                                          color: @errorColor;
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                              &:hover {
                                td {
                                  background-color: @pageBgColor;
                                }
                              }
                            }
                          }
                        }
                      }
                      .fileSelector {
                        margin-bottom: 0;
                        width: 100%;
                        border: none;
                        border-radius: @roundedCorners;
                        position: absolute;
                        top: 0;
                        inset-inline-start: 0;
                        align-items: flex-end;
                        height: 100%;
                        padding: 23px;
                        gap: 6px;
                        font-size: 12px;
                        transition-duration: 0.2s;

                        &:not([hidden]) {
                          display: flex;
                        }
                        i {
                          &.fa-cloud-upload {
                            display: block;
                            position: absolute;
                            inset-inline-end: 23px;
                            font-size: 16px;
                            margin: 0;
                            top: calc(50% - 8px);

                            &::before {
                              font-family: "Font Awesome 5 Pro";
                              font-weight: 300;
                              -moz-osx-font-smoothing: grayscale;
                              -webkit-font-smoothing: antialiased;
                              display: inline-block;
                              font-style: normal;
                              font-variant: normal;
                              text-rendering: auto;
                              line-height: 1;
                              content: "\f56f";
                              color: @darkTextColor;
                              font-size: 16px;
                            }
                            & ~ * {
                              color: @darkTextColor;
                              font-size: 12px;
                            }
                          }
                        }
                        &.fileDragOver {
                          box-shadow: 0 0 0 2px @accentColor50;
                          background: @accentColor5;
                          z-index: 99;
                        }
                        & ~ .file {
                          display: flex;

                          > .row {
                            &:first-child {
                              min-width: 100px;

                              .fileName {
                                flex: auto;
                                white-space: nowrap;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                position: relative;

                                i {
                                  position: absolute;
                                  inset-inline-end: 0;
                                }
                              }
                              .fileSize {
                                flex: 0;
                                white-space: nowrap;
                                margin-inline-end: 10px;
                              }
                              & + .row {
                                .progress {
                                  margin-inline-end: 36px;
                                  height: 4px;
                                  margin-top: 4px;
                                  margin-bottom: 0;
                                  background-color: rgba(27, 37, 60, 0.1);
                                  box-shadow: none;

                                  .progress-bar {
                                    background-color: rgba(41, 188, 143, 1);
                                    border-radius: 5px;
                                  }
                                }
                              }
                            }
                          }
                          &:nth-last-child(2) {
                            margin-bottom: 40px;
                          }
                        }
                      }
                      &.formio-component-multiple {
                        .fileSelector {
                          i {
                            &.fa-cloud-upload {
                              top: initial;
                            }
                          }
                        }
                      }
                      &.has-files {
                        background-color: rgba(41, 188, 143, 0.03);

                        &:not(.formio-component-multiple) {
                          ul {
                            &.list-group {
                              margin-bottom: 36px;
                            }
                          }
                        }
                      }
                      &.has-message {
                        .fileSelector {
                          padding-bottom: 44px;
                        }
                        .formio-errors {
                          margin-top: 34px;
                        }
                      }
                    }
                    &.formio-component-checkbox {
                      .checkbox {
                        margin-top: 0;
                        padding-top: 13px;

                        label {
                          margin-top: 1px;
                          padding-inline-start: 0;

                          span {
                            color: @accentColor50;
                            font-size: @defaultTextSize;
                            font-weight: 400;
                            padding-inline-start: 24px;
                            line-height: 19px;
                            margin-top: 0;

                            &::before {
                              color: @accentColor50;
                              font-size: @defaultTextSize;
                              font-weight: 400;
                              top: 1px;
                            }
                          }
                          input {
                            &[type="radio"] {
                              appearance: inherit;

                              &::before {
                                -webkit-font-smoothing: antialiased;
                                display: inline-block;
                                font-style: normal;
                                font-variant: normal;
                                text-rendering: auto;
                                line-height: 1;
                                font-family: "Font Awesome 5 Pro";
                                font-weight: 400;
                                font-size: @defaultTextSize;
                                content: "\f111";
                                color: @accentColor50;
                              }
                              & + span {
                                font-size: @defaultTextSize;
                                font-weight: 600;
                                color: @accentColor50;
                                margin-inline-start: 3px;
                                position: relative;
                                line-height: 14px;
                                padding-inline-start: 0;
                              }
                              &:checked {
                                &::before {
                                  content: "\f192";
                                  font-weight: 900;
                                  color: @accentColor;
                                }
                                & + span {
                                  color: @accentColor;
                                }
                              }
                              &:focus {
                                outline: none;
                              }
                            }
                            &:checked {
                              & + span {
                                color: @accentColor;

                                &::before {
                                  color: @accentColor;
                                  font-weight: 600;
                                }
                              }
                            }
                            &[type="checkbox"] {
                              transform: scale(0);
                            }
                          }
                          &:hover {
                            span {
                              color: @accentColor;

                              &::before {
                                color: @accentColor;
                              }
                            }
                            input {
                              &[type="radio"] {
                                appearance: inherit;

                                &::before {
                                  color: @accentColor;
                                }
                                & + span {
                                  color: @accentColor;
                                }
                              }
                            }
                          }
                          &.field-required {
                            &::after {
                              content: " ";
                              display: inline-block;
                              background-color: @errorColor70;
                              vertical-align: unset;
                              width: 6px;
                              height: 6px;
                              border-radius: 5px;
                              position: relative;
                              top: -1px;
                              inset-inline-start: 2px;
                              min-width: 6px;
                            }
                          }
                        }
                      }
                      &.remove-top-space {
                        .checkbox {
                          padding-top: 0;
                        }
                      }
                      &.required {
                        label.field-required {
                          &::after {
                            display: none;
                          }
                          span {
                            &::after {
                              content: " ";
                              display: inline-block;
                              background-color: @errorColor70;
                              vertical-align: unset;
                              width: 6px;
                              height: 6px;
                              border-radius: 5px;
                              position: relative;
                              top: -1px;
                              inset-inline-start: 2px;
                              min-width: 6px;
                            }
                          }
                        }
                      }
                      &.formio-component-label-hidden {
                        .checkbox label {
                          margin-top: 0px;

                          input.form-check-input {
                            transform: scale(1);
                            appearance: none;
                            position: initial;
                            margin-inline-start: 0px;
                            margin: 0;

                            &::after {
                              content: "";
                              -webkit-font-smoothing: antialiased;
                              display: inline-block;
                              font-style: normal;
                              font-variant: normal;
                              text-rendering: auto;
                              line-height: 1;
                              font-family: "Font Awesome 5 Pro";
                              font-weight: 400;
                              font-size: 16px;
                              content: "\F0C8";
                              color: rgba(70, 81, 170, 0.5);
                            }
                            &:focus {
                              outline: none;
                            }
                            &:checked {
                              &::after {
                                content: "\F14A";
                                font-weight: 900;
                                color: @accentColor;
                              }
                            }
                          }
                        }
                      }
                    }
                    &.formio-component-table {
                      margin-inline-start: -12px;
                      margin-inline-end: -12px;

                      table {
                        &.table {
                          > tbody {
                            > tr {
                              > td {
                                input {
                                  &:where([type="text"], [type="email"], [type="phoneNumber"]) {
                                    /*border-radius: @roundedCorners !important;*/
                                    height: @fieldHeight !important;
                                  }
                                }
                                .form-group {
                                  &.form-field-type-radio {
                                    &.radio-switch {
                                      .input-group {
                                        ng-form {
                                          .radio-inline {
                                            margin-top: 0;
                                          }
                                        }
                                      }
                                      label {
                                        &.control-label {
                                          & + .input-group {
                                            .radio-inline {
                                              margin-top: 0;
                                            }
                                          }
                                        }
                                      }
                                    }
                                    label {
                                      &.control-label {
                                        & + .input-group {
                                          .radio-inline {
                                            margin-top: 0;
                                          }
                                          .radio {
                                            &:first-child {
                                              margin-top: 0;
                                            }
                                          }
                                        }
                                      }
                                    }
                                    .input-group {
                                      &:first-child {
                                        .radio-inline {
                                          margin-top: 0px;
                                        }
                                      }
                                    }
                                    &.remove-space-top {
                                      .input-group {
                                        .radio-inline {
                                          margin-top: 12px;
                                        }
                                        .radio {
                                          margin-top: 12px;

                                          &:first-child {
                                            margin-top: 13px;
                                          }
                                        }
                                      }
                                    }
                                  }
                                  &:where(.form-field-type-textfield, .form-field-type-number, .form-field-type-email, .form-field-type-phoneNumber) {
                                    &.field-plus-button {
                                      input {
                                        &.form-control {
                                          border-top-right-radius: 0 !important;
                                          border-bottom-right-radius: 0 !important;
                                        }
                                      }
                                    }
                                  }
                                }
                                fieldset {
                                  legend {
                                    line-height: 34px;
                                    display: block;
                                  }
                                }
                              }
                              &.row {
                                display: table-row;

                                &::after,
                                &::before {
                                  display: none;
                                }
                                td {
                                  display: table-cell;

                                  &:first-child {
                                    padding-inline-start: 0;
                                  }
                                  &:last-child {
                                    padding-inline-end: 0;
                                  }
                                }
                              }
                              &:first-child {
                                > td {
                                  > .form-group {
                                    &:first-child {
                                      &:not(.ng-hide) {
                                        margin-top: 0 !important;

                                        &.top-label-space {
                                          padding-top: 28px !important;
                                        }
                                      }
                                      &.form-field-type-radio {
                                        &.top-label-space {
                                          margin-top: 0 !important;
                                        }
                                      }
                                    }
                                  }
                                }
                                & ~ tr {
                                  > td {
                                    > .form-group {
                                      &:first-child {
                                        margin-top: 3rem !important;

                                        &.top-label-space {
                                          margin-top: 3rem !important;
                                          padding-top: 28px !important;
                                        }
                                      }
                                      &.formio-hidden {
                                        & + .formio-component:not(.formio-hidden) {
                                          margin-top: 3rem !important;
                                        }
                                      }
                                      &.form-field-type-checkbox {
                                        .checkbox {
                                          margin-top: 0;
                                        }
                                        &.top-label-space {
                                          .checkbox {
                                            margin-top: 0;
                                          }
                                        }
                                      }
                                      &.form-field-type-radio {
                                        .input-group {
                                          &:first-child {
                                            margin-top: 0;
                                          }
                                        }
                                        &.top-label-space {
                                          margin-top: 3rem;

                                          .input-group {
                                            &:first-child {
                                              padding-top: 34px;
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                              &:last-child {
                                > td {
                                  .formio-component {
                                    &.formio-component-panel {
                                      &:last-child {
                                        margin-bottom: 0;
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                      &.table-responsive {
                        @media screen and (max-width: 768px) {
                          border: none;
                        }
                        @media screen and (max-width: 580px) {
                          table {
                            &.table {
                              tbody {
                                tr {
                                  td {
                                    display: table;
                                    width: 100%;

                                    & + td {
                                      margin-top: 3rem;

                                      > .formio-component {
                                        &:first-child {
                                          margin-top: 0;
                                        }
                                      }
                                    }
                                    .formio-component-textfield {
                                      &.field-plus-button {
                                        input {
                                          &.form-control {
                                            border-radius: @roundedCorners !important;
                                          }
                                        }
                                      }
                                    }
                                    .formio-component-button {
                                      &.field-plus-button {
                                        button {
                                          &.btn {
                                            border-radius: @roundedCorners !important;
                                            margin-inline-start: 0;
                                          }
                                        }
                                      }
                                      &.top-label-space,
                                      &:first-child:not(.ng-hide).top-label-space {
                                        padding-top: 0 !important;
                                      }
                                    }
                                    &:empty {
                                      display: none;
                                    }
                                  }
                                  & + tr {
                                    td {
                                      &:first-child {
                                        margin-top: 3rem;
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                    &.formio-component-columns {
                      clear: both;
                      margin-inline-start: -12px;
                      margin-inline-end: -12px;

                      &:not(.formio-hidden) {
                        display: flex;
                        flex-wrap: wrap;
                      }
                      .formio-component-button {
                        &:first-child {
                          &.top-label-space {
                            margin-top: 0 !important;
                          }
                        }
                      }
                      &.datagrid-custom-footer {
                        margin-top: -26px !important;
                        margin-bottom: 79px;
                        padding-inline-end: 0;
                        margin-inline-start: 0;
                        margin-inline-end: 50px;
                        border: solid 1px @borderColor;
                        border-radius: @roundedCorners;

                        > div[class^="col-"] {
                          padding: 0;
                          flex: 1;

                          :where(.formio-component-textfield, .formio-component-number) {
                            label {
                              display: none;
                            }
                            input {
                              border: none;
                              font-weight: 600;
                              background-color: transparent;
                              background-image: none;

                              &[disabled] {
                                background-color: transparent !important;
                              }
                            }
                          }
                          &:not(:first-child) {
                            input {
                              padding-inline-start: 28px;
                            }
                          }
                        }
                        &.datagrid-add-remove-disabled {
                          padding-inline-end: 0;
                          margin-top: 6px !important;
                          margin-bottom: 0;
                          margin-inline-end: 0;
                        }
                        &.datagrid-condensed {
                          margin-top: -32px !important;

                          > div {
                            &[class^="col-"] {
                              &:not(:first-child) {
                                input {
                                  padding-inline-start: 14px;
                                }
                              }
                            }
                          }
                        }
                      }
                      > div {
                        &[class^="col-"] {
                          padding-inline-start: 12px;
                          padding-inline-end: 12px;

                          .formio-component {
                            &:first-of-type {
                              &.top-label-space {
                                margin-top: 0 !important;
                                padding-top: 29px !important;

                                &.required {
                                  label {
                                    &.field-required {
                                      &.control-label--hidden {
                                        top: 28px;
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                          &.col-md-12 {
                            & ~ [class^="col-"] {
                              .formio-component {
                                &:first-of-type {
                                  margin-top: 3rem;
                                }
                              }
                            }
                          }
                          &.col-md-6 {
                            &:first-of-type {
                              .formio-component {
                                &:first-of-type {
                                  margin-top: 0;
                                }
                              }
                              & + :where(.col-md-6, .col-md-3 + .col-md-3) {
                                & ~ [class^="col-"] {
                                  .formio-component {
                                    &:first-of-type {
                                      margin-top: 3rem;
                                    }
                                  }
                                }
                              }
                              & + .col-md-6 {
                                .formio-component {
                                  &:first-of-type {
                                    margin-top: 0;
                                  }
                                }
                              }
                            }
                          }
                          &.col-md-3 {
                            &:first-of-type {
                              & + :where(.col-md-9, .col-md-3 + .col-md-3 + .col-md-3) {
                                & ~ [class^="col-"] {
                                  > .formio-component:not(.formio-hidden, .hide, .formio-component-hidden) {
                                    &:first-of-type {
                                      margin-top: 3rem;
                                    }
                                    &.formio-component-columns {
                                      > div[class^="col-"] {
                                        > .formio-component:not(.formio-hidden, .hide, .formio-component-hidden) {
                                          &:first-of-type {
                                            margin-top: 0px;
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                          &.col-md-4 {
                            &:first-of-type {
                              & + .col-md-8,
                              & + .col-md-4 + .col-md-4 {
                                & ~ [class^="col-"] {
                                  .formio-component {
                                    &:first-of-type {
                                      margin-top: 3rem;
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                      &.formio-component-totalPrice {
                        display: flex;

                        .formio-component-htmlelement {
                          &.panel-title {
                            h4 {
                              font-size: 16px;
                              font-weight: 600;
                            }
                          }
                        }
                      }
                      &.i-swear {
                        .form-check.checkbox {
                          padding-top: 0px;
                        }
                        .col-md-1 {
                          flex: 0;
                          width: auto;
                          padding-inline-end: 0;
                        }
                      }
                      &.vertical-align-top {
                        align-items: flex-start;
                      }
                      &.vertical-align-center {
                        align-items: center;
                      }
                      &.vertical-align-bottom {
                        align-items: flex-end;
                      }
                    }
                    &.formio-component-panel {
                      &:first-child {
                        margin-top: 0;
                      }
                      &[class*="formio-component-paymentPagePayments"] {
                        .card {
                          .card-body {
                            .formio-component-columns {
                              &.default-pay-form-payments {
                                padding-bottom: 0;
                                margin-bottom: 12px;
                                display: flex;

                                .formio-component {
                                  &.formio-component-checkbox {
                                    .checkbox {
                                      padding-top: 0;
                                      cursor: pointer;

                                      input {
                                        &::before {
                                          cursor: pointer;
                                        }
                                      }
                                    }
                                  }
                                  &.formio-component-htmlelement {
                                    margin: 0;

                                    .tile-text {
                                      label {
                                        margin: 0;
                                        display: flex;
                                      }
                                    }
                                  }
                                }
                                &:nth-last-child(2) {
                                  margin-bottom: 0;
                                }
                              }
                            }
                          }
                        }
                      }
                      &.payment-form-component {
                        .formio-component-totalPrice {
                          border-top: none;

                          &::before {
                            display: block;
                            height: 1px;
                            background-color: @borderColor;
                            position: absolute;
                            top: 0;
                            width: calc(100% - 24px);
                            inset-inline-start: 12px;
                          }
                        }
                      }
                      &:last-child {
                        margin-bottom: 0 !important;
                      }
                      > .card.mb-2 {
                        margin-bottom: 0 !important;
                        margin-top: 0;
                      }
                      > .card {
                        > .card-body {
                          > .formio-component {
                            &:first-child {
                              margin-top: 0 !important;
                            }
                            &.formio-component-editgrid,
                            &.formio-component-columns > [class*="col-"] > .formio-component-editgrid {
                              .list-group {
                                &.has-open-row {
                                  .list-group-item {
                                    &:only-child {
                                      > .formio-component {
                                        &:first-child {
                                          margin-top: 0px !important;
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                      .formio-component-tabs {
                        > .card {
                          padding: 0;
                          box-shadow: none;
                        }
                      }
                      &.highlight-block {
                        > .card {
                          border: solid 1px @infoNotice !important;
                          box-shadow: 0px 0px 20px @infoNotice20 !important;
                          border-radius: @roundedCorners;

                          > .card-body {
                            &:only-child {
                              padding: @panelBodyPadding !important;
                              border-radius: @roundedCorners;
                            }
                          }
                        }
                      }
                    }
                    &.formio-component-hidden {
                      &:first-child {
                        & + .formio-component {
                          margin-top: 0;
                        }
                      }
                    }
                    &.margin-top-zero {
                      margin-top: 0 !important;
                    }
                    &.top-space {
                      padding-top: 3rem;
                    }
                    &.top-space-2x {
                      padding-top: 6rem;
                    }
                    &.top-space-3x {
                      padding-top: 9rem;
                    }
                    &.top-label-space {
                      margin-top: 3rem;
                      padding-top: 29px !important;

                      &.required {
                        label {
                          &.field-required {
                            &.control-label--hidden {
                              top: 28px;
                            }
                          }
                        }
                      }
                    }
                    &.formio-hidden + .formio-component:not(.formio-hidden) {
                      margin-top: 0;
                    }
                    &:not(.formio-hidden) ~ .formio-hidden + .formio-component:not(.formio-hidden) {
                      margin-top: 3rem;
                    }
                    &:not(.formio-hidden) {
                      &:first-child {
                        & ~ .alert + .formio-component:not(.formio-hidden) {
                          margin-top: 3rem;
                        }
                      }
                    }
                    // Multiple
                    &:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-datetime, .formio-component-textarea, .formio-component-url, .formio-component-address) {
                      &.formio-component-multiple {
                        table {
                          &.table {
                            table-layout: auto;
                            border: none;

                            tbody {
                              tr {
                                td {
                                  padding: 12px 0 0;
                                  border: none;

                                  &:first-child {
                                    width: 100%;

                                    & + td {
                                      button {
                                        &.btn {
                                          background-color: transparent;
                                          height: 40px;
                                          font-size: 20px;
                                          line-height: 1;
                                          border: none;

                                          &[ref="removeRow"] {
                                            color: @errorNotice;

                                            i {
                                              font-family: 'Font Awesome 5 Pro';
                                              font-weight: 300;

                                              &::before {
                                                content: "\F057";
                                              }
                                            }
                                            &:hover {
                                              background-color: @errorNotice5;
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                                &:last-child {
                                  td {
                                    padding-top: 0;

                                    button {
                                      &.btn.formio-button-add-another {
                                        border: solid 1px @accentColor;
                                        background-color: transparent;
                                        border-radius: @roundedCorners !important;
                                        color: @accentColor;
                                        padding-inline-start: 25px;
                                        padding-inline-end: 25px;
                                        font-weight: 600;
                                        font-size: 12px;
                                        height: 40px;
                                        display: inline-flex;
                                        align-items: center;
                                        transition-duration: 0.3s;
                                        margin-top: 10px;

                                        > i {
                                          display: none;
                                        }
                                        &:hover {
                                          color: @whiteColor;
                                          background-color: @accentColor;
                                          box-shadow: 0 9px 20px @accentColor40 !important;
                                        }
                                      }
                                    }
                                  }
                                }
                                &:first-child {
                                  td {
                                    padding-top: 0;
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                    // Fields used as notice
                    &:is(.formio-component-textfield, .formio-component-number, .formio-component-textarea) {
                      &:is(.notice, .info-notice, .success-notice, .warning-notice, .error-notice) {
                        background: @darkTextColor3 !important;
                        border-inline-start: solid 3px;
                        border-radius: 0 @roundedCorners @roundedCorners 0;
                        padding: 2rem;

                        :is(input, textarea) {
                          background-color: transparent;
                          border: none !important;
                          padding: 0;
                          height: auto;
                          font-weight: 600;

                          &[disabled] {
                            background-color: transparent !important;
                            margin-bottom: -13px;
                          }
                        }
                      }
                      &.notice {
                        border-left-color: @accentColor;

                        &.fill-background {
                          background-color: @accentColor5 !important;

                          * {
                            color: @accentColor !important;
                          }
                        }
                      }
                      &.info-notice {
                        border-left-color: @infoNotice;

                        &.fill-background {
                          background-color: @infoNotice5 !important;

                          * {
                            color: @infoNotice !important;
                          }
                        }
                      }
                      &.success-notice {
                        border-left-color: @successNotice;

                        &.fill-background {
                          background-color: @successNotice5 !important;

                          * {
                            color: @successNotice !important;
                          }
                        }
                      }
                      &.warning-notice {
                        border-left-color: @warningNotice;

                        &.fill-background {
                          background-color: @warningNotice5 !important;

                          * {
                            color: @warningNotice !important;
                          }
                        }
                      }
                      &.error-notice {
                        border-left-color: @errorNotice;

                        &.fill-background {
                          background-color: @errorNotice5 !important;

                          * {
                            color: @errorNotice !important;
                          }
                        }
                      }
                    }
                    &.text-right {
                      :where(label, input, textarea) {
                        text-align: end !important;
                      }
                      &.formio-component-panel {
                        > .card {
                          > .card-header {
                            .card-title {
                              justify-content: flex-end;

                              .formio-collapse-icon {
                                inset-inline-start: 0;
                                inset-inline-end: initial;
                              }
                              .formio-collapse-text {
                                inset-inline-start: 28px;
                                inset-inline-end: initial !important;
                              }
                            }
                          }
                        }
                      }
                    }
                    &.align-value-right {
                      :where(input, textarea) {
                        &:not(.choices__input) {
                          text-align: end !important;
                        }
                      }
                    }
                  }
                }
                :where(.formio-form .wizard-page > .formio-component, .formio-form > .formio-component) {
                  &.guide-form,
                  .guide-form {
                    margin-top: 9px;
                    display: flex !important;
                    flex-wrap: wrap;
                    gap: 24px;
                    margin-inline: 0px !important;

                    &::after,
                    &::before {
                      display: none;
                    }
                    > .formio-errors {
                      width: 100%;
                    }
                    > div[class^="col-"] {
                      align-items: normal;
                      align-self: stretch;
                      min-width: 320px;
                      flex: 1;
                      padding-inline: 0px !important;

                      > .formio-component {
                        &.formio-component-panel {
                          box-shadow: none;

                          & + .formio-component-panel {
                            margin-top: 2rem;
                          }
                          &.hide:first-child + .formio-component-panel {
                            margin-top: 0;
                          }
                          > .card {
                            background-color: @whiteColor;
                            box-shadow: 0px 0px 20px @black5Color;
                            border-radius: @roundedCorners !important;
                            padding: @panelBodyPadding;

                            > .card-header {
                              padding-top: 0;
                              padding-inline-start: 0;
                              padding-inline-end: 0;
                              padding-bottom: calc(@panelPadding - 2px);

                              &::after {
                                content: "";
                                display: block;
                                height: 2px;
                                background-color: @panelHeadingBottomBorderColor;
                                margin-top: 20px;
                                width: calc(100% + (@panelBodyPadding * 2));
                                margin-inline-start: -@panelBodyPadding;
                              }
                              &:last-child {
                                &::after {
                                  display: none;
                                }
                              }
                              > .card-title {
                                font-size: @guidePanelHeadingFontSize;
                                font-weight: 600;
                                color: @darkTextColor;

                                .formio-collapse-text {
                                  display: none;
                                }
                                i.fa-question-circle {
                                  position: relative;
                                  margin-inline-start: 5px;
                                  display: inline-flex;
                                }
                              }
                            }
                            > .card-body {
                              padding: 0;

                              .formio-component-panel {
                                > .card {
                                  background-color: transparent;
                                  border: none;
                                  margin-top: 0;

                                  > .card-header {
                                    margin: 0;
                                    padding: 0;
                                    background-color: transparent;

                                    > .card-title {
                                      font-size: 18px;
                                      font-weight: 600;
                                      color: @darkTextColor;
                                      position: initial;

                                      i {
                                        box-shadow: none !important;
                                        position: relative;
                                        margin-inline-start: 5px;
                                        display: inline-flex;
                                      }
                                      .formio-collapse-icon {
                                        top: 1px;
                                        font-size: 15px;
                                        inset-inline-end: 0;
                                      }
                                      .formio-collapse-text {
                                        position: absolute;
                                        inset-inline-end: auto;
                                        width: fit-content;
                                        white-space: nowrap;
                                        inset-inline-end: 20px;
                                        font-size: 14px;
                                        font-weight: normal;
                                        cursor: pointer;
                                      }
                                    }
                                  }
                                  > .card-body {
                                    padding: 2.7rem 0 0;
                                  }
                                }
                                & + .formio-component-panel {
                                  margin-top: 3rem;
                                }
                              }
                            }
                          }
                          &:where(.formio-component-mandatoryRegistrationsPanel, .formio-component-optionalRegistrationsPanel) {
                            margin-inline-end: 36px;

                            .formio-component-htmlelement {
                              &.formio-component-mandatoryRegistrationsContent {
                                margin: 0 0 1rem !important;

                                p {
                                  font-size: 13px !important;
                                  line-height: 1.1;
                                }
                              }
                              &:where(.formio-hidden, .hide) {
                                & + .formio-component-checkbox {
                                  .form-check {
                                    &.checkbox {
                                      padding-top: 0;

                                      label {
                                        &.form-check-label {
                                          margin-top: -3px;
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                            .formio-component-checkbox {
                              margin-top: 0;

                              .form-check {
                                &.checkbox {
                                  label {
                                    &.form-check-label {
                                      span {
                                        font-size: 15px;
                                        line-height: 20px;

                                        &::before {
                                          top: 3px;
                                        }
                                      }
                                      input {
                                        &[type="checkbox"] {
                                          &.form-check-input {
                                            &:checked {
                                              & + span {
                                                &::before {
                                                  color: @accentColor;
                                                }
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                          &.formio-component-FeesPanel {
                            > .card {
                              > .card-header {
                                line-height: 1;
                                padding-bottom: 24px;

                                &:last-child {
                                  padding-bottom: 0;
                                }
                                .card-title {
                                  i {
                                    &::before {
                                      color: @accentColor;
                                    }
                                  }
                                }
                              }
                              > .card-body {
                                .formio-component-feesDescription {
                                  border: none;
                                  padding-top: 0;

                                  .formio-component-columns {
                                    &[class*="formio-component-priceRow"] {
                                      margin-top: 0px;

                                      > .col-md-6:first-child {
                                        flex: auto;

                                        & + .col-md-6 {
                                          flex: 0;
                                        }
                                      }
                                      .formio-component-htmlelement {
                                        margin: 0;

                                        :where(p, p span) {
                                          font-size: 14px !important;
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                            &.formio-hidden {
                              & + .formio-component-requiredDocumentsPanel {
                                margin-top: 0;
                              }
                            }
                          }
                          &.formio-component-requiredDocumentsPanel {
                            margin-top: 2rem;

                            > .card {
                              > .card-header {
                                line-height: 1;
                                padding-bottom: 30px;

                                &:last-child {
                                  padding-bottom: 0;
                                }
                                .card-title {
                                  padding-inline-end: 18px;
                                  word-break: break-word;

                                  i {
                                    &::before {
                                      color: @accentColor;
                                    }
                                  }
                                  span {
                                    margin-inline-start: 5px;
                                    font-size: calc(@guidePanelHeadingFontSize - 7px);
                                    margin-inline-end: 6px;
                                    flex: 0 0 auto;
                                  }
                                }
                              }
                              > .card-body {
                                .formio-component-requiredDocumentsContainer {
                                  border: none;
                                  margin-top: -1px;

                                  .formio-component-htmlelement {
                                    line-height: 1.2;
                                    margin-bottom: 1rem;
                                    padding-bottom: 1rem;
                                    border-bottom: solid 1px @borderColor;
                                    margin-top: 0;

                                    li {
                                      div {
                                        line-height: 1.5;

                                        a {
                                          display: none;
                                        }
                                      }
                                    }
                                    &:last-child {
                                      border: none;
                                      padding: 0;
                                      margin: 0;
                                    }
                                    &:first-child {
                                      margin-top: 0;
                                    }
                                  }
                                }
                              }
                            }
                          }
                          // Special alignment rules:
                          &.formio-component-FeesPanel {
                            &.formio-hidden {
                              & + .formio-component {
                                &.formio-component-requiredDocumentsPanel {
                                  &:not(.formio-hidden) {
                                    & ~ .formio-component {
                                      &.formio-component-button {
                                        &:last-child {
                                          margin-bottom: auto !important;
                                        }
                                      }
                                    }
                                  }
                                  &.formio-hidden {
                                    & + .formio-component {
                                      &.formio-component-button {
                                        margin-top: 0;
                                      }
                                    }
                                  }
                                }
                              }
                            }
                            &:not(.formio-hidden) {
                              & ~ .formio-component {
                                &.formio-component-button {
                                  &:last-child {
                                    margin-bottom: auto !important;
                                  }
                                }
                              }
                            }
                          }
                        }
                        &.formio-component-button {
                          margin-top: 2rem;
                        }
                      }
                      &:nth-last-of-type(2) {
                        &::before {
                          inset-inline-start: -50px;
                        }
                      }
                      &:first-of-type {
                        flex: 2;

                        & + div {
                          flex: 1.3;
                        }
                      }
                    }
                    .formio-component-requiredDocumentsPanel {
                      & ~ .formio-component-button {
                        button {
                          width: 100%;
                        }
                      }
                    }
                    @media screen and (max-width: 1073px) {
                      [class*="RegistrationsPanel"] {
                        margin-inline-end: 0px !important;
                      }
                      > div[class^="col-"] {
                        &:nth-last-of-type(2) {
                          &::before {
                            display: none;
                          }
                        }
                      }
                    }
                  }
                  &.formio-component-htmlelement {
                    &:where(.notice, .info-notice, .success-notice, .warning-notice, .error-notice) {
                      background: @whiteColor70 !important;
                    }
                    &.alert {
                      & + .formio-component {
                        margin-top: 0;
                      }
                    }
                  }
                  &.formio-component-hidden + .formio-component:not(.formio-component-hidden):not(.alert) {
                    margin-top: 0;

                    & ~ .formio-component-hidden + .formio-component:not(.formio-component-hidden) {
                      margin-top: 3.2rem;
                    }
                  }
                  &.formio-component-tabs {
                    .formio-component-panel {
                      margin-top: 3.2rem;
                      margin-bottom: 0;

                      > .card {
                        box-shadow: none;
                        background-color: @whiteColor;
                        border-radius: @roundedCorners;
                        border: none;

                        > .card-header {
                          padding: 0;
                          cursor: pointer;

                          > .card-title {
                            font-size: @subPanelHeadingFontSize;
                            font-weight: 600;
                            color: @darkTextColor;
                            transition-duration: 0.3s;
                            min-height: 20px;

                            i {
                              &.formio-collapse-icon {
                                font-weight: 400;
                                font-size: 14px;

                                &::before {
                                  content: "\F106";
                                }
                              }
                              &.fa-question-circle {
                                position: relative;
                                margin-inline-start: 8px;
                                display: flex;
                              }
                            }
                            @media screen and (max-width: 580px) {
                              font-size: 20px;
                            }
                            .formio-collapse-text {
                              position: absolute;
                              inset-inline-end: 28px;
                              font-size: 14px;
                              font-weight: normal;
                            }
                          }
                          &::after {
                            display: none;
                          }
                          &:last-child {
                            padding: 0;
                            transition-duration: 0.3s;
                            border: none;

                            &::after {
                              display: none;
                            }
                            > .card-title {
                              i {
                                &.formio-collapse-icon {
                                  &::before {
                                    content: "\F107";
                                  }
                                }
                              }
                            }
                          }
                        }
                        > .card-body {
                          padding: @panelBodyPadding 0 0;

                          .formio-component {
                            &.formio-component-panel {
                              margin-bottom: 2rem;

                              > .card {
                                background-color: transparent;
                                border: none;
                                margin-top: 0;
                                box-shadow: none;

                                > .card-header {
                                  margin: 0;
                                  padding: 0;
                                  background-color: transparent;
                                  width: 100%;

                                  > .card-title {
                                    font-size: @subPanelHeadingFontSize;
                                    font-weight: 600;
                                    color: @darkTextColor;
                                    width: 100%;

                                    i {
                                      box-shadow: none !important;

                                      &.fa-question-circle {
                                        position: relative;
                                        inset-inline-end: initial;
                                        top: initial;
                                        display: flex;
                                        margin-inline-start: 8px;
                                      }
                                    }
                                    .formio-collapse-icon {
                                      top: initial;
                                      font-size: 15px;
                                      inset-inline-end: initial;
                                      position: relative;
                                      margin-inline-end: 0;
                                      margin-inline-start: 6px;

                                      &::before {
                                        content: "\F106";
                                      }
                                    }
                                    .formio-collapse-text {
                                      position: relative;
                                      inset-inline-end: 0;
                                      width: fit-content;
                                      white-space: nowrap;
                                      inset-inline-start: initial;
                                      font-size: 14px;
                                      font-weight: normal;
                                      cursor: pointer;
                                      margin-inline-start: auto;
                                    }
                                  }
                                  &::after {
                                    display: none;
                                  }
                                  &:last-child {
                                    &::before {
                                      height: 2px;
                                    }
                                    > .card-title {
                                      .formio-collapse-icon {
                                        &::before {
                                          content: "\F107";
                                        }
                                      }
                                    }
                                  }
                                }
                                > .card-body {
                                  padding: 2.7rem 0 0;
                                }
                              }
                              &:first-child {
                                margin-top: 0;

                                > .card {
                                  > .card-header {
                                    &::before {
                                      display: none;
                                    }
                                  }
                                }
                              }
                              & + .formio-component-panel {
                                margin-top: 2rem;
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                  &.formio-component-columns {
                    &.datagrid-custom-footer {
                      margin-top: -56px !important;
                      margin-bottom: 49px;
                    }
                  }
                }
                .formio-form {
                  .wizard-page {
                    padding-top: 22px;
                    display: flex;
                    flex-direction: column;
                    gap: 3rem;

                    > .formio-component {
                      margin-top: 0 !important;

                      &.guide-form {
                        margin-top: 0;
                        display: flex !important;
                      }
                      &.margin-top-zero {
                        margin-top: -3rem !important;
                      }
                    }
                  }
                }
                &:not(.pagename-off) {
                  :where(.formio-form .wizard-page > .formio-component, .formio-form > .formio-component) {
                    &.formio-component-panel:not([class*="roleapplicant"]),
                    &.formio-component-columns:not(.guide-form) > div[class*="col-"] > .formio-component-panel:not([class*="roleapplicant"]),
                    &.formio-component-table > table > tbody > tr > td > .formio-component-panel:not([class*="roleapplicant"]) {
                      margin-top: 3.2rem;
                      margin-bottom: 0;

                      > .card {
                        box-shadow: 0px 0px 20px @black5Color;
                        background-color: @whiteColor;
                        border-radius: @roundedCorners;

                        > .card-header {
                          padding: @panelHeadingPaddingY @panelBodyPadding 0;
                          cursor: pointer;

                          > .card-title {
                            font-size: @panelHeadingFontSize;
                            font-weight: 600;
                            color: @darkTextColor;
                            transition-duration: 0.3s;
                            min-height: 20px;

                            i {
                              &.formio-collapse-icon {
                                font-weight: 400;

                                &::before {
                                  content: "\F106";
                                }
                              }
                              &.fa-question-circle {
                                position: relative;
                                margin-inline-start: 8px;
                                display: flex;
                              }
                            }
                            @media screen and (max-width: 580px) {
                              font-size: 20px;
                            }
                            .formio-collapse-text {
                              position: absolute;
                              inset-inline-end: 28px;
                              font-size: 16px;
                              font-weight: normal;
                            }
                          }
                          &::after {
                            content: "";
                            display: block;
                            height: 2px;
                            background-color: @panelHeadingBottomBorderColor;
                            margin-top: 20px;
                            width: calc(100% + (@panelBodyPadding * 2));
                            margin-inline-start: -@panelBodyPadding;
                          }
                          &:last-child {
                            padding: @panelBodyPadding;
                            transition-duration: 0.3s;

                            &::after {
                              display: none;
                            }
                            > .card-title {
                              i {
                                &.formio-collapse-icon {
                                  &::before {
                                    content: "\F107";
                                  }
                                }
                              }
                            }
                          }
                        }
                        > .card-body {
                          padding: @panelBodyPadding;

                          .formio-component {
                            &.formio-component-panel {
                              > .card {
                                box-shadow: none;
                                background-color: @whiteColor;
                                border-radius: @roundedCorners;
                                border: none;
        
                                > .card-header {
                                  padding: 0;
                                  cursor: pointer;
                                  width: 100%;
                                  margin: 0;
                                  background: transparent;
        
                                  > .card-title {
                                    font-size: @subPanelHeadingFontSize;
                                    font-weight: 600;
                                    color: @darkTextColor;
                                    transition-duration: 0.3s;
                                    min-height: 20px;
        
                                    i {
                                      &.formio-collapse-icon {
                                        font-weight: 400;
                                        inset-inline-end: 0;
                                        font-size: 14px;
        
                                        &::before {
                                          content: "\F106";
                                        }
                                      }
                                      &.fa-question-circle {
                                        position: relative;
                                        margin-inline-start: 8px;
                                        display: flex;
                                      }
                                    }
                                    @media screen and (max-width: 580px) {
                                      font-size: 20px;
                                    }
                                    .formio-collapse-text {
                                      position: absolute;
                                      inset-inline-end: 18px;
                                      font-size: 14px;
                                      font-weight: normal;
                                    }
                                  }
                                  &::after {
                                    display: none;
                                  }
                                  &:last-child {
                                    padding: 0;
                                    border-radius: @roundedCorners;
                                    transition-duration: 0.3s;
                                    border: none;
        
                                    &::after {
                                      display: none;
                                    }
                                    > .card-title {
                                      i {
                                        &.formio-collapse-icon {
                                          &::before {
                                            content: "\F107";
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                                > .card-body {
                                  padding: @panelBodyPadding 0 0;
                                }
                              }
                            }
                          }
                          > .formio-component {
                            &.formio-component-panel {
                              &:first-child {
                                > .card {
                                  > .card-header {
                                    &::before {
                                      display: none;
                                    }
                                  }
                                }
                              }
                            }
                            &.formio-component-columns {
                              [class^="col-"] {
                                .formio-component-panel {
                                  &:first-child {
                                    > .card {
                                      > .card-header {
                                        &::before {
                                          display: none;
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                          @media screen and (max-width: 580px) {
                            padding: @panelPaddingMobile;
                          }
                        }
                      }
                      &.highlight-block {
                        > .card {
                          border: solid 1px @infoNotice;
                          box-shadow: 0px 0px 20px @infoNotice20;

                          > .card-header {
                            > .card-title {
                              color: @infoNotice;
                            }
                          }
                        }
                      }
                      &:first-child {
                        margin-top: 0;
                      }
                      &.mini-block {
                        > .card {
                          > .card-header {
                            padding: @miniPanelYPadding @panelBodyPadding calc(@panelHeadingBottomPadding - 3px);

                            > .card-title {
                              font-size: @miniPanelHeadingFontSize;
                            }
                            &:last-child {
                              padding: @miniPanelYPadding @panelBodyPadding;
                              transition-duration: 0.3s;
                            }
                          }
                        }
                      }
                      &.documents-tab-wrapping-panel {
                        > .card {
                          > .card-header {
                            padding-bottom: 0;
                          }
                          > .card-body {
                            display: flex;
                            gap: 2rem;
                            flex-wrap: wrap;

                            &::before,
                            &::after {
                              display: none;
                            }
                            > .formio-component-panel {
                              margin: 0 !important;
                              min-width: 260px;
                              flex-grow: 1;
                              flex-basis: calc(50% - 2rem);

                              > .card {
                                > .card-body {
                                  &:first-child {
                                    padding-top: 0;
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                    &.formio-component-panel[class*="roleapplicant"] {
                      > .card {
                        box-shadow: none;

                        > .card-header {
                          padding: 0px 0px 2.5rem;

                          .card-title {
                            font-size: @panelHeadingFontSize;
                            font-weight: 600;
                            color: @darkTextColor;
                            transition-duration: 0.3s;
                            min-height: 20px;
                          }
                        }
                        > .card-body {
                          padding: 0px;

                          > .formio-component.formio-component-panel,
                          > .formio-component.formio-component-columns > [class^="col-"] > .formio-component.formio-component-panel {
                            margin-top: 3.2rem;
                            margin-bottom: 0;

                            > .card {
                              box-shadow: 0px 0px 20px @black5Color !important;
                              background-color: @whiteColor !important;
                              border-radius: @roundedCorners;
                              border: none;
                              margin-top: 0px;

                              > .card-header {
                                padding: @panelHeadingPaddingY @panelBodyPadding 0 !important;
                                background-color: transparent;
                                margin: 0;
                                cursor: pointer;
                                width: 100%;

                                > .card-title {
                                  font-size: @panelHeadingFontSize !important;
                                  font-weight: 600;
                                  color: @darkTextColor;
                                  transition-duration: 0.3s;
                                  min-height: 20px;

                                  i {
                                    &.formio-collapse-icon {
                                      font-weight: 400;
                                      inset-inline-end: 0;

                                      &::before {
                                        content: "\F106";
                                      }
                                    }
                                    &.fa-question-circle {
                                      position: relative;
                                      margin-inline-start: 8px;
                                      display: flex;
                                    }
                                  }
                                  @media screen and (max-width: 580px) {
                                    font-size: 20px;
                                  }
                                  .formio-collapse-text {
                                    position: absolute;
                                    inset-inline-end: 28px;
                                    font-size: 16px;
                                    font-weight: normal;
                                  }
                                }
                                &::before {
                                  display: none !important;
                                }
                                &::after {
                                  content: "";
                                  display: block;
                                  height: 2px;
                                  background-color: @panelHeadingBottomBorderColor;
                                  margin-top: 20px;
                                  width: calc(100% + (@panelBodyPadding * 2));
                                  margin-inline-start: -@panelBodyPadding;
                                }
                                &:last-child {
                                  padding: @panelBodyPadding !important;
                                  transition-duration: 0.3s;

                                  &::after {
                                    display: none;
                                  }
                                  > .card-title {
                                    i {
                                      &.formio-collapse-icon {
                                        &::before {
                                          content: "\F107";
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                              > .card-body {
                                padding: @panelBodyPadding !important;

                                .formio-component {
                                  &.formio-component-panel {
                                    > .card {
                                      background-color: transparent;
                                      border: none;
                                      box-shadow: none !important;
                                      margin-top: 0;

                                      > .card-header {
                                        margin: 0;
                                        padding: 0 !important;
                                        background-color: transparent;
                                        width: 100%;

                                        > .card-title {
                                          font-size: @subPanelHeadingFontSize !important;
                                          font-weight: 600;
                                          color: @darkTextColor;
                                          width: 100%;

                                          i {
                                            box-shadow: none !important;

                                            &.fa-question-circle {
                                              position: relative;
                                              inset-inline-end: initial;
                                              top: initial;
                                              display: flex;
                                              margin-inline-start: 8px;
                                            }
                                          }
                                          .formio-collapse-icon {
                                            top: initial;
                                            font-size: 14px;
                                            inset-inline-end: initial;
                                            position: relative;
                                            margin-inline-end: 0;
                                            margin-inline-start: 6px;

                                            &::before {
                                              content: "\F106";
                                            }
                                          }
                                          .formio-collapse-text {
                                            position: relative;
                                            inset-inline-end: 0;
                                            width: fit-content;
                                            white-space: nowrap;
                                            inset-inline-start: initial;
                                            font-size: 14px;
                                            font-weight: normal;
                                            cursor: pointer;
                                            margin-inline-start: auto;
                                          }
                                        }
                                        &::before {
                                          display: none;
                                        }
                                        &::after {
                                          display: none;
                                        }
                                        &:last-child {
                                          padding: 0;
                                          border-radius: @roundedCorners;
                                          transition-duration: 0.3s;
                                          border: none;

                                          &::before {
                                            display: none;
                                          }
                                          > .card-title {
                                            .formio-collapse-icon {
                                              &::before {
                                                content: "\F107";
                                              }
                                            }
                                          }
                                        }
                                      }
                                      > .card-body {
                                        padding: @panelBodyPadding 0 0 !important;
                                      }
                                    }
                                    & + .formio-component-panel {
                                      margin-top: 2rem;
                                    }
                                  }
                                }
                                > .formio-component {
                                  &.formio-component-panel {
                                    &:first-child {
                                      > .card {
                                        > .card-header {
                                          &::before {
                                            display: none;
                                          }
                                        }
                                      }
                                    }
                                  }
                                  &.formio-component-columns {
                                    [class^="col-"] {
                                      .formio-component-panel {
                                        &:first-child {
                                          > .card {
                                            > .card-header {
                                              &::before {
                                                display: none;
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                                @media screen and (max-width: 580px) {
                                  padding: @panelPaddingMobile;
                                }
                              }
                            }
                            &:first-child {
                              margin-top: 0;
                            }
                          }
                          .formio-component.formio-component-tabs .formio-component.formio-component-panel {
                            > .card {
                              background-color: transparent;
                              border: none;
                              box-shadow: none !important;
                              margin-top: 0;

                              > .card-header {
                                margin: 0;
                                padding: 0 !important;
                                background-color: transparent;
                                width: 100%;

                                > .card-title {
                                  font-size: @subPanelHeadingFontSize !important;
                                  font-weight: 600;
                                  color: @darkTextColor;
                                  width: 100%;

                                  i {
                                    box-shadow: none !important;

                                    &.fa-question-circle {
                                      position: relative;
                                      inset-inline-end: initial;
                                      top: initial;
                                      display: flex;
                                      margin-inline-start: 8px;
                                    }
                                  }
                                  .formio-collapse-icon {
                                    top: initial;
                                    font-size: 14px;
                                    inset-inline-end: initial;
                                    position: relative;
                                    margin-inline-end: 0;
                                    margin-inline-start: 6px;

                                    &::before {
                                      content: "\F106";
                                    }
                                  }
                                  .formio-collapse-text {
                                    position: relative;
                                    inset-inline-end: 0;
                                    width: fit-content;
                                    white-space: nowrap;
                                    inset-inline-start: initial;
                                    font-size: 14px;
                                    font-weight: normal;
                                    cursor: pointer;
                                    margin-inline-start: auto;
                                  }
                                }
                                &::before {
                                  display: none;
                                }
                                &::after {
                                  display: none;
                                }
                                &:last-child {
                                  padding: 0;
                                  border-radius: @roundedCorners;
                                  transition-duration: 0.3s;
                                  border: none;

                                  &::before {
                                    display: none;
                                  }
                                  > .card-title {
                                    .formio-collapse-icon {
                                      &::before {
                                        content: "\F107";
                                      }
                                    }
                                  }
                                }
                              }
                              > .card-body {
                                padding: @panelBodyPadding 0 0 !important;
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
                &.pagename-off {
                  .formio-form {
                    > .formio-component {
                      &.formio-component-panel {
                        &[class*="roleapplicant"] {
                          display: contents;

                          > .card {
                            display: contents;

                            > .card-body:only-child {
                              display: contents;
                            }
                          }
                        }
                      }
                    }
                  }
                  .wizard-page {
                    padding-top: 6rem;
                  }
                  :is(.formio-form > .formio-component.formio-component-panel[class*="roleapplicant"] > .card > .card-body:only-child, .formio-form .wizard-page) {
                    > .formio-component {
                      &.formio-component-panel,
                      &.formio-component-tabs .card-body.tab-pane > .formio-component-panel,
                      &.formio-component-fieldset > fieldset > .fieldset-body > .formio-component-panel {
                        margin-top: 3.2rem;
                        margin-bottom: 0;

                        > .card {
                          box-shadow: 0px 0px 20px @black5Color;
                          background-color: @whiteColor;
                          border-radius: @roundedCorners;
                          border: none;
                          margin-top: 0px;

                          > .card-header {
                            padding: @panelHeadingPaddingY @panelBodyPadding 0;
                            cursor: pointer;
                            background-color: transparent;
                            margin: 0;
                            width: 100%;

                            > .card-title {
                              font-size: @panelHeadingFontSize;
                              font-weight: 600;
                              color: @darkTextColor;
                              transition-duration: 0.3s;
                              min-height: 20px;

                              i {
                                &.formio-collapse-icon {
                                  font-weight: 400;

                                  &::before {
                                    content: "\F106";
                                  }
                                }
                                &.fa-question-circle {
                                  position: relative;
                                  margin-inline-start: 8px;
                                  display: flex;
                                }
                              }
                              @media screen and (max-width: 580px) {
                                font-size: 20px;
                              }
                              .formio-collapse-text {
                                position: absolute;
                                inset-inline-end: 28px;
                                font-size: 16px;
                                font-weight: normal;
                              }
                            }
                            &::after {
                              content: "";
                              display: block;
                              height: 2px;
                              background-color: @panelHeadingBottomBorderColor;
                              margin-top: 20px;
                              width: calc(100% + (@panelBodyPadding * 2));
                              margin-inline-start: -@panelBodyPadding;
                            }
                            &:last-child {
                              padding: @panelBodyPadding;
                              transition-duration: 0.3s;

                              &::after {
                                display: none;
                              }
                              > .card-title {
                                i {
                                  &.formio-collapse-icon {
                                    &::before {
                                      content: "\F107";
                                    }
                                  }
                                }
                              }
                            }
                          }
                          > .card-body {
                            padding: @panelBodyPadding !important;

                            .formio-component {
                              &.formio-component-panel {
                                margin-bottom: 2rem;

                                > .card {
                                  background-color: transparent;
                                  border: none;
                                  margin-top: 0;
                                  box-shadow: none;

                                  > .card-header {
                                    margin: 0;
                                    padding: 0;
                                    background-color: transparent;
                                    width: 100%;

                                    > .card-title {
                                      font-size: @subPanelHeadingFontSize !important;
                                      font-weight: 600;
                                      color: @darkTextColor;
                                      width: 100%;

                                      i {
                                        box-shadow: none !important;

                                        &.fa-question-circle {
                                          position: relative;
                                          inset-inline-end: initial;
                                          top: initial;
                                          display: flex;
                                          margin-inline-start: 8px;
                                        }
                                      }
                                      .formio-collapse-icon {
                                        top: initial;
                                        font-size: 14px;
                                        inset-inline-end: initial;
                                        position: relative;
                                        margin-inline-end: 0;
                                        margin-inline-start: 6px;

                                        &::before {
                                          content: "\F106";
                                        }
                                      }
                                      .formio-collapse-text {
                                        position: relative;
                                        inset-inline-end: 0;
                                        width: fit-content;
                                        white-space: nowrap;
                                        inset-inline-start: initial;
                                        font-size: 14px;
                                        font-weight: normal;
                                        cursor: pointer;
                                        margin-inline-start: auto;
                                      }
                                    }
                                    &::before {
                                      display: none;
                                    }
                                    &:last-child {
                                      padding: 0;
                                      border-radius: @roundedCorners;
                                      transition-duration: 0.3s;
                                      border: none;
                                      
                                      > .card-title {
                                        .formio-collapse-icon {
                                          &::before {
                                            content: "\F107";
                                          }
                                        }
                                      }
                                    }
                                  }
                                  > .card-body {
                                    padding: @panelBodyPadding 0 0;
                                  }
                                }
                                & + .formio-component-panel {
                                  margin-top: 2rem;
                                }
                              }
                            }
                            > .formio-component {
                              &.formio-component-panel {
                                &:first-child {
                                  > .card {
                                    > .card-header {
                                      &::before {
                                        display: none;
                                      }
                                    }
                                  }
                                }
                              }
                              &.formio-component-columns {
                                [class^="col-"] {
                                  .formio-component-panel {
                                    &:first-child {
                                      > .card {
                                        > .card-header {
                                          &::before {
                                            display: none;
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                            @media screen and (max-width: 580px) {
                              padding: @panelPaddingMobile;
                            }
                          }
                        }
                        &.highlight-block {
                          > .card {
                            border: solid 1px @infoNotice;
                            box-shadow: 0px 0px 20px @infoNotice20;

                            > .card-header {
                              > .card-title {
                                color: @infoNotice;
                              }
                            }
                          }
                        }
                        &:first-child {
                          margin-top: 0;
                        }
                        &.mini-block {
                          > .card {
                            > .card-header {
                              padding: @miniPanelYPadding @panelBodyPadding calc(@panelHeadingBottomPadding - 3px);

                              > .card-title {
                                font-size: @miniPanelHeadingFontSize;
                              }
                              &:last-child {
                                padding: @miniPanelYPadding @panelBodyPadding;
                                transition-duration: 0.3s;
                              }
                            }
                          }
                        }
                        &.documents-tab-wrapping-panel {
                          > .card {
                            > .card-header {
                              padding-bottom: 0;
                            }
                            > .card-body {
                              display: flex;
                              gap: 2rem;
                              flex-wrap: wrap;

                              &::before,
                              &::after {
                                display: none;
                              }
                              > .formio-component-panel {
                                margin: 0 !important;
                                min-width: 260px;
                                flex-grow: 1;
                                flex-basis: calc(50% - 2rem);

                                > .card {
                                  > .card-body {
                                    &:first-child {
                                      padding-top: 0;
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                  > .formio-component-form {
                    margin-top: 6rem;
                  }
                }
                > .formio-component-form {
                  margin-top: 3rem;

                  > .formio-form {
                    > .formio-component-tabs {
                      > .card {
                        box-shadow: none;

                        > .card-header {
                          height: auto;
                          margin-bottom: 0;

                          ul {
                            &.card-header-tabs {
                              background-color: transparent;
                              height: auto;

                              li {
                                &.nav-item {
                                  flex: 0;
                                  height: auto;

                                  &::before {
                                    display: none;
                                  }
                                  a {
                                    &.nav-link {
                                      font-size: 21px;
                                      color: @accentColor40;
                                      font-weight: 600;
                                      padding-inline-start: 35px;
                                      padding-inline-end: 35px;
                                      border-top: none;
                                      border-top-left-radius: @roundedCorners;
                                      border-top-right-radius: @roundedCorners;

                                      &.active {
                                        background-color: @whiteColor;
                                        box-shadow: 0px -6px 20px @black2Color;
                                        color: @accentColor;
                                        border-bottom: none;
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                          & + .card-body {
                            &.tab-pane {
                              &.active {
                                border-top-left-radius: 0;
                              }
                            }
                          }
                        }
                        > .card-body {
                          &.tab-pane {
                            background-color: @whiteColor;
                            box-shadow: 0px 0px 20px @black5Color;
                            border-radius: @roundedCorners;
                            padding: @panelPadding;
                            margin-top: 0;
                          }
                        }
                      }
                    }
                    .formio-component {
                      &.formio-component-panel {
                        &.payment-form-component {
                          .formio-component-columns {
                            margin-top: 0;
                            align-items: center;

                            &[class*="cost"] {
                              .formio-component-htmlelement {
                                margin: 0;

                                &:first-child {
                                  margin: 0;
                                }
                                p {
                                  margin-bottom: 0;
                                }
                              }
                            }
                            &.formio-component-totalPrice {
                              .formio-component-htmlelement {
                                &:first-child {
                                  margin: 0;
                                }
                                h4 {
                                  font-weight: 600;
                                }
                                p {
                                  span {
                                    font-weight: 600;
                                  }
                                }
                              }
                            }
                          }
                          & ~ .formio-component.formio-component-columns.formio-component-conclusionRow {
                            padding-inline-start: 0 !important;
                          }
                        }
                        .formio-component {
                          &.formio-component-columns {
                            &.default-pay-form-payments {
                              .radio {
                                .form-check {
                                  padding-top: 0;
                                  cursor: pointer;
                                }
                              }
                              .formio-component {
                                &.formio-component-htmlelement {
                                  display: flex;

                                  .tile-text {
                                    display: flex;

                                    label {
                                      margin-bottom: 0;
                                    }
                                  }
                                }
                              }
                              > [class*="col-"] {
                                display: flex;
                              }
                            }
                            &.overlay-simple {
                              margin-top: 0;
                              min-height: 30px;
                              align-items: center;

                              .col-md-1 {
                                flex: 0;

                                .formio-component-checkbox {
                                  .form-check {
                                    &.checkbox {
                                      padding-top: 0;

                                      label {
                                        &.form-check-label {
                                          width: 14px !important;
                                          height: 14px;

                                          input {
                                            &.form-check-input {
                                              margin: 0;
                                              opacity: 1;
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                              .col-md-11 {
                                flex: auto;
                              }
                            }
                          }
                        }
                        &.formio-component-applicantPaymentFees {
                          .formio-component-columns {
                            margin-top: 0;
                            align-items: center;

                            .formio-component-htmlelement {
                              margin-top: 0;
                            }
                            &.formio-component-totalPrice {
                              .formio-component-htmlelement {
                                &:first-child {
                                  margin: 0;
                                }
                                h4 {
                                  font-weight: 600;
                                }
                                p {
                                  span {
                                    font-weight: 600;
                                  }
                                }
                              }
                            }
                          }
                        }
                        &.formio-component-applicantPaymentPayments {
                          > .card {
                            > .card-body {
                              > .formio-component-columns {
                                padding-bottom: 0;

                                .formio-component-checkbox {
                                  .checkbox {
                                    margin-top: -5px;
                                    padding-top: 0;

                                    .form-check-label {
                                      margin-top: 0;
                                      cursor: pointer;

                                      input {
                                        &[type="radio"] {
                                          appearance: none;
                                          margin-top: 0;
                                          cursor: pointer;

                                          &::before {
                                            -webkit-font-smoothing: antialiased;
                                            display: inline-block;
                                            font-style: normal;
                                            font-variant: normal;
                                            text-rendering: auto;
                                            line-height: 1;
                                            font-family: "Font Awesome 5 Pro";
                                            font-weight: 400;
                                            font-size: @defaultTextSize;
                                            content: "\f111";
                                            color: @accentColor50;
                                          }
                                          &:checked {
                                            &::before {
                                              content: "\f192";
                                              font-weight: 900;
                                              color: @accentColor;
                                            }
                                          }
                                          &:focus {
                                            outline: none;
                                          }
                                          &:hover {
                                            &::before {
                                              color: @accentColor;
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                          & + .formio-component-button {
                            button {
                              &.btn {
                                border-radius: @roundedCorners !important;
                                background-color: @accentColor;
                                padding-inline-start: 25px;
                                padding-inline-end: 25px;
                                font-weight: 600;
                                text-transform: none;
                                position: relative;
                                z-index: 1;

                                &:hover {
                                  box-shadow: 0 9px 20px @accentColor40 !important;
                                  opacity: 0.9;
                                }
                              }
                            }
                          }
                        }
                      }
                      &.formio-component-part-b-processing-tab {
                        & ~ .formio-component-panel {
                          margin-top: 0;

                          > .card {
                            .card-body {
                              box-shadow: none;

                              .formio-component {
                                &.formio-component-columns {
                                  &.default-pay-form-payments {
                                    & + .overlay-simple {
                                      display: none;
                                    }
                                  }
                                }
                              }
                            }
                          }
                          & + .formio-component-panel {
                            margin-top: 3rem;
                          }
                        }
                      }
                      &.formio-component-part-b-documents-tab {
                        & + .formio-component {
                          margin-top: 0;
                        }
                        & ~ .formio-component-panel {
                          .card {
                            background-color: transparent;

                            .card-header {
                                padding: 0;
                            }
                            .card-body {
                              padding-top: 2.5rem !important;
                            }
                          }
                        }
                      }
                      &.formio-component-part-b-data-tab {
                        & + .formio-component-button {
                          &[class*="print"] {
                            margin: 0 0 0;
                            display: flex;
                            justify-content: flex-end;

                            button {
                              border-radius: @roundedCorners !important;
                              background-color: @accentColor;
                              padding-inline-start: 25px;
                              padding-inline-end: 25px;
                              font-weight: 600;
                              text-transform: none;
                              position: relative;
                              z-index: 1;
                              height: 26px;
                              padding-top: 0;
                              padding-bottom: 0;
                              font-size: 12px;

                              &:hover {
                                box-shadow: 0 9px 20px @accentColor40 !important;
                                opacity: 0.9;
                              }
                            }
                          }
                          & + .formio-component {
                            &.formio-component-table,
                            &.formio-component-container > div > .formio-component-table {
                              &.formio-component-validationtable {
                                margin: 0 !important;

                                table {
                                  &.table {
                                    tbody {
                                      tr {
                                        td {
                                          &:first-child {
                                            flex: 2;

                                            .formio-component-htmlelement {
                                              .formio-component-checkbox {
                                                &.check-switch {
                                                  margin-top: calc(-5rem + 3px);

                                                  > div {
                                                    &:first-child {
                                                      margin-top: 0px;
                                                      margin-inline-end: 32px;

                                                      & + div {
                                                        flex: auto;

                                                        textarea {
                                                          width: 100%;
                                                          min-height: 64px;
                                                          padding: 4px 10px;
                                                          border: solid 1px @borderColor;
                                                          border-radius: @roundedCorners !important;

                                                          &:hover,
                                                          &:focus {
                                                            outline: none;
                                                          }
                                                          &::-webkit-resizer {
                                                            display: none;
                                                          }
                                                        }
                                                      }
                                                      &.form-check {
                                                        display: flex;
                                                        flex-direction: column;

                                                        > span {
                                                          margin-bottom: 0.75rem;
                                                          font-size: 14px;
                                                          color: @darkTextColor;
                                                          line-height: 1;
                                                        }
                                                        &.mt-3 {
                                                          margin-top: 0 !important;
                                                        }
                                                        &.mb-3 {
                                                          margin-bottom: 0 !important;
                                                        }
                                                      }
                                                    }
                                                  }
                                                  @media screen and (max-width: 768px) {
                                                    flex-direction: column;

                                                    > div {
                                                      &#datarejectionreason {
                                                        display: flex;
                                                        flex-direction: column;
                                                        margin-top: 3rem;
                                                        width: 200%;
                                                      }
                                                    }
                                                  }
                                                }
                                              }
                                            }
                                            & ~ td {
                                              flex: 1;
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                                @media screen and (max-width: 768px) {
                                  overflow: visible;
                                  border: none;
                                }
                              }
                            }
                          }
                          & + .formio-component-container {
                            &.formio-component-previewcontainer {
                              margin-top: calc(-2rem - 3px);
                            }
                          }
                        }
                        & ~ .formio-component-panel {
                          margin-top: 3rem;

                          .card {
                            .card-body {
                              box-shadow: none;
                            }
                          }
                          &:last-child {
                            .card {
                              margin-bottom: 0 !important;
                            }
                          }
                        }
                        & + .formio-component-previewcontainer {
                          margin-top: 0px;
                        }
                      }
                    }
                    > .formio-component {
                      &.formio-component-panel {
                        &[class*="formio-component-tab"] {
                          > .card {
                            > .card-header {
                              margin-top: 2rem;
                            }
                            > .card-body {
                              background: transparent;
                              box-shadow: none;
                              padding-top: 0;

                              > .formio-component {
                                &.formio-component-panel {
                                  margin-top: 3.2rem;
                                  margin-bottom: 0;

                                  > .card {
                                    box-shadow: none;
                                    background-color: transparent;

                                    > .card-header {
                                      padding-top: 0;
                                      padding-inline-start: 0;
                                      padding-inline-end: 0;
                                      padding-bottom: @panelHeadingBottomPadding;
                                      cursor: pointer;
                                      width: 100%;

                                      > .card-title {
                                        font-size: @panelHeadingFontSize;
                                        font-weight: 600;
                                        color: @darkTextColor;
                                        transition-duration: 0.3s;

                                        i {
                                          &.formio-collapse-icon {
                                            font-weight: 400;
                                            inset-inline-end: 0;

                                            &::before {
                                              content: "\F106";
                                            }
                                          }
                                        }
                                        @media screen and (max-width: 580px) {
                                          font-size: 20px;
                                        }
                                      }
                                      &:last-child {
                                        background: @whiteColor;
                                        box-shadow: 0px 0px 20px @black5Color;
                                        border-radius: @roundedCorners;
                                        padding: @panelBodyPadding !important;
                                        transition-duration: 0.3s;

                                        > .card-title {
                                          i {
                                            &.formio-collapse-icon {
                                              &::before {
                                                content: "\F107";
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                    > .card-body {
                                      background-color: @whiteColor;
                                      box-shadow: 0px 0px 20px @black5Color;
                                      border-radius: @roundedCorners;
                                      padding: @panelBodyPadding;

                                      .formio-component {
                                        &.formio-component-panel {
                                          > .card {
                                            background-color: transparent;
                                            border: none;
                                            margin-top: 0;

                                            > .card-header {
                                              margin: 0;
                                              padding: 0;
                                              background-color: transparent;

                                              > .card-title {
                                                font-size: @subPanelHeadingFontSize;
                                                font-weight: 600;
                                                color: @darkTextColor;

                                                i {
                                                  box-shadow: none !important;
                                                }
                                              }
                                            }
                                            > .card-body {
                                              padding: 2.7rem 0 0;
                                            }
                                          }
                                        }
                                      }
                                      @media screen and (max-width: 580px) {
                                        padding: @panelPaddingMobile;
                                      }
                                    }
                                  }
                                  &.highlight-block {
                                    > .card {
                                      > .card-header {
                                        > .card-title {
                                          color: @infoNotice;
                                        }
                                      }
                                    }
                                  }
                                  &:first-child {
                                    margin-top: 0;
                                  }
                                }
                                &.guide-form {
                                  margin-top: 9px;

                                  > div {
                                    > .formio-component {
                                      &.formio-component-panel {
                                        box-shadow: none;

                                        > .card {
                                          background-color: @whiteColor;
                                          box-shadow: 0px 0px 20px @black5Color;
                                          border-radius: @roundedCorners !important;
                                          padding: @panelBodyPadding;

                                          > .card-header {
                                            padding-top: 0;
                                            padding-inline-start: 0;
                                            padding-inline-end: 0;
                                            padding-bottom: calc(@panelPadding - 2px);

                                            > .card-title {
                                              font-size: @guidePanelHeadingFontSize;
                                              font-weight: 600;
                                              color: @darkTextColor;
                                            }
                                          }
                                          > .card-body {
                                            padding: 0;

                                            .card {
                                              background-color: transparent;
                                            }
                                          }
                                        }
                                        &:where(.formio-component-mandatoryRegistrationsPanel, .formio-component-optionalRegistrationsPanel) {
                                          .formio-component-htmlelement {
                                            &.formio-component-mandatoryRegistrationsContent {
                                              margin: 0 0 1rem !important;

                                              p {
                                                font-size: 13px !important;
                                                line-height: 1.1;
                                              }
                                            }
                                            &.formio-hidden {
                                              & + .formio-component-checkbox {
                                                .form-check {
                                                  &.checkbox {
                                                    padding-top: 0;

                                                    label {
                                                      &.form-check-label {
                                                        margin-top: -3px;
                                                      }
                                                    }
                                                  }
                                                }
                                              }
                                            }
                                          }
                                          .formio-component-checkbox {
                                            margin-top: 0;

                                            .form-check {
                                              &.checkbox {
                                                label {
                                                  &.form-check-label {
                                                    span {
                                                      font-size: 15px;
                                                      line-height: 20px;

                                                      &::before {
                                                        top: 3px;
                                                      }
                                                    }
                                                  }
                                                }
                                              }
                                            }
                                          }
                                        }
                                        &.formio-component-FeesPanel {
                                          > .card {
                                            > .card-header {
                                              line-height: 1;
                                              padding-bottom: 30px;

                                              &:last-child {
                                                padding-bottom: 0;
                                              }
                                              .card-title {
                                                i {
                                                  &::before {
                                                    color: @accentColor;
                                                  }
                                                }
                                              }
                                            }
                                            > .card-body {
                                              .formio-component-feesDescription {
                                                border: none;
                                                padding-top: 0;
                                              }
                                            }
                                          }
                                          &.formio-hidden {
                                            & + .formio-component-requiredDocumentsPanel {
                                              margin-top: 0;
                                            }
                                          }
                                        }
                                        &.formio-component-requiredDocumentsPanel {
                                          margin-top: 2rem;

                                          > .card {
                                            > .card-header {
                                              line-height: 1;
                                              padding-bottom: 30px;

                                              &:last-child {
                                                padding-bottom: 0;
                                              }
                                              .card-title {
                                                i {
                                                  &::before {
                                                    color: @accentColor;
                                                  }
                                                }
                                                span {
                                                  margin-inline-start: auto;
                                                  font-size: calc(@guidePanelHeadingFontSize - 7px);
                                                  margin-inline-end: 6px;
                                                }
                                              }
                                            }
                                            > .card-body {
                                              .formio-component-requiredDocumentsContainer {
                                                border: none;
                                                margin-top: -1px;

                                                .formio-component-htmlelement {
                                                  line-height: 1.2;
                                                  margin-bottom: 1rem;
                                                  padding-bottom: 1rem;
                                                  border-bottom: solid 1px @borderColor;
                                                  margin-top: 0;

                                                  li {
                                                    div {
                                                      line-height: 1.5;

                                                      a {
                                                        display: none; // remove if tooltip needed in the document name in the guide
                                                      }
                                                    }
                                                  }
                                                  &:last-child {
                                                    border: none;
                                                    padding: 0;
                                                    margin: 0;
                                                  }
                                                  &:first-child {
                                                    margin-top: 0;
                                                  }
                                                }
                                              }
                                            }
                                          }
                                          & ~ .formio-component-button {
                                            button {
                                              width: 100%;
                                            }
                                          }
                                        }
                                      }
                                      &.formio-component-button {
                                        margin-top: 2rem;
                                      }
                                    }
                                  }
                                  &.row {
                                    @media screen and (max-width: 980px) {
                                      display: grid;
                                      grid-template-columns: 1fr 1fr;

                                      &::before,
                                      &::after {
                                        display: none;
                                      }
                                      > div {
                                        &.col-md-3 {
                                          width: 100%;
                                          padding-inline-end: 0;
                                        }
                                        &.col-md-4 {
                                          padding-inline-end: 0;
                                          width: 100%;

                                          &::after {
                                            display: none;
                                          }
                                        }
                                        &.col-md-4 {
                                          grid-column: span 2;
                                          padding-inline-start: 0;
                                          padding-top: 24px;
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                          &:only-child {
                            > .card {
                              > .card-header {
                                margin-top: 3rem;
                              }
                              > .card-body {
                                > .formio-component.formio-component-panel {
                                  > .card {
                                    > .card-header {
                                      width: 100%;

                                      > .card-title {
                                        min-height: 20px;
                                        font-size: 21px;

                                        i {
                                          &.formio-collapse-icon {
                                            font-weight: 400;
                                            position: absolute;
                                            inset-inline-end: 0;
                                            top: 0;
                                            font-size: 23px;
                                          }
                                          &.fa-question-circle {
                                            position: relative;
                                            margin-inline-start: 8px;
                                            display: flex;
                                          }
                                        }
                                        .formio-collapse-text {
                                          position: absolute;
                                          inset-inline-end: 28px;
                                          font-size: 16px;
                                          font-weight: normal;
                                          inset-inline-start: initial;
                                        }
                                      }
                                    }
                                    .card-body {
                                      box-shadow: none;
                                      padding: 23px 0 0 0;
                                    }
                                    .card-header {
                                      .card-title {
                                        font-size: 21px;
                                      }
                                    }
                                  }
                                }
                                > .formio-component-hidden:first-child + .formio-component-panel,
                                > .formio-component-hidden:first-child + .formio-component-htmlelement.alert.alert-danger + .formio-component-panel {
                                  margin-top: 0;

                                  > .card {
                                    > .card-header {
                                      &::before {
                                        height: 0;
                                      }
                                    }
                                  }
                                }
                                > .formio-component-hidden:first-child + .formio-component-htmlelement.alert.alert-danger:not(.formio-hidden) {
                                  margin-top: 2rem !important;

                                  & ~ .alert.formio-hidden + .formio-component-panel {
                                    margin-top: 0;
                                  }
                                }
                                > .formio-hidden.alert + .formio-component:not(.formio-hidden):not(.formio-component-hidden) {
                                  margin-top: 0;

                                  > .card {
                                    > .card-header {
                                      &:last-child {
                                        padding-top: 20px;
                                        padding-inline-end: 20px;
                                        box-shadow: none;
                                        padding-bottom: @panelHeadingPaddingY !important;

                                        &::before {
                                          display: none;
                                        }
                                      }
                                      &:not(:last-child) {
                                        &::before {
                                          margin-bottom: 20px;
                                        }
                                      }
                                    }
                                  }
                                }
                                > .formio-component-panel {
                                  > .card {
                                    > .card-header {
                                      &:not(:last-child) {
                                        &::before {
                                          margin-bottom: 0;
                                        }
                                      }
                                      &:last-child {
                                        padding-inline-start: 0;
                                        padding-inline-end: 0;
                                      }
                                    }
                                  }
                                }
                                > .formio-hidden.alert + .formio-component:not(.formio-hidden):not(.formio-component-hidden) {
                                  margin-top: 0;

                                  > .card {
                                    > .card-header {
                                      &:last-child {
                                        padding-top: 20px;
                                        padding-inline-end: 20px;
                                        box-shadow: none;

                                        &::before {
                                          display: none;
                                        }
                                      }
                                      &:not(:last-child) {
                                        &::before {
                                          margin-bottom: 20px;
                                        }
                                      }
                                    }
                                  }
                                }
                                > .formio-component-panel {
                                  > .card {
                                    > .card-header {
                                      &:not(:last-child) {
                                        &::before {
                                          margin-bottom: 0;
                                        }
                                      }
                                      &:last-child {
                                        padding-inline-start: 0;
                                        padding-inline-end: 0;
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
                > .alert {
                  &.alert-success {
                    position: fixed !important;
                    top: 19px;
                    inset-inline-end: 0;
                    z-index: 99999;
                    width: auto;
                    max-width: 320px;
                    background: @successNotification !important;
                    color: @whiteColor!important;
                    padding: 1rem 2rem !important;
                    font-size: 16px !important;
                    inset-inline-end: 16px;
                    -webkit-animation-duration: 2s;
                    animation-duration: 2s;
                    -webkit-animation-delay: 1s;
                    animation-delay: 1s;
                    -webkit-animation-fill-mode: both;
                    animation-fill-mode: both;
                    -webkit-animation-name: fadeOut;
                    animation-name: fadeOut;

                    &::after {
                      display: none !important;
                    }
                  }
                  &.alert-danger {
                    --alert-color: 247, 95, 95;
                    bottom: auto;
                    top: 126px;
                    z-index: 99999;
                    transition-duration: 0.2s;
                    padding-inline-start: 1.5rem !important;
                    border-color: rgb(var(--alert-color)) !important;
                    box-shadow: 0 5px 20px rgba(var(--alert-color), 0.4);
                    inset-inline-end: calc(((100vw - 1200px) / 2) - 10px);
                    width: fit-content;

                    button {
                      &.close-button {
                        color: transparent;
                        position: relative;
                        top: -10px;
                        inset-inline-end: -10px;
                        border-radius: 0 6px 0 0;
                        height: 37px;

                        &::before {
                          font-family: 'Font Awesome 5 Pro';
                          font-weight: 300;
                          -moz-osx-font-smoothing: grayscale;
                          -webkit-font-smoothing: antialiased;
                          display: inline-block;
                          font-style: normal;
                          font-variant: normal;
                          text-rendering: auto;
                          line-height: 1;
                          content: "\F00D";
                          color: @darkTextColor;
                          position: absolute;
                          font-size: 16px;
                          top: calc(50% - 8px);
                        }
                        &:hover {
                          background: @accentColor10;
                        }
                      }
                    }
                    p {
                      font-weight: 600;
                      color: rgb(var(--alert-color));
                      line-height: 1.3 !important;
                    }
                    ul {
                      padding-inline-start: 14px;
                      overflow-y: auto;
                      width: 100%;
                      padding-inline-end: 16px;
                      max-height: ~"min(calc(100vh - 200px), 320px)";

                      li {
                        margin-bottom: 8px;
                        font-weight: 600;
                        transition-duration: 0.15s;

                        &:last-child {
                          margin-bottom: 0;
                        }
                        &:hover {
                          padding-inline-start: 0;
                          background: no-repeat;

                          &::marker {
                            content: "\f0a9";
                          }
                          span {
                            &::after {
                              display: none;
                            }
                          }
                        }
                        span {
                          margin-inline-start: 8px;
                          text-decoration: none;
                          display: block;

                          &:hover {
                            text-decoration: underline;
                          }
                        }
                        &::marker {
                          -moz-osx-font-smoothing: grayscale;
                          -webkit-font-smoothing: antialiased;
                          display: inline-block;
                          font-style: normal;
                          font-variant: normal;
                          text-rendering: auto;
                          line-height: 1;
                          font-family: 'Font Awesome 5 Pro';
                          font-weight: 400;
                          content: "\f06a";
                          color: rgb(var(--alert-color));
                        }
                      }
                      &::-webkit-scrollbar {
                        width: 6px;
                        height: 6px;
                      }
                      &::-webkit-scrollbar-thumb {
                        border-radius: 4px;
                        background-color: rgba(var(--alert-color), 0.8);
                        transition-duration: .15s;
                      }
                      &::-webkit-scrollbar-track {
                        background-color: rgba(var(--alert-color), 0.3);
                      }
                    }
                    @media screen and (max-width: 1200px) {
                      inset-inline-end: 2.4rem;
                    }
                  }
                }
                .formio-component {
                  &.hide:first-child + .formio-component:not(.hide, .formio-hidden) {
                    margin-top: 0px;
                  }
                }
                .tooltip {
                  .tooltip-inner {
                    max-width: 240px;
                    white-space: break-spaces;
                    width: max-content;
                  }
                }
              }
            }
          }
        }
      }
      .flatpickr-calendar {
        box-shadow: 0px 0px 20px @black5Color;
        border: solid 1px @borderColor;
        border-radius: @roundedCorners;

        .flatpickr-months {
          :where(.flatpickr-next-month, .flatpickr-prev-month) {
            padding: 5px 10px;
            display: flex;
            align-items: center;
            border-radius: @roundedCorners 0 0 0;
            position: initial;

            svg {
              fill: @accentColor;
            }
            &:hover {
              background-color: @accentColor10;

              svg {
                fill: @accentColor;
              }
            }
          }
          .flatpickr-next-month {
            border-radius: 0 @roundedCorners 0 0;
          }
          .flatpickr-month {
            position: initial;

            .flatpickr-current-month {
              padding-top: 0;
              position: initial;
              display: flex;
              align-items: center;
              width: 100%;

              .flatpickr-monthDropdown-months {
                flex: auto;
                line-height: 20px;
              }
              .numInputWrapper {
                flex: 1;
              }
              :where(.flatpickr-monthDropdown-months, .numInputWrapper) {
                font-weight: 600;
                font-size: 14px;
                color: @accentColor;
                display: inline-flex;
                height: 100%;
                padding: 0 12px;

                &:hover {
                  background-color: @accentColor10;
                }
              }
            }
          }
        }
        .flatpickr-innerContainer {
          .flatpickr-rContainer {
            .flatpickr-weekdays {
              .flatpickr-weekdaycontainer {
                .flatpickr-weekday {
                  font-weight: 600;
                  font-size: 14px;
                  color: @accentColor;
                }
              }
            }
            .flatpickr-days {
              .dayContainer {
                .flatpickr-day {
                  font-size: 12px;
                  border-radius: 0;
                  height: 32px;
                  line-height: 32px;
                  color: @accentColor;
                  border: none;

                  &:hover,
                  &:where(.today, .selected) {
                    background-color: @accentColor10;
                    border: none;
                  }
                  &.selected {
                    font-weight: 600;
                  }
                  &:where(.prevMonthDay, .nextMonthDay) {
                    color: @accentColor40;
                  }
                }
              }
            }
          }
        }
      }
    }
    // Editgrid and datagrid modals
    .formio-component-modal-wrapper-editgrid,
    .formio-component-modal-wrapper-datagrid {
      .formio-dialog-theme-default {
        padding-bottom: 15px;
        .formio-dialog-content {
          width: calc(100% - 130px);
          height: 100%;
          //Formio override
          font-family: "Open Sans", sans-serif;

          .formio-component-datagrid,
          .formio-component-editgrid {
            > table {
              margin-top: 15px;
            }
          }
          .btn {
            &.btn-success {
              &.formio-dialog-button {
                border-radius: @roundedCorners !important;
                background-color: @buttonAccentColor;
                padding-inline-start: 25px;
                padding-inline-end: 25px;
                font-weight: 500;
                text-transform: none;
                position: relative;
                z-index: 1;
                padding-top: 12px;
                padding-bottom: 12px;
                line-height: 1;
                color: @whiteColor;
                text-align: center;
                font-size: 16px;
                gap: 8px;
                &:hover {
                  box-shadow: 0 9px 20px @accentColor40 !important;
                  opacity: 0.9;
                }
              }
            }
          }
        }
      }
    }
    // Editgrid Pagination
    .editgrid-pagination {
      display: flex;
      align-items: center;
      margin-bottom: 10px;

      ul {
        list-style: none;
        padding: 0;
        display: flex;
        margin: 0;
        margin-inline-end: 10px;
        height: 36px;
        align-items: center;
      }
      .pager-button {
        margin-inline-end: 3px;
        border-radius: @roundedCorners;
        width: 36px;
        justify-content: center;

        &:last-child {
          margin-inline-end: 0px;
        }
        &.disabled {
          opacity: 0.5;
          pointer-events: none;
        }
      }
      li {
        padding: 0 10px;
        display: flex;
        height: 100%;
        align-items: center;
        cursor: pointer;
        background-color: #F5F6F9;
        font-size: 12px;
        font-weight: 600;
        color: @darkTextColor;

        &:hover {
          background-color: #E5E9EF;
        }
      }
      .current-page {
        background-color: #E5E9EF;
        font-weight: 900;
      }
      .first-page {
        border-radius: @roundedCorners 0 0 @roundedCorners;
      }
      .last-page {
        border-radius: 0 @roundedCorners @roundedCorners 0;
        margin-inline-end: 3px;
      }
      .results-selector {
        display: flex;
        align-items: center;
        background-color: #f5f6f9;
        padding-inline-start: 14px;
        border-radius: @roundedCorners;
        color: @darkTextColor;
        font-size: 12px;
        font-weight: 600;

        .dropdown-toggle {
          padding-inline-start: 10px;
          padding-inline-end: 10px;
          border-radius: 0 @roundedCorners @roundedCorners 0;
          background-color: transparent;
          color: @darkTextColor;
          font-size: 12px;
          font-weight: 900;

          &::after {
            -webkit-font-smoothing: antialiased;
            display: inline-block;
            font-style: normal;
            font-variant: normal;
            text-rendering: auto;
            line-height: 1;
            font-family: "Font Awesome 5 Pro";
            font-weight: 400;
            content: "\f078";
            margin-inline-start: 10px;
          }
          &:hover {
            background-color: #E5E9EF;
          }
        }
        .dropdown.open {
          .dropdown-toggle {
            border-radius: 0 @roundedCorners 3px 0;
            background-color: #E5E9EF;
          }
          .dropdown-menu {
            border-radius: 3px 3px @roundedCorners @roundedCorners;
          }
        }
        .dropdown-menu {
          min-width: 40px;
          padding: 0;
          width: 100%;
          background-color: #f5f6f9;
          box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.05);
          border: none;

          .dropdown-item {
            display: block;
            padding: 0 10px;
            min-height: 28px;
            text-decoration: none !important;
            color: @darkTextColor;

            &:hover {
              background-color: #E5E9EF;
            }
            &:first-child {
              border-radius: @roundedCorners @roundedCorners 0 0;
            }
            &:last-child {
              border-radius: 0 0 @roundedCorners @roundedCorners;
            }
          }
        }
      }
    }
    /* Business entity modal */
    #select-business-entity {
      background-color: @black5Color;

      .modal-dialog {
        .modal-content {
          box-shadow: 0px 0px 20px @black10Color;
        }
        .panel-heading {
          border-radius: @roundedCorners @roundedCorners 0 0;
          border-bottom: solid 2px #F3F4F5;
          padding: 16px 23px;

          .panel-title {
            font-size: @miniPanelHeadingFontSize;
            font-weight: 600;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .close {
              font-size: 28px;
              font-weight: 400;
              opacity: 0.7;
              line-height: 16px;
              display: flex;

              &:hover {
                opacity: 1;
              }
            }
          }
        }
        .panel {
          border-radius: 0 0 @roundedCorners @roundedCorners;
          background-color: @whiteColor;
          box-shadow: none;

          .panel-body {
            color: @darkTextColor;
            font-size: 16px;
            padding: 16px 23px;

            .radio {
              label {
                margin-top: 0;

                &::before {
                  display: none;
                }
                input {
                  &[type="radio"] {
                    appearance: inherit;

                    &::before {
                      -webkit-font-smoothing: antialiased;
                      display: inline-block;
                      font-style: normal;
                      font-variant: normal;
                      text-rendering: auto;
                      line-height: 1;
                      font-family: "Font Awesome 5 Pro";
                      font-weight: 400;
                      font-size: @defaultTextSize;
                      content: "\f111";
                      color: @accentColor50;
                    }
                    & + span {
                      font-size: @defaultTextSize;
                      font-weight: 600;
                      color: @accentColor50;
                      margin-inline-start: 3px;
                      position: relative;
                      top: 1px;
                      line-height: 14px;
                    }
                    &:checked {
                      &::before {
                        content: "\f192";
                        font-weight: 900;
                        color: @accentColor;
                      }
                      & + span {
                        color: @accentColor;
                      }
                    }
                    &:focus {
                      outline: none;
                    }
                  }
                }
                &:hover {
                  input {
                    &[type="radio"] {
                      appearance: inherit;

                      &::before {
                        color: @accentColor;
                      }
                      & + span {
                        color: @accentColor;
                      }
                    }
                  }
                }
              }
            }
            input.form-control {
              height: @fieldHeight;
              border: solid 1px @borderColor;
              border-radius: @roundedCorners !important;
              font-weight: 600;
              box-shadow: none;

              &:where(:hover, :focus) {
                outline: none !important;
              }
            }
          }
          .panel-footer {
            background-color: transparent;
            border: none;
            padding: 0 23px 16px;

            a {
              border-radius: @roundedCorners !important;
              background-color: @accentColor;
              padding-inline-start: 25px;
              padding-inline-end: 25px;
              font-weight: 600;
              text-transform: none;
              position: relative;
              z-index: 1;
              padding-top: 12px;
              padding-bottom: 12px;
              line-height: 1;
              color: white;
              text-align: center;
              font-size: 16px;
              border: none;
            }
          }
        }
      }
    }
    /* Readonly forms components */
    :is(.readonly-forms-block, .readonly-forms-block ~ .formio-component-panel, .formio-component-previewcontainer ~ .formio-component-panel, .formio-component-previewcontainer .formio-component-panel) {
      .card {
        background-color: transparent;
        border: none !important;
        margin-top: 0 !important;
        margin-bottom: 0 !important;
        box-shadow: none;

        > .card-header {
          margin: 0 !important;
          padding: 0 !important;
          background-color: transparent !important;

          > .card-title {
            font-size: 18px !important;
            font-weight: 600;
            color: @darkTextColor;
          }
        }
        > .card-body {
          &:not(:first-child) {
            padding: 1rem 0 0 !important;
          }
          .formio-component-panel + .formio-component-panel {
            margin-top: 3rem;
          }
        }
      }
    }
    .formio-component-container {
      & ~ .readonly-forms-block {
        margin-top: 3rem !important;
      }
      &.formio-component-previewcontainer {
        & ~ .formio-component-panel {
          margin-bottom: 0px;
          margin-top: 3rem !important;

          > .card {
            margin-bottom: 0px;
          }
        }
      }
    }
    .formio-component-part-b-data-tab + .formio-component-previewcontainer {
      margin-top: 0px;
    }
    .alert.alert-danger:not(.formio-hidden) ~ .formio-component-previewcontainer {
      margin-top: 3rem !important;
    }
    .row {
      &.formio-component {
        &.formio-component-columns {
          &.readonly-form-columns {
            align-items: center;
            margin-top: 0;
            border: solid 1px #e3e3e6;
            min-height: 39px;

            &:focus-within {
              z-index: 99;
            }
            &:nth-child(odd) {
              background-color: rgb(242, 242, 242);
            }
            &:last-child {
              border-bottom: solid 1px #e3e3e6 !important;
            }
            & + .formio-component {
              &.formio-component-columns {
                border-top: none;
              }
            }
            &.formio-hidden {
              & + .formio-component {
                &.formio-component-columns {
                  border-top: solid 1px #e3e3e6;
                }
              }
            }
            &:not(.formio-hidden) {
              & + .formio-hidden {
                & + .formio-component {
                  &.formio-component-columns {
                    border-top: none;
                  }
                }
              }
            }
            > div {
              height: 100%;
              padding: 6px 12px;
              min-height: 39px;
              display: flex;
              align-items: center;

              &.col-md-2 {
                &:first-of-type {
                  border-inline-end: solid 1px #e3e3e6;
                  flex: 0 0 25%;

                  & + div {
                    margin-inline-start: -1px;

                    .formio-component-htmlelement {
                      p, h1, h2, h3, h4, h5, h6 {
                        font-size: 15px;
                        font-weight: 500;
                        color: #404451;
                        margin-top: 0;
                        margin-top: 5px;

                        &:first-child {
                          &:empty {
                            & + :is(blockquote, p) {
                              margin: 0;
                            }
                          }
                        }
                        &:last-of-type {
                          margin-bottom: 0;
                        }
                      }
                      blockquote {
                        margin-inline-start: 0;
                        margin-inline-end: 0;
                        background: transparent !important;
                        padding: 0 !important;

                        &::after {
                          display: none !important;
                        }
                      }
                      &.notice {
                        padding: 0 !important;
                        background: transparent !important;
                        border: none;
                      }
                    }
                    &.col-md-10 {
                      &:nth-last-child(3) {
                        flex: auto;
                        margin-inline-end: 46px;

                        & + .col-md-2 {
                          &:nth-last-child(2) {
                            flex: 0;
                            order: 3;
                            margin-inline-start: auto;

                            > .formio-component-columns {
                              flex-direction: row-reverse;
                              gap: 12px;

                              &::before,
                              &::after {
                                display: none;
                              }
                            }
                          }
                        }
                      }
                    }
                    & + .col-md-2 {
                      min-width: fit-content;

                      .formio-component-columns {
                        align-items: center;

                        .formio-errors {
                          display: none;
                        }
                        > div {
                          &:first-child {
                            flex: 0;
                            padding: 0;
                            min-width: fit-content;

                            & + div {
                              padding: 0;
                              flex: auto;
                              position: absolute;
                              width: fit-content;
                              inset-inline-end: calc(100% + 14px);
                              isolation: isolate;
                              z-index: 9;
                              min-height: 0px;

                              &:focus-within {
                                width: clamp(320px, 30vw, 30vw);
                              }
                              [class*="_validation_reason"] {
                                > div {
                                  display: flex;
                                  justify-content: flex-end;

                                  textarea {
                                    margin: 0 !important;
                                    resize: vertical;
                                    width: 24px;
                                    height: 24px;
                                    transition-duration: 0.15s;

                                    &:focus {
                                      width: 100%;
                                      height: auto;
                                    }
                                  }
                                }
                                &:not(.formio-hidden) {
                                  > div[ref="element"] {
                                    padding: 10px;
                                    border-radius: @roundedCorners;

                                    &::after {
                                      content: "\f4a6";
                                      position: absolute;
                                      z-index: 99;
                                      width: 24px;
                                      height: 24px;
                                      border-radius: 12px;
                                      display: grid;
                                      place-items: center;
                                      cursor: pointer;
                                      outline: solid 4px white;
                                      font-family: 'Font Awesome 5 Pro';
                                      font-weight: 400;
                                      -moz-osx-font-smoothing: grayscale;
                                      -webkit-font-smoothing: antialiased;
                                      font-style: normal;
                                      font-variant: normal;
                                      text-rendering: auto;
                                      line-height: 1;
                                      font-size: 18px;
                                      background: white;
                                      padding: 2px;
                                      color: #d56d72;
                                      transition-duration: 0.15s;
                                      transition-property: color, background, outline;
                                    }
                                    &::before {
                                      content: "";
                                      position: absolute;
                                      width: 14px;
                                      height: 14px;
                                      border-radius: 3px;
                                      transform: rotate(45deg);
                                      inset-inline-end: -6px;
                                      z-index: -1;
                                      display: none;
                                      background: white;
                                      top: calc(50% - 7px);
                                      box-shadow: 0 4px 16px rgba(0, 0, 0, 0.4);
                                    }
                                    &:focus-within {
                                      background: white;
                                      box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);

                                      &::after {
                                        z-index: -1;
                                      }
                                      &::before {
                                        display: block;
                                      }
                                    }
                                    &:hover {
                                      &::after {
                                        color: #ffffff;
                                        background: #a3a3a3;
                                        outline: solid 4px #a3a3a3;
                                      }
                                    }
                                  }
                                }
                                &.required {
                                  label.field-required {
                                    position: absolute;
                                    inset-inline-end: 6px;
                                    width: 6px !important;
                                    margin: 0;
                                    height: 6px;
                                    background: @errorColor;
                                    border-radius: 6px;

                                    &::after {
                                      display: none;
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                    .formio-component-datagrid {
                      margin-top: 0;

                      [class*="_validation_reason"] {
                        &.required {
                          label.field-required {
                            position: absolute;
                            inset-inline-end: 6px;
                            width: 6px !important;
                            margin: 0;
                            height: 6px;
                            background: @errorColor;
                            border-radius: 6px;

                            &::after {
                              display: none;
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
              &.formio-errors {
                display: none;
              }
            }
            input {
              background: transparent !important;
              border: none;
              font-size: 15px;
              color: @darkTextColor;
              -webkit-text-fill-color: @darkTextColor;
              padding-top: 0;
              padding-bottom: 0;
              padding-inline-start: 0;
              height: 27px;

              &:hover {
                outline: none;
              }
              &:placeholder-shown {
                display: none;
              }
            }
            :where(.formio-component, .form-group) {
              width: 100%;

              &.formio-component-htmlelement {
                p {
                  font-size: 14px;
                  font-weight: 600;
                  color: @darkTextColor;
                }
              }
              &.formio-component-radio {
                .form-radio {
                  margin-top: 0;
                  padding-top: 0 !important;

                  :where(.form-check, .form-check-inline) {
                    display: none;
                    padding-bottom: 0 !important;

                    &.radio-selected {
                      display: block;
                      margin: 0;

                      label {
                        &.form-check-label {
                          margin-top: 0;
                          padding-inline-start: 0;
                          font-size: 15px;

                          input {
                            &.form-check-input {
                              display: none;
                            }
                          }
                          span {
                            margin-inline-start: 0 !important;
                          }
                        }
                      }
                    }
                  }
                }
                .form-text.text-muted {
                  display: none;
                }
                &.radio-switch {
                  .form-radio {
                    border: none !important;
                    height: auto !important;

                    :where(.form-check, .form-check-inline) {
                      display: none !important;
                      padding-bottom: 0 !important;

                      &.radio-selected {
                        display: block !important;
                        margin: 0 !important;

                        label {
                          &.form-check-label {
                            display: inline-block !important;
                            margin-top: 0 !important;
                            padding-inline-start: 0 !important;
                            font-size: 15px !important;

                            input {
                              &.form-check-input {
                                display: none;
                              }
                            }
                            span {
                              margin-inline-start: 0 !important;
                              display: block !important;
                              height: auto !important;
                              background: none !important;
                              color: @darkTextColor !important;
                              padding: 0 !important;
                              border: none !important;
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
              &.formio-component-datetime {
                .input-group {
                  display: flex;
                  align-items: center;

                  .input-group-append {
                    display: none;
                  }
                }
                .form-text.text-muted {
                  display: none;
                }
              }
              &.formio-component-textarea {
                .card {
                  &.card-body {
                    padding: 0;
                    box-shadow: none;
                    background: transparent;
                    margin: 0;

                    .formio-editor-read-only-content {
                      font-size: 15px;
                      font-weight: 500;
                      color: #404451;
                      white-space: initial;
                    }
                    &.mb-3 {
                      margin-bottom: 0 !important;
                    }
                  }
                }
                .form-text.text-muted {
                  display: none;
                }
                &[class*="_validation_reason"] {
                  height: auto;
                  padding: 0;
                  background: transparent;
                  border: none !important;

                  textarea {
                    &.form-control {
                      border: solid 1px rgba(64, 68, 81, 0.16);
                      outline: none;
                      border-radius: 6px !important;

                      &:where(:focus, :active) {
                        outline: none;
                        box-shadow: none;
                      }
                    }
                  }
                  &:hover {
                    outline: none;
                  }
                }
              }
              &.formio-component-select {
                .formio-choices {
                  .form-control {
                    &.dropdown {
                      background: transparent;
                      border: none;
                      outline: none;
                      height: 100%;
                      min-height: 26px;
                      line-height: 1;
                      display: flex;
                      align-items: center;
                      padding: 0;

                      .choices__list {
                        .choices__item {
                          span {
                            white-space: break-spaces;
                            font-size: 15px;
                            font-weight: 500;
                            color: #404451;
                          }
                          &.choices__item--selectable {
                            padding-inline-end: 0;
                            min-height: 30px;
                            display: flex;
                            align-items: center;
                          }
                        }
                      }
                    }
                  }
                }
                .formio-select-autocomplete-input {
                  display: none;
                }
                select {
                  border: none;
                  background: transparent;
                }
                .form-text.text-muted {
                  display: none;
                }
                &.formio-component-multiple {
                  .form-control.selection {
                    .choices__list--multiple {
                      flex: auto;
                      display: flex;
                      align-items: flex-start;
                      flex-direction: column;
                      justify-content: flex-start;

                      .choices__item {
                        margin: 0;
                        padding: 0;
                        background: transparent;
                        border: none;
                        min-height: 24px !important;

                        &::before {
                          content: " ";
                          width: 6px;
                          height: 2px;
                          border-radius: 6px;
                          background: #404451;
                          margin-inline-end: 6px;
                        }
                      }
                    }
                    .choices__input {
                      flex: 0;
                    }
                  }
                }
              }
              &.formio-component-datagrid {
                > table {
                  &.table {
                    &.datagrid-table {
                      > tbody {
                        > tr {
                          > td {
                            border: none;
                            padding: 0 !important;

                            .formio-component-table {
                              padding: 0 !important;

                              > table {
                                &.table {
                                  > tbody {
                                    > tr {
                                      align-items: center;
                                      margin: 0;
                                      display: grid;
                                      grid-auto-columns: 25% auto min-content;
                                      grid-auto-flow: column;
                                      position: relative;

                                      > td {
                                        display: flex;
                                        align-items: center;
                                        border: none !important;
                                        border-inline-end: solid 1px #e3e3e6 !important;
                                        border-bottom: solid 1px #e3e3e6 !important;
                                        padding: 0 1.2rem !important;
                                        align-self: stretch;

                                        > .formio-component:not(.formio-hidden) {
                                          min-height: 39px;
                                          place-items: center;
                                        }
                                        &:first-child {
                                          flex: 0 0 25%;

                                          & + td {
                                            padding: 0 !important;

                                            &:nth-last-child(3) {
                                              min-width: initial;
                                              max-width: initial;
                                              border-inline-end: none !important;
                                              padding-inline-end: 36px !important;

                                              > .formio-component:not(.formio-hidden) {
                                                display: flex;
                                              }
                                            }
                                            & + td {
                                              &:nth-last-child(2) {
                                                order: 3;
                                                width: fit-content;
                                                margin-inline-start: auto;

                                                > .formio-component-checkbox {
                                                  justify-content: flex-end;
                                                }
                                              }
                                            }
                                            .formio-component-htmlelement {
                                              p {
                                                font-size: 15px;
                                                font-weight: 500;
                                                color: #404451;
                                              }
                                            }
                                            > .formio-component:not(.formio-component-datagrid) {
                                              margin-inline-start: 1.2rem;
                                            }
                                            &:last-child {
                                              > .formio-component {
                                                &:not(.formio-hidden) {
                                                  display: flex;
                                                  padding-top: 5px;
                                                  padding-bottom: 5px;
                                                }
                                                &:where(.formio-component-radio, .formio-component-selectboxes) {
                                                  .form-radio {
                                                    margin-top: 0;

                                                    .form-check:first-child {
                                                      margin-top: 0;
                                                    }
                                                  }
                                                }
                                              }
                                            }
                                          }
                                          > .formio-component:not(.formio-hidden) {
                                            display: flex;
                                          }
                                        }
                                        &:nth-of-type(4) {
                                          padding: 0 !important;
                                          min-width: 0px;
                                          flex: auto;
                                          position: absolute;
                                          width: fit-content;
                                          inset-inline-end: 80px;
                                          isolation: isolate;
                                          z-index: 9;
                                          min-height: 0px;
                                          border: none !important;
                                          transform: translateY(-50%);
                                          top: 50%;

                                          [class*="_validation_reason"] {
                                            &:not(:empty) {
                                              margin: 0;

                                              textarea {
                                                margin: 0 !important;
                                                resize: vertical;
                                                width: 24px;
                                                height: 24px;
                                                transition-duration: 0.15s;
                                                transform: scale(0);

                                                &:focus {
                                                  width: 100%;
                                                  height: auto;
                                                  transform: scale(1);
                                                }
                                              }
                                            }
                                            &:not(.formio-hidden) {
                                              > div[ref="element"] {
                                                padding: 10px;
                                                border-radius: @roundedCorners;
                                                position: relative;

                                                &::after {
                                                  content: "\f4a6";
                                                  position: absolute;
                                                  z-index: 99;
                                                  width: 24px;
                                                  height: 24px;
                                                  border-radius: 12px;
                                                  display: grid;
                                                  place-items: center;
                                                  cursor: pointer;
                                                  outline: solid 4px white;
                                                  font-family: 'Font Awesome 5 Pro';
                                                  font-weight: 400;
                                                  -moz-osx-font-smoothing: grayscale;
                                                  -webkit-font-smoothing: antialiased;
                                                  font-style: normal;
                                                  font-variant: normal;
                                                  text-rendering: auto;
                                                  line-height: 1;
                                                  font-size: 18px;
                                                  background: white;
                                                  padding: 2px;
                                                  color: #d56d72;
                                                  transition-duration: 0.15s;
                                                  transition-property: color, background, outline;
                                                  inset-inline-start: calc(50% - 12px);
                                                  inset-block-start: calc(50% - 12px);
                                                }
                                                &::before {
                                                  content: "";
                                                  position: absolute;
                                                  width: 14px;
                                                  height: 14px;
                                                  border-radius: 3px;
                                                  transform: rotate(45deg);
                                                  inset-inline-end: -6px;
                                                  z-index: -1;
                                                  display: none;
                                                  background: white;
                                                  top: calc(50% - 7px);
                                                  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.4);
                                                }
                                                &:focus-within {
                                                  background: white;
                                                  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
                                                  margin-inline-end: 10px;

                                                  &::after {
                                                    z-index: -1;
                                                  }
                                                  &::before {
                                                    display: block;
                                                  }
                                                }
                                                &:hover {
                                                  &::after {
                                                    color: #ffffff;
                                                    background: #a3a3a3;
                                                    outline: solid 4px #a3a3a3;
                                                  }
                                                }
                                              }
                                            }
                                          }
                                          &:focus-within {
                                            width: clamp(320px, 30vw, 30vw);
                                          }
                                        }
                                        input {
                                          &:hover {
                                            outline: none;
                                          }
                                        }
                                        .formio-component {
                                          margin-top: 0;
                                        }
                                        &:last-child {
                                          border-inline-end: none !important;
                                        }
                                        .formio-component-htmlelement {
                                          p {
                                            text-align: start;
                                          }
                                        }
                                        .checkbox + .checkbox {
                                          margin-top: 0;
                                        }
                                      }
                                      &:last-child {
                                        > td {
                                          border-bottom: solid 3px #e3e3e6 !important;
                                        }
                                      }
                                      &:focus-within {
                                        z-index: 99;
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                          &:last-child {
                            > td {
                              .formio-component-table {
                                > table {
                                  &.table {
                                    > tbody {
                                      > tr {
                                        &:last-child {
                                          > td {
                                            border-bottom: none !important;
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
              &:where(.formio-component-textfield, .formio-component-number, .formio-component-currency, .formio-component-datetime) {
                .input-group {
                  height: auto;

                  :where(.input-group-prepend, .input-group-append) {
                    background: transparent;
                    border: none;

                    .input-group-text {
                      padding: 0;
                      font-size: 15px;
                      color: #404451;
                      margin-inline-end: 6px;
                    }
                  }
                }
                .field-wrapper {
                  > div {
                    &[style*="margin-inline-start: 33%;"] {
                      margin-inline-start: 0 !important;
                    }
                  }
                }
                .form-text.text-muted {
                  display: none;
                }
                :where(input, .input-group input) {
                  &.form-control {
                    &:where([type="text"], [type="search"], [type="phoneNumber"], [type="email"]) {
                      height: 28px !important;
                      border: none !important;
                      background-color: transparent !important;

                      &:where(:focus, :hover) {
                        outline: none;
                      }
                      &:disabled {
                        background-color: transparent !important;
                      }
                    }
                  }
                }
              }
              &.formio-component-selectboxes {
                .form-radio {
                  margin-top: 0;
                  display: flex;
                  align-items: baseline;
                  flex-wrap: wrap;
                  gap: 20px;

                  :where(.form-check, .form-check-inline) {
                    display: flex;
                    margin-bottom: 0;
                    margin-inline-end: 0;

                    &:not(.radio-selected) {
                      display: none;
                    }
                    label {
                      &.form-check-label {
                        margin: 0;

                        input {
                          display: none;

                          & + span {
                            display: none;
                          }
                          &[checked="true"] {
                            & + span {
                              display: flex;
                              font-size: 15px;
                              font-weight: 500;
                            }
                          }
                        }
                        &::before {
                          display: none;
                        }
                      }
                    }
                  }
                  .checkbox + .checkbox {
                    margin-top: 0;
                  }
                }
                > label {
                  display: none;
                }
              }
              &.formio-component-checkbox {
                &.check-switch {
                  .form-check.checkbox {
                    margin-top: 0;
                  }
                }
              }
              &.formio-component-file {
                > label {
                  display: none;
                }
                ul.list-group {
                  width: 100%;
                  padding-inline-end: 1.2rem;
                }
              }
              > .formio-component {
                &.formio-component-table {
                  table {
                    &.table {
                      table-layout: auto;
                    }
                  }
                }
              }
              select.formio-multiple-mask-select {
                appearance: none;
                background: transparent;
                border: none;
                width: auto;
                padding-inline-start: 0;

                &:hover {
                  outline: none;
                }
              }
            }
            > div[class^="col-"] {
              > .formio-component {
                &.formio-component-table {
                  margin-inline-start: -12px;
                  margin-inline-end: -12px;
                  margin-top: -7px;
                  margin-bottom: -7px;
                  width: calc(100% + 24px);

                  table {
                    &.table {
                      table-layout: auto;

                      tbody {
                        tr {
                          td {
                            padding: 6px 1.2rem;
                            border: solid 1px #e3e3e6 !important;

                            &:first-child {
                              vertical-align: middle;
                              width: 30%;

                              .formio-component-htmlelement {
                                p {
                                  text-align: start;
                                }
                              }
                            }
                            .formio-component {
                              margin: 0;

                              &.formio-component-table {
                                margin-inline-start: -12px !important;
                                margin-inline-end: -12px !important;
                                margin-top: -7px !important;
                                margin-bottom: -7px !important;
                                width: calc(100% + 24px);
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            &.type-selectboxes {
              > .col-md-10 {
                &:nth-last-child(3) {
                  min-width: 200px !important;
                  flex: 1 !important;
                  max-width: 40% !important;
                }
              }
            }
            label.field-required {
              display: none;

              &::after {
                display: none;
              }
            }
            &.type-editgrid {
              > .col-md-10 + .col-md-6.cols-grid-end {
                display: none;
              }
              &:has(.col-md-6.cols-grid-end) {
                .col-md-10 {
                  width: 100%;
                }
              }
            }
            &.type-file {
              strong {
                font-weight: 500;
              }
              ul.list-group {
                padding-inline-end: 0px;
              }
            }
          }
        }
      }
    }
    .error-page-container {
      width: 90%;
      max-width: 500px;
      margin: 0 auto;
      justify-content: space-between;
      background: @whiteColor;
      border-radius: @roundedCorners;
      padding: @panelPadding;
      box-shadow: 0px 0px 20px @black5Color;
      display: flex;
      gap: 12px;

      h1 {
        margin-top: 0;
      }
      .error-body {
        font-size: 14pt;
        line-height: 20pt;
      }
      a {
        border-radius: @roundedCorners !important;
        background-color: @accentColor;
        padding: 12px 25px;
        font-weight: 600;
        line-height: 1;
        color: @whiteColor;
        text-align: center;
        font-size: 16px;
        display: inline-block;
        margin-top: auto;
        width: fit-content;

        &:hover {
          text-decoration: none;
          box-shadow: 0 9px 20px @accentColor40 !important;
          opacity: 0.9;
        }
      }
      .error-page-message {
        display: flex;
        flex-direction: column;
        flex: 2.5;
      }
      .error-page-illustration {
        flex: 1;
        position: relative;

        span {
          position: absolute;
          font-size: clamp(18pt, 0.5vw + 3rem, 30pt);
          font-weight: 600;
          color: #334bb3;
          text-align: center;
          width: 86%;
          height: 32%;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        img {
          height: 100%;
        }
      }
    }
    /* Test */
    select:is(.form-control, .flatpickr-monthDropdown-months, .flatpickr-monthDropdown-months:hover) {
      -moz-appearance: none;
      -webkit-appearance: none;
      appearance: none;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512'%3E%3Cpath d='M310.6 246.6l-127.1 128C176.4 380.9 168.2 384 160 384s-16.38-3.125-22.63-9.375l-127.1-128C.2244 237.5-2.516 223.7 2.438 211.8S19.07 192 32 192h255.1c12.94 0 24.62 7.781 29.58 19.75S319.8 237.5 310.6 246.6z' fill='@{escHexAccentColor}'/%3E%3C/svg%3E");
      background-size: 8px;
      background-repeat: no-repeat;
      background-position: right 12px center;
      padding-inline-end: 32px;
    }
    select option {
      background: white;
      font-weight: normal;
    }
    .search-field {
      background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTMiIGhlaWdodD0iMTEiIHZpZXdCb3g9IjAgMCAxMyAxMSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0wIDVDMCAyLjIzODU5IDIuMjM4NTMgMCA1IDBDNy43NjE0NyAwIDEwIDIuMjM4NTkgMTAgNUMxMCA1LjkyNzYxIDkuNzQ3NDQgNi43OTYyNiA5LjMwNzI1IDcuNTQwODNMMTIuNTUzNSAxMC4wODIzTDExLjkzNyAxMC44Njk3TDguNzE0NiA4LjM0NjkyQzcuNzk5NDQgOS4zNjE5NCA2LjQ3NDI0IDEwIDUgMTBDMi4yMzg1MyAxMCAwIDcuNzYxNDEgMCA1Wk0xIDVDMSAyLjc5MDgzIDIuNzkwODkgMSA1IDFDNy4yMDkxMSAxIDkgMi43OTA4MyA5IDVDOSA3LjIwOTE3IDcuMjA5MTEgOSA1IDlDMi43OTA4OSA5IDEgNy4yMDkxNyAxIDVaIiBmaWxsPSIjMzczNzM3Ii8+Cjwvc3ZnPgo=);
      background-repeat: no-repeat;
      background-position: center right 10px;

      &::placeholder {
        color: @accentColor50;
      }
    }

    // Celia's revamp for Libya
    @menuBarColor: @accentColor;
    @primaryColor: @accentColor;

    :is(.demo-page-menu, .modern-theme-dashboard-menu) {
      background-color: @menuBarColor;
    }
    .warning-list[hidden].modern-warning-list {
      background-color: @dashboarNotifBg;
      box-shadow: -1200px 0 0 @dashboarNotifBg, -2400px 0 0 @dashboarNotifBg, 1200px 0 0 @dashboarNotifBg, 2400px 0 0 @dashboarNotifBg;

      li.several-msg {
        span {
          color: @primaryColor;
          font-weight: 500;
        }
        strong {
          color: @primaryColor;
        }
      }
    }
    .current-service-container {
      background: @menuBarColor !important;
      box-shadow: -320px 0 0 @menuBarColor, -400px 0 0 @menuBarColor, -800px 0 0 @menuBarColor, -1200px 0 0 @menuBarColor, -2400px 0 0 @menuBarColor, 320px 0 0 @menuBarColor, 400px 0 0 @menuBarColor, 800px 0 0 @menuBarColor, 1200px 0 0 @menuBarColor, 2400px 0 0 @menuBarColor !important;
    }
    .modern-theme-dashboard-menu {
      background-color: @menuBarColor !important;
    }
    .service-bar-user-lang-menu {
      ul {
        li {
          a {
            font-weight: 400 !important;
          }
        }
      }
    }
    #header {
      .secondary-menu {
        background-color: @menuBarColor;

        .contain-lg {
          nav.secondary-nav-container {
            .services-select {
              background-color: rgba(0, 0, 0, 0.1) !important;

              &.active {
                .text {
                  opacity: 1;
                }
                .search:focus + .text {
                  opacity: 0.15;
                }
              }
              .menu {
                .item {
                  font-size: 14px;
                  letter-spacing: 0;
                }
              }
            }
            .all-roles-menu {
              ul.secondary-nav {
                li.active a {
                  background-color: rgba(255, 255, 255, 0.1);
                  font-size: 14px !important;
                }
                li.togglers label.menu-toggler span.count-roles {
                  background-color: @primaryColor;
                }
                li.role-menu-item {
                  a {
                    span {
                      font-size: 14px;
                    }
                  }
                }
                .bots-toggler {
                  span {
                    font-size: 14px !important;
                  }
                }
              }
            }
          }
        }
      }
    }
    #base {
      #content {
        .demo-mode-heading {
          background-color: @dashboarNotifBg !important;

          span {
            font-weight: 500 !important;
            color: @primaryColor;
          }
        }
        .section-body {
          .contain-lg {
            #formio-form-wizard {
              .formio-form {
                nav {
                  ul.pagination {
                    li {
                      span.page-link {
                        color: @inactiveTabTextColor !important;
                        font-size: 24px !important;
                        font-weight: 500 !important;
                        padding: 1.7rem 50px !important;

                        &::before {
                          font-size: 24px !important;
                          border: none !important;
                          font-weight: 500 !important;
                          color: @menuBarColor;
                          line-height: 22px !important;
                          margin-inline-end: 4px !important;
                          color: @inactiveTabTextColor !important;
                        }
                        &.progress-complete {
                          &::before {
                            -moz-osx-font-smoothing: grayscale;
                            -webkit-font-smoothing: antialiased;
                            display: inline-block;
                            font-style: normal;
                            font-variant: normal;
                            text-rendering: auto;
                            line-height: 1;
                            font-family: 'Font Awesome 5 Pro';
                            font-weight: 400;
                            color: @menuBarColor !important;
                            content: "\F058" !important;
                            font-size: 21px !important;
                            margin-inline-end: 9px !important;
                          }
                        }
                        svg {
                          display: none !important;
                        }
                      }
                      &.active {
                        span.page-link {
                          color: @darkTextColor !important;

                          &::before {
                            color: @darkTextColor !important;
                          }
                        }
                      }
                    }
                  }
                }
                :where(.wizard-page .formio-component, .formio-component) {
                  label {
                    &.col-form-label {
                      font-size: 14px !important;
                      margin-bottom: 10px !important;
                      color: @labelColor !important;
                      font-weight: 500 !important;
                    }
                  }
                  input.form-control {
                    letter-spacing: 0 !important;
                    font-size: 16px;
                    font-weight: 400 !important;
                  }
                  &.formio-component-button {
                    button {
                      &.btn {
                        background-color: @primaryColor;
                      }
                    }
                  }
                  &.formio-component-editgrid {
                    > button {
                      &.btn {
                        background-color: @accentColor10;

                        &:hover {
                          background-color: @accentColor;
                        }
                      }
                    }
                    .list-group .list-group-item > .row:not(.formio-component-columns) > div {
                      padding-bottom: 10px;
                    }
                  }
                  &.formio-component-datagrid {
                    > table {
                      &.table {
                        &.datagrid-table {
                          :is(th, td):is(.checkbox, .radio, .button) {
                            display: table-cell;
                            box-shadow: none !important;
                          }
                          td.checkbox {
                            padding-inline: 28px !important;
                          }
                          tfoot {
                            tr {
                              td {
                                button {
                                  &.btn {
                                    &.formio-button-add-row {
                                      background-color: @accentColor10;

                                      &:hover {
                                        background-color: @accentColor;
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                  &.formio-component-checkbox {
                    .form-check.checkbox {
                      label.form-check-label {
                        span {
                          font-size: 16px !important;
                          color: @darkTextColor;
                          font-weight: 400 !important;
                        }
                        input.form-check-input:checked + span {
                          color: @darkTextColor !important;

                          &::before {
                            color: @menuBarColor !important;
                          }
                        }
                      }
                    }
                  }
                  &.formio-component-radio:not(.radio-switch) {
                    .form-radio.radio {
                      label.form-check-label {
                        span {
                          font-size: @defaultTextSize;
                          font-weight: 500;
                          color: @accentColor50;
                          margin-inline-start: 6px;
                          position: relative;
                          top: 2px;
                          line-height: 14px;
                        }
                        input.form-check-input[type="radio"] {
                          &::before {
                            font-size: @defaultTextSize;
                            content: "\f111";
                            color: @accentColor50;
                          }
                          &:disabled {
                            background: transparent !important;
                            opacity: .5 !important;

                            & + span {
                              opacity: .5;
                              pointer-events: none;
                            }
                          }
                        }
                        &:hover {
                          color: @accentColor;

                          :is(span, input.form-check-input[type="radio"]),
                          input.form-check-input[type="radio"]::before {
                            color: inherit !important;
                          }
                        }
                      }
                      .form-check {
                        &.radio-selected {
                          label.form-check-label {
                            color: @accentColor;

                            :is(span, input, input::before) {
                              color: inherit !important;
                            }
                          }
                        }
                      }
                    }
                  }
                  &.formio-component-panel {
                    > .card {
                      > .card-header {
                        > .card-title {
                          color: @primaryColor !important;
                          font-weight: 600 !important;
                        }
                      }
                    }
                  }
                  &.formio-component-select {
                    span.value {
                      font-size: 16px;
                      letter-spacing: 0;
                    }
                  }
                  button {
                    &.btn.formio-button-add-another {
                      border: solid 1px @darkTextColor !important;
                      color: @darkTextColor !important;

                      &:hover {
                        color: @whiteColor !important;
                        background-color: @darkTextColor !important;
                        box-shadow: 0 9px 20px @darkTextColor40 !important;
                      }
                    }
                  }
                  &.top-label-space {
                    padding-top: 29px !important;

                    &.required {
                      label {
                        &.field-required {
                          &.control-label--hidden {
                            top: 32px;
                          }
                        }
                      }
                    }
                  }
                  // Overrides
                  &.formio-component-columns > div[class^="col-"] .formio-component:first-of-type.top-label-space {
                    padding-top: 29px !important;
                  }
                }
              }
            }
            .dashboard {
              #tab-applications {
                #services-list {
                  .services-title-search-container {
                    .services-search-icon {
                      z-index: -1;
                    }
                    h2.big-title {
                      color: @primaryColor;
                      font-weight: 500;
                    }
                    #available-services-filter {
                      box-shadow: none;
                      transition-duration: 0.15s;

                      &:where(:focus, :hover) {
                        background-color: @whiteColor70;
                        box-shadow: 0 0 0 1px @menuBarColor;
                      }
                      &:hover {
                        opacity: 0.8;
                      }
                      &:where(:focus, :focus:hover) {
                        opacity: 1;
                      }
                    }
                    &:focus-within {
                      .services-search-icon {
                        z-index: 1;
                      }
                    }
                  }
                  .dashboard-tiles {
                    .dashboard-tile {
                      .service-icon {
                        span {
                          display: none;
                        }
                      }
                      .service-name {
                        color: @primaryColor;
                      }
                    }
                  }
                }
              }
            }
            #entreprise-grid {
              .table-responsive-container {
                table {
                  thead {
                    tr {
                      th {
                        letter-spacing: 0;
                      }
                    }
                  }
                  tbody {
                    tr {
                      td {
                        :is(span.label-status, span.label) {
                          letter-spacing: 0.6px;
                        }
                      }
                    }
                  }
                }
              }
            }
            .overview-panel {
              table.part-b-single-file-resume {
                thead {
                  tr {
                    th {
                      letter-spacing: 0;
                    }
                  }
                }
                tbody {
                  tr {
                    td {
                      file-status {
                        span.label {
                          padding: 3px 5px !important;
                          font-size: 12px;
                          margin-inline-end: 6px;
                          letter-spacing: 0.6px;
                          background-color: transparent;

                          &:not(:empty) {
                            display: flex;
                          }
                          &:where(.success, .label-success) {
                            color: @appStatusProcessed;
                          }
                          &:where(.info, .label-info) {
                            color: @appStatusPending;
                          }
                          &:where(.warning, .label-warning) {
                            color: @appStatusAction;
                          }
                          &:where(.danger, .label-danger) {
                            color: @appStatusRejected;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          #formio-form-role {
            .formio-form {
              > .formio-component-tabs {
                > .card {
                  > .card-header {
                    > .card-header-tabs {
                      li {
                        a {
                          color: @inactiveTabTextColor !important;
                          font-size: 24px !important;
                          font-weight: 500 !important;
                        }
                        &.active a {
                          color: @accentColor !important;
                        }
                      }
                    }
                  }
                  .formio-component-part-b-documents-tab ~ .formio-component {
                    #validationHeader {
                      .form-field-type-mycounter {
                        span.bullet {
                          line-height: 16px;
                        }
                      }
                      [id^="document-"] {
                        span.label {
                          padding: 4px 8px;
                        }
                      }
                    }
                  }
                  .formio-component-costtable {
                    table.table {
                      tbody {
                        tr {
                          td {
                            padding: 5px 0;

                            h4 {
                              font-size: 16px;
                              font-weight: 600;
                            }
                            [class*="totalPrice"] {
                              p {
                                font-weight: 600;
                              }
                            }
                          }
                        }
                      }
                    }
                    & ~ .formio-component-columns {
                      margin-top: 3rem !important;
                    }
                    & ~ .formio-component-confirmationRow {
                      padding: 0 !important;

                      article.margin-bottom-xxl {
                        margin-bottom: 0 !important;
                      }
                    }
                  }
                }
              }
            }
          }
          .readonly-form-columns {
            p:only-child {
              margin-top: 0;
            }
          }
        }
      }
    }
    #select-business-entity .modal-dialog {
      .panel-heading {
        .panel-title {
          color: @darkTextColor;
        }
      }
      .panel-body .radio label input[type="radio"] {
        &:checked::before {
          color: @primaryColor;
        }
        & + span {
          color: @darkTextColor !important;
        }
      }
      .panel .panel-footer a {
        background-color: @primaryColor;
      }
    }
    // Bootstrap override
    .radio + .radio, .checkbox + .checkbox {
      margin-top: initial;
    }
  }
}
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: @accentColor;
}
::-webkit-scrollbar-track {
  background-color: @accentColor40;
}
@-webkit-keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}
@keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}
@keyframes buttonProgress {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}
@-webkit-keyframes fadeOut {
  0% {opacity: 1;}
  100% {opacity: 0;}
}
@keyframes fadeOut {
  0% {opacity: 1;}
  100% {opacity: 0;}
}
@keyframes buttonFeedback {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  90% {
    -webkit-transform: scale(1.15);
    transform: scale(1.15);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes hglightComp {
  0% {
    background-color: rgba(255, 0, 59, 0.15);
    box-shadow: 0 0 0 12px rgba(255, 0, 59, 0.15);
  }
  100% {
    background-color: rgba(255, 0, 59, 0);
    box-shadow: 0 0 0 12px rgba(255, 0, 59, 0);
  }
}

// product list
.choose-product-btn {
  align-items: center !important;
  background-color: rgba(70,81,170,.1) !important;
  border: none !important;
  border-radius: 6px !important;
  color: #4651aa !important;
  display: inline-flex;
  font-size: 12px;
  font-weight: 600 !important;
  height: 40px;
  padding: 0.9rem 2.5rem;
  transition-duration: .3s;
  &:hover {
    background-color: #4651aa !important;
    box-shadow: 0 9px 20px rgba(70,81,170,.4)!important;
    color: #fff !important;
  }
}
